import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: [],
  error: null,
  projectEntity: null,
  projectDefaultLanguage: null
};

export const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
      state.entity = [];
      state.error = null;
    },
    endCall: (state, action) => {
      state.loading = false;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    getAllStrings: (state, action) => {
      state.string.entity = action.payload.data;
      state.string.error = null;
      state.string.loading = false;
      state.error = null;
    },
    getOne: (state, action) => {
      state.projectEntity = action.payload.data;
      state.projectDefaultLanguage = action.payload.defaulLanguage;
      state.error = null;
      state.loading = false;
    },
    getOneBySearch: (state, action) => {
      state.projectEntity = action.payload.data;
      state.projectDefaultLanguage = action.payload.defaulLanguage;
      state.error = null;
      state.loading = false;
    },
    clear: (state, action) => {
      state.projectEntity = null;
      state.projectDefaultLanguage = null;
      state.entity = [];
      state.loading = true;
      state.error = null;
    },
    create: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    remove: (state, action) => {
      state.entity.data = state.entity.data.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    removeProjectPermission: (state, action) => {
      state.projectEntity.userPermission = state?.projectEntity?.userPermission.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    update: (state, action) => {
      state.entity = state?.entity?.map(function (obj) {
        if (obj?.id === action?.payload?.id) {
          let object = obj;
          object = { ...obj, ...action.payload.data };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.error = null;
    },
    updateProjectPermission: (state, action) => {
      // state.projectEntity.userPermission = 
      // state.projectEntity.userPermission.find(function (obj) {
      //   return obj?.id === action?.payload?.id;
      // })?.permission = action?.payload?.data?.permission;

      const { id, data } = action.payload;
      // Find the index of the userPermission object to update
      const index = state.projectEntity?.userPermission?.findIndex(obj => obj.id === id);
      // If a matching userPermission object is found, update it
      if (index !== -1 && index !== undefined) {
        state.projectEntity.userPermission = state.projectEntity.userPermission.map((obj, i) => 
          i === index ? { ...obj, permission: data.permission } : obj
        );
      }
      state.loading = false;
      state.error = null;
    }
  }
});
