import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as projectsActions from "redux/app/projects/Actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntlMessage } from "util/getIntlMessage";
import MultiSelect from "components/forms/MultiSelect";
import { permissionOptionsForNonAdminUser } from "util/data/reactTableData";

const ProjectUserAddForm = ({ setModal }) => {
  const localizationMessage = useIntlMessage();
  const [projectListArray, setProjectListArray] = useState([]);

  const dispatch = useDispatch();

  const {
    projectEntity,
    project,
    loadingButton
  } = useSelector(
    state => ({
      projectEntity: state?.project?.entity,
      project: state?.project || {},
      loadingButton: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  //Initial State
  const InitialState = {
    userField: [],
    permissionField: "",
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    userField: Yup.array().min(1),
    permissionField: Yup.string().required(" required"),
  });

  const SubmitHandler = async values => {
    const payload = {
      userIds: values?.userField || [],
      permission: values.permissionField || "",
      projectId: project?.projectEntity?.id
    }
    dispatch(projectsActions.addUser(payload, setModal));
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });


  useEffect(() => {
    if (projectEntity.length > 0) {
      if (projectEntity) {
        const arr = [];
        projectEntity.map(data =>
          arr.push({ value: data?.id, label: data?.email })
        );
        setProjectListArray(arr);
      }
    }

  }, [projectEntity]);

  useEffect(() => {
    dispatch(projectsActions.getAllUnPermissionedUsers(project?.projectEntity?.id));
  }, [dispatch, project?.projectEntity?.id]);

  return (
    <>
      <form id="addProjectPermissionForm" className="" onSubmit={formMik.handleSubmit}>
        {/* ********* project field ******** */}
        <div className="form-group">
          <div className="form-group">
            <MultiSelect
              name="userField"
              options={projectListArray}
              formMik={formMik}
              ListItemName="project's" // optional prop, will be shown on select like, "6 {ListItemName} selected" ==> "6 project's selected"
              placeholderValue={localizationMessage("projects.selectUnassignedUserField.placeholder")} // optional prop, if not then placeholder will be "select item's"
              noOptionsMessage={() => localizationMessage("noItemsListed")}
            />
          </div>
        </div>
        {/* ********* permission field ******** */}
        <div className="form-group">
          <div className="form-group">
            <select
              name="permissionField"
              className={
                formMik.errors.permissionField &&
                  formMik.touched.permissionField
                  ? "is-invalid form-select"
                  : formMik.touched.permissionField
                    ? "is-valid form-select"
                    : "form-select"
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.permissionField}
            >
              <option value="">{localizationMessage("userAndPermission.selectPermissionField.placeholder")}</option>
              {permissionOptionsForNonAdminUser.map((item, i) => (
                <option value={item.value} key={i}>
                  {localizationMessage(item.label)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Button className="btn form-button c-primary"
          disabled={loadingButton}
          type="submit"
        >
          {localizationMessage("add")}
        </Button>
      </form>
    </>
  );
};

export default ProjectUserAddForm;
