import * as requestFromServer from "./Cruds";
import { stringsSlice } from "./Reducer";
import {
  toastLoadingStart,
  toastLoadingEnd,
  toastSuccess,
  toastError,
  clearToast,
  // clearToast
} from "helper/toast";
import * as commonStringActions from "redux/app/commonStrings/Actions";
import btnLoadingActions from "redux/btnLoading/actions";
import { getLocalizedString } from "util/getLocalizedString";

const { actions } = stringsSlice;

export const clear = () => dispatch => {
  dispatch(actions.clear());
};

export const setFetchingData = bool_val => dispatch => {
  dispatch(actions.setFetchingData(bool_val));
};



//download specificLanguageStrings for all platforms using specific projectLanguageId
export const downloadCurrent = (projLangId, projLangName, projName) => dispatch => {
  const toastId = "downloadCurrent";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .downloadCurrent(projLangId)
    .then(response => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response?.data])
      );
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${projName}.zip` || `translate${projLangName}.zip`);
      document.body.appendChild(link);
      link.click();
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.startDownloading"));
      dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

//download allLanguageStrings for all platforms using specific projectId
export const downloadAll = (projectId, projectName) => dispatch => {
  const toastId = "downloadAll";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .downloadAll(projectId)
    .then(response => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response?.data])
      );
      const link2 = document.createElement("a");
      link2.href = downloadUrl;
      link2.setAttribute("download", `${projectName}.zip` || "translateAll.zip");
      document.body.appendChild(link2);
      link2.click();
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.startDownloading"));
      dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// create a new string record
export const create = (data, resetForm) => dispatch => {
  const toastId = "random";
  dispatch(actions.startCallCreate());
  return requestFromServer
    .create(data)
    .then(response => {
      if(resetForm) resetForm();
      dispatch(actions.create({ data: response?.data }));
      toastSuccess(toastId, getLocalizedString("successMessages.recordAdded"));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));

      dispatch(actions.endCall());
    });
};

// add a new string record
export const addToString = (data) => dispatch => {
  // dispatch(actions.startCallCreate());

  dispatch(actions.create(data));
  // toastSuccess("random", getLocalizedString("successMessages.recordAdded"));
};

// update value of existing key-value pair/record
export const update = (stringKeyId, projectLanguageId, strValueId, payload, setIsEdit, whichToast) => async (dispatch) => {
  let toastId = "random";
  let success = false;
  if (whichToast === "toastPromise"){
    toastId = stringKeyId || "random";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  await dispatch(actions.startCallUpdate());
  await requestFromServer
    .update(stringKeyId, projectLanguageId, strValueId, payload)
    .then(async response => {
      success = true;
      if (response?.data?.affected === 1) {
        await dispatch(actions.update({ id: stringKeyId, data: payload }));
        if (whichToast === "toastSimple") toastSuccess(toastId, getLocalizedString("successMessages.processCompleted"));
        if (whichToast === "toastPromise") toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.processCompleted")
        );
        if (typeof setIsEdit === 'function') {
          setIsEdit(false);
        }
      }
    })
    .catch(async (error) => {
      success = false;
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      // const messageLocation = "--error from getAllByprojectLangaugeId--";
      // const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      // await dispatch(actions.catchError({ error: errorMessageWithLocation }));
      
      dispatch(actions.endCall());
    });
  return success;
};

// add string records manually or by copyPaste using textBox/editField
export const addBulk = (data, setBulkAddForm) => async (dispatch) => {
  const toastId = "addBulk";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCallAnother());
  await requestFromServer
    .addBulk(data)
    .then(async (response) => {
      const duplicateStrLength = response?.data?.DuplicateError?.length;
      const addedStrLength = response?.data?.stringArray?.length;
      const totalLength = duplicateStrLength + addedStrLength;
      if (duplicateStrLength !== 0 || addedStrLength !== 0) {
        const toastMessage = getLocalizedString(
          "successMessages.multipleRecordsAddedDynamicMessage",
          [ addedStrLength, totalLength, duplicateStrLength ]
        );
        toastLoadingEnd(
          toastId,
          "success",
          toastMessage,
          3000
        );
        dispatch(btnLoadingActions.setLoading(false));
        setBulkAddForm(false);
      } else {
        throw new Error("Invalid or empty content provided");
      }
      dispatch(getAllByprojectLangaugeId(data?.projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

// add string records by uploading file in .txt format
export const addFile = (data, setFileAddForm) => async (dispatch) => {
  const toastId = "addFile";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCallAnother());
  await requestFromServer
    .addFile(data)
    .then(async (response) => {
      const duplicateStrLength = response?.data?.DuplicateError?.length;
      const addedStrLength = response?.data?.stringArray?.length;
      const totalLength = duplicateStrLength + addedStrLength;
      let toastMessage = "";
      if (duplicateStrLength !== 0 || addedStrLength !== 0) {
        const toastMessage = getLocalizedString(
          "successMessages.multipleRecordsAddedDynamicMessage",
          [ addedStrLength, totalLength, duplicateStrLength ]
        );
        toastLoadingEnd(
          toastId,
          "success",
          toastMessage
        );
        await dispatch(btnLoadingActions.setLoading(false));
        setFileAddForm(false);
      } else {
        throw new Error("'Invalid file'/'empty content'");
      }
      await dispatch(getAllByprojectLangaugeId(data.get("projectLanguageId")));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
};

// get the string translation progress in percentage
export const getTranslationProgress = (projectId) => async (dispatch) => {
  const toastId = "allLanguageTranslateAll";
  let progress = 0;
  let timer; // Timer variable scoped to fetchData function

  const makeRequest = () => {
    return new Promise((resolve, reject) => {
      const checkProgress = () => {
        requestFromServer.getTranslationProgress(projectId)
          .then(res => {            
            progress = res?.data?.progress;
            if (progress >= 100) {
              toastLoadingEnd(
                toastId,
                "success",
                getLocalizedString("successMessages.translationCompleted")
              );
              resolve();
            } else {
              const formattedProgress = parseFloat(progress.toFixed(2));
              // Update toast with current progress
              const toastMessage = getLocalizedString(
                "successMessages.translationProgressDynamicMessage",
                [formattedProgress]
              );
              toastLoadingEnd(
                toastId,
                "info",
                toastMessage,
                false,
                false
              );
              timer = setTimeout(checkProgress, 3000); // Call checkProgress again after 3 seconds
            }
          })
          .catch(error => {
            const errorMessagre = error?.message;
            const errorMessage =
              error?.code === "ERR_NETWORK"
                ? "errorMessages.networkOrServerError"
                : error?.message || "errorMessages.somethingWentWrong";
            toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
            reject(error);
          })
      };

      // Initial call to checkProgress
      checkProgress();
    });
  };

  // Usage:
  await makeRequest()
    .then(() => {
      // translation completed successfully
      return true
    })
    .catch(error => {
      // translation failed
      return false
    });
};

// translate all strings of all languages for specific project
export const allLanguageTranslateAll = (
  payload,
  projectLanguageId
) => async dispatch => {
  const toastId = "allLanguageTranslateAll";
  dispatch(btnLoadingActions.setLoadingProgress(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .allLanguageTranslateAll(payload)
    .then(async response => {
      //wait for 3 seconds to get the progress.
      await new Promise(resolve => setTimeout(resolve, 3000));
      // call this get the translation progress in percentage
      const isProgressCompleted = await dispatch(getTranslationProgress(payload?.projectId));
      if(isProgressCompleted){
        dispatch(getAllByprojectLangaugeId(projectLanguageId));
      }
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
  dispatch(btnLoadingActions.setLoadingProgress(false));
};

// translate missing strings of all languages for specific project
export const allLanguageTranslateMissing = (
  payload,
  projectLanguageId
) => async dispatch => {
  const toastId = "allLanguageTranslateAll";
  dispatch(btnLoadingActions.setLoadingProgress(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .allLanguageTranslateMissing(payload)
    .then(async response => {
      //wait for 3 seconds to get the progress.
      await new Promise(resolve => setTimeout(resolve, 3000));
      // call this get the translation progress in percentage
      const isProgressCompleted = await dispatch(getTranslationProgress(payload?.projectId));
      if(isProgressCompleted){
        await dispatch(getAllByprojectLangaugeId(projectLanguageId));
      }
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
    dispatch(btnLoadingActions.setLoadingProgress(false));
};

// translate all strings of current/selected language for specific project
export const currentLanguageTranslateAll = payload => dispatch => {
  const toastId = "currentLanguageTranslateAll";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(
    toastId,
    getLocalizedString("infoMessages.processingRequest")
  );
  requestFromServer
    .currentLanguageTranslateAll(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.allStringsCurrentLanguageTranslated")
      );
      dispatch(getAllByprojectLangaugeId(payload?.projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// translate missing strings of current/selected language for specific project
export const currentLanguageTranslateMissing = payload => dispatch => {
  const toastId = "currentLanguageTranslateMissing";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(
    toastId,
    getLocalizedString("infoMessages.processingRequest")
  );
  requestFromServer
    .currentLanguageTranslateMissing(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.missingStringsCurrentLanguageTranslated")
      );
      dispatch(getAllByprojectLangaugeId(payload?.projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// delete string record from spicific/all language
export const remove = (payload) => async (dispatch) => {
  const min = 1;
  const max = 1000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const toastId = `removeString's_${randomNumber}`;
  let success = false;
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .remove(payload)
    .then(async (response) => {
      // await dispatch(actions.remove({ id: payload?.stringValueId }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      success = false;
    });
  return success;
};

export const removeAll = (projLangId, isDefaultLanguage) => async (dispatch) => {
  const toastId = `removeAllStrings_${projLangId}`;
  let success = false;
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .removeAll(projLangId)
    .then(async (response) => {
      if (isDefaultLanguage) {
        await dispatch(actions.removeAll());
      }
      else {
        await dispatch(getAllByprojectLangaugeId(projLangId));
      }
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsDeleted")
      );
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      success = false;
    });
  return success;
};


export const addStringToCommonStrings = (payload) => dispatch => {
  const toastId = payload?.key || "fetchingDataFromServer";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  dispatch(btnLoadingActions.setLoading(true));
  return requestFromServer
    .addStringToCommonStrings(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
      dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
      dispatch(btnLoadingActions.setLoading(false));
    });
};



export const getBothByprojectLangaugeId = (projectLanguageId, shouldLoading = false, whichToast = "toastSimple") => async (dispatch) => {
  let toastId = "fetchingDataFromServer";
  if (whichToast === "toastPromise") {
    toastId = projectLanguageId ? String(projectLanguageId) : "fetchingDataFromServer";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  // dispatch(actions.startCall());
  if (shouldLoading) dispatch(actions.setFetchingData(true));
  await requestFromServer
    .getAllByprojectLangaugeId(projectLanguageId)
    .then(async (response) => {
      // adding projectLanguageId in the redux for further use
      const result = response?.data?.map(record => ({
        ...record,
        projectLanguageId
      }));
      dispatch(actions.getAll({ data: result || [] }));

      // dispatch(actions.getAll({ data: response?.data || [] }));
      await dispatch(commonStringActions.getAll_CS_ByprojectLangaugeId(projectLanguageId, true, toastId, "toastPromise", actions, response?.data));

    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));

      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};


//get all strings of specific projectLanguageId
export const getAllByprojectLangaugeId = (projectLanguageId, shouldLoading = false, whichToast = "toastSimple") => dispatch => {
  let toastId = "fetchingDataFromServer";
  if (whichToast === "toastPromise"){
    toastId = projectLanguageId || "fetchingDataFromServer";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  // dispatch(actions.startCall());
  if (shouldLoading) dispatch(actions.setFetchingData(true));
  return requestFromServer
    .getAllByprojectLangaugeId(projectLanguageId)
    .then(response => {
      // adding projectLanguageId in the redux for further use
      const result = response?.data?.map(record => ({
        ...record,
        projectLanguageId
      }));
      dispatch(actions.getAll({ data: result || [] }));
      if (whichToast === "toastPromise") toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
      if (shouldLoading) dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const translateOne = (option, payload) => async (dispatch) => {
  let success = false;
  const toastId = `translateOne_{id}_{option}`
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .translateOne(option, payload)
    .then(async (response) => {
      // await dispatch(actions.removeAll());
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordTranslated")
      );
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      success = false;
    });
  return success;
};
