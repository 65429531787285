import React, { useState } from "react";
import PageTitle from "components/common/PageTitle";
import PageviewsChartWidget from "components/widgets/pageviewsChartWidget/PageviewsChartWidget";
import Select from "react-select";
import { DurationOption, ProjectOption } from "util/data/SelectMenuData";
import { Button } from "reactstrap";
import styled from "styled-components";

const Div = styled.div`
  padding: 0;
  margin: 40px 0px !important;
`;

function Analytics() {
  const analyticsOf = window.location.pathname.substring(11);

  if (analyticsOf === "projects") {
  } else if (analyticsOf === "string") {
  } else if (analyticsOf === "platforms") {
  } else if (analyticsOf === "logs") {
  } else {
  }

  // const projects = useSelector(state => state.project.value);
  // const platforms = useSelector(state => state.platform.value);
  // const string = useSelector(state => state.commonstring.value);
  // const logs = useSelector(state => state.logs.value);
  // const favourite = useSelector(state => state.project.value);
  // const store = useSelector(state => state.project.value);

  const [name, setname] = useState(ProjectOption[0].label);
  const [duration, setDuration] = useState();

  const Search = () => {
    if (!name || !duration) {
      document.getElementById("error-tag").style.display = "block";
    } else {
      document.getElementById("error-tag").style.display = "none";
    }
  };

  return (
    <>
      <PageTitle
        middle={true}
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Dashboard"
          },
          {
            name: analyticsOf
          },
          {
            name: "analytics"
          }
        ]}
      />

      <Div className="row m-0 minWidth-27">
        <div className="col-12 col-md-8 order-2 order-md-1 mb-2">
          <PageviewsChartWidget title={name} />
        </div>

        <div className="col-12 col-md-4 order-1 order-md-2 mb-2">
          <div className="roe-card-style h-100">
            <div className="roe-card-header" style={{ marginTop: "15px" }}>
              <span className="hash"># </span>Search {analyticsOf}
            </div>
            <div className="row m-0">
              <div className="roe-card-body col-12 col-sm-6 col-md-12 plr-10 _pb-10">
                <Select
                  placeholder={"Select " + analyticsOf}
                  options={ProjectOption}
                  onChange={e => {
                    setname(e.value);
                  }}
                />
              </div>

              <div className="roe-card-body col-12 col-sm-6 col-md-12 plr-10 _pb-10">
                <Select
                  placeholder="Duration"
                  options={DurationOption}
                  onChange={e => {
                    setDuration(e.value);
                  }}
                />
              </div>
            </div>
            <div className="row m-0">
              <div className="roe-card-body _fs-12 col-7 col-sm-6 col-md-12 plr-10 _pb-10">
                <div id="error-tag">
                  <span>All fields are required</span>
                </div>
              </div>

              <div className="roe-card-body col-5 col-sm-6 col-md-12 plr-10 _pb-10">
                <Button className="c-primary w-100" onClick={Search}>
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </>
  );
}

export default Analytics;
