import PageTitle from "components/common/PageTitle";
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import * as whatsnewVersionsActions from "redux/app/whatsnewVersions/Actions";
import WhatsNewTable from "views/app/projects/whatsnew/WhatsNewTable";
import * as projectActions from "redux/app/projects/Actions";
import ErrorComponent from "views/ErrorComponent";
import { useLayoutEffect } from "react";

function WhatsNew(props) {

  const dispatch = useDispatch();
  let projectId = props?.match?.params?.id;

  //redux
  const {
    project,
    projectLoading,
    whatsnewVersionLoading,
    whatsnewVersionError,
    reduxProjectId,
    projectError
  } = useSelector(
    state => ({
      project: state?.project?.projectEntity || null,
      projectLoading: state?.project?.loading,
      whatsnewVersionLoading: state?.whatsnewVersion?.loading,
      whatsnewVersionError: state?.whatsnewVersion?.error,
      reduxProjectId: state?.project?.projectEntity?.id || null,
      projectError: state?.project?.error
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(projectActions.getOne(projectId));
    dispatch(whatsnewVersionsActions.getAll(projectId));
  }, [dispatch, projectId]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      {useMemo(() => {
        return (
          <PageTitle
            title=""
            middle={true}
            className="plr-7 minWidth-28"
            breadCrumb={[
              {
                name: "Projects",
                localizationId: "projects.title"
              },
              {
                name: project?.name ? project?.name : "Project-Name"
              },
              {
                name: "Whats New",
                localizationId: "whatsNew.whatsNew"
              }
            ]}
          />
        );
      }, [project])}

      {useMemo(() => {
        return (
          (projectLoading || whatsnewVersionLoading) ? (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "50vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          ) : null
        );
      }, [whatsnewVersionLoading, projectLoading])}

      {/********************/}
      {useMemo(() => {
        if (!projectLoading && !whatsnewVersionLoading) {
          if (reduxProjectId && !(projectError || whatsnewVersionError)) {
            return <WhatsNewTable  projectId={projectId}/>;
          }
          else {
            return <ErrorComponent />;
          }
        }
      }, [reduxProjectId, projectLoading, whatsnewVersionLoading, projectError, whatsnewVersionError])}
    </>
  );
}

export default WhatsNew;
