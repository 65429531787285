import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.Project;
const API_STRING = ApiUrl.String;

// export function getAll() {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" }
//   });
// }

export function getAll() {
  return axios[API.GetAll.Method](API.GetAll.Url, {
    order: { createdAt: "ASC" }
  });
}

export function getAllProject(queryString) {
  let url = API.GetAllProject.Url;
  url = url.replace("##query##", `?${queryString}`);
  return axios[API.GetAllProject.Method](url,
    //    {
    //   order: { createdAt: "ASC" }
    // }
  );
}


export function getAllUnPermissionedProjects(id) {
  let url = API.GetAllUnPermissionedProjects.Url;
  url = url.replace("##paramid##", id);
  return axios[API.GetAllUnPermissionedProjects.Method](url);
}

export function getAllUnPermissionedUsers(id) {
  let url = API.GetAllUnPermissionedUsers.Url;
  url = url.replace("##paramid##", id);
  return axios[API.GetAllUnPermissionedUsers.Method](url);
}

export function removeProjectPermission(id) {
  let url = API.DeleteProjectPermission.Url;
  url = url.replace("##paramid##", id);
  return axios[API.DeleteProjectPermission.Method](url);
}

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function addUser(payload) {
  return axios[API.AddUser.Method](API.AddUser.Url, payload);
}

// export function getOne(id) {
//   return axios[API.GetOne.Method](API.GetOne.Url, {
//     order: { createdAt: "ASC" },
//     where: { id: id },
//     relations: [
//       "projectLanguages",
//       "translationService",
//       "projectLanguages.language",
//       "projectPlatforms",
//       "projectPlatforms.platform"
//     ]
//   });
// }

export function getOne(id) {
  let url = API.GetOne.Url;
  url = url.replace("##paramid##", id);
  return axios[API.GetOne.Method](url, {
    order: { createdAt: "ASC" },
    where: { id: id },
    relations: [
      "projectLanguages",
      "translationService",
      "projectLanguages.language",
      "projectPlatforms",
      "projectPlatforms.platform"
    ]
  });
}

export function getOneBySearch(id) {
  return axios[API.Search.Method](API.Search.Url, {
    order: { createdAt: "ASC" },
    where: { id: id },
    relations: [
      "projectLanguages",
      "translationService",
      "projectLanguages.language",
      "projectPlatforms",
      "projectPlatforms.platform"
    ]
  });
}

export function getAllStrings(id) {
  // return axios[API_STRING.GetAllByprojectLangaugeId.Method](`${API_STRING.GetAllByprojectLangaugeId.Url}/${id}`, {
  //   where: { id: id },
  // });
  return axios.get(`${API_STRING.GetAllByprojectLangaugeId.Url}/${id}`);
}

export function removeAll() {
  return axios[API.DeleteAll.Method](API.DeleteAll.Url);
}

export function remove(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}

export function Update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}
