import React, { useMemo, useState, useCallback, useRef } from "react";
import { useTable } from "react-table";
import Button from "components/button/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import classnames from "classnames";
import ProjectFormModal from "views/app/projects/ProjectFormModal";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as projectsActions from "redux/app/projects/Actions";
import paginationActions from "redux/pagination/actions";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TablePagination from '@mui/material/TablePagination';
import { debounce } from "lodash";
import { useIntlMessage } from "util/getIntlMessage";
import { status } from "helper/constant";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const ProjectsTable = props => {
  
  const localizationMessage = useIntlMessage();

  const dispatch = useDispatch();
  const { projectEntity, projectCount, buttonLoading, projectsPerPageDefault, languageLocale } = useSelector(
    state => ({
      projectEntity: state?.project?.entity?.data || [],
      projectCount: state?.project?.entity?.count,
      buttonLoading: state?.buttonLoading?.loading,
      projectsPerPageDefault: state?.pagination?.projectsPerPageDefault,
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;
  //form
  const [modal, setModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [row, setRow] = useState(null);
  const search_useRef = useRef("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(projectsPerPageDefault);
  const [searchText, setSearchText] = useState("");

  const findByTitle = async (value) => {
    const searchValue = value.toLowerCase();
    const payload = { take: rowsPerPage, skip: page * rowsPerPage, count: -1, order: "ASC", searchText: searchValue };
    const success = await dispatch(projectsActions.getAllProject(payload));
    if (success) {
      setPage(0);
    }
    setSearchText(searchValue);
  }

  const handleReset_searchField = () => {
    debouncedHandleSearch("");
    if (search_useRef.current) {
      search_useRef.current.value = ''; // Reset the value
    }
    setSearchText("");
  };

  const debouncedHandleSearch = debounce(findByTitle, 500);

  const formAction = useCallback(
    (action, data = null) => {
      const id = data?.id;
      if (action === "detail") {
        props.history.push({
          pathname: `/projects/${id}/detail`,
          state: { id: id, data: data }
        });
      } else if (action === "strings") {
        props.history.push({
          pathname: `/projects/${id}/strings`,
          state: { id: id, data: data }
        });
      } else if (action === "logs") {
        props.history.push({
          pathname: `/projects/${id}/logs`,
          state: { data: data }
        });
      } else if (action === "whatsnew") {
        props.history.push({
          pathname: `/projects/${id}/whatsnew`,
          state: { data: data }
        });
      } else if (action === "storelisting") {
        props.history.push({
          pathname: `/projects/${id}/storelisting`,
          state: { data: data }
        });
      } else if (action === "delete") {
        setRow(data);
        setDeleteConfirm(true);
      }
    },
    [props.history]
  );

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("serialNumber")}/>;
        },
        accessor: "id",
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              {((rowsPerPage * page) + parseInt(tableInstance?.row?.id)) + 1}
            </div>
          );
        }
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("projects.projectName")} />;
        },
        accessor: "name"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("projects.status")} />;
        },
        accessor: "status",
        Cell: tableInstance => (
          <div className="react-action-class">
            {
              tableInstance?.row?.original?.status === status.INACTIVE
              ?
              localizationMessage("inactive")
              :
              localizationMessage("active")
            }
          </div>
        )
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("projects.detail")} />;
        },
        accessor: "detail",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success c-btn-sm"
              onClick={() => formAction("detail", tableInstance?.row?.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-file-lines" />
              </div>
            </Button>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("projects.string")} />;
        },
        accessor: "strings",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info c-btn-sm"
              onClick={() => formAction("strings", tableInstance?.row?.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-s" />
              </div>
            </Button>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("projects.projectActivity")} />;
        },
        accessor: "logs",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-secondary c-btn-sm"
              onClick={() => formAction("logs", tableInstance?.row?.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-file-pen" />
              </div>
            </Button>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance?.column?.isSortedDesc}
              title={localizationMessage("projects.whatsNew")}
            />
          );
        },
        accessor: "whatsnew",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-warning c-btn-sm"
              onClick={() => formAction("whatsnew", tableInstance?.row?.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-n" />
              </div>
            </Button>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance?.column?.isSortedDesc}
              title={localizationMessage("projects.storelisting")}
            />
          );
        },
        accessor: "storelisting",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-dark c-btn-sm"
              onClick={() =>
                formAction("storelisting", tableInstance?.row?.original)
              }
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-file-arrow-down" />
              </div>
            </Button>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance?.column?.isSortedDesc}
              title={localizationMessage("delete")}
            />
          );
        },
        accessor: "delete",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">

            <Button
              className="c-btn c-danger c-btn-sm"
              // only below mentioned user's can have access to this button
              disabled={buttonLoading || !(isSuperAdmin || false)}
              onClick={() => {
                formAction("delete", tableInstance?.row?.original)
              }}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>
        )
      }
    ],
    [formAction, rowsPerPage, page, buttonLoading, isSuperAdmin, languageLocale]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: projectEntity.length > 0 ? projectEntity : [{
        "count": 0,
        "data": []
      }],
      columns: columns
    },
  );

  const handleChangePage = async (event, newPage) => {
    const payload = { take: rowsPerPage, skip: newPage * rowsPerPage, count: -1, order: "ASC", searchText: searchText };
    const success = await dispatch(projectsActions.getAllProject(payload));
    if (success) {
      setPage(() => newPage);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    const noOfRowsToShow = event?.target?.value;
    const payload = { take: noOfRowsToShow, skip: 0, count: -1, order: "ASC", searchText: searchText };
    const success = await dispatch(projectsActions.getAllProject(payload));
    if (success) {
      // setProjectRows
      // await dispatch(paginationActions.  .getAllProject(payload));
      await dispatch(paginationActions.setProjectRows(noOfRowsToShow));
      setRowsPerPage(parseInt(noOfRowsToShow, 10));
      setPage(0);
    }
  };

  return (
    <>
      <Modal
        isOpen={deleteConfirm}
        fade={false}
        className="mx-auto minWidth-28 maxWidth-45"
        toggle={() => setDeleteConfirm(!deleteConfirm)}
      >
        <ModalHeader toggle={() => setDeleteConfirm(!deleteConfirm)}>
          <span className="_mr-5">{localizationMessage("projects.deleteProject")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteConfirm(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            disabled={buttonLoading}
            className="btn ml-2 c-primary"
            onClick={async () => {
              setDeleteConfirm(false);
              const success_remove = await dispatch(projectsActions.remove(row.id));
              if (success_remove) {
                let payload = {};
                if (projectEntity?.length > 0) {
                  payload = { take: rowsPerPage, skip: page * rowsPerPage, count: -1, order: "ASC", searchText: searchText };
                }
                if (projectEntity?.length === 1 && page > 0) {
                  payload = { take: rowsPerPage, skip: (page - 1) * rowsPerPage, count: -1, order: "ASC", searchText: searchText };
                }
                const success_getAllProject = await dispatch(projectsActions.getAllProject(payload))
                if (success_getAllProject && projectEntity?.length === 1 && page > 0) setPage(page - 1);
              }

            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* add Project modal */}
      {modal && <ProjectFormModal setModal={setModal} modal={modal} rowsPerPage={rowsPerPage} setPage={setPage} handleReset_searchField={handleReset_searchField} />}

      <ReactTableWrapper {...props}>
        <div className="plr-7 minWidth-28">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header d-flex justify-content-between flex-wrap-reverse ">

              <input
                type="text"
                className="form-control mr-3"
                style={{maxWidth: "300px", minWidth: "200px"}}
                placeholder = {localizationMessage("projects.search.Placeholder")}
                onChange={(e) => debouncedHandleSearch(e.target.value)}
                ref={search_useRef}
              />
            {
              isSuperAdmin
              ?
              <div className="d-flex mb-2 mb-sm-0">
                <Button
                  className="c-btn my-1 ml-auto c-primary"
                  onClick={() => setModal(!modal)}
                  // only below mentioned user's can have access to this button
                  // disabled={buttonLoading || !(isSuperAdmin || false)}
                  disabled={buttonLoading}
                >
                  <i className="fas fa-plus _mr-10" />
                  {localizationMessage("projects.addProject")}
                </Button>
              </div>
              :
              null
            }
            </div>

            {/* search field is here */}
            

            {(projectEntity && projectEntity?.length > 0) ?
              <div className="table-container text-center overflow-auto">
                <table
                  border={1}
                  className="custom-react-table-theme-class"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps(
                              // header.getSortByToggleProps()
                            )}
                          >
                            <div>{header.render("Header")}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {rows?.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell, index) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
              </div> : null
            }

            {(projectEntity && projectEntity?.length > 0) ?
              <TablePagination
                SelectProps={{
                  disabled: buttonLoading
                }}
                backIconButtonProps={
                  buttonLoading
                    ? {
                      disabled: buttonLoading
                    }
                    : undefined
                }
                nextIconButtonProps={
                  buttonLoading
                    ? {
                      disabled: buttonLoading
                    }
                    : undefined
                }
                labelRowsPerPage={localizationMessage("pagination.rowsPerPage")} // Custom label
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={projectCount} //total no.of.rows
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              : null
            }
          </div>
        </div>
      </ReactTableWrapper>

      {
        projectEntity?.length === 0 && (searchText.length === 0)
        ?
          isSuperAdmin
          ? 
          <span className="text-center font-weight-bold text-muted mt-5 w-75 d-block mlr-auto">
            {localizationMessage("projects.addNewProjectRequestMessage")}
          </span>
          : 
          <span className="text-center font-weight-bold text-muted mt-5 w-75 d-block mlr-auto">
            {localizationMessage("projects.noProjectCreatedMessage")}
          </span>
        :
        null
      }

      {
        projectEntity?.length === 0 && (searchText.length > 0) ? (
          <span className="text-center font-weight-bold text-muted mt-5 w-75 d-block mlr-auto">
            {localizationMessage("projects.notFoundMessage")}
          </span>
        ) : null
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(ProjectsTable));
