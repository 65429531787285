import React, { useState } from "react";
import { withRouter } from "react-router";
import { connect, useDispatch } from "react-redux";
import ReactTableWrapper from "../../../../components/reacttable/reacttbl.style";
import "assets/customstyles/whatsnew.css";
import CardStoreListing from "views/app/projects/storelisting/CardStoreListing";
import CardStoreListingNew from "views/app/projects/storelisting/CardStoreListingNew";
import { useSelector, shallowEqual } from "react-redux";
import DropdownRedirection from "views/app/DropdownRedirection";
import * as storeListingActions from "redux/app/storelisting/Actions";
import { Button } from "reactstrap";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const StoreListingTable = ({projectId}) => {

  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  const { project, storeListingEntity } = useSelector(
    state => ({
      project: state?.project || {},
      storeListingEntity: state?.storeListing
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  /**** States ****/
  const [collapse, setCollapse] = useState(true);
  const [openGotoDropdown, setOpenGotoDropdown] = useState(false);

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  const redirectionDropdownItems = [
    {
      name: localizationMessage("projectString.strings"),
      redirect: `/projects/${projectId}/strings`
    },
    {
      name: localizationMessage("whatsNew.whatsNew"),
      redirect: `/projects/${projectId}/whatsnew`
    },
    {
      name: localizationMessage("logs.logs"),
      redirect: `/projects/${projectId}/logs`
    },
    {
      name: localizationMessage("projectDetail.detail"),
      redirect: `/projects/${projectId}/detail`
    }
  ];

  const TranslateAll = async () => {
    const projectDefaultLanguageId = project?.projectDefaultLanguage?.id;
    const projectId = project?.projectEntity?.id;
    const payload = {
      projectLanguageId: projectDefaultLanguageId
    };
    dispatch(storeListingActions.translateAll(payload, projectId));
  };

  //sort object with default language at top in new array using(isDefault) in this case
  var reorder = function (comparisonValue, originalArray) {
    var newArray = originalArray.slice(); // clone array
    newArray.sort(function (a) {
      return a.isDefault !== comparisonValue ? 1 : -1;
    }); // put in first
    return newArray;
  };

  return (
    <>
      {/*************** Page Header ***************/}
      <ReactTableWrapper>
        <div className="plr-7 minWidth-28">
          <div className="flex header flex-wrap">
            <div className="version-dropdown">
              <span className="display-5 font-weight-bold">{localizationMessage("storeListing.storeListing")}</span>
            </div>
            <div className="flex-grow-1 d-flex justify-content-end ms-2 mt-2 mt-sm-0 row">
              <div className="d-flex justify-content-end col-12 col-sm-auto p-sm-0 mr-0 mr-sm-2">
                {
                  (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
                  !storeListingEntity?.isNew 
                  ? 
                  <Button
                    className="fs-12 c-primary ms-2 "
                    onClick={() => TranslateAll()}
                  >
                    {localizationMessage("translateAll")}
                  </Button>
                  : 
                  null
                }
                <div className="ms-2">
                  <DropdownRedirection 
                    openDropdown={openGotoDropdown}
                    setOpenDropdown={setOpenGotoDropdown}
                    dropdownTitle={localizationMessage("goto")}
                    items={redirectionDropdownItems}
                  />
                </div>
              </div>
              {
                !storeListingEntity?.isNew ? 
                  <div className="add-btn d-flex justify-content-end col-12 col-sm-auto mt-2 mt-sm-0 p-sm-0">
                    <button
                      onClick={() => {
                        setCollapse(!collapse);
                      }}
                    >
                      <p className="_ml-10">
                        {!collapse ? (
                          <>
                            {localizationMessage("collapseAll")}
                            <i className="fas fa-angle-up" />
                          </>
                        ) : (
                          <>
                            {localizationMessage("expandAll")}
                            <i className="fas fa-angle-down" />
                          </>
                        )}
                      </p>
                    </button>
                  </div>
                : null
              }
            </div>
          </div>

          {/*************** New Store Lisitng ***************/}
          {
            storeListingEntity?.isNew
            ?
              (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) 
              ? 
              <CardStoreListingNew />
              :
              <div className="text-center font-weight-bold text-muted mt-5">
                {localizationMessage("storeListing.notFoundMessage")}
              </div>
            :
            null
          }
          
          {/*************** Saved Lisitng ***************/}
          {!storeListingEntity?.isNew &&
            reorder(1, storeListingEntity.entity).map((item, i) => {
              return (
                <div key={i}>
                  <CardStoreListing parentCollapse={collapse} entity={item} />
                </div>
              );
            })}
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(StoreListingTable));
