import React from "react";
import "assets/customstyles/platforms.css";
import { Input } from "reactstrap";
import * as stringActions from "redux/app/strings/Actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "reactstrap/lib/Button";
import { useIntlMessage } from "util/getIntlMessage";

const CommentFormModal = ({ object, setCommentModal, isDefaultLanguage }) => {
  const localizationMessage = useIntlMessage();

  const dispatch = useDispatch();

  //redux
  const {
    loadingUpdate,
    projectDefaultLanguage,
  } = useSelector(
    state => ({
      loadingUpdate: state?.string?.loadingUpdate,
      projectDefaultLanguage: state?.project?.projectDefaultLanguage
    }),
    shallowEqual
  );

  //Initial State
  const InitialState = {
    comment: object?.comment?.length > 0 ? object?.comment : ""
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    comment: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    //add/update comment
    const payload = {
      key: object?.key,
      value: object?.value,
      comment: formMik.values.comment,
      type: object?.type
    }
    const success = await dispatch(stringActions.update(object?.stringKeyId, object?.projectLanguageId, object?.stringValueId, payload, setCommentModal, "toastPromise"));
    if (success) {
      dispatch(
        stringActions.getAllByprojectLangaugeId(
          object?.projectLanguageId
        ));
    }
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  const deleteComment = async () => {
    const payload = {
      key: object?.key,
      value: object?.value,
      comment: "",
      type: object?.type
    }
    const success_remove = await dispatch(stringActions.update(object?.stringKeyId, object?.projectLanguageId, object?.stringValueId, payload, setCommentModal, "toastPromise"));
    if (success_remove) {
      dispatch(
        stringActions.getAllByprojectLangaugeId(
          object?.projectLanguageId
        ));
    }
  }

  return (
    <>
      <form id="commentFormModal" className="_ml-10 _mr-10 _mt-10 mb-0" onSubmit={formMik.handleSubmit}>
        <div className="form-group">
          <Input
            name="comment"
            placeholder={localizationMessage("commentFieldPlaceholder")}
            disabled={!object?.value && !isDefaultLanguage()}
            className={
              formMik.errors.comment && formMik.touched.comment
                ? "is-invalid"
                : formMik.touched.comment
                  ? "is-valid"
                  : ""
            }
            onChange={formMik.handleChange}
            onBlur={formMik.handleBlur}
            value={formMik.values.comment}
          />
        </div>

        <div className="flex">
          <Button
            className="btn ml-auto c-primary ptb-5 font-weight-normal _fs-16"
            // disabled={loadingUpdate}
            disabled={(!object?.value && !isDefaultLanguage()) || loadingUpdate}
            onClick={() => { object?.comment?.length > 0 ? deleteComment() : setCommentModal(false) }}
          >
            {
              object?.comment?.length > 0 ? 
              localizationMessage("delete")
              : 
              localizationMessage("cancel")
            }
          </Button>
          <Button
            className="btn ml-2 c-primary ptb-5 font-weight-normal _fs-16"
            // disabled={loadingUpdate}
            disabled={(!object?.value && !isDefaultLanguage()) || loadingUpdate}
            type="submit"
          >
            {
              object?.comment?.length > 0 ? 
              localizationMessage("update")
              : 
              localizationMessage("add")
            }
          </Button>
        </div>
      </form>
    </>
  );
};

export default CommentFormModal;
