import React, { useLayoutEffect } from "react";
import PageTitle from "components/common/PageTitle";
import * as translationServiceProviderActions from "redux/app/translation-service-provider/Actions";
import "assets/customstyles/projects.css";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ViewTranslationServiceProviderDetail from "./ViewTranslationServiceProviderDetail";
import { useMemo, useRef } from "react";
import Spinner from "reactstrap/lib/Spinner";
import ErrorComponent from "views/ErrorComponent";

const ViewTranslationServiceProvider = (props) => {
  const dispatch = useDispatch();
  let isUseEffectCalled = useRef(false);
  const translationServiceId = props?.match?.params?.id;

  const { providerEntity, providerLoading, providerError } = useSelector(
    state => ({
      providerEntity: state?.translationServiceProvider?.translationServiceProviderEntity || null,
      providerLoading: state?.translationServiceProvider?.loading,
      providerError: state?.translationServiceProvider?.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    isUseEffectCalled.current = true;
    dispatch(translationServiceProviderActions.getOne(translationServiceId));
  }, [dispatch, translationServiceId]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <div>
      {useMemo(() => {
        return (
          <PageTitle
            title=""
            middle={true}
            className="plr-7 minWidth-28"
            breadCrumb={[
              {
                name: "Translation Service Provider",
                localizationId: "translationServiceProvider.title"
              },
              {
                name: providerEntity?.name ? providerEntity?.name : "Provider-Name"
              },
              {
                name: "Detail",
                localizationId: "translationServiceProvider.detail"
              }
            ]}
          />
        );
      }, [providerEntity])}

      {useMemo(() => {
        return (
          providerLoading && (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "50vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        );
      }, [providerLoading])}

      {useMemo(() => {
        if (!providerLoading && isUseEffectCalled.current) {
          if (providerEntity) {
            return <ViewTranslationServiceProviderDetail providerEntity={providerEntity} />;
          }
          if (providerError && !providerEntity) {
            return <ErrorComponent />;
          }
        }
      }, [providerEntity, providerLoading, providerError])}
    </div>
  );
};

export default ViewTranslationServiceProvider;
