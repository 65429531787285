import React from "react";
import { loginBack, ForgotIcon } from "helper/constant";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { LoadingButton } from "@mui/lab";
import AuthActions from "redux/auth/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const ForgotPassword = props => {
  let { authLoading } = useSelector(
    state => ({
      authLoading: state?.auth?.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const { enqueueSnackbar } = useSnackbar();

  const SubmitHandler = async values => {
    dispatch(AuthActions.setLoading(true));
    const auth = getAuth();
    await sendPasswordResetEmail(auth, values.email)
      .then(response => {
        enqueueSnackbar(
          " please check your email, reset email has been sent successfully ",
          { variant: "success" }
        );
      })
      .catch(error => {
        enqueueSnackbar(" please enter corret email address ", {
          variant: "error"
        });
      });
    dispatch(AuthActions.setLoading(false));
  };

  // form field validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email address")
      .required("Email is required!")
  });

  //formMik Handler
  const formMik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 _mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form className="pa-24" onSubmit={formMik.handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              className={
                formMik.errors.email && formMik.touched.email
                  ? "is-invalid form-control "
                  : formMik.touched.email
                  ? "is-valid form-control "
                  : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.email}
              name="email"
            />
          </div>
          <LoadingButton
            className="btn form-button c-success"
            type="submit"
            endIcon={<></>}
            loading={authLoading}
            loadingPosition="end"
            variant="contained"
            fullWidth
          >
            <span className="">Forgot Password</span>
          </LoadingButton>

          <div className="text-center _mt-2">
            <span
              className="text-center link-label _mr-1 "
              onClick={() => props.history.push("/login")}
            >
              Login
            </span>
            <pre className="d-inline"> to your account</pre>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
