import React from "react";
import { Button, Input } from "reactstrap";
import "assets/customstyles/serviceprovider.css";
import { Formik, Form, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import * as actions from "redux/app/translation-service-provider/Actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntlMessage } from "util/getIntlMessage";

const TranslationServiceProviderFormModal = ({ setmodal, object, isEdit = false, translationServiceDataId = null, isDefault }) => {
  const localizationMessage = useIntlMessage();
  const dispatch = useDispatch();
  const { loadingButton } = useSelector(
    state => ({
      loadingButton: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  //formMik Handler
  const InitialState =
  {
    name: object?.name ? object?.name : "",
    translationServiceData: object?.translationServiceData ? object?.translationServiceData : [{ key: "", value: "" }],
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(" required"),
    translationServiceData: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("required"),
        value: Yup.string().required("required")
      })
    )
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    if (translationServiceDataId) {
      dispatch(actions.update(translationServiceDataId, values, setmodal));
    }
    else dispatch(actions.create(values, setmodal));
  };

  return (
    <>
      <div>
        <Formik
          initialValues={InitialState}
          onSubmit={SubmitHandler}
          validationSchema={ValidationSchema}>
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          }) => (
            <Form>
              <div className="form-group">
                <Input
                  name="name"
                  disabled={isEdit ? true : false}
                  placeholder={localizationMessage("translationServiceProvider.serviceProvider")}
                  className={
                    errors.name && touched.name
                      ? "is-invalid"
                      : touched.name
                        ? "is-valid"
                        : ""
                  }
                  onChange={!isEdit ? handleChange : null}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </div>
              {/* <div className="form-group">
                <select
                  name="name"
                  className={
                    errors.name && touched.name
                      ? "is-invalid form-select"
                      : touched.name
                        ? "is-valid form-select"
                        : "form-select"
                  }
                  onChange={!isEdit ? handleChange : null}
                  disabled={isEdit}
                  onBlur={handleBlur}
                  value={values.name}
                >
                  <option value="">Select Translation Service</option>
                    <option value="ChatGPT">ChatGPT</option>
                    <option value="Google">Google</option>
                </select>
              </div> */}
              {/* ***************** */}
              <FieldArray
                name="translationServiceData"
                render={arrayHelpers => (
                  <div className="form-group">
                    {values?.translationServiceData &&
                      values?.translationServiceData?.length > 0
                      ? values?.translationServiceData.map((data, index) => {
                        const key = `translationServiceData[${index}].key`;
                        const touchedKey = getIn(touched, key);
                        const errorKey = getIn(errors, key);

                        const value = `translationServiceData[${index}].value`;
                        const touchedValue = getIn(touched, value);
                        const errorValue = getIn(errors, value);

                        return (
                          <div className="d-flex mb-2 row _ml-5 _mr-5" key={index}>
                            {/**************/}
                            <div className="order-1 order-sm-1 col-11 offset-sm-0 col-sm-4 pl-0 pr-0 flex mt-1 mt-sm-0">
                              <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={localizationMessage("key")}
                                disabled={isDefault}
                                name={key}
                                value={data.key}
                                className={
                                  errorKey && touchedKey
                                    ? "is-invalid"
                                    : touchedKey
                                      ? "is-valid"
                                      : ""
                                }
                              />
                            </div>
                            <div className="order-3 order-sm-2 col-11 offset-sm-0 col-sm-7 pl-0 pl-sm-3 pr-sm-0 flex mt-1 mt-sm-0">
                              <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={localizationMessage("value")}
                                name={value}
                                value={data?.value}
                                className={
                                  errorValue && touchedValue
                                    ? "is-invalid"
                                    : touchedValue
                                      ? "is-valid"
                                      : ""
                                }
                              />
                            </div>
                            {index ? (
                              <div className="order-2 order-sm-3 col p-0 d-flex cursor-pointer">
                                <button 
                                  type="button" 
                                  // className="btnBorder del w-100"
                                  className={`btnBorder mt-1 mt-sm-0 w-100 ${!isDefault ? "del" : ""}`}
                                  disabled={isDefault}
                                  onClick={() => arrayHelpers.remove(index)}>
                                  <i
                                    className="fa-solid fa-trash-can d-flex align-items-center justify-content-center w-100"
                                  ></i>
                                </button>
                              </div>) : null
                            }
                            {/**************/}
                          </div>
                        );
                      })
                      : null}
                    <div>
                      <h1 className="form-group mt-2 text-center">
                        <button 
                          type="button"
                          disabled={isDefault}
                          onClick={() => {
                            arrayHelpers.push({ key: "", value: "" });
                          }}
                          >
                          <i
                            className="fa-solid fa-circle-plus"
                          />
                        </button>
                      </h1>
                      <Button
                        className="c-primary w-100"
                        type="submit"
                        disabled={
                          Array.isArray(errors) ||
                          Object.values(errors).toString() !== "" ||
                          loadingButton
                        }
                      >
                        {localizationMessage("submit")}
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TranslationServiceProviderFormModal;
