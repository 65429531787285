import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { withRouter } from "react-router";
import { connect, useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import classnames from "classnames";
import { TablePagination } from "@mui/material";
import { useState } from "react";
import * as stringsLogsActions from "redux/app/stringsLogs/Actions";
import DropdownRedirection from "../DropdownRedirection";
import { useIntlMessage } from "util/getIntlMessage";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const ProjectLogsTable = props => {

  const localizationMessage = useIntlMessage();
  const dispatch = useDispatch();

  const {
    entity,
    logsCount,
    logsPerPageDefault,
    loading,
    buttonLoading,
    languageLocale
  } = useSelector(
    state => ({
      entity: state?.stringsLogs?.entity?.data || [],
      logsCount: state?.stringsLogs?.entity?.count,
      logsPerPageDefault: state?.pagination?.logsPerPageDefault,
      loading: state?.stringsLogs?.loading,
      buttonLoading: state?.buttonLoading?.loading,
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  const projectName = props?.project?.name || "";
  const projectId = props?.project?.id;


  const [rowsPerPage, setRowsPerPage] = useState(logsPerPageDefault);
  const [page, setPage] = useState(0);
  const [openGotoDropdown, setOpenGotoDropdown] = useState(false);

  const redirectionDropdownItems = [
    {
      name: localizationMessage("projectString.strings"),
      redirect: `/projects/${projectId}/strings`
    },
    {
      name: localizationMessage("whatsNew.whatsNew"),
      redirect: `/projects/${projectId}/whatsnew`
    },
    {
      name: localizationMessage("storeListing.storeListing"),
      redirect: `/projects/${projectId}/storelisting`
    },
    {
      name: localizationMessage("projectDetail.detail"),
      redirect: `/projects/${projectId}/detail`
    }
  ];

  const handleChangePage = async (event, newPage) => {
    const paginationPayload = { take: rowsPerPage, skip: newPage * rowsPerPage};
    const success = await dispatch(stringsLogsActions.getAllByprojectId(projectId, paginationPayload));
    if (success) {
      setPage(() => newPage);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    const noOfRowsToShow = event?.target?.value;
    const paginationPayload = { take: noOfRowsToShow, skip: 0};
    const success = await dispatch(stringsLogsActions.getAllByprojectId(projectId, paginationPayload));
    if (success) {
      setRowsPerPage(parseInt(noOfRowsToShow, 10));
      setPage(0);
    }
  };

  const tblData = entity;

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.stringKey")} />;
        },
        accessor: "key.key",
        Cell: ({ value }) => value !== null && value !== undefined ? value : "null"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.previousValue")} />;
        },
        accessor: "string"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.updatedValue")} />;
        },
        accessor: "commitString"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.language")} />;
        },
        accessor: "language.name",
        Cell: ({ value }) => value !== null && value !== undefined ? value : "null"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.description")} />;
        },
        accessor: "description"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.status")}/>;
        },
        accessor: "status"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.commitBy")} />;
        },
        accessor: "executedBy.email"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("logs.modified")} />;
        },
        accessor: "updatedAt"
      },
    ],
    [languageLocale]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: tblData,
      columns: columns,
      manualSortBy: true
    },
    useSortBy
  );

  return (
    <>
      <ReactTableWrapper {...props}>
        {/* <button className="btn btn-primary d-block ms-auto">kmkmkm</button> */}
        <div className="d-flex justify-content-end ms-auto">
          <DropdownRedirection 
            openDropdown={openGotoDropdown}
            setOpenDropdown={setOpenGotoDropdown}
            dropdownTitle={localizationMessage("goto")}
            items={redirectionDropdownItems}
          />
        </div>
        <div>
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <h4>{projectName} {localizationMessage("logs.logs")}</h4>
            </div>

            {entity?.length > 0 && loading === false ?
              <div className="table-container text-center overflow-auto">
                <table
                  border={1}
                  className="custom-react-table-theme-class"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps(
                              header.getSortByToggleProps()
                            )}
                          >
                            <div>{header.render("Header")}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> : null
            }

            {
              entity?.length === 0 &&
                loading === false ? (
                <div className="text-center font-weight-bold text-muted">
                  {localizationMessage("logs.emptyLogsMessage")}
                </div>
              ) : null
            }


            {(entity && entity?.length > 0) ?
              <TablePagination
                SelectProps={{
                  disabled: buttonLoading
                }}
                backIconButtonProps={
                  buttonLoading
                    ? {
                      disabled: buttonLoading
                    }
                    : undefined
                }
                nextIconButtonProps={
                  buttonLoading
                    ? {
                      disabled: buttonLoading
                    }
                    : undefined
                }
                labelRowsPerPage={localizationMessage("pagination.rowsPerPage")} // Custom label
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={logsCount} //total no.of.rows
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              : null
            }

          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(ProjectLogsTable));
