import React from "react";
import "assets/customstyles/errorComponent.css";
import { useIntlMessage } from "util/getIntlMessage";

const ErrorComponent = () => {
  const localizationMessage = useIntlMessage();
  return (
    <div className="Container">
      <div className="errorPageLogo">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>
      <div>
        <span className="MainTitle">{localizationMessage("oops")}</span>
        <span className="SecondTitle">{localizationMessage("errorMessages.somethingWentWrong")}</span>
        <p className="MainDescription">
          {localizationMessage("errorPageContentUnavailableMessage")}
        </p>
      </div>
    </div>
  );
};

export default ErrorComponent;
