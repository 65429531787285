import React, { useMemo, useState } from "react";
import { useTable, useSortBy } from "react-table";
import Button from "components/button/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import classnames from "classnames";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TranslationServiceProviderForm from "views/app/translationServiceProvider/TranslationServiceProviderFormModal";
import "assets/customstyles/projects.css";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "redux/app/translation-service-provider/Actions";
import { useIntlMessage } from "util/getIntlMessage";
const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const TranslationServiceProviderTable = props => {
  const localizationMessage = useIntlMessage();
  const dispatch = useDispatch();
  const {
    entity,
    buttonLoading,
    languageLocale
  } = useSelector(
    state => ({
      entity: state?.translationServiceProvider?.entity,
      buttonLoading: state?.buttonLoading?.loading,
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  const [modal, setmodal] = useState(false);
  const [deleteAlertAll, setDeleteAlertAll] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [translationServiceDataId, setTranslationServiceDataId] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("serialNumber")} />;
        },
        accessor: "id",
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              {+tableInstance?.row?.id + 1}
            </div>
          );
        }
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("translationServiceProvider.serviceProvider")}/>;
        },
        accessor: "name"
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance?.column?.isSortedDesc}
              title={localizationMessage("translationServiceProvider.actions")}
            />
          );
        },
        accessor: "actions",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-secondary c-btn-sm mb-1"
              disabled={!(isSuperAdmin || false)}
              onClick={() => {
                // view(tableInstance.row.original);
                const payload = tableInstance?.row?.original;
                const id = tableInstance?.row?.original?.id;
                props.history.push({
                  pathname: `/provider/${id}/view`,
                  state: { payload }
                });
              }}
            >
              <div className="_fs-14 medium-text">
                <i className="fa-solid fa-eye"></i>
              </div>
            </Button>
            <Button
              className="c-btn c-danger c-btn-sm"
              disabled={buttonLoading || !(isSuperAdmin || false) || tableInstance?.row?.original?.isDefault}
              onClick={() => {
                setTranslationServiceDataId(tableInstance?.row?.original?.id);
                setDeleteAlert(true);
              }}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>
        )
      }
    ],
    [props.history, buttonLoading, isSuperAdmin, languageLocale]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: entity,
      columns: columns,
      manualSortBy: true
    },
    useSortBy
  );

  return (
    <>
      <Modal isOpen={modal} fade={false} className="mx-auto minWidth-28 maxWidth-45" toggle={() => setmodal(!modal)}>
        <ModalHeader toggle={() => setmodal(!modal)}>
          {localizationMessage("translationServiceProvider.addTranslationService")}
        </ModalHeader>
        <ModalBody>
          <TranslationServiceProviderForm
            setmodal={setmodal}
          // object={row || null}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteAlertAll}
        fade={false}
        toggle={() => setDeleteAlertAll(!deleteAlertAll)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAlertAll(!deleteAlertAll)}>
          <span className="mr-5">{localizationMessage("translationServiceProvider.deleteTranslationService")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlertAll(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(actions.removeAll());
              setDeleteAlertAll(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteAlert}
        fade={false}
        toggle={() => setDeleteAlert(!deleteAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAlert(!deleteAlert)}>
          <span className="mr-5">{localizationMessage("translationServiceProvider.deleteTranslationService")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(actions.remove(translationServiceDataId));
              setDeleteAlert(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper {...props}>
        <div className="plr-7 minWidth-28">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header flex">
              {/* <div className="ml-auto">
                <Button
                  className="c-btn c-primary d-block d-sm-inline ml-auto ml-sm-2"
                  disabled={buttonLoading || !(isSuperAdmin || false)}
                  onClick={() => setmodal(!modal)}
                >
                  <i className="fas fa-plus _mr-10" />
                  Add New Service Provider
                </Button>
                {entity?.length > 0 ?
                  <Button
                    className="c-btn c-primary mt-2 mt-sm-0 d-block d-sm-inline ml-auto ml-sm-2"
                    disabled={buttonLoading || !(isSuperAdmin || false)}
                    onClick={() => setDeleteAlertAll(true)}
                  >
                    <i className="fas fa-trash _mr-10" />
                    Remove All
                  </Button> : null
                }
              </div> */}
            </div>

            {entity?.length > 0 ?
              <div className="table-container text-center overflow-auto">
                <table
                  border={1}
                  className="custom-react-table-theme-class"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps(
                              header.getSortByToggleProps({
                                style: {
                                  minWidth: "min-content",
                                  padding: 0
                                }
                              })
                            )}
                          >
                            <div>{header.render("Header")}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="tab_row">
                          {row.cells.map((cell, index) => {
                            if (index === 0) {
                              return (
                                <td {...cell.getCellProps({
                                  style: {
                                    minWidth: "min-content",
                                    color: "#484848",
                                    fontWeight: "bolder"
                                  }
                                })}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            } else if (index === 0) {
                              return (
                                <td {...cell.getCellProps({
                                  style: {
                                    minWidth: "min-content"
                                  }
                                })}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            }
                            else {
                              return (
                                <td
                                  // onClick={() => openPlatform(row.values)}
                                  {...cell.getCellProps({
                                    style: {
                                      minWidth: "min-content",
                                      overflow: "scroll",
                                      padding: 0
                                    }
                                  })}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> :

              <div className="text-center font-weight-bold text-muted mt-2 mb-3">
                {localizationMessage("translationServiceProvider.notFoundMessage")}
              </div>
            }

          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(TranslationServiceProviderTable));
