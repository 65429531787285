import React, { useEffect } from "react";
import PageTitle from "components/common/PageTitle";
import "assets/customstyles/projects.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as userAndPermissionActions from "redux/app/userAndPermission/Actions";
import * as usersActions from "redux/app/users/Actions"
import { Spinner } from "reactstrap";
import { useMemo } from "react";
import ErrorComponent from "views/ErrorComponent";
import UsersAndPermissionsTable from "./UsersAndPermissionsTable";

const ViewUserPermissions = (props) => {
  const dispatch = useDispatch();
  let userId = props?.match?.params?.id;

  const {
    userEntity,
    userLoading,
    usersError,
    uApLoading,
    userAndPermissionError
  } = useSelector(
    state => ({
      userEntity: state?.users?.userEntity,
      userLoading: state?.users?.loading,
      usersError: state?.users?.error,
      uApLoading: state?.userAndPermission?.loading,
      userAndPermissionError: state?.userAndPermission?.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(userAndPermissionActions.getByUserId(userId));
    dispatch(usersActions.getOne(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(userAndPermissionActions.setBackFlow());
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      {useMemo(() => {
        return (
          <PageTitle
            title=""
            middle={true}
            className="plr-7 minWidth-28"
            breadCrumb={[
              {
                name: "Users And Permission",
                localizationId: "userAndPermission.userAndPermission"
              },
              {
                name: (userEntity) ? userEntity?.email : "User-Email"
              },
              {
                name: "Permissions",
                localizationId: "userAndPermission.permissions"
              }
            ]}
          />
        );
      }, [userEntity])}

      {useMemo(() => {
        if ((uApLoading)) {
          return (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem",
                  zIndex: "10",
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        }
      }, [uApLoading])}

      {useMemo(() => {
        if (!uApLoading && !userLoading) {
          if (userAndPermissionError || usersError) {
            return <ErrorComponent />;
          }
          else {
            return <UsersAndPermissionsTable userId={userId} />;
          }
        }
      }, [uApLoading, userAndPermissionError, userLoading, usersError, userId])}
    </>
  );
};

export default ViewUserPermissions;