import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

//call this before calling api generally
export const toastLoadingStart = async (toastId, toastMessage) => {
  // toast.clearWaitingQueue();
  await delay(100);
  if (!toast.isActive(toastId)) {
    toast.loading(toastMessage, {
      toastId: toastId,
      position: "top-right",
      closeButton: false,
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light"
    });
  }
};

//use when promise return, call this in resolve/reject or response/error section generally
export const toastLoadingEnd = async (toastId, toastType, toastUpdateMessage, delayToast, closeButton) => {
  //requirement toastId, toastType, toastUpdateMessage all must be string only
  toastId = toastId.toString();
  toastUpdateMessage = toastUpdateMessage.toString();
  toast.clearWaitingQueue();
  await delay(100);
  if (toast.isActive(toastId)) {
    if (toastType === "error") {
      toastType = toast.TYPE.ERROR;
      delayToast = delayToast !== undefined ? delayToast : 3000;
    }
    if (toastType === "success") {
      toastType = toast.TYPE.SUCCESS;
      delayToast = delayToast !== undefined ? delayToast : 1000;
    }
    if (toastType === "info") {
      toastType = toast.TYPE.INFO;
      delayToast = delayToast !== undefined ? delayToast : 2000;
    }
    closeButton = closeButton !== false ? true : false;
    toast.update(toastId, {
      autoClose: delayToast,
      isLoading: false,
      type: toastType,
      closeButton: closeButton,
      render: toastUpdateMessage,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: undefined,
      // transition: transitionType,
      theme: "light"
    });
  }
  //clear all toast from screen if failed to fetched toastId/get wrong toastId
  else toast.dismiss();
};

export const toastSuccess = async (toastId, toastMessage, delayToast = 2000) => {
  await delay(100);
  if (!toast.isActive(toastId)) {
    let toastProperties = {
      autoClose: delayToast,
      position: "top-right",
      closeButton: true,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      // transition: transitionType,
      theme: "light"
    };
    if (toastId !== "random") {
      toastProperties = { ...toastProperties, toastId: toastId };
    }
    toast.success(toastMessage, toastProperties);
  }
};

export const toastError = async (toastId, toastMessage) => {
  await delay(100);
  if (!toast.isActive(toastId)) {
    let toastProperties = {
      position: "top-right",
      closeButton: true,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      // transition: transitionType,
      theme: "light"
    };
    if (toastId !== "random") {
      toastProperties = { ...toastProperties, toastId: toastId };
    }
    toast.error(toastMessage, toastProperties);
  }
};

export const clearToast = async (toastId) => {
  await delay(100);
  if (toast.isActive(toastId)) {
    toast.dismiss(toastId);
  }
}

export const clearAllToast = async () => {
  await delay(100);
  toast.dismiss();
}
