import React, { useEffect, useState } from "react";
import { Collapse, Input } from "reactstrap";
import "assets/customstyles/whatsnew.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as storeListingActions from "redux/app/storelisting/Actions";
import { toastSuccess } from "helper/toast";
import Button from "reactstrap/lib/Button";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const CardStoreListing = ({ parentCollapse, entity }) => {

  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  //redux
  const { buttonLoading } = useSelector(
    state => ({
      buttonLoading: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  const languageDirection = entity?.language?.direction;

  // initilization
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    description: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    const storeListingId = entity.storeListing.id;
    const projectLanguageId = entity.id;
    const payloadUpdate = { ...values, projectLanguageId: projectLanguageId };
    dispatch(storeListingActions.update(payloadUpdate, storeListingId, setIsEdit));
  };

  const handleUpdateAndTranslate = (values) => {
    const projectId = entity.projectId;
    const projectLanguageId = entity.id;
    const storeListingId = entity.storeListing.id;
    const payloadUpdate = { ...values, projectLanguageId: projectLanguageId };
    const payloadTranslateAll = {
      projectLanguageId: projectLanguageId
    };
    const shouldTranslateAll = true;
    dispatch(storeListingActions.update(payloadUpdate, storeListingId, setIsEdit, projectId, shouldTranslateAll, payloadTranslateAll));
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: { description: "", projectLanguageId: entity.id },
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  const Translate = async () => {
    const payload = {
      projectLanguageId: entity?.id
    };
    dispatch(storeListingActions.translate(payload, entity?.projectId));
  };

  // const TranslateAll = async () => {
  //   const payload = {
  //     projectLanguageId: entity?.id
  //   };
  //   dispatch(storeListingActions.translateAll(payload, entity?.projectId));
  // };

  useEffect(() => {
    setCollapse(parentCollapse);
  }, [parentCollapse]);

  return (
    <>
      <div className="roe-card-style mtb-10 card-language">
        <div className="roe-card-header module-header flex">
          <div className="heading-language">
            {entity?.language?.name || "no name"}
            {entity.isDefault ? (
              <span className="lan-type"> ({localizationMessage("primary")})</span>
            ) : null}
          </div>
          {isEdit ? (
            <div className="ml-auto flex justify-content-end">
              <button
                onClick={() => {
                  setError("");
                  setIsEdit(false);
                }}
              >
                <i className="fas fa-xmark" />
              </button>
            </div>
          ) : (
            <div className="ml-auto flex justify-content-end">

              {!collapse ? (
                <div className="flex flex-wrap-reverse justify-content-end">

                  <div className="btn-secondary btn-sm">
                    {localizationMessage("characters")} <span className="badge badge-light"> {entity?.storeListing?.description?.length || 0} </span>
                  </div>
                  <div>
                    {
                      !entity.isDefault ?
                      <button
                        disabled={buttonLoading}
                        className={(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false) ? "" : "d-none"}
                        title={localizationMessage("translate")}
                        onClick={() => Translate()}
                      >
                        <i className="fa-solid fa-language"></i>
                      </button>
                      : null
                    }
                    <button
                      title={localizationMessage("copy")}
                      onClick={() => {
                        if (entity?.storeListing?.description.length > 0) {
                          navigator.clipboard.writeText(
                            entity?.storeListing?.description || ""
                          );
                          toastSuccess("copy", localizationMessage("copied"), 800);
                        }
                      }}
                    >
                      <i className="fa-regular fa-copy" />
                    </button>
                    <button
                      // className="ml-auto"
                      className={(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false) ? "ml-auto" : "d-none"}
                      // disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                      title={localizationMessage("edit")}
                      onClick={() => {
                        formMik.setFieldValue(
                          "description",
                          entity?.storeListing?.description || ""
                        );
                        setIsEdit(true);
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square" />
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <button
                className="d-flex"
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? (
                  <i className="fa-solid fa-angle-down" />
                ) : (
                  <i className="fa-solid fa-angle-up" />
                )}
              </button>
            </div>
          )}
        </div>
        <Collapse isOpen={!collapse}>
          {isEdit ? (
            <div className="edit-form">
              <form onSubmit={formMik.handleSubmit}>
                <Input
                  // autoFocus={true}
                  type="textarea"
                  rows={5}
                  placeholder={localizationMessage("storeListing.descField.placeholder")}
                  className={
                    formMik.errors.description && formMik.touched.description
                      ? "is-invalid rich-input"
                      : formMik.touched.description
                        ? "is-valid rich-input"
                        : "rich-input"
                  }
                  onChange={e => {
                    setError("");
                    formMik.setFieldValue("description", e.target.value);
                  }}
                  onBlur={formMik.handleBlur}
                  value={formMik.values.description}
                  name="description"
                />

                <div className="text-danger mt-2">{error}</div>

                <div className="mt-3 d-sm-flex">
                  {
                    entity.isDefault ?
                      <Button
                        className="btn c-primary modalButtonSize px-5 ml-auto d-block me-0 me-sm-2"
                        type="button"
                        onClick={()=> formMik.isValid && handleUpdateAndTranslate(formMik.values)}
                        // disabled={buttonLoading}
                        disabled={buttonLoading || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                      >
                        {localizationMessage("storeListing.updateAndTranslate")}
                      </Button>
                    : null
                  }

                  <Button
                    className={`btn c-primary modalButtonSize px-5 mt-2 mt-sm-0 d-block ml-auto ${entity?.isDefault ? "ml-sm-0" : "" }`}
                    type="submit"
                    disabled={buttonLoading}
                  >
                    {localizationMessage("storeListing.update")}
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div className="detail" style={languageDirection==="rtl" ? {direction: "rtl", whiteSpace: "pre-line"} : {whiteSpace: "pre-line"}} >{entity?.storeListing?.description}</div>
          )}
        </Collapse>
      </div>
    </>
  );
};

export default CardStoreListing;
