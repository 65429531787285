import auth from "./auth/reducer";
import themeChanger from "./themeChanger/reducer";
import languageSwitcher from "./languageSwitcher/reducer";
import themeSetting from "./themeSettings/reducer";
import scrumboard from "./scrumboard/reducer";
import { translationServiceProviderSlice } from "./app/translation-service-provider/Reducer";
import { platformSlice } from "./app/platform/Reducer";
import { placeHolderSlice } from "./app/placeholders/Reducer";
import { languageSlice } from "./app/language/Reducer";
import { projectSlice } from "./app/projects/Reducer";
import { storeListingSlice } from "./app/storelisting/Reducer";
import { whatsnewVersionSlice } from "./app/whatsnewVersions/Reducer";
import { whatsnewSlice } from "./app/whatsnew/Reducer";
import { stringsSlice } from "./app/strings/Reducer";
import { userAndPermissionSlice } from "./app/userAndPermission/Reducer";
import { stringsLogsSlice } from "./app/stringsLogs/Reducer";

import { commonStringsSlice } from "./app/commonStrings/Reducer";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { btnLoadingReducer } from "./btnLoading/reducer";
import { paginationReducer } from "./pagination/reducer";
import { usersSlice } from "./app/users/Reducer";
// import storage from "redux-persist/lib/storage";

// import authAction from "./auth/actions";

const createReducer = asyncReducers => {
  const appReducer = combineReducers({
    //App
    translationServiceProvider: translationServiceProviderSlice.reducer,
    platform: platformSlice.reducer,
    placeHolder: placeHolderSlice.reducer,
    language: languageSlice.reducer,
    project: projectSlice.reducer,
    storeListing: storeListingSlice.reducer,
    whatsnewVersion: whatsnewVersionSlice.reducer,
    whatsnew: whatsnewSlice.reducer,
    string: stringsSlice.reducer,
    commonString: commonStringsSlice.reducer,
    userAndPermission: userAndPermissionSlice.reducer,
    stringsLogs: stringsLogsSlice.reducer,
    users: usersSlice.reducer,

    // Auth / Theme / Route / Settings
    auth,
    themeChanger,
    languageSwitcher,
    themeSetting,
    scrumboard,
    router: routerReducer,
    buttonLoading: btnLoadingReducer,
    pagination: paginationReducer,
    ...asyncReducers
  });

  // return (state, action) => {
  //   if (action.type === 'LOGOUT_USER')
  //   { state = undefined; }
  //   return appReducer(state, action);
  // }

  // const appReducer = combineReducers({ myReducer, ...asyncReducers });
  return (state, action) => {
    // if (action.type === "LOGOUT") {
    //   return appReducer(undefined, action);
    // }
    // if (action.type === "CLEAR_REDUX") {
    //   return appReducer(undefined, action);
    //   // return {
    //   //   pagination: state.pagination, // Preserve 'pagination' state
    //   // };
    // }

    if (action.type === "LOGOUT" || action.type === "CLEAR_REDUX") {
      // Reset all reducers except 'pagination' and 'languageSwitcher'
      const { pagination, languageSwitcher, ...restState } = state;
      const rest = appReducer(undefined, action);
      const modifiedState = {...rest, pagination, languageSwitcher}
      return appReducer(modifiedState, action);
    }

    return appReducer(state, action);
  };
};

export default createReducer;
