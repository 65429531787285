import React, { useState, useEffect } from "react";
import { loginBack, iconDemo } from "helper/constant";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { withRouter, useHistory } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "firebase/firebase";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ApiUrl } from "services/Api";
import { LoadingButton } from "@mui/lab";

const { login } = AuthActions;

const Login = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { authLoading } = useSelector(
    state => ({
      authLoading: state?.auth?.loading
    }),
    shallowEqual
  );

  const [error, setError] = useState("");
  //css
  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  useEffect(() => {
    const checkAdminExists = async () => {
      try {
        const response = await axios.get(`${ApiUrl.Users.CheckSuperAdmin.Url}`);
        if (response.data !== true) {
          history.push("/register");
        }
      } catch (err) {
        // setError(`"An error occurred while checking admin status." ${err}`);
      }
    };

    checkAdminExists();
  }, [history]);

  //Submit Handler
  const SubmitHandler = async values => {
    dispatch(AuthActions.setLoading(true));

    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async res => {
        // if user email is verified only then login
        if (res?.user?.emailVerified) {
          // API calling
          axios[ApiUrl.Users.Login.Method](
            `${ApiUrl.Users.Login.Url}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + res.user.accessToken
              }
            }
          )
            .then((response) => {
              dispatch(AuthActions.login(res?.user?.accessToken, response?.data));

              //navigating to login
              props.history.push("/projects");
              dispatch(AuthActions.setLoading(false));
            })
            .catch((error) => {
              setError(`Invalid email or password`);
              dispatch(AuthActions.setLoading(false));
            });
        }

        else {
          throw new Error("error: Email not verified");
        }

      })
      .catch(error => {
        setError(`Invalid email or password`);
        dispatch(AuthActions.setLoading(false));
      });
  };

  // form field validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email address")
      .required("Email is required!"),
    password: Yup.string()
      .required("Password is required!")
      .min(8, "Password must be at least 8 characters long")
  });

  //formMik Handler
  const formMik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={iconDemo} alt="icon" height="100px" />
        </div>
        <div className="fs-4 text-center">Sign in to your account</div>

        <form className="p-3 p-sm-4" onSubmit={formMik.handleSubmit}>
          {(error && !authLoading) ? <div className="message-error">{error}</div> : null}
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter email"
              className={
                formMik.errors.email && formMik.touched.email
                  ? "is-invalid form-control "
                  : formMik.touched.email
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={e => {
                setError("");
                formMik.setFieldValue("email", e.target.value);
              }}
              onBlur={formMik.handleBlur}
              value={formMik.values.email}
              name="email"
            />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter password"
              className={
                formMik.errors.password && formMik.touched.password
                  ? "is-invalid form-control "
                  : formMik.touched.password
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={e => {
                if(e.target.value.length<8){
                  setError("Password must be at least 8 characters long")
                }
                else{
                  setError("");
                }
                formMik.setFieldValue("password", e.target.value);
              }}
              onBlur={formMik.handleBlur}
              value={formMik.values.password}
              name="password"
            />
          </div>

          <LoadingButton
            className="btn form-button c-success"
            type="submit"
            endIcon={<></>}
            loading={authLoading}
            loadingPosition="end"
            variant="contained"
            fullWidth
          >
            <span className="">Login</span>
          </LoadingButton>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(Login);
