import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.StringLogs;

// export function getAllByVersionId(versionId) {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" },
//     where:{whatsNewVersionId:versionId},
//     relations:['projectLanguage','projectLanguage.language']
//   });
// }
// export function getAll() {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" }
//   });
// }

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}


// export function removeAll() {
//   return axios[API.DeleteAll.Method](API.DeleteAll.Url);
// }
export function getAllByprojectLangaugeId(projectLanguageId) {
  let url = API.GetAllByprojectLangaugeId.Url;
  url = url.replace("##paramid##", projectLanguageId);
  return axios[API.GetAllByprojectLangaugeId.Method](url);
}

export function getAllByprojectId(projectId, paginationPayload) {
  return axios[API.GetAllByprojectId.Method](API.GetAllByprojectId.Url,
    {
      order: { updatedAt: "DESC" },
      where: { projectId: projectId},
      relations: ["executedBy", "language", "key", "key.project"],
      ...paginationPayload
    }
  );
}


// export function remove(payload) {
//   let url = API.Delete.Url;
//   return axios[API.Delete.Method](url, payload);
// }

export function remove(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}
