import { getLocalizedString } from "util/getLocalizedString";
import * as requestFromServer from "./cruds";
import { toastError } from "helper/toast";

const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  UPDATE: "UPDATE",
  SETLOADING: "SET_LOADING",
  GETALLUSERS: "GETALL_USERS",

  setLoading: loadingValue => {
    return {
      type: authActions.SETLOADING,
      loading: loadingValue
    };
  },

  login: (accessToken, data) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("user", JSON.stringify(data));
    return {
      type: authActions.LOGIN,
      user: data
    };
  },

  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("persist:root");
    return {
      type: authActions.LOGOUT,
      isLogin: false
    };
  },

  refreshToken: refreshedToken => {
    localStorage.setItem("accessToken", refreshedToken);
  },

  update: (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    return {
      type: authActions.UPDATE,
      user: data?.user ? data?.user : ""
    };
  }

};

export default authActions;



export const getAllUsers = () => dispatch => {
  // dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch({
        type: authActions.GETALLUSERS,
        user: response?.data ? response?.data : ""
      });
      // dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      toastError(
        "fetchingDataFromServer",
        error?.message || getLocalizedString("errorMessages.somethingWentWrong")
      );
    });
};

export const getOneUser = (userId) => async (dispatch) => {
  // dispatch(actions.startCall());
  await requestFromServer
    .getOne(userId)
    .then(async response => {
      dispatch({
        type: authActions.GETALLUSERS,
        user: response?.data ? response?.data : ""
      });
      // dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      toastError(
        "fetchingDataFromServer",
        error?.message || getLocalizedString("errorMessages.somethingWentWrong")
      );
    });
};

// export const deleteUser = (id) => dispatch => {
//   toastLoadingStart(id, getLocalizedString("infoMessages.processingRequest"));
//   return requestFromServer
//     .removeProjectPermission(id)
//     .then(async response => {
//       dispatch(actions.removeProjectPermission({ id: id }));
//       toastLoadingEnd(
//         id,
//         "success",
//         getLocalizedString("successMessages.recordDeleted")
//       );
//     })
//     .catch(async (error) => {
//       //wait to get id(toastId) for what it started with
//       await new Promise(resolve => setTimeout(resolve, 200));
//       toastLoadingEnd(
//         id,
//         "error",
//         error?.message || getLocalizedString("errorMessages.somethingWentWrong")
//       );
//     });
// };