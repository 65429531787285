import React, { useMemo, useState, useCallback } from "react";
import { useTable } from "react-table";
import Button from "components/button/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import classnames from "classnames";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PlatformForm from "./PlatformFormModal";
import "assets/customstyles/projects.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "redux/app/platform/Actions";
import ViewPlatforms from "./ViewPlatforms";
import { useIntlMessage } from "util/getIntlMessage";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-2": true,
    "-sort-asc": props?.isSortedDesc !== undefined && !props?.isSortedDesc,
    "-sort-desc": props?.isSortedDesc !== undefined && props?.isSortedDesc
  };
  return <div className={classnames(classes)}>{props?.title}</div>;
};

const PlatformsTable = props => {

  const localizationMessage = useIntlMessage();

  const dispatch = useDispatch();
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  //redux
  const { loader, entity, languageLocale } = useSelector(
    state => ({
      loader: state?.platform?.loading,
      entity: state?.platform?.entity || [],
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  const pageLength = 10;
  const [modal, setmodal] = useState(false);
  const [deleteAlertAll, setDeleteAlertAll] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [row, setRow] = useState(null);
  const [platformDataId, setPlatformDataId] = useState("");

  //formActionsHandler
  const formAction = useCallback(
    (action, data = null) => {
      if (action === "edit") {
        setRow(data);
        setmodal(!modal);
      } else if (action === "delete") {
        setDeleteAlert(true);
        setPlatformDataId(data?.id);
      }
    },
    [modal]
  );

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("serialNumber")} />;
        },
        accessor: "id",
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              {+tableInstance?.row?.id + 1}
            </div>
          );
        }
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.platform")} />;
        },
        accessor: "name"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.fileStartFormat")} />;
        },
        accessor: "startFormat"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.fileEndFormat")} />;
        },
        accessor: "endFormat"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.keyValuePair")} />;
        },
        accessor: "iteration"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.keyValuePairSeparator")} />;
        },
        accessor: "iterationSeprator"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.fileName")} />;
        },
        accessor: "fileName"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("platforms.actions")} />;
        },
        accessor: "Actions",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class d-flex flex-column flex-xl-row justify-content-center">
            <Button
              className="c-btn c-primary c-btn-sm mt-1 mt-xl-0 mb-1 mb-xl-0 maxWidth-minContent mx-auto  mx-xl-1"
              disabled={!(isSuperAdmin || false) || tableInstance?.row?.original?.isDefault}
              onClick={() => formAction("edit", tableInstance?.row?.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>

            <Button
              className="c-btn c-danger c-btn-sm mb-1 mb-xl-0 maxWidth-minContent mx-auto  mx-xl-1"
              disabled={!(isSuperAdmin || false) || tableInstance?.row?.original?.isDefault}
              onClick={() => formAction("delete", tableInstance?.row?.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>
        )
      }
    ],
    [formAction, isSuperAdmin, languageLocale]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: entity,
      columns: columns,
      manualSortBy: true,
      initialState: {
        pageSize: pageLength,
        pageIndex: 0
      }
    },
  );

  const RemoveAll = () => {
    setDeleteAlertAll(true);
  };

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        backdrop={"static"}
        className="mx-auto minWidth-32 maxWidth-68 "
        toggle={() => setmodal(!modal)}>
        <ModalHeader toggle={() => setmodal(!modal)}>{row ? localizationMessage("platforms.updatePlatform") : localizationMessage("platforms.addPlatform")}</ModalHeader>
        <ModalBody>
          <PlatformForm setmodal={setmodal} key={1} object={row} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteAlertAll}
        fade={false}
        toggle={() => setDeleteAlertAll(!deleteAlertAll)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAlertAll(!deleteAlertAll)}>
          <span className="mr-5">{localizationMessage("platforms.deletePlatforms")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlertAll(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(actions.removeAll());
              setDeleteAlertAll(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteAlert}
        fade={false}
        toggle={() => setDeleteAlert(!deleteAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAlert(!deleteAlert)}>
          <span className="mr-5">{localizationMessage("platforms.deletePlatform")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(actions.remove(platformDataId));
              // deleteAlert=false;
              setDeleteAlert(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper {...props}>
        <div className="plr-7 minWidth-28">
          <div className="roe-card-style mtb-15 minWidth-minContent">
            <div className="roe-card-header flex">
              <div className="ml-auto">
                <Button
                  className="c-btn c-primary d-block d-sm-inline ml-auto ml-sm-2"
                  disabled={!(isSuperAdmin || false)}
                  onClick={() => {
                    setRow(null);
                    setmodal(!modal);
                  }}
                >
                  <i className="fas fa-plus _mr-10" />
                  {localizationMessage("platforms.addPlatform")}
                </Button>
                {entity?.length > 0 ?
                  <Button className="c-btn c-primary mt-2 mt-sm-0 d-block d-sm-inline ml-auto ml-sm-2"
                    disabled={!(isSuperAdmin || false)}
                    onClick={RemoveAll}>
                    <i className="fas fa-trash-can _mr-10" />
                    {localizationMessage("removeAll")}
                  </Button>
                  : null}
              </div>
            </div>

            {entity?.length > 0 ?
              <>
                <div className="d-block d-lg-none minWidth-minContent">
                  {
                    entity.map((platformData, index) => {
                      return (
                        <div key={index} className="mb-2">
                          <ViewPlatforms platformData={platformData} setRow={setRow} setmodal={setmodal} setDeleteAlert={setDeleteAlert} setPlatformDataId={setPlatformDataId} />
                        </div>
                      );
                    })
                  }
                </div>

                <div className="table-container text-center overflow-auto d-none d-lg-block">
                  <table
                    border={1}
                    className="custom-react-table-theme-class"
                    {...getTableProps()}
                  >
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(header => (
                            <th
                              {...header.getHeaderProps(
                                // header.getSortByToggleProps()
                              )}
                            >
                              <div>{header.render("Header")}</div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="tab_row">
                            {row.cells.map((cell, index) => {
                              if (index === 7) {
                                return (
                                  <td {...cell.getCellProps({
                                    style: {
                                      minWidth: "min-content"
                                    }
                                  })}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    // onClick={() => openPlatform(row.values)}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
              : <div className="text-center font-weight-bold text-muted mt-2 mb-3">
                {localizationMessage("platforms.addNewPlatformRequestMessage")}
              </div>
            }

          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(PlatformsTable));
