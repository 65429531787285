import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: null,
  translationServiceProviderEntity: null,
  error: null
};

export const translationServiceProviderSlice = createSlice({
  name: "translationServiceProvider",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
      // state.entity = [];
      // state.error = null;
    },
    getOne: (state, action) => {
      state.translationServiceProviderEntity = action.payload.data;
      state.entity = null;
      state.error = null;
      state.loading = false;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    create: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    remove: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    update: (state, action) => {
      const tspe = state.translationServiceProviderEntity;
      if (tspe && tspe?.translationServiceData) {
        tspe.translationServiceData = action?.payload?.data;
      }
      // if (obj.id === action.payload.id) {
      // }


      // state.translationServiceProviderEntity = state.translationServiceProviderEntity.map(function (obj) {
      //   if (obj.id === action.payload.id) {
      //     let object = obj;
      //     object = { ...obj, ...action.payload.data };
      //     return object;
      //   } else return obj;
      // });
      // state.loading = false;
      // state.error = null;
    },
    clear: (state, action) => {
      state.loading = true;
      state.entity = null;
      state.translationServiceProviderEntity = null;
      state.error = null;
    }
  }
});
