import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "./reacttbl.style";
import classnames from "classnames";
import { logs } from "util/data/reactTableData";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const LogsTable = props => {
  const tblData = logs;

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title="ID" />;
        },
        accessor: "id"
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title="Project" />;
        },
        accessor: "project"
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title="Error" />;
        },
        accessor: "error"
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title="Date and Time" />;
        },
        accessor: "time"
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: tblData,
      columns: columns,
      manualSortBy: true
    },
    useSortBy
  );

  return (
    <>
      <ReactTableWrapper {...props}>
        <div>
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <h4>Error Logs List</h4>
            </div>
            <div className="table-container text-center overflow-auto">
              <table
                border={1}
                className="custom-react-table-theme-class"
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(header => (
                        <th
                          {...header.getHeaderProps(
                            header.getSortByToggleProps()
                          )}
                        >
                          <div>{header.render("Header")}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(LogsTable));
