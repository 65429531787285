import React from "react";
import "assets/customstyles/projects.css";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import * as userAndPermissionActions from "redux/app/userAndPermission/Actions";
import { useIntlMessage } from "util/getIntlMessage";
import { useState } from "react";

const UserRoleUpdateForm = ({ setModal, row }) => {
  const localizationMessage = useIntlMessage();
  const dispatch = useDispatch();

  // Initialize state based on row.admin
  const [isSystemAdmin, setIsSystemAdmin] = useState(row?.super_admin || false);

  // Handle change event
  const handleChange = (event) => {
    setIsSystemAdmin(event.target.value === 'admin');
  };

  const handleUserUpdate = ()=>{
    const user_id = row?.user_id;
    const payload = {
      superAdmin: isSystemAdmin
    }
    dispatch(userAndPermissionActions.updateUser(user_id, payload, setModal));
    // setModal(false);
  }


  return (
    <div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="adminOptions"
          id="adminOption"
          value="admin"
          checked={isSystemAdmin === true}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="adminOption">
          {localizationMessage("admin")}
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="adminOptions"
          id="userOption"
          value="user"
          checked={isSystemAdmin === false}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="userOption">
          {localizationMessage("user")}
        </label>
      </div>

      <Button className="btn form-button c-primary mt-3"
        onClick={handleUserUpdate}
        type="button"
      >
        {localizationMessage("userAndPermission.updateProjectPermission")}
      </Button>
    </div>
  );
};
export default UserRoleUpdateForm;
