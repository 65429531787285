// import appLocaleData from "react-intl/locale-data/ur";
import saMessages from "../locales/ur_PK.json";

const saLang = {
  messages: {
    ...saMessages
  },
  locale: "ur-PK",
  // data: appLocaleData
};
export default saLang;
