import React, { useEffect } from "react";
import PageTitle from "components/common/PageTitle";
import StoreListingTable from "views/app/projects/storelisting/StoreListingTable";
import { useDispatch } from "react-redux";
import * as projectActions from "redux/app/projects/Actions";
import * as storeListingActions from "redux/app/storelisting/Actions";
import { useSelector, shallowEqual } from "react-redux";
import { Spinner } from "reactstrap";
import { useMemo } from "react";
import ErrorComponent from "views/ErrorComponent";
import { useLayoutEffect } from "react";

function StoreListing(props) {

  const dispatch = useDispatch();
  let projectId = props?.match?.params?.id;

  /**** Redux ****/
  const {
    project,
    projectLoading,
    projectError,
    reduxStoreListingId,
    storeListingLoading,
    storeListingError,
    reduxProjectId
  } = useSelector(
    state => ({
      project: state?.project?.projectEntity || null,
      projectLoading: state?.project?.loading,
      projectError: state?.project?.error,
      reduxStoreListingId: state?.storeListing?.entity[0]?.id,
      storeListingLoading: state?.storeListing?.loading,
      storeListingError: state?.storeListing?.error,
      reduxProjectId: state?.project?.projectEntity?.id || null
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(projectActions.getOne(projectId));
    dispatch(storeListingActions.getAll(projectId));
  }, [dispatch, projectId]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title=""
        middle={true}
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Projects",
            localizationId: "projects.title"
          },
          {
            name: project?.name ? project?.name : "Project-Name"
          },
          {
            name: "Store Listing",
            localizationId: "storeListing.storeListing"
          }
        ]}
      />
      {useMemo(() => {
        return (
          storeListingLoading || projectLoading ? (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "50vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          ) : null
        );
      }, [storeListingLoading, projectLoading])}

      {useMemo(() => {

        if (!storeListingLoading && !projectLoading) {

          if (reduxProjectId && reduxStoreListingId && !(projectError || storeListingError)) {
            return <StoreListingTable projectId={reduxProjectId} />;
          }
          else {
            return <ErrorComponent />;
          }
        }
      }, [
        reduxProjectId,
        storeListingLoading,
        storeListingError,
        reduxStoreListingId,
        projectError,
        projectLoading
      ])}
    </>
  );
}

export default StoreListing;
