import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.PlaceHoldersFormate;

export function getAll() {
  return axios[API.GetAll.Method](API.GetAll.Url);
}

// export function create(data) {
//   return axios.post(`${baseUrl}contact-from-finder`, data);
// }

// export function remove(id) {
//   return axios.delete(`${baseUrl}contact-from-finder/${id}`);
// }
