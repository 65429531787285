import axios from "axios";
import { ApiUrl } from "services/Api";

const API = ApiUrl.whatsNewVersions;

export function getAll(projectId) {
  return axios[API.Search.Method](API.Search.Url, {
    order: { createdAt: "ASC" },
    where: { projectId: projectId }
  });
}
export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function remove(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}
