import React, { useLayoutEffect } from "react";
import PageTitle from "components/common/PageTitle";
import ProjectLogsTable from "./ProjectLogsTable";
import { useDispatch } from "react-redux";
import * as projectActions from "redux/app/projects/Actions";
import * as stringsLogsActions from "redux/app/stringsLogs/Actions";
import { useSelector, shallowEqual } from "react-redux";
import { Spinner } from "reactstrap";
import { useMemo } from "react";
import { useEffect } from "react";
import ErrorComponent from "views/ErrorComponent";

function ProjectLogs(props) {

  /**** Initilization ****/
  const dispatch = useDispatch();
  const projectId = props?.match?.params?.id;

  /**** Redux ****/
  const {
    project,
    projectError,
    projectloading,
    stringsLogsloading,
    stringLogsEntity,
    logsPerPageDefault,
    stringsLogserror
  } = useSelector(
    state => ({
      project: state?.project?.projectEntity || null,
      projectError: state?.project?.error,
      projectloading: state?.project?.loading,
      stringsLogsloading: state?.stringsLogs?.loading,
      stringLogsEntity: state?.stringsLogs?.entity,
      logsPerPageDefault: state?.pagination?.logsPerPageDefault,
      stringsLogserror: state?.stringsLogs?.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(projectActions.getOne(projectId));
    const paginationPayload = { take: logsPerPageDefault, skip: 0};
    dispatch(stringsLogsActions.getAllByprojectId(projectId, paginationPayload));
    

  }, [dispatch, projectId]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      {useMemo(() => {
        return (
          <PageTitle
            title=""
            middle={true}
            className="plr-7 minWidth-28"
            breadCrumb={[
              {
                name: "Projects",
                localizationId: "projects.title"
              },
              {
                name: project?.name ? project?.name : "Project-Name"
              },
              {
                name: "Logs",
                localizationId: "logs.logs"
              }
            ]}
          />
        );
      }, [project])}

      {useMemo(() => {
        return (
          stringsLogsloading && (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        );
      }, [stringsLogsloading])}

      {useMemo(() => {
        if (!stringsLogsloading && !projectloading) {
          if ((stringsLogserror || projectError) && stringLogsEntity?.length === 0) {
            return <ErrorComponent />
          }
          else {
            return <ProjectLogsTable project={project} />
          }
        }
      }, [stringsLogsloading, projectloading, stringsLogserror, projectError, stringLogsEntity, project])}
    </>
  );
}

export default ProjectLogs;
