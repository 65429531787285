import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: [],
  error: null
};

export const languageSlice = createSlice({
  name: "language",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    clear: (state, action) => {
      state.loading = true;
      state.entity = [];
      state.error = null;
    }
  }
});
