import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: [],
  error: null,
  userEntity: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
      state.entity = [];
      state.error = null;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    getOne: (state, action) => {
      state.userEntity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    getOneBySearch: (state, action) => {
      state.userEntity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    clear: (state, action) => {
      state.userEntity = null;
      state.entity = [];
      state.loading = true;
      state.error = null;
    },
    create: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    remove: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },

  }
});
