const btnLoadingAction = {
  SETLOADING: "SET_BUTTONLOADING",
  SETLOADINGPROGRESS: "SETLOADINGPROGRESS",

  setLoading: loadingValue => {
    return {
      type: btnLoadingAction.SETLOADING,
      payload: loadingValue
    };
  },

  setLoadingProgress: loadingValue => {
    return {
      type: btnLoadingAction.SETLOADINGPROGRESS,
      payload: loadingValue
    };
  }
};

export default btnLoadingAction;
