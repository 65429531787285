import React, { useEffect } from "react";
import PageTitle from "components/common/PageTitle";
import "assets/customstyles/projects.css";
import * as projectActions from "redux/app/projects/Actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as platformActions from "redux/app/platform/Actions";
import * as languageActions from "redux/app/language/Actions";
import * as translationProviderActions from "redux/app/translation-service-provider/Actions";
import { useMemo } from "react";
import ViewProjectDetail from "./ViewProjectDetail";
import ErrorComponent from "views/ErrorComponent";
import { Spinner } from "reactstrap";
import { useLayoutEffect } from "react";
import ProjectUsersList from "./ProjectUsersList";
import useGetProjectPermission from "../useGetProjectPermission";

const ViewProject = (props) => {

  const dispatch = useDispatch();
  const {currentProjectPermission} = useGetProjectPermission();
  let projectId = props?.match?.params?.id;

  const {
    project,
    reduxProjectId,
    projectLoading,
    projectError,
    languageError,
    platformError,
    translationServiceProviderError,
    languageLoading,
    translationServiceProviderLoading,
    platformLoading

  } = useSelector(
    state => ({
      project: state?.project?.projectEntity || null,
      reduxProjectId: state?.project?.projectEntity?.id || null,
      projectLoading: state?.project?.loading,
      projectError: state?.project?.error,
      languageError: state?.language?.error,
      platformError: state?.platform?.error,
      translationServiceProviderError: state?.translationServiceProvider?.error,
      languageLoading: state?.language?.loading,
      translationServiceProviderLoading: state?.translationServiceProvider?.loading,
      platformLoading: state?.platform?.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(platformActions.getAll());
    dispatch(translationProviderActions.getAll());
    dispatch(languageActions.getAll());
    dispatch(projectActions.getOne(projectId));
  }, [projectId, dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      {useMemo(() => {
        return (
          <PageTitle
            title=""
            middle={true}
            className="plr-7 minWidth-28"
            breadCrumb={[
              {
                name: "Projects",
                localizationId: "projects.title"
              },
              {
                name: project?.name || "Project-Name"
              },
              {
                name: "Detail",
                localizationId: "projectDetail.detail"
              }
            ]}
          />
        );
      }, [project])}

      {useMemo(() => {
        return (
          (projectLoading || languageLoading || translationServiceProviderLoading || platformLoading) ? (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "50vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          ) : null
        );
      }, [projectLoading, languageLoading, translationServiceProviderLoading, platformLoading])}

      {useMemo(() => {
        if (!projectLoading && !languageLoading && !translationServiceProviderLoading && !platformLoading) {
          if (reduxProjectId && !(projectError || languageError || platformError || translationServiceProviderError)) {
            return (
              <div className="plr-7 minWidth-28">
                <ViewProjectDetail project={project} currentProjectPermission={currentProjectPermission} />
                <ProjectUsersList />
              </div>
            );
          }
          else {
            return <ErrorComponent />;
          }
        }
      }, [
        reduxProjectId,
        project,
        projectError,
        languageError,
        platformError,
        translationServiceProviderError,
        currentProjectPermission,
        projectLoading, languageLoading, translationServiceProviderLoading, platformLoading
      ])}
    </>
  );
};

export default ViewProject;
