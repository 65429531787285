import * as requestFromServer from "./Cruds";
import {
  toastLoadingStart,
  toastLoadingEnd,
  // toastSuccess,
  toastError
} from "helper/toast";
import { translationServiceProviderSlice } from "./Reducer";
import btnLoadingActions from "redux/btnLoading/actions";
import { getLocalizedString } from "util/getLocalizedString";
const { actions } = translationServiceProviderSlice;

//get one records :
export const getOne = (translationServiceId) => dispatch => {
  const toastId = "fetchingDataFromServer";
  dispatch(actions.startCall());
  return requestFromServer
    .getOne(translationServiceId)
    .then(async response => {
      dispatch(actions.getOne({ data: response?.data || null }));
    })
    .catch(error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getOne--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

//get all records :
export const getAll = (shouldStartCall = true) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) {
    dispatch(actions.startCall());
  }
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch(error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      if (shouldStartCall) {
        const messageLocation = "--error from getAllTranslationServiceProvider--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      }
    });
};

// // create a new record :
export const create = (data, setmodal) => dispatch => {
  const toastId = "createTranslationService";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .create(data)
    .then(async response => {
      dispatch(actions.create({ data: response?.data }));
      dispatch(btnLoadingActions.setLoading(false));
      setmodal(false);
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.recordAdded"));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

// update a new record :
export const update = (id, payload, setmodal) => async (dispatch) => {
  const toastId = id || "createTranslationService";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .update(id, payload)
    .then(async response => {
      if (id && payload) dispatch(actions.update({ data: payload?.translationServiceData }));
      dispatch(btnLoadingActions.setLoading(false));
      setmodal(false);
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.recordUpdated"));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

// delete a new record :
export const remove = (id) => async dispatch => {
  const min = 1;
  const max = 1000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const toastId = `removeTranslationService_${randomNumber}`;
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .remove(id)
    .then(async response => {
      await dispatch(actions.remove({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
};

// //get all records :
export const removeAll = () => async (dispatch) => {
  const min = 1;
  const max = 1000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const toastId = `removeAllTranslationServices_${randomNumber}`;
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCall());
  await requestFromServer
    .removeAll()
    .then(async response => {
      await dispatch(getAll(false));
      toastLoadingEnd(
        toastId,
        "info",
        response?.data?.message || getLocalizedString("successMessages.recordsDeleted")
      );
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
};

export const clear = () => dispatch => {
  dispatch(actions.clear());
};
