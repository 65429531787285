import React from "react";
import "assets/customstyles/platforms.css";
import { Input } from "reactstrap";
import { LoadingButton } from "@mui/lab";
import * as placeHolderactions from "redux/app/placeholders/Actions";
import * as platformActions from "redux/app/platform/Actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControl, InputAdornment, InputLabel, Tooltip, Typography } from "@mui/material";
import { CommentBank, DeleteOutline, HelpCenterOutlined, HelpOutline } from "@mui/icons-material";
import EditPlatformPreviewPanel from "./EditPlatformPreviewPanel";
import { useIntlMessage } from "util/getIntlMessage";

const PlatformForm = ({ setmodal, object }) => {

  const localizationMessage = useIntlMessage();

  const dispatch = useDispatch();

  //redux
  const { loadingButton, placeHolderEntity } = useSelector(
    state => ({
      loadingButton: state?.buttonLoading?.loading,
      placeHolderEntity: state?.placeHolder?.entity
    }),
    shallowEqual
  );

  //Initial State
  const InitialState = {
    name: object?.name ? object?.name : "",
    startFormat: object?.startFormat ? object?.startFormat : "",
    endFormat: object?.endFormat ? object?.endFormat : "",
    iteration: object?.iteration ? object?.iteration : "",
    iterationSeprator: object?.iterationSeprator
      ? object?.iterationSeprator
      : "",
    fileName: object?.fileName ? object?.fileName : "",
    charactersToEscape: object?.charactersToEscape ? object?.charactersToEscape : "",
    escapeCharacter: object?.escapeCharacter ? object?.escapeCharacter : "",
    charactersToEncode: object?.charactersToEncode ? object?.charactersToEncode : "",
    placeholderId: object?.placeholderId ? object?.placeholderId : ""
  };

  const helpingIconColor = "#9ab4da";

  const helpingText = {
    name: localizationMessage("platforms.nameField.help"),
    startFormat: localizationMessage("platforms.startField.help"),
    endFormat: localizationMessage("platforms.endField.help"),
    iteration: localizationMessage("platforms.pairField.help"),
    iterationSeprator: localizationMessage("platforms.separatorField.help"),
    fileName: localizationMessage("platforms.fileNameField.help"),
    charactersToEscape: localizationMessage("platforms.charactersToEscapeField.help"),
    escapeCharacter: localizationMessage("platforms.escapeCharacterField.help"),
    charactersToEncode: localizationMessage("platforms.charactersToEncodeField.help"),
    placeholderId: localizationMessage("platforms.formatField.help"),
  }

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(" required"),
    startFormat: Yup.string(),
    endFormat: Yup.string(),
    iteration: Yup.string().required(" required"),
    iterationSeprator: Yup.string(),
    fileName: Yup.string().required(" required"),
    charactersToEscape: Yup.string().required(" required"),
    escapeCharacter: Yup.string().required(" required"),
    charactersToEncode: Yup.string().required(" required"),
    placeholderId: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    if (object) {
      dispatch(
        platformActions.update(object?.id, values, setmodal)
      );
    } else {
      dispatch(platformActions.create(values, setmodal));
    }
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  //useEffect
  useEffect(() => {
    dispatch(placeHolderactions.getAll());
  }, [dispatch]);

  return (
    <div className="d-flex">
      <div className="minWidth-32">
        <form id="form" className="project-form" onSubmit={formMik.handleSubmit}>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="name"
                disabled={object ? true : false}
                placeholder={localizationMessage("platforms.nameField.placeholder")}
                className={
                  formMik.errors.name && formMik.touched.name
                    ? "is-invalid"
                    : formMik.touched.name
                      ? "is-valid"
                      : ""
                }
                onChange={!object ? formMik.handleChange : null}
                onBlur={formMik.handleBlur}
                value={formMik.values.name}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.name.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }
                >
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="startFormat"
                placeholder={localizationMessage("platforms.startField.placeholder")}
                className={
                  formMik.errors.startFormat && formMik.touched.startFormat
                    ? "is-invalid"
                    : formMik.touched.startFormat
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.startFormat}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.startFormat.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <p style={{ fontSize: "14px" }}> {localizationMessage("platforms.key.description")}</p>
            <p style={{ fontSize: "14px" }}> {localizationMessage("platforms.value.description")}</p>
            <br />
            <div className="d-flex align-items-center">
              <Input
                name="iteration"
                placeholder={localizationMessage("platforms.pairField.placeholder")}
                className={
                  formMik.errors.iteration && formMik.touched.iteration
                    ? "is-invalid"
                    : formMik.touched.iteration
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.iteration}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.iteration.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="iterationSeprator"
                placeholder={localizationMessage("platforms.separatorField.placeholder")}
                className={
                  formMik.errors.iterationSeprator &&
                    formMik.touched.iterationSeprator
                    ? "is-invalid"
                    : formMik.touched.iterationSeprator
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.iterationSeprator}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.iterationSeprator.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="endFormat"
                placeholder={localizationMessage("platforms.endField.placeholder")}
                className={
                  formMik.errors.endFormat && formMik.touched.endFormat
                    ? "is-invalid"
                    : formMik.touched.endFormat
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.endFormat}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.endFormat.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="fileName"
                placeholder={localizationMessage("platforms.fileNameField.placeholder")}
                className={
                  formMik.errors.fileName && formMik.touched.fileName
                    ? "is-invalid"
                    : formMik.touched.fileName
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.fileName}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.fileName.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="charactersToEscape"
                placeholder={localizationMessage("platforms.charactersToEscape.placeholder")}
                className={
                  formMik.errors.charactersToEscape && formMik.touched.charactersToEscape
                    ? "is-invalid"
                    : formMik.touched.charactersToEscape
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.charactersToEscape}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.charactersToEscape.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="escapeCharacter"
                placeholder={localizationMessage("platforms.escapeCharacter.placeholder")}
                className={
                  formMik.errors.escapeCharacter && formMik.touched.escapeCharacter
                    ? "is-invalid"
                    : formMik.touched.escapeCharacter
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.escapeCharacter}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.escapeCharacter.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Input
                name="charactersToEncode"
                placeholder={localizationMessage("platforms.charactersToEncode.placeholder")}
                className={
                  formMik.errors.charactersToEncode && formMik.touched.charactersToEncode
                    ? "is-invalid"
                    : formMik.touched.charactersToEncode
                      ? "is-valid"
                      : ""
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.charactersToEncode}
              />
              <Tooltip 
                title={
                  <Typography>
                    {helpingText.charactersToEncode.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </Typography>
                }>
                <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
              </Tooltip>
            </div>
          </div>
          {/* ***************** */}
          <div className="form-group">
            <div className="form-group">
              <div className="d-flex align-items-center">
                <select
                  name="placeholderId"
                  className={
                    formMik.errors.placeholderId && formMik.touched.placeholderId
                      ? "is-invalid form-select"
                      : formMik.touched.placeholderId
                        ? "is-valid form-select"
                        : "form-select"
                  }
                  onChange={formMik.handleChange}
                  onBlur={formMik.handleBlur}
                  value={formMik.values.placeholderId}
                >
                  <option value="">
                    {localizationMessage("platforms.formatField.placeholder")}
                  </option>
                  {placeHolderEntity?.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.format}
                    </option>
                  ))}
                </select>
                <Tooltip 
                  title={
                    <Typography>
                      {helpingText.placeholderId.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </Typography>
                  }>
                  <HelpOutline className="ms-1" style={{fill: helpingIconColor}}/>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="flex">

            <LoadingButton
              className="btn form-button c-primary"
              type="submit"
              endIcon={<></>}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
            >
              <span className="formLoadingBtnText">
                {object ? localizationMessage("platforms.updatePlatform") : localizationMessage("platforms.addPlatform")}
              </span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <div className="platformPreviewPanel">
        <EditPlatformPreviewPanel formMik={formMik}/>
      </div>
    </div>
  );
};

export default PlatformForm;
