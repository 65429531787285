import React from "react";
import { connect } from "react-redux";
import PageviewsChartWidget from "components/widgets/pageviewsChartWidget/PageviewsChartWidget";
import PageTitle from "components/common/PageTitle";
const Intro = props => {
  const OpenAnalytics = name => {
    props.history.push(`/dashboard/${name}`);
  };

  return (
    <div>
      <PageTitle
        title=""
        middle={true}
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Dashboard"
          },
        ]}
      />
      <div className="plr-7 minWidth-27">
        <div className="row">
          <div className="col-12 col-sm-6 ptb-15">
            <PageviewsChartWidget
              title="All Projects Analytics"
              onClick={() => OpenAnalytics("Project")}
            />
          </div>
          <div className="col-12 col-sm-6 ptb-15">
            <PageviewsChartWidget
              title="String Analytics"
              onClick={() => OpenAnalytics("String")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 ptb-15">
            <PageviewsChartWidget
              title="Platforms Analytics"
              onClick={() => OpenAnalytics("Platforms")}
            />
          </div>
          <div className="col-12 col-sm-6 ptb-15">
            <PageviewsChartWidget
              title="Logs Analytics"
              onClick={() => OpenAnalytics("Logs")}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 ptb-15">
            <PageviewsChartWidget
              title="Favourite Analytics"
              onClick={() => OpenAnalytics("Favourite")}
            />
          </div>
          <div className="col-12 col-sm-6 ptb-15">
            <PageviewsChartWidget
              title="Store Analytics"
              onClick={() => OpenAnalytics("Store")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Intro);
