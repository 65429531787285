import React, { useLayoutEffect, useMemo, useRef } from "react";
import PageTitle from "components/common/PageTitle";
import TranslationServiceProviderTable from "views/app/translationServiceProvider/TranslationServiceProviderTable";
import * as translationServiceProviderActions from "redux/app/translation-service-provider/Actions";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Spinner from "reactstrap/lib/Spinner";
import ErrorComponent from "views/ErrorComponent";

const TranslationServiceProvider = () => {

  const dispatch = useDispatch();
  let isUseEffectCalled = useRef(false);

  const { providerEntity, providerLoading, providerError } = useSelector(
    state => ({
      providerEntity: state?.translationServiceProvider?.entity || null,
      providerLoading: state?.translationServiceProvider?.loading,
      providerError: state?.translationServiceProvider?.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    isUseEffectCalled.current = true;
    dispatch(translationServiceProviderActions.getAll());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title=""
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Translation Service Provider",
            localizationId: "translationServiceProvider.title"
          }
        ]}
      />

      {useMemo(() => {
        return (
          providerLoading && (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        );
      }, [providerLoading])}

      {useMemo(() => {
        if (!providerLoading && isUseEffectCalled.current) {
          if (providerEntity) {
            return <TranslationServiceProviderTable />;
          }
          if (providerError && !providerEntity) {
            return <ErrorComponent />;
          }
        }
      }, [providerEntity, providerLoading, providerError])}
    </>
  );
};

export default React.memo(TranslationServiceProvider);
