import React, { useState, useMemo } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../../components/reacttable/reacttbl.style";
import { Modal, ModalHeader, ModalFooter, ModalBody, Input, Button } from "reactstrap";
import AddFile from "components/forms/AddFile";
import BulkAddForm from "components/forms/BulkAddForm";
import * as stringsActions from "redux/app/strings/Actions";
import * as commonStringsAction from "redux/app/commonStrings/Actions";
import "assets/customstyles/projectStrings.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import StringList from "./StringList";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDeepCompareEffect } from "react-use";
import CommonStringList from "./CommonStringList";
import DropdownRedirection from "views/app/DropdownRedirection";
import ReactMentionsInput from "../../../../components/reactmentions/ReactMentionsInput";
import { stringVariableMentionsInputTags } from "util/data/stringPlaceholderVariableMentionsTags";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const ProjectStringsTable = () => {

  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  const [deleteAllAlert, setDeleteAllAlert] = useState(false);
  const [deleteAllAlertMultiple, setDeleteAllAlertMultiple] = useState(false);
  const [openGotoDropdown, setOpenGotoDropdown] = useState(false);
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;  

  let {
    project,
    projectLanguages,
    strings,
    commonStrings,
    commonStringLoading,
    loadingCreate,
    loadingString,
    fetchingData,
    loadingProgress
  } = useSelector(
    state => ({
      project: state?.project?.projectEntity,
      projectLanguages: state?.project?.projectEntity?.projectLanguages || [],
      strings: state?.string?.entity,
      commonStrings: state?.commonString?.entity,
      commonStringLoading: state?.commonString?.loading,
      loadingCreate: state?.string?.loadingCreate,
      loadingString: state?.string?.loading,
      fetchingData: state?.string?.fetchingData,
      loadingProgress: state?.buttonLoading?.loadingProgress,
    }),
    shallowEqual
  );

  const redirectionDropdownItems = [
    {
      name: localizationMessage("whatsNew.whatsNew"),
      redirect: `/projects/${project?.id}/whatsnew`
    },
    {
      name: localizationMessage("storeListing.storeListing"),
      redirect: `/projects/${project?.id}/storelisting`
    },
    {
      name: localizationMessage("logs.logs"),
      redirect: `/projects/${project?.id}/logs`
    },
    {
      name: localizationMessage("projectDetail.detail"),
      redirect: `/projects/${project?.id}/detail`
    }
  ];


  //form
  const dispatch = useDispatch();
  const [fileAddForm, setFileAddForm] = useState(false);
  const [bulkAddForm, setBulkAddForm] = useState(false);
  // dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const isDisabled = false;
  const getProjectDefaultLanguageIndex = () => {
    let indexValue = 0;
    if (projectLanguages?.length > 0) {
      indexValue = projectLanguages.indexOf(
        projectLanguages?.find(item => item.isDefault === 1)
      );
    }
    return indexValue;
  };

  const [projectLanguageIndex, setProjectLanguageIndex] = useState(
    getProjectDefaultLanguageIndex()
  );

  //Initial State
  const InitialStateCreateField = {
    key: "",
    value: ""
  };

  //Validation Schema :
  const ValidationSchemaCreateField = Yup.object().shape({
    key: Yup.string().required(" required"),
    value: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async (values, { resetForm }) => {
    values.key = values?.key.trim();
    const payload = {
      ...values,
      projectId: project?.id,
      projectLanguageId: projectLanguages[projectLanguageIndex]?.id
    };
    dispatch(stringsActions.create(payload, resetForm));
  };

  //formikCreateField Handler
  const formikCreateField = useFormik({
    initialValues: InitialStateCreateField,
    validationSchema: ValidationSchemaCreateField,
    onSubmit: SubmitHandler
  });

  const getProjectDefaultLanguageId = () => {
    let defaultLanguage = { id: "" };
    if (projectLanguages?.length > 0) {
      defaultLanguage = projectLanguages?.find(item => item.isDefault === 1);
    }
    return defaultLanguage?.id;
  };

  const DownloadAll = async (projId, projName) => {
    dispatch(stringsActions.downloadAll(projId, projName));
  };

  const DownloadCurrent = async (projLangId, projLangName, projName) => {
    dispatch(stringsActions.downloadCurrent(projLangId, projLangName, projName));
  };

  // translate strings of all languages
  const TranslateAllStrings = async () => {
    const payload = {
      projectId: project?.id
    };
    dispatch(
      stringsActions.allLanguageTranslateAll(
        payload,
        projectLanguages[projectLanguageIndex]?.id
      )
    );
  };

  // translate missing strings of all languages
  const TranslateAllMissingStrings = async () => {
    const payload = {
      projectId: project?.id
    };
    dispatch(
      stringsActions.allLanguageTranslateMissing(
        payload,
        projectLanguages[projectLanguageIndex]?.id
      )
    );
  };

  const CurrentTranslateAllStrings = async () => {
    const payload = {
      projectLanguageId: projectLanguages[projectLanguageIndex]?.id
    };
    dispatch(stringsActions.currentLanguageTranslateAll(payload));
  };

  const CurrentTranslateMissingStrings = async () => {
    const payload = {
      projectLanguageId: projectLanguages[projectLanguageIndex]?.id
    };
    dispatch(stringsActions.currentLanguageTranslateMissing(payload));
  };

  useDeepCompareEffect(() => {
    if (projectLanguages.length !== 0) {
      dispatch(
        stringsActions.getAllByprojectLangaugeId(
          project?.projectLanguages[getProjectDefaultLanguageIndex()]?.id
        )
      );
      dispatch(
        commonStringsAction.getAllByprojectLangaugeId(
          project?.projectLanguages[getProjectDefaultLanguageIndex()]?.id
        )
      );
    }
  }, [dispatch, project, projectLanguages, getProjectDefaultLanguageIndex()]);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   const noOfRowsToShow = event.target.value;

  //   setRowsPerPage(parseInt(noOfRowsToShow, 10));
  //   setPage(0);
  // };

  return (
    <div>
      <Modal
        isOpen={fileAddForm}
        fade={false}
        className="mx-auto minWidth-28 maxWidth-45"
        toggle={() => setFileAddForm(!fileAddForm)}
      >
        <ModalHeader toggle={() => setFileAddForm(!fileAddForm)}>
          {localizationMessage("projectString.addFileTitle")}
        </ModalHeader>
        <ModalBody>
          <AddFile
            projectData={project}
            projectSelectedLanguageId={
              projectLanguages[projectLanguageIndex]?.id
            }
            setFileAddForm={setFileAddForm}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={bulkAddForm}
        fade={false}
        className="mx-auto minWidth-28 maxWidth-45"
        toggle={() => setBulkAddForm(!bulkAddForm)}
      >
        <ModalHeader toggle={() => setBulkAddForm(!bulkAddForm)}>
          {localizationMessage("projectString.bulkAddTitle")}
        </ModalHeader>
        <ModalBody>
          <BulkAddForm
            projectData={project}
            projectSelectedLanguageId={
              projectLanguages[projectLanguageIndex]?.id
            }
            setBulkAddForm={setBulkAddForm}
          />
        </ModalBody>
      </Modal>

      {/* delete all strings dialogbox */}
      <Modal
        isOpen={deleteAllAlert}
        fade={false}
        toggle={() => setDeleteAllAlert(!deleteAllAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAllAlert(!deleteAllAlert)}>
          <span className="mr-5">
            {getProjectDefaultLanguageId() === projectLanguages[projectLanguageIndex]?.id ?
              localizationMessage("deleteAllFromAllLanguages") :
              localizationMessage("deleteAllFromCurrentLanguage")
            }
          </span>

          {/* <span className="mr-5">Delete all strings from all languages</span> */}
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => {
              setDeleteAllAlert(false);
            }}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              const isDefaultLanguage = (getProjectDefaultLanguageId() === projectLanguages[projectLanguageIndex]?.id) ? true : false;
              dispatch(stringsActions.removeAll(projectLanguages[projectLanguageIndex]?.id, isDefaultLanguage));
              setDeleteAllAlert(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>


      {/* delete all strings multiple dialogbox */}
      <Modal
        isOpen={deleteAllAlertMultiple}
        fade={false}
        toggle={() => setDeleteAllAlertMultiple(!deleteAllAlertMultiple)}
        className="mx-auto minWidth-28 maxWidth-45"
      // style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteAllAlertMultiple(!deleteAllAlertMultiple)}>
          <span className="mr-5">{localizationMessage("deleteAllStrings")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllFromConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => {
              // dispatch(commonStringsAction.removeAll());
              setDeleteAllAlertMultiple(false);
            }}
          >
            {localizationMessage("allLanguages")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              // dispatch(commonStringsAction.removeAllFromCurrentLanguage(languageEntity[languageIndex]?.id));
              setDeleteAllAlertMultiple(false);
            }}
          >
            {localizationMessage("currentLanguage")}
          </Button>
        </ModalFooter>
      </Modal>
    
    <div className="d-flex justify-content-end me-2">
      <DropdownRedirection 
        openDropdown={openGotoDropdown}
        setOpenDropdown={setOpenGotoDropdown}
        dropdownTitle={localizationMessage("goto")}
        items={redirectionDropdownItems}
      />
    </div>

      <ReactTableWrapper>
        <div className="plr-7 minWidth-27">
          <div className="roe-card-style mtb-15">
            <div>
              <div className="customHeader">
                {localizationMessage("projectString.commonStrings")}
              </div>

              <div className="customHeight mt-1">
                {
                  commonStrings.length === 0 &&
                    commonStringLoading === false &&
                    loadingCreate === false ? (
                    <div className="text-center font-weight-bold text-muted mt-2">
                      {localizationMessage("projectString.commonStringsNotAdded")}
                    </div>
                  ) : null
                }


                {useMemo(() => {
                  if (commonStrings.length > 0 && (!commonStringLoading)) {
                    return (
                      <div className="table-mainBorder">
                        {commonStrings.map((row, id_id) => (
                          <CommonStringList data={row} key={id_id} rowKey={id_id} languageId={projectLanguages[projectLanguageIndex]?.id} projectLanguageId={projectLanguages[projectLanguageIndex]?.id} />
                        ))}
                      </div>
                    );
                  }
                }, [commonStrings, commonStringLoading, projectLanguageIndex, projectLanguages])}
              </div>
            </div>
          </div>
        </div>
      </ReactTableWrapper>

      <ReactTableWrapper>
        <div className="plr-7 minWidth-27 pb-5">
          <div className="roe-card-style mtb-15">
            <div>
              <div className="customHeader">{localizationMessage("projectString.projectStrings")}</div>
              { 
                (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
                getProjectDefaultLanguageId() === projectLanguages[projectLanguageIndex]?.id 
                ? 
                (
                <form
                  name="newFieldForm"
                  onSubmit={formikCreateField.handleSubmit}>
                  <div className="table-border">
                    <div className="row flex head-row">
                      <div className="col-sm-4 flex v-LineForNotSmallScreen mt-1">
                        <i className="fas fa-user-gear icon-commonstr mr-2" />
                        <Input
                          placeholder={localizationMessage("key")}
                          name="key"
                          disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                          className={
                            formikCreateField.errors.key &&
                              formikCreateField.touched.key
                              ? "is-invalid valueInputClass"
                              : formikCreateField.touched.key
                                ? "is-valid valueInputClass"
                                : "valueInputClass"
                          }
                          style={{margin: "auto 0px"}}
                          onChange={formikCreateField.handleChange}
                          onBlur={formikCreateField.handleBlur}
                          value={formikCreateField.values.key}
                        />
                      </div>
                      <div className="col ml-sm-0 1 mb-1 mt-1">
                        <div style={{display: "flex", flexGrow: 1}} >
                          {/* <Input
                            placeholder={localizationMessage("value")}
                            name="value"
                            disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                            className={
                              formikCreateField.errors.value &&
                                formikCreateField.touched.value
                                ? "is-invalid valueInputClass"
                                : formikCreateField.touched.value
                                  ? "is-valid valueInputClass"
                                  : "valueInputClass"
                            }
                            onChange={formikCreateField.handleChange}
                            onBlur={formikCreateField.handleBlur}
                            value={formikCreateField.values.value}
                          /> */}
                          <div 
                            className={`custom-input ${formikCreateField?.errors?.value ? 'is-invalid' : formikCreateField?.values?.value ? 'is-valid' : ''}`}
                            >
                            <ReactMentionsInput
                              fieldName={"value"} 
                              fieldValue={formikCreateField.values.value} 
                              setFieldValue={formikCreateField.setFieldValue} 
                              mentionPlaceholder={localizationMessage("value")}
                              mensionsTrigger = {"/"}
                              mentionsInputTags = {stringVariableMentionsInputTags}
                              handleChangeFromParent = {formikCreateField.handleChange}
                              setValues = {formikCreateField.setValues}
                              handleSubmit = {formikCreateField.handleSubmit}
                            />
                          </div>
                          <InputGroupAddon addonType="append" style={{marginLeft: "1px"}}>
                            <LoadingButton
                              sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                              className="btnBorder leftRadius"
                              disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                              name="newFieldForm"
                              variant="outlined"
                              loading={loadingCreate}
                              type="submit"
                            >
                              <i className="fas fa-check" />
                            </LoadingButton>
                          </InputGroupAddon>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>)
                : null
              }

              <div className="customHeight">
                {
                  strings.length === 0 &&
                    loadingString === false &&
                    loadingCreate === false ? (
                    <div className="text-center font-weight-bold text-muted mt-2">
                      {localizationMessage("projectString.stringsNotAdded")}
                    </div>
                  ) : null}

                {/* <TablePagination

                  SelectProps={{
                    disabled: isDisabled
                  }}
                  backIconButtonProps={
                    isDisabled
                      ? {
                        disabled: isDisabled
                      }
                      : undefined
                  }
                  nextIconButtonProps={
                    isDisabled
                      ? {
                        disabled: isDisabled
                      }
                      : undefined
                  }

                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={34} //total no.of.rows
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}

                {useMemo(() => {
                  if (strings.length !== 0) {
                    return (
                      <div className="table-mainBorder">
                        {strings.map((row, id_id) => (
                          <StringList data={row} key={`key_${id_id + 1}`} rowKey={id_id} projectLanguage={projectLanguages[projectLanguageIndex]} />
                        ))}
                      </div>
                    );
                  }
                }, [strings, projectLanguages[projectLanguageIndex]?.id])}
              </div>
            </div>
          </div>
        </div>
      </ReactTableWrapper>

      <div className="groupClass justify-content-end d-flex flex-wrap ml-auto flex bottom-buttons customButtonsStyle _pl-16">
        {/* ************* ADD FILE *********** */}
        {
          (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
          getProjectDefaultLanguageId() === projectLanguages[projectLanguageIndex]?.id 
          ? 
          <div className="margin-all">
            <button
              disabled={fetchingData || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
              className="c-primary btn-shadow btn btn-secondary"
              onClick={() => setFileAddForm(!fileAddForm)}
            >
              <i className="fas fa-plus _mr-10" style={{ color: "#fff" }} />
              {localizationMessage("projectString.addFile")}
            </button>
          </div> 
          : 
          null
        }

        {/* ************* ADD BULK *********** */}
        {
          (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
          getProjectDefaultLanguageId() === projectLanguages[projectLanguageIndex]?.id 
          ? 
          <div className="margin-all">
            <button
              disabled={fetchingData || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
              className="c-primary btn-shadow btn btn-secondary"
              onClick={() => {
                setBulkAddForm(!bulkAddForm);
              }}
            >
              <i className="fas fa-plus _mr-10" style={{ color: "#fff" }} />
              {localizationMessage("projectString.bulkAdd")}
            </button>
          </div>
          : 
          null
        }

        {/* ************* PROJECT LANGUAGES *********** */}
        {projectLanguages[projectLanguageIndex]?.language?.name ? (
          <Dropdown
            direction="up"
            className="margin-all"
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle
              caret
              className="c-primary btn-shadow"
              disabled={fetchingData}
            >
              {projectLanguages[projectLanguageIndex]?.language?.name}{" "}
            </DropdownToggle>
            <DropdownMenu right>
              {projectLanguages?.length &&
                projectLanguages.map((data, index) => (
                  <DropdownItem
                    key={data?.id}
                    className="dropdown-item"
                    onClick={() => {
                      setProjectLanguageIndex(index);
                      dispatch(
                        stringsActions.getBothByprojectLangaugeId(data?.id, true, "toastPromise")
                      );
                    }}
                  >
                    {index === getProjectDefaultLanguageIndex() ? (
                      <span>
                        {`${data?.language?.name} `}
                        <span className="fw-bolder text-decoration-underline">
                          {"[Primary]"}
                        </span>
                      </span>
                    ) : (
                      data?.language?.name
                    )}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        ) : null}

        {/* ************* TRANSLATE ALL STRINGS *********** */}
        {
          (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
          getProjectDefaultLanguageId() === projectLanguages[projectLanguageIndex]?.id &&
          strings && 
          strings.length > 0
          ?
          <Dropdown
            direction="up"
            className="margin-all"
            isOpen={dropdownOpen1}
            toggle={() => setDropdownOpen1(!dropdownOpen1)}
          >
            <DropdownToggle
              caret
              className="c-primary btn-shadow"
              disabled={loadingProgress || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
            >
              {localizationMessage("translateAll")}{" "}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                className="dropdown-item"
                onClick={() => {
                  TranslateAllStrings();
                }}
              >
                {localizationMessage("allStrings")}
              </DropdownItem>
              <DropdownItem
                className="dropdown-item"
                onClick={() => {
                  TranslateAllMissingStrings();
                }}
              >
                {localizationMessage("missingStrings")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          : 
          null
        }

        {/* ************* TRANSLATE CURRENT LANGUAGE STRINGS *********** */}
        {
          (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
          getProjectDefaultLanguageId() !== projectLanguages[projectLanguageIndex]?.id &&
          strings && 
          strings.length > 0
          ?
          <Dropdown
            direction="up"
            className="margin-all"
            isOpen={dropdownOpen2}
            toggle={() => setDropdownOpen2(!dropdownOpen2)}
          >
            <DropdownToggle
              caret
              className="c-primary btn-shadow"
              // disabled={fetchingData}
              disabled={fetchingData || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
            >
              {localizationMessage("translate")}{" "}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                className="dropdown-item"
                onClick={() => {
                  CurrentTranslateAllStrings();
                }}
              >
                {localizationMessage("allStrings")}
              </DropdownItem>
              <DropdownItem
                className="dropdown-item"
                onClick={() => {
                  CurrentTranslateMissingStrings();
                }}
              >
                {localizationMessage("missingStrings")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          : 
          null
        }

        {/* ************* DOWNLOAD STRINGS *********** */}
        {strings && strings.length > 0 ? (
          <Dropdown
            direction="up"
            className="margin-all"
            isOpen={dropdownOpen3}
            toggle={() => setDropdownOpen3(!dropdownOpen3)}
          >
            <DropdownToggle
              caret
              className="c-primary btn-shadow"
              disabled={fetchingData}
            >
              {localizationMessage("download")}{" "}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                className="dropdown-item"
                onClick={() => {
                  const projLangId =
                    projectLanguages[projectLanguageIndex]?.id;
                  const projLangName =
                    projectLanguages[projectLanguageIndex]?.language?.name;
                  DownloadCurrent(projLangId, projLangName, project.name);
                }}
              >
                {projectLanguages[projectLanguageIndex]?.language?.name}
              </DropdownItem>
              <DropdownItem
                className="dropdown-item"
                onClick={() => {
                  DownloadAll(project?.id, project?.name);
                }}
              >
                {localizationMessage("allLanguages")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>) : null
        }

        {/* ************* Remove All *********** */}
        {
          (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
          strings && 
          strings.length > 0 
          ?
          <div className="margin-all">
            <button
              disabled={fetchingData || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
              className="c-primary btn-shadow btn btn-secondary"
              onClick={() => {
                setDeleteAllAlert(true)
              }}
            >
              {localizationMessage("removeAll")}
            </button>
          </div>
          :
          null
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(ProjectStringsTable));