import { getCurrentLanguage } from "settings/languageConfig";

const languageAction = {
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  ACTIVATE_LANG_MODAL: "ACTIVATE_LANG_MODAL",

  changeLanguage: language => {
    return {
      type: languageAction.CHANGE_LANGUAGE,
      language: getCurrentLanguage(language)
    };
  }
};
export default languageAction;
