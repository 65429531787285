import {
  toastLoadingStart,
  toastLoadingEnd,
  // toastSuccess,
  toastError
} from "helper/toast";
import btnLoadingActions from "redux/btnLoading/actions";
import * as requestFromServer from "./Cruds";
import { storeListingSlice } from "./Reducer";
import { getLocalizedString } from "util/getLocalizedString";
const { actions } = storeListingSlice;

//get all records :
export const getAll = (projectId, shouldStartCall = true) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getAll(projectId)
    .then(async response => {
      let isNew = true;
      const storeListing = response?.data?.projectLanguages || [];
      //checking if its new storeListing Object
      await Promise.all(
        storeListing.map(item => {
          if (item.storeListing) {
            isNew = false;
          }
          return item;
        })
      );
      dispatch(actions.getAll({ data: storeListing, isNew: isNew }));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getAll--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const create = (payloadCreate, projectId, shouldTranslateAll, payloadTranslateAll) => async dispatch => {
  const toastId = "createStoreListing";
  try {
    await dispatch(btnLoadingActions.setLoading(true));
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
    await requestFromServer.create(payloadCreate);
    if(shouldTranslateAll){
      await requestFromServer.TranslateAll(payloadTranslateAll, projectId);
    }
    const result = await requestFromServer.getAll(projectId);
    let isNew = true;
    const storeListing = result?.data?.projectLanguages || [];
    //checking if its new storeListing Object
    await Promise.all(
      storeListing.map(item => {
        if (item.storeListing) {
          isNew = false;
        }
        return item;
      })
    );
    await dispatch(actions.getAll({ data: storeListing, isNew: isNew }));
    await dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.processCompleted")
      );
  } catch (error) {
    await new Promise(resolve => setTimeout(resolve, 200));
    toastLoadingEnd(
      toastId,
      "error",
      error?.message || getLocalizedString("errorMessages.somethingWentWrong")
    );
    dispatch(btnLoadingActions.setLoading(false));
  }
};

export const update = (payloadUpdate, storeListingId, setIsEdit, projectId, shouldTranslateAll, payloadTranslateAll) => async (dispatch) => {
  const toastId = `updateStorelisting_${storeListingId}`;
  try {
    await dispatch(btnLoadingActions.setLoading(true));
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));

    await requestFromServer.Update(storeListingId, payloadUpdate);
    if(shouldTranslateAll){
      await requestFromServer.TranslateAll(payloadTranslateAll, projectId);
      const result = await requestFromServer.getAll(projectId);
      const storeListing = result?.data?.projectLanguages || [];
      await dispatch(actions.getAll({ data: storeListing}));
    }
    else{
      await dispatch(actions.update({ data: payloadUpdate }));
    }
    await dispatch(btnLoadingActions.setLoading(false));
    setIsEdit(false);
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.processCompleted")
      );
  } catch (error) {
    await new Promise(resolve => setTimeout(resolve, 200));
    toastLoadingEnd(
      toastId,
      "error",
      error?.message || getLocalizedString("errorMessages.somethingWentWrong")
    );
    dispatch(btnLoadingActions.setLoading(false));
  }
};

export const updateTemp = (payload, projLandId, setIsEdit) => dispatch => {
  const toastId = projLandId || "storeListingUpdate";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .Update(projLandId)
    .then(async response => {
      dispatch(actions.update({ data: payload }));
      setIsEdit(false);
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
      dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

export const translate = (payload, projectID) => async (dispatch) => {
  const toastId = payload?.projectLanguageId ? `storeListingtranslate_${payload?.projectLanguageId}` : "storeListingtranslate";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .Translate(payload)
    .then(async response => {
      await dispatch(getAll(projectID, false));
      await dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsTranslated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await dispatch(btnLoadingActions.setLoading(false));
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

export const translateAll = (payload, projectID) => async (dispatch) => {
  const toastId = projectID ? `storeListingtranslateAll_${projectID}` : "storeListingtranslateAll";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .TranslateAll(payload, projectID)
    .then(async response => {
      await dispatch(getAll(projectID, false));
      await dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsTranslated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await dispatch(btnLoadingActions.setLoading(false));
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};