import * as requestFromServer from "./Cruds";
import btnLoadingActions from "redux/btnLoading/actions";
import { platformSlice } from "./Reducer";
import {
  toastLoadingStart,
  toastLoadingEnd,
  // toastSuccess,
  toastError
} from "helper/toast";
import { getLocalizedString } from "util/getLocalizedString";
const { actions } = platformSlice;

//get all records :
export const getAll = () => dispatch => {
  const toastId = "fetchingDataFromServer";
  dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch(error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getAllPlatforms--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// create a new record :
export const create = (data, setmodal) => dispatch => {
  const toastId = "createPlatform";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  dispatch(btnLoadingActions.setLoading(true));
  return requestFromServer
    .create(data)
    .then(async response => {
      dispatch(actions.create({ data: response?.data }));
      setmodal(false);
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
      dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));

      dispatch(btnLoadingActions.setLoading(false));
    });
};

// update a new record :
export const update = (id, payload, setmodal) => dispatch => {
  const toastId = "updatePlatform";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .Update(id, payload)
    .then(async response => {
      dispatch(actions.update({ id: id, data: payload }));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from update--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));

      dispatch(btnLoadingActions.setLoading(false));
    });
};

// delete a new record :
export const remove = (id) => dispatch => {
  const toastId = id || "remove";
  toastLoadingStart(id, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .remove(id)
    .then(async response => {
      dispatch(actions.remove({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from remove--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

//get all records :
export const removeAll = () => dispatch => {
  const toastId = "removeAll";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCall());
  return requestFromServer
    .removeAll()
    .then(async response => {
      // dispatch(actions.getAll({ data: [] }));
      dispatch(getAll());
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from removeAll--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const clear = () => dispatch => {
  dispatch(actions.clear());
};
