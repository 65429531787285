import React, { useState, useMemo } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from "reactstrap";
// import AddFile from "components/forms/AddFile";
// import BulkAddForm from "components/forms/BulkAddForm";
import * as commonStringsAction from "redux/app/commonStrings/Actions";
import "assets/customstyles/projectStrings.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
// import StringList from "./StringList";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDeepCompareEffect } from "react-use";
import Spinner from "reactstrap/lib/Spinner";
import { useRef } from "react";
import StringList from "./StringList";
import { useEffect } from "react";
import { useCallback } from "react";
import AddAllToProject from "./AddAllToProject";
import AddToProject from "./AddToProject";
import ReactMentionsInput from "components/reactmentions/ReactMentionsInput";
import { stringVariableMentionsInputTags } from "util/data/stringPlaceholderVariableMentionsTags";
import { useIntlMessage } from "util/getIntlMessage";

const CommonStringsTable = () => {

  const localizationMessage = useIntlMessage();

  let {
    // project,
    // projectLanguages,
    languageEntity,
    strings,
    loadingCreate,
    loadingString,
    // stringError,
    fetchingData,
    userPermission
  } = useSelector(
    state => ({
      // project: state?.project?.entity,
      // projectLanguages: state?.project?.projectEntity?.projectLanguages || [],
      languageEntity: state?.language?.entity,
      strings: state?.commonString?.entity,
      loadingCreate: state?.commonString?.loadingCreate,
      loadingString: state?.commonString?.loading,
      // stringError: state?.commonString?.error,
      fetchingData: state?.commonString?.fetchingData,
      userPermission: state?.project?.projectEntity?.userPermission[0]?.permission || "viewer"
    }),
    shallowEqual
  );

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  //form
  const dispatch = useDispatch();
  // const [fileAddForm, setFileAddForm] = useState(false);
  // const [bulkAddForm, setBulkAddForm] = useState(false);
  const [deleteAlertAll, setDeleteAlertAll] = useState(false);
  const [deleteAlertAllCurrent, setDeleteAlertAllCurrent] = useState(false);
  const [deleteAlertMultiple, setDeleteAlertMultiple] = useState(false);
  const [addAllToProjectAlert, setAddAllToProjectAlert] = useState(false);
  const [deleteBulkAlert, setDeleteBulkAlert] = useState(false);


  // const [languageIndex, setLanguageIndex] = useState(0);
  const [addToProjectAlert, setAddToProjectAlert] = useState(false);
  const [commonStringkeyIdArray, setCommonStringkeyIdArray] = useState([]);

  const selectedRows = useRef([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [checkboxesTrueCount, setCheckboxesTrueCount] = useState(0);

  useEffect(() => {
    let count = checkboxes.filter((value) => value === true).length;
    setCheckboxesTrueCount(count);
  }, [checkboxes, checkboxesTrueCount]);

  useEffect(() => {
    setCheckboxes(Array.from({ length: strings.length }, () => false));
    selectedRows.current = [];
  }, [strings]);

  const languageIndex = 0;

  const lan_index = useRef(languageIndex);

  useDeepCompareEffect(() => {
    if (languageEntity.length > 0) {
      dispatch(
        commonStringsAction.getAllByLangaugeId(
          languageEntity[lan_index.current]?.id
        )
      );
    }
  }, [dispatch, languageEntity]);

  // dropdown
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [dropdownOpen1, setDropdownOpen1] = useState(false);
  // const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  // const [dropdownOpen4, setDropdownOpen4] = useState(false);

  const DownloadAll = async () => {
    dispatch(commonStringsAction.downloadAll());
  };

  const DownloadCurrent = async (languageId, languageName) => {
    dispatch(commonStringsAction.downloadCurrent(languageId, languageName));
  };

  //Initial State
  const InitialStateCreateField = {
    key: "",
    value: ""
  };

  //Validation Schema :
  const ValidationSchemaCreateField = Yup.object().shape({
    key: Yup.string().required(" required"),
    value: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async (values, { resetForm }) => {
    const payload = {
      ...values,
      comment: "",
      languageId: languageEntity[languageIndex]?.id
    };
    const success = await dispatch(commonStringsAction.create(payload, resetForm));
    if (success) {
      setSelectAll(false);
    }

    // handleCheckboxChange();
    // setSelectAll(false);
  };

  //formikCreateField Handler
  const formikCreateField = useFormik({
    initialValues: InitialStateCreateField,
    validationSchema: ValidationSchemaCreateField,
    onSubmit: SubmitHandler
  });

  const handleRowSelect = useCallback((index, data) => {
    if (!checkboxes[index]) {
      selectedRows.current.push(data);
    } else {
      selectedRows.current = selectedRows.current.filter((obj) => {
        return obj.id !== data.id
      });
    }
  }, [checkboxes]);

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map(() => !selectAll);
    setCheckboxes(updatedCheckboxes);
    if (selectAll) selectedRows.current = [];
    else selectedRows.current = strings;
    setSelectAll(!selectAll);
  };

  const handleAddToProject = () => {

    const arrayOfcommonStringkeyId = selectedRows.current.length > 0 ? selectedRows.current.map(obj => {
      const commonStringkeyIdSelected = obj?.commonStringValue[0]?.stringKeyId;
      return commonStringkeyIdSelected;
    }) : null;

    setCommonStringkeyIdArray(arrayOfcommonStringkeyId)

    // const payload = {
    //   commonStringKeyId: arrayOfcommonStringkeyId,
    //   projectId: project // it gives all the projects
    // }

    setAddToProjectAlert(true);
  }

  const handleDeleteBulk = () => {
    Delete("all");
    // const isPrimaryLanguage =
    //   reduxString?.entity[0]?.projectLanguage?.isDefault;
    // if (isPrimaryLanguage === 1) {
    // if (isDefaultLanguage()) {
    //   setDeleteAlertAll(true);
    // } else {
    //   setDeleteAlertMultiple(true);
    // }
  };

  const Delete = async option => {


    const arrayOfcommonStringkeyId = selectedRows.current.length > 0 ? selectedRows.current.map(obj => {
      const commonStringkeyIdSelected = obj?.commonStringValue[0]?.stringKeyId;
      return commonStringkeyIdSelected;
    }) : null;

    setCommonStringkeyIdArray(arrayOfcommonStringkeyId);

    const payload = {
      commonStringKeyId: arrayOfcommonStringkeyId,
      // projectId: project // it gives all the projects
    }

    const success = await dispatch(commonStringsAction.removeCommonString(option, payload));
    if (success) {
      setSelectAll(false);
      if (languageEntity.length > 0) {
        dispatch(
          commonStringsAction.getAllByLangaugeId(
            languageEntity[lan_index.current]?.id
          )
        );
      }
      // handleCheckboxChange(); // to update the selectAll checkbox to unchecked state when any string deleted
    }



    // setAddToProjectAlert(true);

    // const val = {
    //   option: option,
    //   stringValueId: string_valueId,
    //   stringkeyId: string_keyId,
    // };
    // const success = await dispatch(commonStringsAction.remove(val));
    // if (success) {
    //   handleCheckboxChange(); // to update the selectAll checkbox to unchecked state when any string deleted
    // }
  };


  return (
    <div>
      {/* <Modal
        isOpen={fileAddForm}
        fade={false}
        toggle={() => setFileAddForm(!fileAddForm)}
      >
        <ModalHeader toggle={() => setFileAddForm(!fileAddForm)}>
          Add File
        </ModalHeader>
        <ModalBody>
          <AddFile
            projectData={project}
            projectSelectedLanguageId={
              projectLanguages[languageIndex]?.id
            }
            setFileAddForm={setFileAddForm}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={bulkAddForm}
        fade={false}
        toggle={() => setBulkAddForm(!bulkAddForm)}
      >
        <ModalHeader toggle={() => setBulkAddForm(!bulkAddForm)}>
          Bulk Add Strings
        </ModalHeader>
        <ModalBody>
          <BulkAddForm
            projectData={project}
            projectSelectedLanguageId={
              projectLanguages[languageIndex]?.id
            }
            setBulkAddForm={setBulkAddForm}
          />
        </ModalBody>
      </Modal> */}

      <Modal
        isOpen={addAllToProjectAlert}
        fade={false}
        backdrop={"static"}
        keyboard={false}
        toggle={() => setAddAllToProjectAlert(!addAllToProjectAlert)}
      >
        <ModalHeader toggle={() => setAddAllToProjectAlert(!addAllToProjectAlert)}>
          {localizationMessage("commonString.addAllStringsToProject")}
        </ModalHeader>
        <ModalBody>
          <AddAllToProject setAddAllToProjectAlert={setAddAllToProjectAlert} />
          {/* <AddToProject
            // projectData={project}
            // projectSelectedLanguageId={
            //   projectLanguages[projectLanguageIndex]?.id
            // }
            // string_keyId={string_keyId}
            // setAddAllToProjectAlert={setAddAllToProjectAlert}
          /> */}
        </ModalBody>
      </Modal>



      {/* delete all dialogbox */}
      <Modal
        isOpen={deleteAlertAll}
        fade={false}
        toggle={() => setDeleteAlertAll(!deleteAlertAll)}
        className="mx-auto"
        style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteAlertAll(!deleteAlertAll)}>
          <span className="mr-5">{localizationMessage("deleteAllStrings")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => {
              setDeleteAlertAll(false);
            }}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(commonStringsAction.removeAll());
              setDeleteAlertAll(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={addToProjectAlert}
        fade={false}
        backdrop={"static"}
        keyboard={false}
        className="mx-auto maxWidth-45"
        toggle={() => setAddToProjectAlert(!addToProjectAlert)}
      >
        <ModalHeader toggle={() => setAddToProjectAlert(!addToProjectAlert)}>
          {localizationMessage("commonString.addToProject")}
        </ModalHeader>
        <ModalBody>
          {/* <AddMultipleStringsToProject
            // projectData={project}
            // projectSelectedLanguageId={
            //   projectLanguages[projectLanguageIndex]?.id
            // }
            string_keyId={commonStringkeyIdArray}
            setAddToProjectAlert={setAddToProjectAlert}
          /> */}
          <AddToProject
            string_keyId={commonStringkeyIdArray}
            setAddToProjectAlert={setAddToProjectAlert}
          />
        </ModalBody>
      </Modal>

      {/* delete multiple dialogbox */}
      <Modal
        isOpen={deleteAlertMultiple}
        fade={false}
        toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        className="mx-auto minWidth-28"
      // style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}>
          <span className="mr-5">{localizationMessage("deleteAllStrings")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllFromConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => {
              dispatch(commonStringsAction.removeAll());
              setDeleteAlertMultiple(false);
            }}
          >
            {localizationMessage("allLanguages")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(commonStringsAction.removeAllFromCurrentLanguage(languageEntity[languageIndex]?.id));
              setDeleteAlertMultiple(false);
            }}
          >
            {localizationMessage("currentLanguage")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* delete bulk/selected string's dialogbox */}
      <Modal
        isOpen={deleteBulkAlert}
        fade={false}
        toggle={() => setDeleteBulkAlert(!deleteBulkAlert)}
        className="mx-auto minWidth-28"
        style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteBulkAlert(!deleteBulkAlert)}>
          <span style={{ marginRight: "140px" }}>{localizationMessage("deleteSelectedStrings")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSelectedConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => {
              // dispatch(commonStringsAction.removeAll());
              setDeleteBulkAlert(false);
            }}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              // dispatch(commonStringsAction.removeAllFromCurrentLanguage(languageEntity[languageIndex]?.id));
              handleDeleteBulk();
              setDeleteBulkAlert(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>


      {/* delete all dialogbox */}
      <Modal
        isOpen={deleteAlertAllCurrent}
        fade={false}
        toggle={() => setDeleteAlertAllCurrent(!deleteAlertAllCurrent)}
        className="mx-auto"
        style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteAlertAllCurrent(!deleteAlertAllCurrent)}>
          <span className="mr-5">{localizationMessage("deleteAllFromCurrentLanguage")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteAllConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlertAllCurrent(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              // Delete("all");
              dispatch(commonStringsAction.remove(languageEntity[languageIndex]?.id));
              setDeleteAlertAllCurrent(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper>
        <div className="plr-7 minWidth-27 pb-5">
          <div className="roe-card-style mtb-15">
            <div>
              <div className="roe-card-header module-header bottom-buttons"></div>
              { 
                // (isSuperAdmin || ["admin", "editor"].includes(userPermission || "")) &&
                languageIndex === 0 
                ?
                <form
                  name="newFieldForm"
                  onSubmit={formikCreateField.handleSubmit}
                >
                  <div className="table-border">
                    <div className="row flex head-row">
                      <div className="col-sm-4 flex v-LineForNotSmallScreen mt-1">
                        <i className="fas fa-user-gear icon-commonstr mr-2" />
                        <Input
                          placeholder={localizationMessage("key")}
                          name="key"
                          className={
                            formikCreateField.errors.key &&
                              formikCreateField.touched.key
                              ? "is-invalid valueInputClass"
                              : formikCreateField.touched.key
                                ? "is-valid valueInputClass"
                                : "valueInputClass"
                          }
                          style={{margin: "auto 0px"}}
                          // disabled={!(isSuperAdmin || false)}
                          onChange={formikCreateField.handleChange}
                          onBlur={formikCreateField.handleBlur}
                          value={formikCreateField.values.key}
                        />
                      </div>
                      <div className="col ml-sm-0 1 mb-1 mt-1">
                        <div style={{display: "flex", flexGrow: 1}} >
                          {/* <Input
                            placeholder={localizationMessage("value")}
                            name="value"
                            className={
                              formikCreateField.errors.value &&
                                formikCreateField.touched.value
                                ? "is-invalid valueInputClass"
                                : formikCreateField.touched.value
                                  ? "is-valid valueInputClass"
                                  : "valueInputClass"
                            }
                            disabled={!(isSuperAdmin || false)}
                            onChange={formikCreateField.handleChange}
                            onBlur={formikCreateField.handleBlur}
                            value={formikCreateField.values.value}
                          /> */}
                          <div 
                            className={`custom-input ${formikCreateField?.errors?.value ? 'is-invalid' : formikCreateField?.values?.value ? 'is-valid' : ''}`}
                            >
                            <ReactMentionsInput
                              fieldName={"value"} 
                              fieldValue={formikCreateField.values.value} 
                              setFieldValue={formikCreateField.setFieldValue} 
                              mentionPlaceholder={localizationMessage("value")}
                              mensionsTrigger = {"/"}
                              mentionsInputTags = {stringVariableMentionsInputTags}
                              handleChangeFromParent = {formikCreateField.handleChange}
                              setValues = {formikCreateField.setValues}
                              handleSubmit = {formikCreateField.handleSubmit}
                              />
                          </div>
                          <InputGroupAddon addonType="append" style={{marginLeft: "1px"}}>
                            <LoadingButton
                              sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                              className="btnBorder leftRadius"
                              // disabled={!(isSuperAdmin || false)}
                              name="newFieldForm"
                              variant="outlined"
                              loading={loadingCreate}
                              type="submit"
                            >
                              <i className="fas fa-check" />
                            </LoadingButton>
                          </InputGroupAddon>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                : 
                null
              }

              {useMemo(() => {
                return (
                  loadingString && (
                    <div className="text-center">
                      <Spinner
                        color="#563c91"
                        type="grow"
                        style={{
                          color: "#563c91 !important",
                          textAlign: "center",
                          position: "fixed",
                          top: "55vh",
                          right: "45vw",
                          height: "3rem",
                          width: "3rem"
                        }}
                      >
                        Loading...
                      </Spinner>
                    </div>
                  )
                );
              }, [loadingString])}

              <div className="customHeight">
                {
                  strings?.length === 0 &&
                    loadingString === false &&
                    loadingCreate === false ? (
                    <div className="text-center font-weight-bold text-muted">
                      {localizationMessage("commonString.addNewStringRequestMessage")}
                    </div>
                  ) : null}

                { 
                  // (isSuperAdmin || ["admin", "editor"].includes(userPermission || "")) &&
                  strings?.length > 0 
                  ?
                  <div className="d-flex mx-3" style={{ border: "1px solid #cfcfcf", backgroundColor: "white", height: "36px" }}>
                    <input
                      type="checkbox"
                      title="selectAll"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      style={{ height: "16px", width: "16px", alignSelf: "center" }}
                      className="ml-2 mb-0 pb-0"
                    />

                    {checkboxesTrueCount > 0 ?
                      <div className="ml-auto align-self-center">
                        <button
                          className="btn btn-secondary dropdownStringButton "
                          title="filtering"
                          type="button"
                          id="filterButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fas fa-bars"></i>

                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="filterButton"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              // setAddToProjectAlert(true);
                              handleAddToProject();
                            }}
                          >
                            {localizationMessage("commonString.addToProject")}
                          </button>
                          <button
                            className="dropdown-item"
                            disabled={!(isSuperAdmin || false)}
                            type="reset"
                            onClick={() => {
                              // deleteOptions();
                              setDeleteBulkAlert(true)
                              // if (languageId === 1) setDeleteAlertAll(true);
                              // else setDeleteAlertCurrent(true);
                            }}
                          >
                            {localizationMessage("delete")}
                          </button>
                        </div>
                      </div> :

                      <div className="my-2">

                      </div>

                      // <button
                      //   className="btn btn-secondary dropdownStringButton ml-auto"
                      //   title="filtering"
                      //   type="button"
                      // >
                      //   <i className="fas fa-bars"></i>

                      // </button>
                    }

                  </div> 
                  : 
                  null
                }

                {useMemo(() => {

                  const handleCheckboxChange = (index, row) => {
                    const updatedCheckboxes = [...checkboxes];
                    updatedCheckboxes[index] = !updatedCheckboxes[index];
                    setCheckboxes(updatedCheckboxes);

                    const isAllChecked = updatedCheckboxes.every((checkbox) => checkbox);
                    setSelectAll(isAllChecked);

                    if (row?.commonStringValue[0]) {
                      // const info = row.commonStringValue[0];
                      // const obj = {
                      //   key: row?.key,
                      //   value: info?.value,
                      //   comment: info?.comment,
                      //   stringKeyId: info?.stringKeyId,
                      //   stringValueId: info?.id,
                      //   languageId: info?.languageId,
                      // }
                    }

                    handleRowSelect(index, row)
                  };

                  if (strings?.length > 0) {
                    return (
                      <div className="table-mainBorder">
                        {strings.map((row, id_id) => (
                          <StringList data={row} key={id_id} rowKey={id_id} languageId={languageEntity[languageIndex]?.id}
                            checked={checkboxes[id_id]}
                            handleCheckboxChange={() => handleCheckboxChange(id_id, row)}
                          />
                        ))}
                      </div>
                    );
                  }
                }, [strings, languageEntity, languageIndex, checkboxes, handleRowSelect])}

              </div>
            </div>
          </div>

          <div className="groupClass justify-content-end d-flex flex-wrap ml-auto flex bottom-buttons customButtonsStyle _pl-16">

            {/* ************* ADD FILE *********** */}
            {/* <div className="margin-all">
              <button
                disabled={fetchingData}
                className="c-primary btn-shadow btn btn-secondary"
                onClick={() => setFileAddForm(!fileAddForm)}
              >
                <i className="fas fa-plus _mr-10" style={{ color: "#fff" }} />
                Add File
              </button>
            </div> */}

            {/* ************* ADD BULK *********** */}
            {/* <div className="margin-all">
              <button
                disabled={fetchingData}
                className="c-primary btn-shadow btn btn-secondary"
                onClick={() => setBulkAddForm(!bulkAddForm)}
              >
                <i className="fas fa-plus _mr-10" style={{ color: "#fff" }} />
                Bulk Add
              </button>
            </div> */}

            {/* ************* All LANGUAGES *********** */}
            {/* {languageEntity?.length > 0 ? (
              <Dropdown
                direction="up"
                className="margin-all"
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
              >
                <DropdownToggle
                  caret
                  className="c-primary btn-shadow"
                  disabled={fetchingData}
                >
                  {languageEntity[languageIndex]?.name}{" "}
                </DropdownToggle>
                <DropdownMenu right>
                  <div style={{ height: "150px", overflowY: "auto" }}>
                    {
                      languageEntity.map((data, index) => (
                        <DropdownItem
                          key={data?.id}
                          className="dropdown-item"
                          onClick={() => {
                            setLanguageIndex(index);
                            dispatch(commonStringsAction.getAllByLangaugeId(languageEntity[index]?.id, true, "toastPromise"));
                          }}
                        >
                          {index === 0 ? (
                            <span>
                              {`${data?.name} `}
                              <span className="fw-bolder text-decoration-underline">
                                {"[Primary]"}
                              </span>
                            </span>
                          ) : (
                            data?.name
                          )}
                        </DropdownItem>
                      ))}
                  </div>
                </DropdownMenu>
              </Dropdown>
            ) : null} */}

            {/* ************* TRANSLATE ALL STRINGS *********** */}
            {/* {getProjectDefaultLanguageId() ===
              projectLanguages[languageIndex]?.id ? (
              <Dropdown
                direction="up"
                className="margin-all"
                isOpen={dropdownOpen1}
                toggle={() => setDropdownOpen1(!dropdownOpen1)}
              >
                <DropdownToggle
                  caret
                  className="c-primary btn-shadow"
                  disabled={fetchingData}
                >
                  Translate All{" "}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => {
                      TranslateAllStrings();
                    }}
                  >
                    All Strings
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => {
                      TranslateAllMissingStrings();
                    }}
                  >
                    Missing Strings
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null} */}

            {/* ************* TRANSLATE CURRENT LANGUAGE STRINGS *********** */}
            {/* {getProjectDefaultLanguageId() !==
              projectLanguages[languageIndex]?.id ? (
              <Dropdown
                direction="up"
                className="margin-all"
                isOpen={dropdownOpen2}
                toggle={() => setDropdownOpen2(!dropdownOpen2)}
              >
                <DropdownToggle
                  caret
                  className="c-primary btn-shadow"
                  disabled={fetchingData}
                >
                  Translate{" "}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => {
                      CurrentTranslateAllStrings();
                    }}
                  >
                    All Strings
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => {
                      CurrentTranslateMissingStrings();
                    }}
                  >
                    Missing Strings
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null} */}

            {/* ************* Add all strings to one project *********** */}
            {/* {strings && strings.length > 0 ? (
              <div className="margin-all">
                <button
                  disabled={fetchingData}
                  className="c-primary btn-shadow btn btn-secondary"

                  onClick={() => {
                    if (languageEntity[languageIndex]?.id === 1) setAddAllToProjectAlert(true)
                    // else setDeleteAlertMultiple(true);

                  }}
                >
                  Add All
                </button>
              </div>) : null
            } */}

            {/* ************* DOWNLOAD STRINGS *********** */}
            {strings && strings.length > 0 ? (
              <div className="margin-all">
                <button
                  // disabled={fetchingData}
                  disabled={fetchingData}
                  className="c-primary btn-shadow btn btn-secondary"

                  onClick={() => {
                    DownloadCurrent(languageEntity[languageIndex]?.id, languageEntity[languageIndex]?.name);
                  }}
                >
                  {localizationMessage("download")}
                </button>
              </div>) : null
            }

            {/* ************* Remove All *********** */}
            {/* <Dropdown
              direction="up"
              className="margin-all"
              isOpen={dropdownOpen4}
              toggle={() => setDropdownOpen4(!dropdownOpen4)}
            >
              <DropdownToggle
                caret
                className="c-primary btn-shadow"
                disabled={fetchingData}
              >
                Remove All{" "}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => {
                    setDeleteAlertAllCurrent(true);
                  }}
                >
                  {languageEntity[languageIndex]?.name}
                </DropdownItem>
                <DropdownItem
                  className="dropdown-item"
                  onClick={() => {
                    setDeleteAlertAll(true);
                  }}
                >
                  All Languages
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}

            {/* ************* Remove All *********** */}
            {/* {strings && strings.length > 0 ? (
              <div className="margin-all">
                <button
                  // disabled={fetchingData}
                  disabled={fetchingData || !(isSuperAdmin || false)}
                  className="c-primary btn-shadow btn btn-secondary"

                  onClick={() => {
                    if (languageEntity[languageIndex]?.id === 1) setDeleteAlertAll(true)
                    else setDeleteAlertMultiple(true);

                  }}
                >
                  Remove All
                </button>
              </div>) : null
            } */}

          </div>
        </div>
      </ReactTableWrapper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(CommonStringsTable));
