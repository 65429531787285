import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.UserAndPermission;

// export function getAll() {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" }
//   });
// }

export function getAll() {
  return axios[API.GetAll.Method](API.GetAll.Url,);
}


export function getAllUserPermission(queryString) {
  let url = API.GetAllUserPermission.Url;
  url = url.replace("##query##", `?${queryString}`);
  return axios[API.GetAllUserPermission.Method](url,
    //    {
    //   order: { createdAt: "ASC" }
    // }
    {
      where: { superAdmin: false }
    }
  );
}

// export function getByUserId(userId) {
//   return axios[API.GetOne.Method](API.GetOne.Url,
//     {
//       order: { createdAt: "ASC" },
//       // where: { key: { userId: userId } },
//       where: { userId: userId },
//       relations: ["project", "user"]
//     }
//   );
// }

export function getByUserId(id) {
  let url = API.GetOnes.Url;
  url = url.replace("##paramid##", id);
  return axios[API.GetOnes.Method](url);
}

export function getOne() {
  return axios[API.GetOne.Method](API.GetOne.Url, {
    order: { createdAt: "ASC" },
    // where: { user_id: "FIHC4ZD9uURzPjLysU1E5WDd9Hp2" },
  });
}



// export function getOne(id) {
//   return axios[API.GetOne.Method](API.GetOne.Url, {
//     order: { createdAt: "ASC" },
//     where: { id: id },
//     relations: [
//       "projectLanguages",
//       "translationService",
//       "projectLanguages.language",
//       "projectPlatforms",
//       "projectPlatforms.platform"
//     ]
//   });
// }

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function removeAll() {
  return axios[API.DeleteAll.Method](API.DeleteAll.Url);
}

export function remove(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}

export function removeUserProjects(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}

export function removeUser(id) {
  let userURl = ApiUrl.Users.Delete.Url;
  // const payload = [id]
  let data = {
    ids: [id]
  }

  // userURl = userURl.replace("##paramid##", id);
  // const url = `${"http://192.168.0.109:3002"}${apiName}`;
  return axios({
    url: userURl,
    method: "DELETE",
    data,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  // return axios[ApiUrl.Users.Delete.Method](userURl, payload);
}


export function removeProjectPermission(id) {
  let url = API.DeleteProjectPermission.Url;
  url = url.replace("##paramid##", id);
  return axios[API.DeleteProjectPermission.Method](url);
}

export function Update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}

export function updateUser(id, payload) {
  let url = API.UpdateUser.Url;
  url = url.replace("##paramid##", id);
  return axios[API.UpdateUser.Method](url, payload);
}

export function updateExistingUserAndPermission(id, payload) {
  let url = API.updateExistingUserAndPermission.Url;
  url = url.replace("##paramid##", id);
  return axios[API.updateExistingUserAndPermission.Method](url, payload);
}

export function addProject(id, payload) {
  let url = API.AddProject.Url;
  url = url.replace("##paramid##", id);
  return axios[API.AddProject.Method](url, payload);
}

export function UpdatePermission(id, payload) {
  let url = API.UpdatePermission.Url;
  url = url.replace("##paramid##", id);
  return axios[API.UpdatePermission.Method](url, payload);
}