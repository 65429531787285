import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.StoreListing;

export function getAll(projectId) {
  let url = API.GetByPorjectId.Url;
  url = url.replace("##paramid##", projectId);
  return axios[API.GetByPorjectId.Method](url);
}

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function Translate(payload) {
  let url = API.Translate.Url;
  return axios[API.Translate.Method](url, payload);
}

export function TranslateAll(payload, projectId) {
  let url = API.TranslateAll.Url;
  url = url.replace("##paramid##", projectId);
  return axios[API.TranslateAll.Method](url, payload);
}

// export function removeAll() {
//   return axios[API.DeleteAll.Method](API.DeleteAll.Url);
// }

// export function remove(id) {
//   let url = API.Delete.Url;
//   url = url.replace("##paramid##", id);
//   return axios[API.Delete.Method](url);
// }

export function Update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}
