import React, { useMemo, useState, useCallback, useRef } from "react";
import { useTable, useSortBy } from "react-table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Button from "components/button/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import * as projectsActions from "redux/app/projects/Actions";
import classnames from "classnames";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import "assets/customstyles/users&permissions.css";
import { useIntlMessage } from "util/getIntlMessage";
import ProjectUserAddForm from "./ProjectUserAddForm";
import useGetProjectPermission from "../useGetProjectPermission";
import ProjectPermissionUpdateForm from "./ProjectPermissionUpdateForm";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const ProjectUsersList = props => {
  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  const {
    loading,
    userPermissionList,
    superAdmins,
    buttonLoading,
    usersPerPageDefault,
    languageLocale
  } = useSelector(
    state => ({
      loading: state?.project?.loading,
      userPermissionList: state?.project?.projectEntity?.userPermission || [],
      superAdmins: state?.project?.projectEntity?.superAdmins || [],
      buttonLoading: state?.buttonLoading?.loading,
      usersPerPageDefault: state?.pagination?.usersPerPageDefault,
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  // adding superadmins into the list of users that have access to specific project
  const user = JSON.parse(localStorage.getItem("user"));
  // Transform anotherArray to match the structure of anArray
  const modifiedSuperAdminsArray = superAdmins.map(value => ({
    user: value
  }));
  const modifiedUserPermissionList = [...userPermissionList, ...modifiedSuperAdminsArray]
  const tblData = modifiedUserPermissionList;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(usersPerPageDefault);
  const [searchText, setSearchText] = useState("");
  const [modal, setmodal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [row, setRow] = useState(null);

  const dispatch = useDispatch();

  const formAction = useCallback(
    (action, data = null) => {
      if (action === "delete") {
        setRow(data);
        setDeleteConfirm(true);
      } else if (action === "editProjectPermission") {
        setRow(data);
        setPermissionModal(true);
      }
    },
    [props.history]
  );

  const columns = useMemo(
    () => [
      {
        Header: (tableInstance) => {
          return <HeaderComponent title="Sr." />;
        },
        accessor: "user_id",
        Cell: (tableInstance) => {
          return (
            <div className="react-action-class">
              {rowsPerPage * page + parseInt(tableInstance?.row?.id) + 1}
            </div>
          );
        },
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              title={localizationMessage("projectDetail.userName")}
            />
          );
        },
        accessor: "user.name",
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              title={localizationMessage("projectDetail.userEmail")}
            />
          );
        },
        accessor: "user.email",
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              title={localizationMessage("projectDetail.permission")}
            />
          );
        },
        accessor: "permission",
        Cell: (tableInstance) => (
          <div
            className={`react-action-class ${
              (user?.superAdmin || ["admin"].includes(currentProjectPermission)) &&
              user?.id != tableInstance?.row?.original?.userId &&
              !tableInstance?.row?.original?.user?.superAdmin
                ? "clickableRowStyle"
                : ""
            }`}
            onClick={() => {
              if (
                (user?.superAdmin || ["admin"].includes(currentProjectPermission)) &&
                user?.id != tableInstance?.row?.original?.userId &&
                !tableInstance?.row?.original?.user?.superAdmin
              ) {
                formAction("editProjectPermission", tableInstance.row.original);
              }
            }}
          >
            {tableInstance?.row?.original?.user?.superAdmin
              ? localizationMessage("systemAdmin")
              : localizationMessage(tableInstance?.row?.original?.permission)}
          </div>
        ),
      },
      {
        Header: (tableInstance) => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title={localizationMessage("delete")}
            />
          );
        },
        accessor: "delete",
        disableSortBy: true,
        Cell: (tableInstance) => (
          <div className="react-action-class">
            <Button
              className={`c-btn c-btn-sm ${
                !((user?.superAdmin || ["admin"].includes(currentProjectPermission)) &&
                user?.id != tableInstance?.row?.original?.userId &&
                !tableInstance?.row?.original?.user?.superAdmin)
                  ? ""
                  : "c-danger"
              }`}
              disabled={
                buttonLoading ||
                !((user?.superAdmin || ["admin"].includes(currentProjectPermission)) &&
                user?.id != tableInstance?.row?.original?.userId &&
                !tableInstance?.row?.original?.user?.superAdmin)
              }
              onClick={() => formAction("delete", tableInstance.row.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>
        ),
      },
    ],
    [
      formAction,
      rowsPerPage,
      page,
      user?.superAdmin,
      languageLocale,
      currentProjectPermission,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: tblData,
      columns: columns,
      manualSortBy: true,
    },
    useSortBy
  );

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setmodal(!modal)}>
        <ModalHeader toggle={() => setmodal(!modal)}>
          {localizationMessage("projectDetail.addNewUser")} 
        </ModalHeader>
        <ModalBody>
          <ProjectUserAddForm setModal={setmodal}/>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={permissionModal}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setPermissionModal(!permissionModal)}>
        <ModalHeader toggle={() => setPermissionModal(!permissionModal)}>
          {localizationMessage("userAndPermission.updateProjectPermission")}
        </ModalHeader>
        <ModalBody>
          <ProjectPermissionUpdateForm 
            setModal={setPermissionModal} 
            row={row}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteConfirm}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setDeleteConfirm(!deleteConfirm)}
      >
        <ModalHeader toggle={() => setDeleteConfirm(!deleteConfirm)}>
          <span className="_mr-5">{localizationMessage("userAndPermission.deleteUser")} </span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteConfirm(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={async () => {
              setDeleteConfirm(false)
              const success_remove = await dispatch(projectsActions.removeProjectPermission(row.id));
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper {...props}>
        <div className="plr-7 minWidth-28">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header flex">

              <h4 className="grey--text detail-heading d-inline">
                {localizationMessage("projectDetail.assignee")}
              </h4>
              <div className="d-flex flex-wrap justify-content-end ml-auto">
                <Button
                  className="c-btn c-primary d-block d-sm-inline ml-auto ml-sm-2"
                  disabled={buttonLoading || !(user?.superAdmin || ["admin"].includes(currentProjectPermission))}
                  onClick={() => setmodal(!modal)}
                  >
                    <i className="fas fa-plus _mr-10" />
                    {localizationMessage("projectDetail.addAssignee")}
                </Button>
              </div>
            </div>

            {userPermissionList && userPermissionList.length > 0 && loading === false ?
              <div className="table-container text-center overflow-auto">
                <table
                  border={1}
                  className="custom-react-table-theme-class"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps(
                              header.getSortByToggleProps()
                            )}
                          >
                            <div>{header.render("Header")}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {rows?.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr 
                            className={`${row?.original?.user?.id===user?.id ? "loggedInUserStyle" : ""}`} 
                            {...row.getRowProps()}
                            >
                            {row.cells.map((cell, index) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
              </div>
              : null
            }

            {
              userPermissionList?.length === 0 &&
                loading === false ? (
                <div className="text-center font-weight-bold text-muted mb-2">
                  {localizationMessage("projectDetail.emptyUserListMessage")}
                </div>
              ) : null
            }
          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(ProjectUsersList));