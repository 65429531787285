import {
  toastLoadingStart,
  toastLoadingEnd,
  toastError,
} from "helper/toast";
import * as requestFromServer from "./Cruds";
import * as requestFromServerwhatsnew from "../whatsnew/Cruds";
import { whatsnewVersionSlice } from "./Reducer";
import btnLoadingActions from "redux/btnLoading/actions";
import { getLocalizedString } from "util/getLocalizedString";
// import { whatsnewSlice } from "../whatsnew/Reducer";
const { actions } = whatsnewVersionSlice;
// const { whatsnewActions } = whatsnewSlice;

//get all records :
export const getAll = projectId => dispatch => {
  const toastId = "fetchingDataFromServer";
  dispatch(actions.startCall());
  return requestFromServer
    .getAll(projectId)
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getAll--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// create a new record :
export const create = (data, setmodal, shouldTranslateAll) => async dispatch => {
  const toastId = data?.projectId || "createWhatsNewVersion";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .create(data)
    .then(async response => {
      await dispatch(actions.create({ data: response?.data }));
      const whatsNewVersionid = response?.data?.id;
      // console.log("ugugug 1:", data, response, whatsNewVersionid);

      if(shouldTranslateAll){
        // console.log("ugugug 2:", data, response, whatsNewVersionid);
        await requestFromServerwhatsnew.TranslateAll(whatsNewVersionid);
      }
      // await requestFromServerwhatsnew.getAllByVersionId(whatsNewVersionid);
      // await dispatch(whatsnewActions.getAll({ data: response?.data || [] }));


      
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
      setmodal(false);
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await dispatch(btnLoadingActions.setLoading(false));
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

// delete a new record :
export const remove = (id) => async dispatch => {
  let success = false;
  const min = 1;
  const max = 1000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const toastId = `removeWhatsNewVersion_${randomNumber}`;
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .remove(id)
    .then(async response => {
      await dispatch(actions.remove({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.whatsNewVersionDeleted")
      );
      await dispatch(btnLoadingActions.setLoading(false));
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
      success = false;
    });
    return success;
};
