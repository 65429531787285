import React, { useState } from "react";
import "assets/customstyles/projectStrings.css";
import { Input, InputGroup, InputGroupAddon, ModalFooter } from "reactstrap";
import * as stringsActions from "redux/app/strings/Actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CommentFormModal from "./CommentFormModal";
import ReactMentionsInput from "../../../../components/reactmentions/ReactMentionsInput";
import { useEffect } from "react";
import { stringVariableMentionsInputTags } from "util/data/stringPlaceholderVariableMentionsTags";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const StringList = (props) => {
  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  let {
    loadingUpdate,
    loadingButton,
    project
  } = useSelector(
    (state) => ({
      loadingUpdate: state?.string?.loadingUpdate,
      loadingButton: state?.buttonLoading?.loading,
      project: state?.project,
    }),
    shallowEqual
  );

  const languageDirection = props?.data?.languageDirection;
  const dispatch = useDispatch();
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;
  const row = props?.data;
  const projectLanguage = props?.projectLanguage;
  const [isEdit, setIsEdit] = useState(false);
  const [deleteAlertAll, setDeleteAlertAll] = useState(false);
  const [deleteAlertMultiple, setDeleteAlertMultiple] = useState(false);
  const [translateOneAlertMultiple, setTranslateOneAlertMultiple] = useState(false);
  const [translateOneAlert, setTranslateOneAlert] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  // const [translationType, setTranslationType] = useState(row?.type || "auto");
  const translationType = row?.type;

  //Initial State
  const InitialStateUpdateField = {
    value: row?.value || "",
  };


  //Validation Schema :
  const ValidationSchemaUpdateField = Yup.object().shape({
    value: Yup.string().required(" required"),
  });

  //Handler for Update field submit button
  const SubmitUpdateHandler = async (values) => {
    const payload = {
      key: row?.key,
      comment:
        row?.comment?.length > 0 ? row?.comment : "",
      ...values,
      type: "manual"
    };
    const success = await dispatch(stringsActions.update(row?.stringKeyId, projectLanguage?.id, row?.stringValueId, payload, setIsEdit, "toastSimple"));
    // fetch all string data after updating record
    // if (success) {
    //   dispatch(
    //     stringsActions.getAllByprojectLangaugeId(
    //       projectLanguage?.id
    //     ));
    // }
  };

  const formik = useFormik({
    initialValues: InitialStateUpdateField,
    validationSchema: ValidationSchemaUpdateField,
    onSubmit: SubmitUpdateHandler,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e); 

    // if (value.includes('@')) {
    //   // Add logic to modify 'value' based on your requirements
    //   formik.setFieldValue(name, value + 'additional characters');
    // } else {
    //   formik.handleChange(e); // Default handling if no special conditions
    // }
  };

  const isEnglishLanguage = ()=>{
    if(props?.projectLanguage?.language?.name==="English") return true;
    return false;
  }

  const isDefaultLanguage = () => {
    if (row?.projectLanguageId && project?.projectDefaultLanguage?.id) {
      if (row?.projectLanguageId === project?.projectDefaultLanguage?.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const Delete = async (option) => {
    const val = {
      option: option,
      stringKeyId: [row?.stringKeyId],
      stringValueId: [row?.stringValueId],
    };
    // dispatch(stringsActions.remove(val));


    const success_remove = await dispatch(stringsActions.remove(val));
    if (success_remove) {
      dispatch(
        stringsActions.getAllByprojectLangaugeId(
          row?.projectLanguageId
        ));
    }
  };

  const translateOneString = async (option) => {
    const payload = {
      projectLanguageId: row?.projectLanguageId,
      // projectId: 
      stringKeyId: row?.stringKeyId,
      stringValueId: row?.stringValueId,
    };

    const success_translate = await dispatch(stringsActions.translateOne(option, payload));
    if (success_translate) {
      dispatch(
        stringsActions.getAllByprojectLangaugeId(
          row?.projectLanguageId
        ));
    }
  };

  const translateOneOptions = () => {
    if (isDefaultLanguage()) {
      setTranslateOneAlert(true);
    } else {
      setTranslateOneAlertMultiple(true);
    }
  };

  const deleteOptions = () => {
    if (isDefaultLanguage()) {
      setDeleteAlertAll(true);
    } else {
      setDeleteAlertMultiple(true);
    }
  };

  const handleChangeTranslationType = async (transType) => {

    const payload = {
      key: row?.key,
      comment:
        row?.comment?.length > 0 ? row?.comment : "",
      value: row?.value,
      type: transType
    };
    const success = await dispatch(stringsActions.update(row?.stringKeyId, projectLanguage?.id, row?.stringValueId, payload, undefined, "toastPromise"));
    if (success) {
      // dispatch(
      //   stringsActions.getAllByprojectLangaugeId(
      //     projectLanguage?.id
      //   ));
      // setTranslationType(transType)
    }

  };

  // Ensure Formik's initialValues update when `row` prop changes
  useEffect(() => {
    formik.setValues({
      value: row?.value || "",
    });
  }, [row]);

  return (
    <>
      <Modal
        isOpen={translateOneAlertMultiple}
        fade={false}
        toggle={() => setTranslateOneAlertMultiple(!translateOneAlertMultiple)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader
          toggle={() =>
            setTranslateOneAlertMultiple(!translateOneAlertMultiple)
          }
        >
          {localizationMessage("translateStringFromNonPrimaryLanTitle")}
        </ModalHeader>
        <ModalBody>{localizationMessage("whereToTranslateStringConfirmationMsg")}</ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary modalButtonSize"
            type="button"
            disabled={loadingButton}
            onClick={() => {
              setTranslateOneAlertMultiple(false);
              translateOneString("all");
            }}
          >
            {localizationMessage("allLanguages")}
          </Button>
          <Button
            className="btn ml-2 c-primary modalButtonSize"
            type="button"
            disabled={loadingButton}
            onClick={() => {
              setTranslateOneAlertMultiple(false);
              translateOneString("one");
            }}
          >
            {localizationMessage("currentLanguage")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={translateOneAlert}
        fade={false}
        toggle={() => setTranslateOneAlert(!translateOneAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setTranslateOneAlert(!translateOneAlert)}>
          <span className="mr-5">{localizationMessage("translateStringToAllLanguages")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("translateSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            type="button"
            onClick={() => setTranslateOneAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            type="button"
            disabled={loadingButton}
            onClick={() => {
              setTranslateOneAlert(false);
              translateOneString("all");
            }}
          >
            {localizationMessage("translate")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={commentModal}
        fade={false}
        toggle={() => setCommentModal(!commentModal)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setCommentModal(!commentModal)}>
          {localizationMessage("editStringComment")}
        </ModalHeader>
        <ModalBody>
          <CommentFormModal
            object={{
              stringKeyId: row?.stringKeyId,
              projectLanguageId: row?.projectLanguageId,
              // projectLanguageId: project?.projectDefaultLanguage?.id,
              stringValueId: row?.stringValueId,
              key: row?.key,
              value: row?.value,
              comment: row?.comment,
              type: row?.type
            }}
            setCommentModal={setCommentModal}
            isDefaultLanguage={isDefaultLanguage}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteAlertMultiple}
        fade={false}
        toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader
          toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        >
          {localizationMessage("deleteString")}
        </ModalHeader>
        <ModalBody>{localizationMessage("deleteSingleFromConfirmationMessage")}</ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary modalButtonSize"
            type="button"
            disabled={loadingButton}
            onClick={() => {
              setDeleteAlertMultiple(false);
              Delete("all");
            }}
          >
            {localizationMessage("allLanguages")}
          </Button>
          <Button
            className="btn ml-2 c-primary modalButtonSize"
            type="button"
            disabled={loadingButton}
            onClick={() => {
              setDeleteAlertMultiple(false);
              Delete("one");
            }}
          >
            {localizationMessage("currentLanguage")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* delete all dialogbox */}
      <Modal
        isOpen={deleteAlertAll}
        fade={false}
        toggle={() => setDeleteAlertAll(!deleteAlertAll)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAlertAll(!deleteAlertAll)}>
          <span className="mr-5">{localizationMessage("deleteOneStringFromAllLanguages")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            type="button"
            onClick={() => setDeleteAlertAll(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            type="button"
            disabled={loadingButton}
            onClick={() => {
              setDeleteAlertAll(false);
              Delete("all");
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <form name="editStringFieldForm" onSubmit={formik.handleSubmit}>
        <div>
          {isEdit ? (
            <div className="row flex edit-row">
              <div className="col-12 col-sm-4 flex v-LineForNotSmallScreen pl-2 mt-2 mt-sm-0">
                <i className="fas fa-user-gear icon-commonstr ml-2" />
                <div className="input"></div>
                <div className="key">
                  <div className="overflow-hidden">{row?.key}</div>
                </div>
              </div>
              <div className="col ml-sm-0 1 mb-1 mt-1 mb-sm-0 mt-sm-0">
                {/* <div 
                  // className={`custom-input ${formik.errors.value && formik.touched.value ? 'is-invalid' : formik.touched.value ? 'is-valid' : ''}`}
                  className={`custom-input ${formik?.errors?.value ? 'is-invalid' : 'is-valid'}`}
                  >
                  <ReactMentionsInput 
                    fieldName={"value"} 
                    fieldValue={formik.values.value} 
                    setFieldValue={formik.setFieldValue} 
                    mensionsTrigger = {"@"}
                    mentionsInputTags = {mentionsInputTags}
                    handleChangeFromParent = {handleChange}
                    setValues = {formik.setValues}
                    />
                </div> */}

                <div style={{display: "flex", flexGrow: 1}} >
                  {/* <Input
                    autoFocus={true}
                    name="value"
                    style={
                      languageDirection === "rtl" ? { direction: "rtl" } : {}
                    }
                    className={
                      formik.errors.value && formik.touched.value
                        ? "is-invalid valueInputClass"
                        : formik.touched.value
                        ? "is-valid valueInputClass"
                        : "valueInputClass"
                    }
                    // onChange={formik.handleChange}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    // defaultValue={row?.value}
                    value={formik.values.value}
                  /> */}
                  <div 
                    className={`custom-input ${formik?.errors?.value ? 'is-invalid' : 'is-valid'}`}
                    >
                    <ReactMentionsInput 
                      fieldName={"value"} 
                      fieldValue={formik.values.value} 
                      setFieldValue={formik.setFieldValue} 
                      mentionPlaceholder={localizationMessage("value")}
                      mensionsTrigger = {"/"}
                      mentionsInputTags = {stringVariableMentionsInputTags}
                      handleChangeFromParent = {handleChange}
                      setValues = {formik.setValues}
                      handleSubmit = {formik.handleSubmit}
                      />
                  </div>
                  <InputGroupAddon addonType="append" style={{marginLeft: "1px"}}>
                    <LoadingButton
                      sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                      className="btnBorder rounded-0"
                      type="submit"
                      loading={loadingUpdate}
                      variant="outlined"
                    >
                      <i className="fas fa-check" />
                    </LoadingButton>
                    <LoadingButton
                      sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                      className="btnBorder leftRadius"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      <i className="fas fa-xmark" />
                    </LoadingButton>
                  </InputGroupAddon>
                </div>
              </div>
            </div>
          ) : (
            <div id="data-rowInstance" className="row flex display-row">
              <div className="col-12 col-sm-4 p-0 flex v-LineForNotSmallScreen pl-2 mt-1 mt-sm-0">
                {/* <i className="fas fa-user-gear icon-commonstr ml-2 " /> */}

                <div 
                  className="dropdown ml-1 translationBtnDropdown d-flex" 
                  title={
                    isDefaultLanguage() ? (
                      localizationMessage("manuallyAdded")
                    ) : !row?.value ? (
                      localizationMessage("notAddedYet")
                    ) : translationType === "manual" ? (
                      localizationMessage("manuallyAdded")
                    ) : localizationMessage("autoGenerated")
                  }
                  >
                  <button
                    className="btn btn-light my-auto"
                    disabled={isDefaultLanguage() || !row?.value || loadingUpdate}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      isDefaultLanguage() ? (
                        <i className="fas fa-user-gear icon-commonstr " />
                      ) : !row?.value ? (
                        <i className="fas fa-question icon-commonstr" />
                      ) : translationType === "manual" ? (
                        <i className="fas fa-user-gear icon-commonstr" />
                      ) : <i className="fas fa-gears icon-commonstr" />
                    }
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    title=""
                    style={{
                      zIndex: "6",
                    }}
                  >
                    <div
                      className="btn-group-vertical btn-group-toggle"
                      // data-toggle="buttons"
                    >
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={
                          () => {
                            const payload = `${translationType === "auto" ? "manual" : "auto"}`;
                            handleChangeTranslationType(payload)
                          }
                        }
                      >
                        <span>
                          {
                            translationType==="auto" 
                            ? 
                            localizationMessage("changeToManual") 
                            : 
                            localizationMessage("changeToAuto") 
                          }
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="key overflow-hidden">{row?.key}</div>
              </div>
              <div className="col-12 col-sm-8 p-0 flex pl-2 mb-1 mt-2 mb-sm-0 mt-sm-0">
                <div 
                  className="ml-4 ml-sm-0 overflow-hidden flex" 
                  style={{flexGrow: 1}}
                  onDoubleClick={()=>{
                    if((isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission))){
                      setIsEdit(true);
                    }
                  }}
                  >
                  <div
                    className="value"
                    style={
                      languageDirection === "rtl" ? { direction: "rtl", whiteSpace: "pre-wrap" } : {whiteSpace: "pre-wrap"}
                    }
                  >
                    {row?.value}
                  </div>
                </div>

                {
                  (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission))
                  ?
                  <div className="p-0 mr-1 w-auto d-flex row align-content-start align-items-center">
                    <div className="col-12 col-sm-6 p-0 order-sm-0 order-last d-flex justify-content-end">
                      <div className="width-20 mr-0 _pr-10">
                        <button
                          type="button"
                          onClick={() => setCommentModal(true)}
                          className={
                            row?.comment?.length > 0
                              ? "d-block p-0 mx-auto"
                              : "d-none"
                          }
                        >
                          <i
                            className="fa-solid fa-file-lines _fs-18"
                            style={{ color: "#757575" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div className="dropdown col-12 col-sm-6 p-0 d-flex justify-content-end">
                      <button
                        className="btn btn-secondary dropdownStringButton"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-ellipsis-vertical" />
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ zIndex: "6" }}
                      >
                        <button
                          className="dropdown-item font-weight-bold"
                          disabled={
                            !(
                              isSuperAdmin ||
                              ["admin", "editor"].includes(
                                currentProjectPermission
                              ) ||
                              false
                            )
                          }
                          type="button"
                          onClick={() => {
                            setIsEdit(true);
                          }}
                        >
                          {localizationMessage("edit")}
                        </button>
                        <button
                          className="dropdown-item font-weight-bold"
                          disabled={
                            !(
                              isSuperAdmin ||
                              ["admin", "editor"].includes(
                                currentProjectPermission
                              ) ||
                              false
                            )
                          }
                          type="reset"
                          onClick={() => {
                            deleteOptions();
                          }}
                        >
                          {localizationMessage("delete")}
                        </button>
                        {
                          row?.value
                          ?
                          <button
                            type="button"
                            className={
                              row?.comment?.length > 0
                                ? "d-none"
                                : "dropdown-item font-weight-bold"
                            }
                            onClick={() => {
                              setCommentModal(true);
                            }}
                          >
                            {localizationMessage("comment")}
                          </button>
                          :
                          null
                        }
                        {
                          isEnglishLanguage() && row?.value ? (
                            <button
                              className="dropdown-item font-weight-bold"
                              disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission))}
                              type="button"
                              onClick={() => {
                                const payload = {
                                  key: row?.key,
                                  value: row?.value,
                                };
                                dispatch(
                                  stringsActions.addStringToCommonStrings(payload)
                                );
                              }}
                            >
                              {localizationMessage("projectString.addToCommonStrings")}
                            </button>
                          ) : null
                        }
                        <button
                          className="dropdown-item font-weight-bold"
                          disabled={
                            !(
                              isSuperAdmin ||
                              ["admin", "editor"].includes(
                                currentProjectPermission
                              ) ||
                              false
                            )
                          }
                          type="button"
                          onClick={() => {
                            translateOneOptions();
                          }}
                        >
                          {localizationMessage("translate")}
                        </button>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }


              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default StringList;