import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DropdownRedirection = ({openDropdown, setOpenDropdown, dropdownTitle, items=[]}) => {
  const history = useHistory();
  return (
    <div className="">
      <Dropdown
        direction="down"
        className="margin-all"
        isOpen={openDropdown}
        toggle={() => setOpenDropdown(!openDropdown)}
      >
        <DropdownToggle
          caret
          className="c-primary fs-12"
        >
          {dropdownTitle}
        </DropdownToggle>
        <DropdownMenu right>
          {
            items.map((item, index)=>{
              return (
                <DropdownItem
                  className="dropdown-item"
                  key={`DropdownItem_${index + 1}`}
                  onClick={() => {
                    if(item?.redirect){
                      history.push(item?.redirect);
                    }
                  }}
                >
                  {item?.name}
                </DropdownItem>
              )
            })
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default DropdownRedirection;
