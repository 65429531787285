import React from "react";
import "assets/customstyles/projects.css";
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import ProjectFormModal from "./ProjectFormModal";
import { useState, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DropdownRedirection from "../DropdownRedirection";
import { useIntlMessage } from "util/getIntlMessage";
import { status } from "helper/constant";

const ViewProjectDetail = ({ project, currentProjectPermission }) => {

  const localizationMessage = useIntlMessage();

  const [modal, setModal] = useState(false);
  const [openGotoDropdown, setOpenGotoDropdown] = useState(false);

  const redirectionDropdownItems = [
    {
      name: localizationMessage("projectString.strings"),
      redirect: `/projects/${project?.id}/strings`
    },
    {
      name: localizationMessage("logs.logs"),
      redirect: `/projects/${project?.id}/logs`
    },
    {
      name: localizationMessage("whatsNew.whatsNew"),
      redirect: `/projects/${project?.id}/whatsnew`
    },
    {
      name: localizationMessage("storeListing.storeListing"),
      redirect: `/projects/${project?.id}/storelisting`
    }
  ];

  const history = useHistory();
  

  const styles = {
    small_heading:
      "detail-heading-small col-lg-3 col-md-3 col-sm-12 text-secondary fw-bold"
  };
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  return (
    <>
      {useMemo(() => {
        if (modal) {
          return (
            <ProjectFormModal
              setModal={setModal}
              modal={modal}
              isEdit={true}
              data={project}
            />
          );
        }
      }, [modal, project])}

      <div className="roe-shadow-2">
        <div className="flex detail-header flex-wrap">
          <div className="d-flex">
            <h4 className="grey--text detail-heading d-inline">
              {project?.name}
            </h4>
            <h5 className="detail-status d-inline">
              <Badge className="c-warning">
              {
                project?.status === status.INACTIVE
                ?
                localizationMessage("inactive")
                :
                localizationMessage("active")
              }
              </Badge>
            </h5>
          </div>

          <div className="ml-auto d-flex mt-2 mt-sm-0">
            <div className="d-flex">
              <Button
                className="c-primary py-1 fs-12"
                // only below mentioned user's can have access to this button
                disabled={!(isSuperAdmin || ["admin"].includes(currentProjectPermission) || false)}
                onClick={() => setModal(true)}
              >
                {localizationMessage("edit")}
              </Button>
            </div>
            <div className="ml-2">
              <DropdownRedirection 
                openDropdown={openGotoDropdown}
                setOpenDropdown={setOpenGotoDropdown}
                dropdownTitle={localizationMessage("goto")}
                items={redirectionDropdownItems}
              />
            </div>
          </div>

        </div>

        <div className="detail-description-box">
          <div className="detail-des row">
            <div className={`${styles.small_heading} text-nowrap`}>{localizationMessage("projectDetail.primaryLanguage")}</div>
            <div className="col c-text-focus">
              {project?.projectLanguages?.length ? (
                project?.projectLanguages.map((data, i) => {
                  if (data?.isDefault === 1) {
                    return (
                      <div key={i}>
                        <Badge className="mr-1" color="secondary" pill>
                          {data?.language?.name}
                        </Badge>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="detail-des row">
            <div className={`${styles.small_heading} text-nowrap`}>{localizationMessage("projectDetail.translationLanguages")}</div>
            <div className="col c-text-focus d-flex flex-wrap">
              {project?.projectLanguages?.length ? (
                project?.projectLanguages.map((data, i) => {
                  return (
                    <div key={i}>
                      {data.isDefault !== 1 ? (
                        <Badge
                          key={i}
                          className="mr-1"
                          style={{ backgroundColor: "rgb(37, 150, 190)" }}
                          pill
                        >
                          {data?.language?.name}
                        </Badge>
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="detail-des row">
            <div className={`${styles.small_heading} text-nowrap`}>{localizationMessage("projectDetail.translationService")}</div>
            <div className="col c-text-focus d-flex flex-wrap">
              {project?.translationService?.name ? (
                <div>
                  <Badge
                    className="mr-1"
                    style={{ backgroundColor: "rgb(37, 150, 190)" }}
                    pill
                  >
                    {project?.translationService?.name}
                  </Badge>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="detail-des row">
            <div className={`${styles.small_heading} text-nowrap`}>{localizationMessage("projectDetail.projectPlatforms")}</div>
            <div className="col c-text-focus d-flex flex-wrap">
              {project?.projectPlatforms?.length > 0 ? (
                project?.projectPlatforms.map((data, i) => {
                  return (
                    <div key={i}>
                      <Badge
                        key={i}
                        className="mr-1"
                        color="primary"
                        // style={{ backgroundColor: "default" }}
                        pill
                      >
                        {data?.platform?.name}
                      </Badge>
                    </div>
                  );
                })
              ) : (
                <>null</>
              )}
            </div>
          </div>

          <div className="detail-des row">
            <div className={`${styles.small_heading} text-nowrap`}>{localizationMessage("projectDetail.description")}</div>
            <div
              className="col c-text-focus font-weight-bold"
              style={{
                overflowWrap: "break-word"
              }}
            >
              {project?.description}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProjectDetail;
