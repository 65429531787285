import React, { useEffect, useState } from "react";
import { loginBack, iconDemo } from "helper/constant";
import axios from "axios";
import { ApiUrl } from "services/Api";
import { LoadingButton } from "@mui/lab";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthActions from "redux/auth/actions";

const Register = props => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");


  let { authLoading } = useSelector(
    state => ({
      authLoading: state?.auth?.loading
    }),
    shallowEqual
  );


  //Initial State
  const InitialState = {
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string().required("This field is required").email("Invalid email address"),
    password: Yup.string().required("This field is required").min(8),
    confirmPassword: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref('password'), null], "Passwords must match")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    dispatch(AuthActions.setLoading(true));
    try {
      await axios.post(`${ApiUrl.Users.CreateSuperAdmin.Url}`, {
        name: values.name,
        email: values.email,
        password: values.password,
      });
      props.history.push("/login");
      dispatch(AuthActions.setLoading(false));
    } catch (error) {
      setError(error.message);
      dispatch(AuthActions.setLoading(false));
    }
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axios.get(`${ApiUrl.Users.CheckSuperAdmin.Url}`);
        if (response.data === true) {
          props.history.push("/login");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    checkAdmin();
  }, [props.history]);

  const { handleChange, handleBlur, errors, touched, submitCount } = formMik;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div>
        <div className="form-container">
          <div className="login-icon">
            <img src={iconDemo} alt="icon" height="100px" />
          </div>
          <div className="login-title">Create Super Admin</div>
          <form className="pa-24" onSubmit={formMik.handleSubmit}>
          {(error && !authLoading) ? <div className="message-error">{error}</div> : null}
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Name"
              />
              <Error field="name" />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control react-form-input"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email"
              />
              <Error field="email" />
            </div>

            <div className="form-group">
              <label>Create Password</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Create Password"
              />
              <Error field="password" />
            </div>

            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Confirm Password"
              />
              <Error field="confirmPassword" />
            </div>

            <LoadingButton
              className="btn form-button c-success"
              type="submit"
              endIcon={<></>}
              loading={authLoading}
              loadingPosition="end"
              variant="contained"
              fullWidth
            >
            <span className="">Register</span>
          </LoadingButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
