import React, { useEffect, useLayoutEffect, useMemo } from "react";
import PageTitle from "components/common/PageTitle";
import * as userAndPermissionActions from "redux/app/userAndPermission/Actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as projectsActions from "redux/app/projects/Actions";
import Spinner from "reactstrap/lib/Spinner";
import ErrorComponent from "views/ErrorComponent";
import UsersProjectList from "./UsersProjectList";

const UserPermissions = () => {

  const dispatch = useDispatch();
  const {
    loading,
    backFlow,
    userAndPermissionError,
    usersPerPageDefault
  } = useSelector(
    (state) => ({
      loading: state?.userAndPermission?.loading,
      backFlow: state?.userAndPermission?.backFlow,
      userAndPermissionError: state?.userAndPermission?.error,
      usersPerPageDefault: state?.pagination?.usersPerPageDefault,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(projectsActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(userAndPermissionActions.getAllUserPermission({ take: usersPerPageDefault, skip: 0, count: -1, order: "ASC", searchText: "" }, true));
  }, [dispatch, usersPerPageDefault]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title=""
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Users And Permission",
            localizationId: "userAndPermission.userAndPermission"
          }
        ]}
      />

      {useMemo(() => {
        return (
          loading && (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        );
      }, [loading])}

      {useMemo(() => {
        if (!loading && !backFlow) {
          if (userAndPermissionError) {
            return <ErrorComponent />;
          }
          return <UsersProjectList />;
        }
      }, [loading, backFlow, userAndPermissionError])}
    </>
  );
};
export default UserPermissions;
