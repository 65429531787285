import React from "react";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import authActions from "redux/auth/actions";
import { ApiUrl } from "services/Api";
import axios from "axios";
import {
  toastLoadingStart,
  toastLoadingEnd
} from "helper/toast";
import {
  getAuth
} from "firebase/auth";
import { useIntlMessage } from "util/getIntlMessage";
const UpdateProfile = () => {
  const localizationMessage = useIntlMessage();
  const dispatch = useDispatch();
  const [loadButton, setLoadButton] = useState(false);

  //redux
  // const { user, auth } = useSelector(
  //   state => ({
  //     user: state.auth.user,
  //     auth: state.auth
  //   }),
  //   shallowEqual
  // );

  const user = JSON.parse(localStorage.getItem("user"));

  const initialValues = {
    name: user?.name ? user?.name : "",
    displayName: user?.displayName ? user?.displayName : ""
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Current Password is Required!"),
    displayName: Yup.string().required("New Password is Required!")
  });

  const SubmitHandler = async values => {
    setLoadButton(true);
    toastLoadingStart("updateProfile", localizationMessage("infoMessages.processingRequest"));
    try {
      const auth = getAuth();
      let url = ApiUrl.Users.Update.Url;
      url = url.replace("##paramid##", user.id);
      axios[ApiUrl.Users.Update.Method](url, values, {
        headers: {
          Authorization: "Bearer " + auth.accessToken
        }
      })
        .then(() => {
          const data = { ...user, name: values?.name, displayName: values?.displayName }
          dispatch(authActions.update(data));
          toastLoadingEnd("updateProfile", "success", localizationMessage("successMessages.profileUpdated"));
          setLoadButton(false);
        })
        .catch(async (error) => {
          await new Promise(resolve => setTimeout(resolve, 200));
          toastLoadingEnd(
            "updateProfile",
            "error",
            error?.message || localizationMessage("errorMessages.somethingWentWrong")
          );
          setLoadButton(false);
        });
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 200));
      toastLoadingEnd(
        "updateProfile",
        "error",
        error?.message || localizationMessage("errorMessages.somethingWentWrong")
      );
      setLoadButton(false);
    }
  };
  //formMik Handler
  const formMik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <div className="minWidth-27">
      <div className="py-2 card my-2 ml-2 ml-sm-0 mr-2">
        <h5 className="header text-center">
          {localizationMessage("settings.EditProfile.label")}
        </h5>
        <form className="pa-24" onSubmit={formMik.handleSubmit}>
          {/* ****************** */}
          <div className="form-group ">
            <label>{localizationMessage("settings.EditProfile.nameField.label")}</label>
            <input
              type="text"
              placeholder={localizationMessage("settings.EditProfile.nameField.placeholder")}
              className={
                formMik.errors.name && formMik.touched.name
                  ? "is-invalid form-control "
                  : formMik.touched.name
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.name}
              name="name"
            />
          </div>
          {/* ****************** */}
          <div className="form-group">
            <label>{localizationMessage("settings.EditProfile.displayNameField.label")}</label>
            <input
              type="text"
              placeholder={localizationMessage("settings.EditProfile.displayNameField.placeholder")}
              className={
                formMik.errors.displayName && formMik.touched.displayName
                  ? "is-invalid form-control "
                  : formMik.touched.displayName
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.displayName}
              name="displayName"
            />
          </div>

          <button
            type="submit"
            disabled={loadButton}
            className="btn form-button c-primary"
          >
            {localizationMessage("settings.EditProfile.updateProfile")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfile;
