export const whats_new = [
  { id: 1, versionName: "version-1", description: "description here 1" },
  { id: 2, versionName: "version-2", description: "description here 2" },
  { id: 3, versionName: "version-3", description: "description here 3" },
  { id: 4, versionName: "version-4", description: "description here 4" },
  { id: 5, versionName: "version-5", description: "description here 5" },
  { id: 6, versionName: "version-6", description: "description here 6" },
  { id: 7, versionName: "version-7", description: "description here 7" },
  { id: 8, versionName: "version-8", description: "description here 8" },
  { id: 9, versionName: "version-9", description: "description here 9" }
];

export const versions = [
  { id: 1, version: "1.1" },
  { id: 2, version: "1.0" }
];

export const project_logs = [
  {
    id: 1,
    string: "String-1",
    commitString: "Commit String - 1",
    commitBy: "user-1",
    role: "admin",
    date: "12/02/2022 01:23:24"
  },
  {
    id: 2,
    string: "String-2",
    commitString: "Commit String - 2",
    commitBy: "user-2",
    role: "admin",
    date: "13/02/2022 02:23:24"
  },
  {
    id: 3,
    string: "String-3",
    commitString: "Commit String - 3",
    commitBy: "user-3",
    role: "admin",
    date: "14/03/2022 03:23:24"
  },
  {
    id: 4,
    string: "String-4",
    commitString: "Commit String - 4",
    commitBy: "user-4",
    role: "admin",
    date: "15/04/2022 04:23:24"
  },
  {
    id: 5,
    string: "String-5",
    commitString: "Commit String - 5",
    commitBy: "user-5",
    role: "admin",
    date: "16/05/2022 05:23:24"
  },
  {
    id: 6,
    string: "String-6",
    commitString: "Commit String - 6",
    commitBy: "user-6",
    role: "admin",
    date: "12/06/2022 06:23:24"
  }
];

export const store_listing = [
  { id: 1, description: "description - 1" },
  { id: 2, description: "description - 2" },
  { id: 3, description: "description - 3" },
  { id: 4, description: "description - 4" },
  { id: 5, description: "description - 5" },
  { id: 6, description: "description - 6" },
  { id: 7, description: "description - 7" },
  { id: 8, description: "description - 8" },
  { id: 9, description: "description - 9" }
];

export const translation_service_providers = [
  {
    id: 1,
    name: "Google translator",
    keys: ["key-1"],
    values: ["value-1"]
  },

  {
    id: 2,
    name: "Yandex translator",
    keys: ["key-2"],
    values: ["value-2"]
  }
];

export const logs = [
  {
    id: 1,
    project: "Project-1",
    error: "Error-1",
    time: "11/02/2022 02:21:24"
  },
  {
    id: 2,
    project: "Project-2",
    error: "Error-2",
    time: "12/02/2022 02:22:24"
  },
  {
    id: 3,
    project: "Project-3",
    error: "Error-3",
    time: "10/02/2022 02:23:24"
  },
  { id: 4, project: "Project-4", error: "Error-4", time: "09/02/2022 02:24:24" }
];

export const platforms = [
  {
    id: 1,
    name: "Flutter",
    startformat: "StartFormat-1 ",
    endformat: "EndFormat-1",
    iteration: "iteration-1",
    filename: "",
    iterationseparator: "iterationseparator-1"
  },

  {
    id: 2,
    name: "Android",
    startformat: "StartFormat-2",
    endformat: "EndFormat-2",
    iteration: "iteration-2",
    filename: "",
    iterationseparator: "iterationseparator-2"
  },

  {
    id: 3,
    name: "IOS",
    startformat: "StartFormat-3",
    endformat: "EndFormat-3",
    iteration: "iteration-3",
    filename: "",
    iterationseparator: "iterationseparator-3"
  }
];

export const platform_names = [
  { value: "Flutter", label: "Flutter" },
  { value: "IOS", label: "IOS" },
  { value: "Android", label: "Android" }
];

export const start_format = [
  { value: "start-format-1", label: "start-format-1" },
  { value: "start-format-2", label: "start-format-2" },
  { value: "start-format-3", label: "start-format-3" },
  { value: "start-format-4", label: "start-format-4" },
  { value: "start-format-5", label: "start-format-5" },
  { value: "start-format-6", label: "start-format-6" }
];

export const end_format = [
  { value: "end-format-1", label: "end-format-1", key: "1" },
  { value: "end-format-2", label: "end-format-2", key: "2" },
  { value: "end-format-3", label: "end-format-3", key: "3" },
  { value: "end-format-4", label: "end-format-4", key: "4" },
  { value: "end-format-5", label: "end-format-5", key: "5" },
  { value: "end-format-6", label: "end-format-6", key: "6" }
];

export const dummyData = [
  {
    id: 1,
    projectname: "Project 1",
    status: "active",
    primary_language: "primary-language-1",
    translation_language: ["translation-language-1"],
    api: "thgnmid87nbtnbty",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    platform: ["android"],
    translation_service_provider: ""
  },

  {
    id: 2,
    projectname: "Project 2",
    status: "Inactive",
    primary_language: "primary-language-2",
    translation_language: ["translation-language-2"],
    api: "thgnmid87nbtnbty",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    platform: ["android"],
    translation_service_provider: ""
  },

  {
    id: 3,
    projectname: "Project 3",
    status: "Active",
    primary_language: "primary-language-3",
    translation_language: ["translation-language-3"],
    api: "thgnmid87nbtnbty",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    platform: ["android"],
    translation_service_provider: ""
  },

  {
    id: 4,
    projectname: "Project 4",
    status: "Active",
    primary_language: "primary-language-4",
    translation_language: ["translation-language-4"],
    api: "thgnmid87nbtnbty",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
    platform: ["android"],
    translation_service_provider: ""
  },

  {
    id: 5,
    projectname: "Language Management System",
    status: "Active",
    primary_language: "zxcvbnm",
    translation_language: ["Ardrhgv"],
    api: "creyt/p;]086gffes",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    platform: ["android"],
    translation_service_provider: ""
  }
];

export const common_strings = [
  {
    id: 1,
    key: "id_email",
    string:
      "It seems that you have denied this app to access Camera. Please goto Settings and allow access to Camera."
  },
  {
    id: 2,
    key: "id_send_link",
    string:
      "Your device does not support camera. Do you want to open device's Photo Library?"
  },
  { id: 3, key: "id_gallery", string: "Resume batch process" },
  { id: 4, key: "id_export_title", string: "Camera Not Found" },
  {
    id: 5,
    key: "id_Force_Close_Ad_View_Label",
    string: "Enable the steps you want to use"
  },
  { id: 6, key: "id_app_name", string: "Create new Template" },
  {
    id: 7,
    key: "id_tag_line",
    string: "Choose template you want to apply on landscape photos"
  },
  {
    id: 1,
    key: "id_email",
    string:
      "It seems that you have denied this app to access Camera. Please goto Settings and allow access to Camera."
  },
  {
    id: 2,
    key: "id_send_link",
    string:
      "Your device does not support camera. Do you want to open device's Photo Library?"
  },
  { id: 3, key: "id_gallery", string: "Resume batch process" },
  { id: 4, key: "id_export_title", string: "Camera Not Found" },
  {
    id: 5,
    key: "id_Force_Close_Ad_View_Label",
    string: "Enable the steps you want to use"
  },
  { id: 6, key: "id_app_name", string: "Create new Template" },
  {
    id: 7,
    key: "id_tag_line",
    string: "Choose template you want to apply on landscape photos"
  }
];

export const languages = [
  { value: "English", label: "English" },
  { value: "Urdu", label: "Urdu" },
  { value: "German", label: "German" },
  { value: "Russian", label: "Russian" },
  { value: "Chinese", label: "Chinese" }
];

export const users_permissions = [
  {
    id: 1,
    projects: ["Project 1"],
    firstName: "FName-1",
    lastName: "LName-1",
    userEmail: "user1@gmail.com",
    api: "iudgiv/vdi8y32",
    role: "admin"
  },
  {
    id: 2,
    projects: ["Project 2"],
    firstName: "FName-2",
    lastName: "LName-2",
    userEmail: "user2@gmail.com",
    api: "iudgiv/vdi8y32",
    role: "Editor"
  },
  {
    id: 3,
    projects: ["Project 3"],
    firstName: "FName-3",
    lastName: "LName-3",
    userEmail: "user3@gmail.com",
    api: "iudgiv/vdi8y32",
    role: "Viewer"
  },
  {
    id: 4,
    projects: ["Project 4"],
    firstName: "FName-4",
    lastName: "LName-4",
    userEmail: "user4@gmail.com",
    api: "iudgiv/vdi8y32",
    role: "Viewer"
  },
  {
    id: 5,
    projects: ["Project 5"],
    firstName: "FName-5",
    lastName: "LName-5",
    userEmail: "user5@gmail.com",
    api: "iudgiv/vdi8y32",
    role: "Editor"
  },
  {
    id: 6,
    projects: ["Project 6"],
    firstName: "FName-6",
    lastName: "LName-6",
    userEmail: "user6@gmail.com",
    api: "iudgiv/vdi8y32",
    role: "Editor"
  }
];

export const permissionOptionsForNonAdminUser = [
  { value: "admin", label: "admin" },
  { value: "editor", label: "editor" },
  { value: "viewer", label: "viewer" }
];

export const rowData = [
  {
    id: 1,
    firstname: "Erikson",
    lastname: "Steven",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 2,
    firstname: "Fakes",
    lastname: "Dennis",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 3,
    firstname: "Cooley",
    lastname: "Mason",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 4,
    firstname: "Braine",
    lastname: "John",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 5,
    firstname: "Allais",
    lastname: "Alphonse",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 6,
    firstname: "Philip",
    lastname: "Edinburgh",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 7,
    firstname: "Pinker",
    lastname: "Steven",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 8,
    firstname: "Qin Shi",
    lastname: "Huang",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 9,
    firstname: "Theron",
    lastname: "Charlize",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 10,
    firstname: "Spolsky",
    lastname: "Joel",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 11,
    firstname: "Palme",
    lastname: "Olof",
    address: {
      city: "Iraq",
      state: "Bandung",
      country: "Bahia",
      postelcode: "765436"
    }
  },
  {
    id: 12,
    firstname: "Pasteur",
    lastname: "Louis",
    address: {
      city: "Barnaul",
      state: "Bandung",
      country: "Russia",
      postelcode: "453256"
    }
  },
  {
    id: 13,
    firstname: "Reich",
    lastname: "Wilhelm",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thiland",
      postelcode: "345333"
    }
  },
  {
    id: 14,
    firstname: "Braine",
    lastname: "John",
    address: {
      city: "Baliuag",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "45644"
    }
  },
  {
    id: 15,
    firstname: "Sagan",
    lastname: "Carl",
    address: {
      city: "Batumi",
      state: "Bahddd",
      country: "Georgia",
      postelcode: "346654"
    }
  },
  {
    id: 16,
    firstname: "Josip Broz",
    lastname: "Tito",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 17,
    firstname: "Trotsky",
    lastname: "Leon",
    address: {
      city: "Bahia Blanca",
      state: "Argentina",
      country: "Baghdad",
      postelcode: "453256"
    }
  },
  {
    id: 18,
    firstname: "Jarmo",
    lastname: "Visakorpi",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 19,
    firstname: "Theron",
    lastname: "Charlize",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 20,
    firstname: "Wall",
    lastname: "Larry",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 21,
    firstname: "Erikson",
    lastname: "Steven",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 22,
    firstname: "Fakes",
    lastname: "Dennis",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 23,
    firstname: "Cooley",
    lastname: "Mason",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 24,
    firstname: "Braine",
    lastname: "John",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 25,
    firstname: "Allais",
    lastname: "Alphonse",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 26,
    firstname: "Philip",
    lastname: "Edinburgh",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 27,
    firstname: "Pinker",
    lastname: "Steven",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 28,
    firstname: "Qin Shi",
    lastname: "Huang",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 29,
    firstname: "Theron",
    lastname: "Charlize",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 30,
    firstname: "Spolsky",
    lastname: "Joel",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 31,
    firstname: "Wellstone",
    lastname: "Paul",
    address: {
      city: "Baghdad",
      state: "Bandung",
      country: "Iraq",
      postelcode: "436333"
    }
  },
  {
    id: 32,
    firstname: "Whitman",
    lastname: "Walt",
    address: {
      city: "Bahia Blanca",
      state: "ahia",
      country: "Argentina",
      postelcode: "568565"
    }
  },
  {
    id: 33,
    firstname: "Maney",
    lastname: "Panday",
    address: {
      city: "Uiusy",
      state: "Chgdyy",
      country: "Yusyyhh",
      postelcode: "098765"
    }
  },
  {
    id: 34,
    firstname: "Gurha",
    lastname: "Punhah",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 35,
    firstname: "Xyshh",
    lastname: "Xoho",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 36,
    firstname: "Uyyy",
    lastname: "Kiduu",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 37,
    firstname: "Yetahh",
    lastname: "isuuy",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 38,
    firstname: "Yhdh Shi",
    lastname: "Huang",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 39,
    firstname: "Uhdh",
    lastname: "Charlize",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 40,
    firstname: "Yshkks",
    lastname: "Joel",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 41,
    firstname: "Erikson",
    lastname: "Steven",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 42,
    firstname: "Fakes",
    lastname: "Dennis",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 43,
    firstname: "Cooley",
    lastname: "Mason",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 44,
    firstname: "Braine",
    lastname: "John",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 45,
    firstname: "Allais",
    lastname: "Alphonse",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  },
  {
    id: 46,
    firstname: "Philip",
    lastname: "Edinburgh",
    address: {
      city: "Baghdad",
      state: "Bahia",
      country: "Iraq",
      postelcode: "765436"
    }
  },
  {
    id: 47,
    firstname: "Pinker",
    lastname: "Steven",
    address: {
      city: "Bahia Blanca",
      state: "Bandung",
      country: "Argentina",
      postelcode: "453256"
    }
  },
  {
    id: 48,
    firstname: "Qin Shi",
    lastname: "Huang",
    address: {
      city: "Balakovo",
      state: "Bangkok",
      country: "Azerbaijan",
      postelcode: "098765"
    }
  },
  {
    id: 49,
    firstname: "Theron",
    lastname: "Charlize",
    address: {
      city: "Baliuag",
      state: "Banjul",
      country: "Thailand",
      postelcode: "543234"
    }
  },
  {
    id: 50,
    firstname: "Spolsky",
    lastname: "Joel",
    address: {
      city: "Turkey",
      state: "Batumi",
      country: "Georgia",
      postelcode: "345777"
    }
  }
];
