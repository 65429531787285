import React from "react";
import { useFormik } from "formik";
import { useState } from "react";
import {
  toastLoadingStart,
  toastLoadingEnd
} from "helper/toast";
import * as Yup from "yup";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from "firebase/auth";
import { useIntlMessage } from "util/getIntlMessage";

const UpdatePassword = () => {
  const localizationMessage = useIntlMessage();
  const [loadButton, setLoadButton] = useState(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string()
      .min(8, localizationMessage("validate.passwordLengthMessage"))
      .required(localizationMessage("validate.passwordIsRequired")),
    newPassword: Yup.string()
      .min(8, localizationMessage("validate.passwordLengthMessage"))
      .required(localizationMessage("validate.passwordIsRequired")),
    confirmNewPassword: Yup.string()
      .required(localizationMessage("validate.passwordIsRequired"))
      .oneOf([Yup.ref("newPassword"), null], localizationMessage("validate.passwordNotMatch"))
  });

  const SubmitHandler = async values => {
    setLoadButton(true);
    toastLoadingStart("updatePassword", localizationMessage("infoMessages.processingRequest"));
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      setLoadButton(true);
      await reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(user.email, values.currentPassword)
      );
      updatePassword(user, values.newPassword)
        .then(() => {
          toastLoadingEnd("updatePassword", "success", localizationMessage("successMessages.passwordUpdated"));
          setLoadButton(false);
          // navigate("/");
        })
        .catch(async (error) => {
          await new Promise(resolve => setTimeout(resolve, 200));
          toastLoadingEnd(
            "updatePassword",
            "error",
            error?.message || localizationMessage("errorMessages.somethingWentWrong")
          );
          setLoadButton(false);
        });
      // setLoadButton(false);
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 200));
      toastLoadingEnd(
        "updatePassword",
        "error",
        error?.message || localizationMessage("errorMessages.somethingWentWrong")
      );
      setLoadButton(false);
    }
  };
  //formMik Handler
  const formMik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <div className="minWidth-27">
      <div className="py-2 card my-2 mr-2 mr-sm-0 ml-2">
        <h5 className="header text-center">{localizationMessage("settings.password.label")}</h5>
        <form className="pa-24" onSubmit={formMik.handleSubmit}>
          {/* ****************** */}
          <div className="form-group ">
            <label>{localizationMessage("settings.password.currentPassword.label")}</label>
            <input
              type="password"
              placeholder={localizationMessage("settings.password.currentPassword.label")}
              className={
                formMik.errors.currentPassword && formMik.touched.currentPassword
                  ? "is-invalid form-control "
                  : formMik.touched.currentPassword
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.currentPassword}
              name="currentPassword"
            />
          </div>
          {/* ****************** */}
          <div className="form-group">
            <label>{localizationMessage("settings.password.newPassword.label")}</label>
            <input
              type="password"
              placeholder={localizationMessage("settings.password.newPassword.label")}
              className={
                formMik.errors.newPassword && formMik.touched.newPassword
                  ? "is-invalid form-control "
                  : formMik.touched.newPassword
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.newPassword}
              name="newPassword"
            />
          </div>

          {/* ****************** */}
          <div className="form-group">
            <label>{localizationMessage("settings.password.confirmNewPassword")}</label>
            <input
              type="password"
              placeholder={localizationMessage("settings.password.confirmNewPassword")}
              className={
                formMik.errors.confirmNewPassword &&
                  formMik.touched.confirmNewPassword
                  ? "is-invalid form-control "
                  : formMik.touched.confirmNewPassword
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.confirmNewPassword}
              name="confirmNewPassword"
            />
          </div>

          <button
            type="submit"
            disabled={loadButton}
            className="btn form-button c-primary"
          >
            {localizationMessage("settings.password.updatePassword")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
