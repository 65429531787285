import React, { useEffect, useState } from "react";
import { Collapse, Input } from "reactstrap";
import "assets/customstyles/whatsnew.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as whatsNewActions from "redux/app/whatsnew/Actions";
import { toastSuccess } from "helper/toast";
import Button from "reactstrap/lib/Button";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const CardWhatsnew = ({ entity, parentCollapse }) => {

  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  //redux
  const { buttonLoading } = useSelector(
    state => ({
      buttonLoading: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  const languageDirection = entity?.projectLanguage?.language?.direction;

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  // initilization
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    description: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    dispatch(whatsNewActions.update(entity?.id, values, setIsEdit));
  };

  const handleUpdateAndTranslate = (values) => {
    const whatsNewVersionId = entity?.whatsNewVersionId;
    const shouldTranslateAll = true;
    dispatch(whatsNewActions.update(entity?.id, values, setIsEdit, shouldTranslateAll, whatsNewVersionId));
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: { description: "" },
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  const Translate = async () => {
    const payload = {
      whatsNewVersionId: entity?.whatsNewVersionId,
      projectLanguageId: entity?.projectLanguage?.id
    };
    dispatch(whatsNewActions.translate(entity?.whatsNewVersionId, payload));
  };

  const TranslateAll = async () => {
    dispatch(whatsNewActions.translateAll(entity?.whatsNewVersionId))
  };

  useEffect(() => {
    setCollapse(parentCollapse);
  }, [parentCollapse]);

  return (
    <>
      <div className="roe-card-style mtb-10 card-language">
        <div className="roe-card-header module-header flex">
          <div className="heading-language">
            {entity?.projectLanguage?.language?.name}
            {entity?.projectLanguage?.isDefault ? (
              <span className="lan-type"> ({localizationMessage("primary")})</span>
            ) : (
              <></>
            )}
          </div>
          {isEdit ? (
            <div className="ml-auto flex justify-content-end">
              <button
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                <i className="fas fa-xmark" />
              </button>
            </div>
          ) : (
            <div className="ml-auto flex justify-content-end">
              {!collapse ? (
                <>
                  <div className="flex">
                    { entity?.projectLanguage?.isDefault !== 1 ?
                      <button
                        disabled={buttonLoading}
                        className={(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false) ? "" : "d-none"}
                        title={localizationMessage("translate")}
                        onClick={() => {
                          Translate();
                        }}
                      >
                        <i className="fa-solid fa-language"></i>
                      </button>
                      : null
                    }
                    <button
                      title={localizationMessage("copy")}
                      onClick={() => {
                        if (entity?.description?.length > 0) {
                          navigator.clipboard.writeText(
                            entity?.description || ""
                          );
                          toastSuccess("copy", localizationMessage("copied"));
                        }
                      }}
                    >
                      <i className="fa-regular fa-copy" />
                    </button>
                    <button
                      className={(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false) ? "ml-auto" : "d-none"}
                      title={localizationMessage("edit")}
                      onClick={() => {
                        formMik.setFieldValue(
                          "description",
                          entity?.description || ""
                        );
                        setIsEdit(true);
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square" />
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              <button
                className="ml-auto"
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? (
                  <i className="fa-solid fa-angle-down" />
                ) : (
                  <i className="fa-solid fa-angle-up" />
                )}
              </button>
            </div>
          )}
        </div>
        <Collapse isOpen={!collapse}>
          {isEdit ? (
            <div className="edit-form">
              <form onSubmit={formMik.handleSubmit}>
                <Input
                  type="textarea"
                  rows={5}
                  placeholder={localizationMessage("whatsNew.descField.placeholder")}
                  className={
                    formMik.errors.description && formMik.touched.description
                      ? "is-invalid rich-input"
                      : formMik.touched.description
                        ? "is-valid rich-input"
                        : "rich-input"
                  }
                  onChange={e => {
                    formMik.setFieldValue("description", e.target.value);
                  }}
                  onBlur={formMik.handleBlur}
                  value={formMik.values.description}
                  name="description"
                />

                <div className="mt-3 d-flex flex-wrap justify-content-end gap-2">
                  {
                    entity?.projectLanguage?.isDefault ?
                      <Button
                        className="btn c-primary modalButtonSize px-3 d-block"
                        type="button"
                        onClick={()=> formMik.isValid && handleUpdateAndTranslate(formMik.values)}
                        disabled={buttonLoading}
                        // disabled={buttonLoading || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                      >
                        {localizationMessage("whatsNew.updateAndTranslate")}
                      </Button>
                    : null
                  }
                  <Button
                    className="btn c-primary modalButtonSize px-3 d-block"
                    type="submit"
                    disabled={buttonLoading}
                  >
                    {localizationMessage("whatsNew.update")}
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div className="detail" style={languageDirection==="rtl" ? {direction: "rtl"} : {}} >{entity?.description}</div>
          )}
        </Collapse>
      </div>
    </>
  );
};

export default CardWhatsnew;
