import * as requestFromServer from "./Cruds";
import { whatsnewSlice } from "./Reducer";
import btnLoadingActions from "redux/btnLoading/actions";
import { whatsnewVersionSlice } from "../whatsnewVersions/Reducer";
import {
  toastLoadingStart,
  toastLoadingEnd,
  toastSuccess,
  toastError
} from "helper/toast";
import { getLocalizedString } from "util/getLocalizedString";
const { actions } = whatsnewSlice;
const whatsnewVersionActions = whatsnewVersionSlice.actions;

//get all records :
export const getAllByVersionId = (
  versionId,
  isStartCall = true
) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (isStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getAllByVersionId(versionId)
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getAllByVersionId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};
export const deaultRedux = () => dispatch => {
  dispatch(actions.startCall());
};

// create a new record :
export const create = (data, setmodal) => dispatch => {
  const toastId = "create";
  return requestFromServer
    .create(data)
    .then(async response => {
      dispatch(whatsnewVersionActions.create({ data: response?.data }));
      setmodal(false);
      toastSuccess(toastId, getLocalizedString("successMessages.whatsNewVersionAdded"));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      // const messageLocation = "--error from getAllPlatforms--";
      // const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      // dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// // update a new record :
export const update = (id, payload, setmodal, shouldTranslateAll, versionId) => async dispatch => {
  const toastId = id || "fetchingDataFromServer";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  try {
    await requestFromServer.Update(id, payload);
    if(shouldTranslateAll){
      const response = await requestFromServer.TranslateAll(versionId);
      // await requestFromServer.getAllByVersionId(versionId);
      await dispatch(actions.getAll({ data: response?.data || [] }));
    }
    else{
      dispatch(actions.update({ id: id, data: payload }));
    }
    toastLoadingEnd(
      toastId,
      "success",
      getLocalizedString("successMessages.processCompleted")
    );
  } catch (error) {
    await new Promise(resolve => setTimeout(resolve, 200));
      toastLoadingEnd(
        toastId,
        "error",
        error?.message || getLocalizedString("errorMessages.somethingWentWrong")
      );
  } finally{
    setmodal(false);
    dispatch(btnLoadingActions.setLoading(false));
  }
};

export const translate = (projectID, payload) => async (dispatch) => {
  const toastId = payload?.projectLanguageId ? `translateWhatsNew_${payload?.projectLanguageId}` : "translateWhatsNew";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .Translate(payload)
    .then(async response => {
      await dispatch(getAllByVersionId(projectID, false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.translationCompleted")
      );
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
};

export const translateAll = (versionId) => async (dispatch) => {
  const toastId = versionId || "fetchingDataFromServer";
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .TranslateAll(versionId)
    .then(async response => {
      // dispatch(getAllByVersionId(versionId, false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.translationCompleted")
      );
      await requestFromServer.getAllByVersionId(versionId)
        .then(async response => {
          await dispatch(actions.getAll({ data: response?.data || [] }));
        })
        .catch((error) => {

        })
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
};