import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import "assets/customstyles/whatsnew.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as storeListingActions from "redux/app/storelisting/Actions";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const CardStoreListingNew = () => {

  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  //redux
  const { projectDefaultLanguage, reduxProjectId, buttonLoading } = useSelector(
    state => ({
      projectDefaultLanguage: state.project.projectDefaultLanguage,
      reduxProjectId: state?.project?.projectEntity?.id || null,
      buttonLoading: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    description: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    const payload = { ...values, projectLanguageId: projectDefaultLanguage.id };
    dispatch(storeListingActions.create(payload, reduxProjectId));
  };

  const handleSaveAndTranslate = (values) => {
    const payloadCreate = { ...values, projectLanguageId: projectDefaultLanguage.id };
    const payloadTranslateAll = {
      projectLanguageId: projectDefaultLanguage.id
    };
    const shouldTranslateAll = true;
    dispatch(storeListingActions.create(payloadCreate, reduxProjectId, shouldTranslateAll, payloadTranslateAll));
  };
  

  //formMik Handler
  const formMik = useFormik({
    initialValues: { description: "" },
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <>
      <form onSubmit={formMik.handleSubmit}>
        <div className="roe-card-style mtb-30 card-language">
          <div className="roe-card-header module-header flex">
            <div className="heading-language">
              {projectDefaultLanguage?.language?.name}{" "}
              <span className="lan-type">({localizationMessage("primary")})</span>
            </div>
          </div>
          <div className="edit-form">
            <Input
              autoFocus={true}
              type="textarea"
              rows={5}
              placeholder={localizationMessage("storeListing.descField.placeholder")}
              className={
                formMik.errors.description && formMik.touched.description
                  ? "is-invalid rich-input"
                  : formMik.touched.description
                    ? "is-valid rich-input"
                    : "rich-input"
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.description}
              name="description"
            />

            <div className="text-danger mt-2">{error}</div>
            <div className="mt-3 d-sm-flex">
              <Button
                className="btn c-primary modalButtonSize px-5 ml-auto d-block"
                type="button"
                onClick={()=> formMik.isValid && handleSaveAndTranslate(formMik.values)}
                // disabled={buttonLoading}
                disabled={buttonLoading || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
              >
                {localizationMessage("storeListing.saveAndTranslate")}
              </Button>
              <Button
                className="btn c-primary modalButtonSize px-5 ml-auto ml-sm-2 mt-2 mt-sm-0 d-block"
                type="submit"
                // disabled={buttonLoading}
                disabled={buttonLoading || !(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
              >
                {localizationMessage("storeListing.save")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CardStoreListingNew;
