import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: [],
  error: null
};

export const placeHolderSlice = createSlice({
  name: "placeHolder",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    create: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    remove: (state, action) => {
      state.entity = state.entity.filter(function(obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    }
  }
});
