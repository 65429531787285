import React from "react";
import PageTitle from "components/common/PageTitle";
import LogsTable from "components/reacttable/LogsTable";

const Logs = () => {
  return (
    <>
      <PageTitle
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Logs"
          }
        ]}
      />
      <LogsTable />
    </>
  );
};

export default Logs;
