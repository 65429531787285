import React from "react";
import HeaderWrapper from "./header.style";
// import { UncontrolledPopover, PopoverBody, Popover, Button } from "reactstrap";
// import { ProfileLockScreen } from "helper/constant";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import LanguageActions from "redux/languageSwitcher/actions";
import { withRouter } from "react-router-dom";
import LanguageSwitcher from "components/themesetting/LanguageSwitcher";
import config from "settings/languageConfig";
import IntlMessages from "util/intlMessages";
import { FormattedMessage } from "react-intl";
import { useIntlMessage } from "util/getIntlMessage";

const { logout } = AuthActions;

const Header = props => {
  const localizationMessage = useIntlMessage();
  const { drawerMiniMethod, mini } = props;
  // const [toggle, setToggle] = useState(true);

  let user = localStorage.getItem("user");
  //set userInitials to "US" if user name not found only
  let userInitials = "US"
  if (user) {
    user = JSON.parse(user);
    if (user?.name && user?.name.length > 0) {
      userInitials = user?.name.split(' ').slice(0, 2).map(word => word.charAt(0)).join('');
      userInitials = userInitials.toUpperCase();
    }
  }

  const dispatch = useDispatch();

  const userSignout = () => {
    dispatch(AuthActions.logout());
    // logout();
    props.history.push("/logout");
  };

  const changeLanguageHandler = id => {
    dispatch(LanguageActions.changeLanguage(id));
    // props.changeLanguage(id);
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name _fs-16 bold-text">{localizationMessage("sidebar.AppName")}</span>
          </div>

          <div className="_pl-10 flex-1">
            {/* //Theme Setting
            <button
              onClick={layoutSettingDrawerToggle}
              className="top-header-icon"
            >
              <i className="fas fa-th-large"></i>
            </button> */}
          </div>

          <div className="_pl-10 d-flex">
            {/* <div
              id="profile"
              className="cursor-pointer"
            // onClick={() => { setToggle(!toggle) }}
            >
              <img
                className="top-header-profile-class"
                src={ProfileLockScreen}
                alt="notify"
              />
            </div> */}

            {/* <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
            // isOpen={toggle}
            // trigger="legacy"
            >
              <PopoverBody trigger="legacy">
                <div
                  className="roy-menu-list"
                  onClick={() => {
                    setToggle(false);
                    props.history.push("/settings");
                  }}
                >
                  Settings
                </div>
                <div className="roy-menu-list" onClick={userSignout}>
                  Logout
                </div>
              </PopoverBody>
            </UncontrolledPopover> */}

            {/* <select value={context} onChange={context.selectLanguage}>
              <option value="en">English</option>
              <option value="fr">French</option>
            </select> */}

            <div>
              <div
                className="btn btn-secondary dropdownStringButton cursor-pointer"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg width="36" height="36">
                  <circle cx="18" cy="18" r="18" fill="#563c91" />
                  <foreignObject x="0" y="0" width="36" height="36">
                    <div xmlns="http://www.w3.org/1999/xhtml" style={{textAlign: "center", lineHeight: "36px"}}>
                      <i className="fas fa-globe" style={{ color: 'white', fontSize: '16px' }}></i>
                    </div>
                  </foreignObject>

                </svg>
              </div>
              <div
                className="dropdown-menu"
                style={{minWidth: "min-content"}}
                aria-labelledby="dropdownMenuButton"
                >
                <LanguageSwitcher
                  config={config}
                  // selectedId={language.languageId}
                  selectedId={props?.languageSwitcher?.language?.languageId || "en"}
                  changeLanguage={changeLanguageHandler}
                />
              </div>
            </div>

            <div>
              <div
                className="btn btn-secondary dropdownStringButton cursor-pointer"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg width="36" height="36">
                  <circle cx="18" cy="18" r="18" fill="#563c91" />
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    fill="white"
                    fontSize="16px"
                    fontFamily="serif"
                    dy=".34em"
                  >
                    {userInitials}
                  </text>
                </svg>
              </div>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => {
                    // setToggle(false);
                    props.history.push("/settings");
                  }}
                >
                  {localizationMessage("header.settings")}
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={userSignout}
                >
                  {localizationMessage("header.signout")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default compose(withRouter, connect(null, { logout }))(Header);
