import * as requestFromServer from "./Cruds";
import btnLoadingActions from "redux/btnLoading/actions";
import { usersSlice } from "./Reducer";
import {
  toastLoadingStart,
  toastLoadingEnd,
  // toastSuccess,
  toastError
} from "helper/toast";
import { getLocalizedString } from "util/getLocalizedString";
import { getAllUserPermission } from "../userAndPermission/Actions";
const { actions } = usersSlice;

export const getOne = (userId, shouldStartCall = true) => dispatch => {
  // const toastId = `users_getOne_${userId}`;
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getOne(userId)
    .then(async response => {
      dispatch(
        actions.getOne({
          data: response?.data || null,
        })
      );
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      if (shouldStartCall) {
        const messageLocation = "--error from getOne--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      }
    });
};


//get all records :
export const getAll = () => dispatch => {
  const toastId = "fetchingDataFromServer";
  // dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch(error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getAll--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};


// create a new record :
export const create = (data, setmodal) => dispatch => {
  const toastId = "createUserAndPermission";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  dispatch(btnLoadingActions.setLoading(true));
  return requestFromServer
    .create(data)
    .then(async response => {
      dispatch(actions.create({ data: response?.data }));
      setmodal(false);
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
      dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));

      let errorMessage = "";
      if (Array.isArray(error?.message)) {
        errorMessage = error?.message.join(' \n');
      }
      else {
        errorMessage = error?.message;
      }
      
      errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));

      dispatch(btnLoadingActions.setLoading(false));
    });
};

// update a new record :
export const update = (id, payload, setmodal) => dispatch => {
  const toastId = "updateUserAndPermission";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .Update(id, payload)
    .then(async response => {
      dispatch(actions.update({ id: id, data: payload }));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

// delete a new record :
export const remove = (id) => dispatch => {
  const toastId = id || "updateUserAndPermission";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .remove(id)
    .then(async response => {
      dispatch(actions.remove({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};


//get all records :
export const removeAll = () => dispatch => {
  const toastId = "removeAll";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCall());
  return requestFromServer
    .removeAll()
    .then(async response => {
      dispatch(actions.getAll({ data: [] }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

export const clear = () => dispatch => {
  dispatch(actions.clear());
};

// create a superAdmin :
export const CreateSuperAdmin = (data, setmodal, obj) => async (dispatch) => {
  const toastId = "createAdmin";
  let success = false;
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await dispatch(btnLoadingActions.setLoading(true));
  await requestFromServer
    .CreateSuperAdmin(data)
    .then(async response => {
      success = true;
      // dispatch(actions.create({ data: response?.data }));
      setmodal(false);
      await dispatch(getAllUserPermission(obj))
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      success = false;
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));

      let errorMessage = "";
      if (Array.isArray(error?.message)) {
        errorMessage = error?.message.join(' \n');
      }
      else {
        errorMessage = error?.message;
      }

      errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
  return success;
};