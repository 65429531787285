import React from "react";
import "assets/customstyles/errorComponent.css";
import { useIntlMessage } from "util/getIntlMessage";

const ErrorComponent = (props) => {
  const localizationMessage = useIntlMessage();
  return (
    <div className="Container" style={{marginTop: "10vw"}}>
      <div className="errorPageLogo">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>
      <div>
        <span className="MainTitle">{localizationMessage("oops")}</span>
        <span className="SecondTitle">{localizationMessage("errorMessages.somethingWentWrong")}</span>
        <p className="MainDescription">
          {localizationMessage("errorPageContentUnavailableMessage")}
        </p>
      </div>

      <button
        className="btn c-outline-primary mt-4"
        onClick={() => props.history.push("/")}
      >
        Go Home
      </button>
    </div>
  );
};

export default ErrorComponent;
