import React from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import './mention-style.css'
import './custom.css'; 
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const ReactMentionsInput = ({fieldName, fieldValue, setFieldValue, mentionPlaceholder, mensionsTrigger, mentionsInputTags, handleSubmit}) => {
  // below function will convert string, like from "$#LMS_VARs$#" to "@[string](2)"
  const transformInput = (fieldValue, mentionsInputTags) => {
    // Define mappings for LMS_VAR characters to tag values
    const varMappings = {
      'd': 'int',
      's': 'string',
      'f': 'float'
    };
  
    // Regular expression to find specific LMS_VAR placeholders (d, s, f)
    const varRegex = /\$#LMS_VAR([dsf])\$#/g;
  
    // Function to replace placeholders with corresponding tags
    const replacePlaceholder = (match, varChar) => {
      const tagValue = varMappings[varChar]; // Map LMS_VAR to tag value
      const tag = mentionsInputTags.find(tag => tag.value === tagValue);
      if (tag) {
        return `@[${tag.value}](${tag.id})`;
      }
      return match; // Return original match if tag not found
    };
  
    // Replace specific LMS_VAR placeholders
    let outputText = fieldValue.replace(varRegex, replacePlaceholder);
  
    // Regular expression to find generic LMS_VAR placeholder
    const otherRegex = /\$#LMS_VAR\$#/g;
  
    // Replace generic LMS_VAR placeholder if 'variable' tag is present
    outputText = outputText.replace(otherRegex, (match) => {
      const tag = mentionsInputTags.find(tag => tag.value === 'variable');
      if (tag) {
        return `@[${tag.value}](${tag.id})`;
      }
      return match; // Return original match if tag not found
    });
  
    return outputText;
  };
  

  const [value, setValue] = useState(transformInput(fieldValue, mentionsInputTags, mensionsTrigger));
  const mentionsInputRef = useRef(null);
  const isEnterKeyPressedRef = useRef(null);
  
  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    // newValue contains the text value including mentions markup
    const formikValueFormatModified = replaceMentionsWithCustomFormat(newValue);
    const fieldValueFormatModified = generateLibraryCompaitableFormat(newValue);

    const AddedCharacters = (prevString, currentString) => {
      const prevStrLength = prevString?.length;
      const currStrLength = currentString?.length;
      if(currStrLength > prevStrLength){
        const addedString = currentString.slice(prevStrLength,currStrLength);
        return addedString;
      }
    }

    // below code run when enter key is pressed inside the field
    if(AddedCharacters(value, fieldValueFormatModified)==="\n" && isEnterKeyPressedRef.current){
      handleSubmit();
      return;
    }
    // save value to formik
    setFieldValue(fieldName, formikValueFormatModified);
    // set value to show inside the input field
    setValue(fieldValueFormatModified);
  };

  const renderTagSuggestion = ( suggestion, search, highlightedDisplay, index, focused ) => {
    // Customize how tag suggestions are rendered
    return (
      <div className={`tag ${focused ? 'focused' : ''}`}>
        {highlightedDisplay}
      </div>
    );
  };

  // this will replace library output format to custom like "@[add string variable](2)" to  "$#LMS_VARs$#"
  const replaceMentionsWithCustomFormat = (inputText) => {
    // Regular expression to find mentions like @[Tag](ID)
    const mentionRegex = /\@\[([^\]]+)\]\((\d+)\)/g;
    return inputText.replace(mentionRegex, (match, tagName, tagId) => {
        // Find the tag object by ID
        const tag = mentionsInputTags.find(t => t.id.toString() === tagId);
        if (tag) {
          let tagVariable = '';
          if (tagName.includes("int")) {
            tagVariable = "$#LMS_VARd$#";
          } else if (tagName.includes("string")) {
            tagVariable = "$#LMS_VARs$#";
          } else if (tagName.includes("float")) {
            tagVariable = "$#LMS_VARf$#";
          } else if (tagName.includes("variable")) {
            tagVariable = "$#LMS_VAR$#";
          }
          return tagVariable;
        }
        return match; // If tag not found, return original match
    });
  };

  //this will generate library compatible format like from "@[add int variable](1)" to "@[int](1)"
  const generateLibraryCompaitableFormat = (inputText) => {
    // Regular expression to find mentions like @[Tag](ID)
    const mentionRegex = /\@\[([^\]]+)\]\((\d+)\)/g;
    return inputText.replace(mentionRegex, (match, tagName, tagId) => {
        // Find the tag object by ID
        const tag = mentionsInputTags.find(t => t.id.toString() === tagId);
        if (tag) {
            // Replace with custom format %Tag_Variable%
            return `@[${tag.value}](${tag.id})`;
        }
        return match; // If tag not found, return original match
    });
  };

  // below code parse true whenever Enter key is pressed instead false in specific variable
  useEffect(() => {
    // Function to handle key press
    const handleKeyPress = (e) => {
      isEnterKeyPressedRef.current = false;
      if(e.key==="Enter" && !e.shiftKey){
        isEnterKeyPressedRef.current = true;
      }      
    };
    const textareaElement = mentionsInputRef.current;
    
    if (textareaElement) {
      textareaElement.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (textareaElement) {
        textareaElement.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, []);

  // below code cause to show the updated formik value in field
  useEffect(()=>{
    if(!fieldValue){
      setValue("");
    }
  }, [fieldValue]);

  return (
    <div className='w-100'>
      <MentionsInput
        value={value}
        onChange={handleChange}
        inputRef={mentionsInputRef}
        placeholder={mentionPlaceholder}
        className="mentions"
      >
        <Mention
          trigger={mensionsTrigger}
          data={mentionsInputTags}
          renderSuggestion={renderTagSuggestion}
          className="mentions__mention"
        />
      </MentionsInput>
    </div>
  )
}

export default ReactMentionsInput
