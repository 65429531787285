import { language } from "./index";

import unitedStateFlag from "assets/images/internationalization/united_state.png";
import chinaFlag from "assets/images/internationalization/china.png";
import spainFlag from "assets/images/internationalization/spain.png";
import franceFlag from "assets/images/internationalization/france.png";
import italyFlag from "assets/images/internationalization/italy.png";
import pakistanFlag from "assets/images/internationalization/pakistan.png";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: unitedStateFlag
    },
    // {
    //   languageId: "chinese",
    //   locale: "zh",
    //   text: "Chinese",
    //   icon: chinaFlag
    // },
    // {
    //   languageId: "spanish",
    //   locale: "es",
    //   text: "Spanish",
    //   icon: spainFlag
    // },
    // {
    //   languageId: "french",
    //   locale: "fr",
    //   text: "French",
    //   icon: franceFlag
    // },
    // {
    //   languageId: "italian",
    //   locale: "it",
    //   text: "Italian",
    //   icon: italyFlag
    // },
    {
      languageId: "pakistani",
      locale: "ur",
      text: "pakistani",
      icon: pakistanFlag
    }
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
