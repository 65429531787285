import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.Platforms;

export function getAll() {
  return axios[API.Search.Method](API.Search.Url, {
    order: { createdAt: "ASC" },
    // relations: ['userPermission']
  });
}
export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function removeAll() {
  return axios[API.DeleteAll.Method](API.DeleteAll.Url);
}

export function remove(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}
export function Update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}
