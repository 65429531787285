import React, { useMemo, useState, useCallback } from "react";
import { useTable, useSortBy } from "react-table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Button from "components/button/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import classnames from "classnames";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import * as userAndPermissionActions from "redux/app/userAndPermission/Actions";
import "assets/customstyles/users&permissions.css";
import PermissionUpdateForm from "./PermissionUpdateForm";
import AddProjectPermissionForm from "./AddProjectPermissionForm";
import { useIntlMessage } from "util/getIntlMessage";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props?.title}</div>;
};

const UsersAndPermissionsTable = props => {
  const localizationMessage = useIntlMessage();

  //form
  const [modal, setmodal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [row, setRow] = useState(null);
  const [permissionModal, setPermissionModal] = useState(false);

  const userId = props?.userId;
  const {
    userEntity,
    loading,
    uAPEntity,
    languageLocale
  } = useSelector(
    (state) => ({
      userEntity: state?.users?.userEntity,
      loading: state?.userAndPermission?.loading,
      uAPEntity: state?.userAndPermission?.userAndPermissionEntity || [],
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  const tblData = uAPEntity;
  const dispatch = useDispatch();

  const formAction = useCallback(
    (action, data = null) => {
      if (action === "edit") {
        setRow(data);
        setPermissionModal(true);
      } else if (action === "delete") {
        setRow(data);
        setDeleteConfirm(true);
      }
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("serialNumber")} />;
        },
        accessor: "id",
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              {+tableInstance?.row?.id + 1}
            </div>
          );
        }
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("userAndPermission.usersProject")} />;
        },
        accessor: "project.name"
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("userAndPermission.permission")} />;
        },
        accessor: "permission",
        Cell: tableInstance => (
          <div className="react-action-class">
            {localizationMessage(tableInstance?.row?.original?.permission)}
          </div>
        )
      },

      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("edit")} />;
        },
        accessor: "edit",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-secondary c-btn-sm"
              onClick={() => formAction("edit", tableInstance.row.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          </div>
        )
      },

      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title={localizationMessage("delete")}
            />
          );
        },
        accessor: "delete",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className="c-btn c-danger c-btn-sm"
              onClick={() => formAction("delete", tableInstance.row.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>
        )
      }
    ],
    [formAction, languageLocale]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: tblData,
      columns: columns,
      manualSortBy: true
    },
    useSortBy
  );

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setmodal(!modal)}
        backdrop={"static"}
        keyboard={false}
      >
        <ModalHeader toggle={() => setmodal(!modal)}>
          {localizationMessage("userAndPermission.addProjectPermission")}
        </ModalHeader>
        <ModalBody>
          <AddProjectPermissionForm setModal={setmodal} userId={userId} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={permissionModal}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setPermissionModal(!permissionModal)}>
        <ModalHeader toggle={() => setPermissionModal(!permissionModal)}>
          {localizationMessage("userAndPermission.updateProjectPermission")}
        </ModalHeader>
        <ModalBody>
          <PermissionUpdateForm 
            setModal={setPermissionModal} 
            row={row}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteConfirm}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setDeleteConfirm(!deleteConfirm)}
      >
        <ModalHeader toggle={() => setDeleteConfirm(!deleteConfirm)}>
          <span className="_mr-5">{localizationMessage("userAndPermission.deleteProjectFromUser")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteConfirm(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              dispatch(userAndPermissionActions.removeProjectPermission(row?.id));
              setDeleteConfirm(false)
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper {...props}>
        <div className="plr-7 minWidth-28">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header flex">
              <div className="_fs-14 flex flex-wrap align-items-center w-100">
                <div className="d-inlin text-nowrap mb-1">
                  <b>{localizationMessage("userAndPermission.userName")}</b> &nbsp; {(userEntity) ? userEntity?.name : "User-Name"}
                </div>
                &nbsp; &nbsp; &nbsp;
                <div className="d-inline text-nowrap mb-1">
                  <b>{localizationMessage("userAndPermission.userEmail")}</b> &nbsp; {(userEntity) ? userEntity?.email : "User-Email"}
                </div>
                <div className="ml-auto">
                  <Button
                    className={`${!userEntity?.superAdmin ? "c-btn c-primary d-block d-sm-inline ml-auto ml-sm-2" : "d-none"}`}
                    onClick={() => setmodal(!modal)}
                  >
                    <i className="fas fa-plus _mr-10" />
                    {localizationMessage("userAndPermission.addProject")}
                  </Button>
                  {/* <Button className="c-btn c-primary mt-2 mt-sm-0 d-block d-sm-inline ml-auto ml-sm-2" onClick={RemoveAll}>
                  <i className="fas fa-trash _mr-10" />
                  Remove All Permissions
                </Button> */}
                </div>
              </div>
            </div>

            {uAPEntity?.length > 0 && !loading ?
              <div className="table-container text-center overflow-auto">
                <table
                  border={1}
                  className="custom-react-table-theme-class"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps(
                              header.getSortByToggleProps()
                            )}
                          >
                            <div>{header.render("Header")}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows?.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              : null}

            {
              uAPEntity?.length === 0 &&
                loading === false ? (
                <div className="text-center font-weight-bold text-muted mb-2">
                  ------------------------------------<br />
                  {
                    !userEntity?.superAdmin 
                    ? 
                    localizationMessage("userAndPermission.projectNotFoundString")
                    :
                    localizationMessage("userAndPermission.projectSuperAdminAccessMessage")
                  }

                </div>
              ) : null}

          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(UsersAndPermissionsTable));
