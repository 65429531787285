import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: [],
  error: null,
  userAndPermissionEntity: null,
  backFlow: false
};

export const userAndPermissionSlice = createSlice({
  name: "userAndPermission",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
      state.entity = [];
      state.error = null;
    },
    resetCall: (state, action) => {
      state.loading = true;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    getOne: (state, action) => {
      state.userAndPermissionEntity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    getOneBySearch: (state, action) => {
      state.userAndPermissionEntity = action.payload.data;
      state.error = null;
      state.loading = false;
    },
    clear: (state, action) => {
      state.userAndPermissionEntity = null;
      state.entity = [];
      state.loading = true;
      state.error = null;
    },
    create: (state, action) => {
      state.entity.data.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    setBackFlow: (state, action) => {
      state.backFlow = true;
    },
    remove: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    removeUserProjects: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        return obj.user_id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    removeProjectPermission: (state, action) => {
      state.userAndPermissionEntity = state.userAndPermissionEntity.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    addProject: (state, action) => {
      state.userAndPermissionEntity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    updatePermission: (state, action) => {
      state.userAndPermissionEntity = state.userAndPermissionEntity.map(function (obj) {
        if (obj.id === action.payload.id) {
          let object = obj;
          object = { ...obj, permission: action?.payload?.data?.permission };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.error = null;
    },
    updateUser: (state, action) => {
      state.entity.data = state?.entity?.data?.map(function (obj) {
        if (obj?.user_id === action?.payload?.id) {
          const object = { 
            ...obj, 
            super_admin: action?.payload?.data?.superAdmin,
            ...(action?.payload?.data?.superAdmin && {count: 0})
          };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.error = null;
    },
    addExistingUserAndPermission: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },

  }
});
