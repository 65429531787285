import React from "react";
import UpdatePassword from "./UpdatePassword";
import userImage from "assets/images/userImage.png"
import UpdateProfile from "./UpdateProfile";

const Settings = () => {
  return (
    <>
      <div className="card text-center minWidth-27">
        <div className="profile-widget-image my-4">
          <img
            style={{ width: "150px ", borderRadius: "50%" }}
            className="top-header-profile-class"
            src={userImage}
            alt="notify"
          />
        </div>
      </div>
      <div className="container-fluid _mt-2">
        <div className="row ">
          <div className="col-12 col-sm-6 p-0">
            <UpdateProfile />
          </div>
          <div className="col-12 col-sm-6 p-0">
            <UpdatePassword />
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
