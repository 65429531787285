// import { initializeApp } from "firebase/app";
// // import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore'

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId:process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // const auth = getAuth();
// const db = getFirestore(app);

// export { app, db};

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "./firebase-config";

export const app = initializeApp(firebaseConfig);

// export const app = async () => {
//   const initApp = initializeApp(firebaseConfig);
//   const auth = getAuth(initApp);
//   return initApp;
// }
export const auth = getAuth(app);

// export const firebaseSignUp = async (email, password) => {
//   try {
//     const credential = await createUserWithEmailAndPassword(auth, email, password);
//     await sendEmailVerification(getAuth().currentUser)
//     return credential.user;
//   } catch (error) {
//     console.error(" Firebase | firebaseSignUp : ", error.message);
//   }
// };

// export const firebaseLogin = async (email, password) => {
//   try {
//     const res = await signInWithEmailAndPassword(auth, email, password);
//     // Cookies.set("AuthorityHawkToken", res?.user?.accessToken , { expires: process.env.REACT_APP_JS_COOKIES_EXPIRE });
//     return res.user;
//   } catch (error) {
//     console.error(" Firebase | firebaseSignUp : ", error.message);
//   }
// };

// export const firebaseLogout = () => {
//   return signOut(auth)
// }
