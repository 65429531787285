import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(<App />);
}

serviceWorker.unregister();
