import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  loadingCreate: false,
  loadingUpdate: false,
  fetchingData: false,
  entity: [],
  error: null
};

export const stringsSlice = createSlice({
  name: "strings",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    startCall: (state, action) => {
      state.loading = true;
      // state.entity = [];
    },
    startCallAnother: (state, action) => {
      state.loading = true;
    },
    startCallCreate: (state, action) => {
      state.loadingCreate = true;
      state.fetchingData = false;
    },
    startCallUpdate: (state, action) => {
      state.loadingUpdate = true;
      state.fetchingData = false;
    },
    clear: (state, action) => {
      state.entity = [];
      state.loading = true;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    setFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
    endCall: (state, action) => {
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    create: (state, action) => {
      state.entity.unshift(action.payload.data);
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    remove: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        if (obj.id !== action.payload.id) {
        }
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    removeAll: (state, action) => {
      state.entity = [];
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    update: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        if (obj.stringKeyId === action?.payload?.id) {
          let object = obj;
          object = {
            ...obj, ...action.payload.data,
            stringKey: { ...obj.stringKey, comment: action.payload.data.comment }
          };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    }
  }
});
