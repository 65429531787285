import * as requestFromServer from "./Cruds";
import { commonStringsSlice } from "./Reducer";
import * as stringActions from "redux/app/strings/Actions";
import {
  toastLoadingStart,
  toastLoadingEnd,
  toastSuccess,
  toastError,
  // clearToast
} from "helper/toast";
import { getLocalizedString } from "util/getLocalizedString";

const { actions } = commonStringsSlice;

export const clear = () => dispatch => {
  dispatch(actions.clear());
};

export const setFetchingData = bool_val => dispatch => {
  dispatch(actions.setFetchingData(bool_val));
};

//get all strings of specific projectLanguageId
export const getAllByLangaugeId = (languageId, shouldLoading = false, whichToast = "toastSimple") => dispatch => {
  let toastId = "fetchingDataFromServer";
  if (whichToast === "toastPromise") {
    toastId = languageId;
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  // dispatch(actions.startCall());
  if (shouldLoading) dispatch(actions.setFetchingData(true));
  return requestFromServer
    .getAllByLangaugeId(languageId)
    .then(response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
      if (whichToast === "toastPromise") toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
      if (shouldLoading) dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

//download specificLanguageStrings for all platforms using specific projectLanguageId
export const downloadCurrent = (languageId, languageName) => dispatch => {
  let toastId = "downloadCurrent"
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .downloadCurrent(languageId)
    .then(async (response) => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response?.data])
      );
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `translateCommonStrings_${languageName}.zip`);
      document.body.appendChild(link);
      link.click();
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.startDownloading"));
      dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from downloadCurrent--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

//download allLanguageStrings for all platforms using specific projectId
export const downloadAll = () => dispatch => {
  const toastId = "downloadAll";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .downloadAll()
    .then(async (response) => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response?.data])
      );
      const link2 = document.createElement("a");
      link2.href = downloadUrl;
      link2.setAttribute("download", `translateCommonStrings_allLanguages.zip`);
      document.body.appendChild(link2);
      link2.click();
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.startDownloading"));
      dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from downloadAll--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// create a new string record
export const create = (data, resetForm) => async (dispatch) => {
  const toastId = "random";
  let success = false;
  await dispatch(actions.startCallCreate());
  await requestFromServer
    .create(data)
    .then(async (response) => {
      if (resetForm) resetForm();
      await dispatch(actions.create({ data: response?.data }));
      toastSuccess(toastId, getLocalizedString("successMessages.recordAdded"));
      success = true;
    })
    .catch(async (error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from createCommonString--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
      success = false;
    });
  return success;
};

// update value of existing key-value pair/record
export const updateString = (string_valueId, payload, setIsEdit) => dispatch => {
  // const toastId = string_valueId ? `updateCommonString_${string_valueId}` : "fetchingDataFromServer_updateString";
  const toastId = "random";
  dispatch(actions.startCallUpdate());
  return requestFromServer
    .update(string_valueId, payload)
    .then(async response => {
      dispatch(actions.updateString({ data: payload, string_valueId: string_valueId }));
      toastSuccess(toastId, getLocalizedString("successMessages.recordUpdated"));
      setIsEdit(false);
    })
    .catch(async error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from updateCommonString--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const updateProjectCommonString = (payload, setIsEdit) => dispatch => {
  const toastId = "random";
  dispatch(actions.startCallUpdate());
  return requestFromServer
    .updateProjectCommonString(payload)
    .then(async response => {
      dispatch(actions.updateProjectCommonString({ value: response?.data?.value, projectCommonStringId: payload?.projectCommonStringId }));
      toastSuccess(toastId, getLocalizedString("successMessages.recordUpdated"));
      setIsEdit(false);
    })
    .catch(async error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from updateProjectCommonString--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// add string records manually or by copyPaste using textBox/editField
export const addBulk = (data, setBulkAddForm) => dispatch => {
  const toastId = "random";
  dispatch(actions.startCallAnother());
  requestFromServer
    .addBulk(data)
    .then(response => {
      const duplicateStrLength = response?.data?.DuplicateError?.length;
      const addedStrLength = response?.data?.stringArray?.length;
      const totalLength = duplicateStrLength + addedStrLength;
      let toastMessage = "";
      if (duplicateStrLength !== 0 || addedStrLength !== 0) {
        toastMessage = getLocalizedString(
          "successMessages.multipleRecordsAddedDynamicMessage",
          [addedStrLength, totalLength, duplicateStrLength]
        );
        toastSuccess(toastId, toastMessage);
        setBulkAddForm(false);
      } else {
        throw new Error("Invalid or empty content provided");
      }
      dispatch(getAllByLangaugeId(data?.projectLanguageId));
    })
    .catch(async error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from addBulk--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// add string records by uploading file in .txt format
export const addFile = (data, setFileAddForm) => dispatch => {
  const toastId = "random";
  dispatch(actions.startCallAnother());
  requestFromServer
    .addFile(data)
    .then(response => {
      const duplicateStrLength = response?.data?.DuplicateError?.length;
      const addedStrLength = response?.data?.stringArray?.length;
      const totalLength = duplicateStrLength + addedStrLength;
      let toastMessage = "";
      if (duplicateStrLength !== 0 || addedStrLength !== 0) {
        toastMessage = getLocalizedString(
          "successMessages.multipleRecordsAddedDynamicMessage",
          [addedStrLength, totalLength, duplicateStrLength]
        );
        toastSuccess(toastId, toastMessage);
        setFileAddForm(false);
      } else {
        throw new Error("'Invalid file'/'empty content'");
      }
      dispatch(getAllByLangaugeId(data.get("projectLanguageId")));
    })
    .catch(async error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from addFile--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// translate all strings of all languages for specific project
export const allLanguageTranslateAll = (
  payload,
  projectLanguageId
) => dispatch => {
  const toastId = "allLanguageTranslateAll";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  requestFromServer
    .allLanguageTranslateAll(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.allStringsAllLanguagesTranslated")
      );
      dispatch(getAllByLangaugeId(projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// translate missing strings of all languages for specific project
export const allLanguageTranslateMissing = (
  payload,
  projectLanguageId
) => dispatch => {
  const toastId = "allLanguageTranslateMissing";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(
    toastId,
    getLocalizedString("infoMessages.processingRequest")
  );
  requestFromServer
    .allLanguageTranslateMissing(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.missingStringsAllLanguagesTranslated")
      );
      dispatch(getAllByLangaugeId(projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// translate all strings of current/selected language for specific project
export const currentLanguageTranslateAll = payload => dispatch => {
  const toastId = "currentLanguageTranslateAll";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(
    toastId,
    getLocalizedString("infoMessages.processingRequest")
  );
  requestFromServer
    .currentLanguageTranslateAll(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.allStringsCurrentLanguageTranslated")
      );
      dispatch(getAllByLangaugeId(payload?.projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 1000));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// translate missing strings of current/selected language for specific project
export const currentLanguageTranslateMissing = payload => dispatch => {
  const toastId = "currentLanguageTranslateMissing";
  dispatch(actions.setFetchingData(true));
  toastLoadingStart(
    toastId,
    getLocalizedString("infoMessages.processingRequest")
  );
  requestFromServer
    .currentLanguageTranslateMissing(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.missingStringsCurrentLanguageTranslated")
      );
      dispatch(getAllByLangaugeId(payload?.projectLanguageId));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// delete string record from spicific/all language
export const remove = (payload, projectLanguageId) => async (dispatch) => {
  const toastId = payload?.stringkeyId ? `removeCommonStr_${payload.stringkeyId}` : "removeCommonString";
  let success = false;
  if (payload?.stringkeyId) {
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
    await requestFromServer
      .remove(payload)
      .then(async (response) => {
        // await dispatch(actions.remove({ id: payload?.stringValueId }));
        if (payload?.option === "all") await dispatch(actions.removeRow(payload));
        else await dispatch(actions.remove(payload));
        // await dispatch(getAllByprojectLangaugeId(projectLanguageId));
        toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.recordDeleted")
        );
        success = true;
      })
      .catch(async (error) => {
        //wait to get id(toastId) for what it started with
        await new Promise(resolve => setTimeout(resolve, 200));
        const errorMessage =
          error?.code === "ERR_NETWORK"
            ? "errorMessages.networkOrServerError"
            : error?.message || "errorMessages.somethingWentWrong";
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
        const messageLocation = "--error from remove--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        await dispatch(actions.catchError({ error: errorMessageWithLocation }));
        success = false;
      });
  }
  else {
    toastError(
      toastId,
      "Can't delete, because value does't exist"
    );
  }
  return success;
};

// delete string record from spicific/all language
export const removeCommonString = (option, payload) => async (dispatch) => {
  const min = 1;
  const max = 1000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const toastId = `removeCommonString_${randomNumber}`;
  let success = false;
  
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .removeCommonString(option, payload)
    .then(async (response) => {
      // await dispatch(actions.remove({ id: payload?.stringValueId }));
      // if (payload?.option === "all") await dispatch(actions.removeRow(payload));
      // else await dispatch(actions.remove(payload));
      // await dispatch(getAllByprojectLangaugeId(projectLanguageId));
      const toastMessage = response?.data?.message;
      toastLoadingEnd(
        toastId,
        "info",
        getLocalizedString(toastMessage)
      );
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
      success = false;
    });
  return success;
};

export const removeProjectCommonString = (payload, projectLanguageId) => async (dispatch) => {
  const toastId = payload?.stringValueId ? `removeCommonStr_${payload.stringValueId}` : "removeCommonString";
  let success = false;
  if (payload?.stringValueId) {
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
    await requestFromServer
      .remove(payload)
      .then(async (response) => {
        // await dispatch(actions.remove({ id: payload?.stringValueId }));
        if (payload?.option === "all") await dispatch(actions.removeRow(payload));
        else await dispatch(actions.removeProjectCommonString(payload));
        // await dispatch(getAllByprojectLangaugeId(projectLanguageId));
        toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.recordDeleted")
        );
        success = true;
      })
      .catch(async (error) => {
        //wait to get id(toastId) for what it started with
        await new Promise(resolve => setTimeout(resolve, 200));
        const errorMessage =
          error?.code === "ERR_NETWORK"
            ? "errorMessages.networkOrServerError"
            : error?.message || "errorMessages.somethingWentWrong";
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
        const messageLocation = "--error from create--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
        success = false;
      });
  }
  else {
    toastError(
      toastId,
      "Can't delete, because value does't exist"
    );
  }
  return success;
};

export const removeAll = payload => dispatch => {
  const toastId = "removeAllCommonStrings";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .removeAll()
    .then(response => {
      dispatch(actions.removeAll());
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const removeAllFromCurrentLanguage = languageId => dispatch => {
  const toastId = languageId ? `${languageId}_removeAllFromCurrentLanguage` : "fetchingDataFromServer";
  if (languageId) {
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
    return requestFromServer
      .removeAllFromCurrentLanguage(languageId)
      .then(response => {
        dispatch(actions.removeAllFromCurrentLanguage());
        toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.recordDeleted")
        );
      })
      .catch(async (error) => {
        //wait to get id(toastId) for what it started with
        await new Promise(resolve => setTimeout(resolve, 200));
        const errorMessage =
          error?.code === "ERR_NETWORK"
            ? "errorMessages.networkOrServerError"
            : error?.message || "errorMessages.somethingWentWrong";
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
        const messageLocation = "--error from create--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      });
  }
  else {
    toastError(
      toastId,
      "LanguageId no found"
    );
  }
};

// delete string record from spicific/all language
export const removeByProjectCommonStringId = projectCommonStringId => dispatch => {
  const toastId = projectCommonStringId || "fetchingDataFromServer";
  if (projectCommonStringId) {
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
    return requestFromServer
      .removeByProjectCommonStringId(projectCommonStringId)
      .then(response => {
        dispatch(actions.removeByProjectCommonStringId({ id: projectCommonStringId }));
        toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.recordDeleted")
        );
      })
      .catch(async (error) => {
        //wait to get id(toastId) for what it started with
        await new Promise(resolve => setTimeout(resolve, 200));
        const errorMessage =
          error?.code === "ERR_NETWORK"
            ? "errorMessages.networkOrServerError"
            : error?.message || "errorMessages.somethingWentWrong";
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
        const messageLocation = "--error from create--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      });
  }
  else {
    toastError(
      toastId,
      "Can't delete, because projectCommonStringId not found"
    );
  }

};

export const verifyBeforeAddToProject = (payload, setAddAlertMultiple, setAddToProjectAlert) => async dispatch => {
  const toastId = "addCommonStringToProject";
  dispatch(actions.setFetchingData(true));
  // const toastId = `addToProject_${payload?.projectId || "fetchingDataFromServer"}`;
  await toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCallAnother());
  try {
    var resp = await requestFromServer
      // .addToproject(data)
      .verifyBeforeAddToProject(payload)
      .then(async (response) => {

        if (!response?.data?.success || !response?.data?.translationPrimary) {
          await toastLoadingEnd(
            toastId,
            "success",
            getLocalizedString("successMessages.dataFetched"),
            500
          );
        }
        await dispatch(actions.setFetchingData(false));
        return response;
      })
      .catch(async (error) => {
        //wait to get id(toastId) for what it started with
        await new Promise(resolve => setTimeout(resolve, 200));
        const errorMessage =
          error?.code === "ERR_NETWORK"
            ? "errorMessages.networkOrServerError"
            : error?.message || "errorMessages.somethingWentWrong";
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
        // const messageLocation = "--error from create--";
        // const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        // dispatch(actions.catchError({ error: errorMessageWithLocation }));
        await dispatch(actions.setFetchingData(false));
        return error?.response;
      });
    return resp;
  } catch (error) {
  }

};


export const addToProject = (payload, skipTranslation, setAddAlertMultiple, setAddToProjectAlert, toastThinking) => async dispatch => {
  // const toastId = `addToProject_${payload?.projectId || "fetchingDataFromServer"}`;
  let toastId = "addCommonStringToProject";
  if (toastThinking === "random") {
    const min = 1;
    const max = 1000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    toastId = `addCommonStringToProject_${randomNumber}`;
  }

  dispatch(actions.setFetchingData(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCallAnother());

  await requestFromServer
    .addToproject(payload, skipTranslation)
    // .verifyBeforeAddToProject(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.addedInProject")
      );
      setAddAlertMultiple(false);
      setAddToProjectAlert(false);
      // dispatch(getAllByLangaugeId(payload.get("projectLanguageId")));
      dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      // const messageLocation = "--error from create--";
      // const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      // dispatch(actions.catchError({ error: errorMessageWithLocation }));
      dispatch(actions.setFetchingData(false));
    });
};

export const addToProjectString = (payload) => async dispatch => {
  const toastId = payload?.projectCommonStringId ? `addToProjectString_${payload.projectCommonStringId}` : "fetchingDataFromServer";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCallAnother());

  await requestFromServer
    .addToProjectString(payload)
    // .verifyBeforeAddToProject(payload)
    .then(response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.addedInProjectsStrings")
      );
      //remove from commmon string redux
      dispatch(actions.removeByProjectCommonStringId({ id: payload.projectCommonStringId }));
      //add to project string redux
      dispatch(stringActions.addToString({ data: response?.data }));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};


export const getAllByprojectLangaugeId = (languageId, shouldLoading = false, whichToast = "toastSimple") => dispatch => {
  let toastId = "fetchingDataFromServer";
  if (whichToast === "toastPromise"){
    toastId = languageId || "fetchingDataFromServer";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"))
  }
  // dispatch(actions.startCall());
  if (shouldLoading) dispatch(actions.setFetchingData(true));
  return requestFromServer
    .getAllByprojectLangaugeId(languageId)
    .then(response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
      if (whichToast === "toastPromise") toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
      if (shouldLoading) dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};





export const getAll_CS_ByprojectLangaugeId = (projectLanguageId, shouldLoading = false, toastId, whichToast = "toastSimple", stringActions, apiData) => dispatch => {
  toastId = toastId || "fetchingDataFromServer";
  return requestFromServer
    .getAllByprojectLangaugeId(projectLanguageId)
    .then(async (response) => {
      dispatch(actions.getAll({ data: response?.data || [] }));
      // dispatch(stringActions.getAll({ data: apiData || [] }));
      if (whichToast === "toastPromise") {
        toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.dataFetched")
        );
      }
      if (shouldLoading) dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      // const messageLocation = "--error from getAllByprojectLangaugeId--";
      // const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      // await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};