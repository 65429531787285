const paginationAction = {
  SET_PROJECT_ROWS: "SET_PROJECT_ROWS",

  setProjectRows: value => {
    return {
      type: paginationAction.SET_PROJECT_ROWS,
      payload: value
    };
  }
};

export default paginationAction;
