import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";
import axios from "axios";
import * as _redux from "./redux";
import { SnackbarProvider } from "notistack";
import {
  ToastContainer,
  // Slide, 
  Zoom,
  // Flip, 
  // Bounce 
} from "react-toastify";
import AppRoutes from "AppRoutes";
// import { Error400 } from "views/pages";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const App = () => {
  const token = _redux.setupAxios(axios, store);

  return (
    <Fragment>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <ToastContainer limit={3} transition={Zoom} />
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <StyleRoot>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  {/*Start layout routes */}
                  <AppRoutes history={history} token={token} />

                  {/*End layout routes */}
                </PersistGate>
              </Provider>
            </StyleRoot>
          </ThemeProvider>
        </IntlProvider>
      </SnackbarProvider>
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.
// If you want to change sidebar nav list then go to util/data/sidebar.jsx
