// AppRoutes.js
import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
// Import your layoutRoutes and history
import layoutRoutes from "./routes/index.jsx";

const AppRoutes = ({ history, token }) => {

  return (
    <>
    <Router history={history} token={token}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/projects" />}
        />
        {layoutRoutes.map((prop, key) => (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
            history={history}
            token={token}
          />
        ))}
      </Switch>
    </Router>
    </>
  )
};

export default AppRoutes;