import React, { useState, useLayoutEffect } from "react";
import "assets/customstyles/whatsnew.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import WhatsNewForm from "views/app/projects/whatsnew/WhatsNewForm";
import ReactTableWrapper from "../../../../components/reacttable/reacttbl.style";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as whatsnewActions from "redux/app/whatsnew/Actions";
import CardWhatsnew from "./CardWhatsnew";
import { useMemo } from "react";
import ErrorComponent from "views/ErrorComponent";
import * as whatsnewVersionsActions from "redux/app/whatsnewVersions/Actions";
import DropdownRedirection from "views/app/DropdownRedirection";
import { useEffect } from "react";
import { useIntlMessage } from "util/getIntlMessage";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const WhatsNewTable = ({projectId}) => {

  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();

  //redux
  const { whatsnewVersionEntity, whatsnew, whatsnewLoading, whatsNewError } = useSelector(
    state => ({
      whatsnewVersionEntity: state?.whatsnewVersion?.entity,
      whatsnew: state?.whatsnew,
      whatsnewLoading: state?.whatsnew?.loading,
      whatsNewError: state?.whatsnew?.error
    }),
    shallowEqual
  );

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;
  const [newModal, setNewModal] = useState(false);
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(true);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [LatestVersionId, setLatestVersionId] = useState(whatsnewVersionEntity?.[whatsnewVersionEntity.length-1]?.id);
  const [openGotoDropdown, setOpenGotoDropdown] = useState(false);
  const [selectedVersionId, setSelectedVersionId] = useState(whatsnewVersionEntity?.[0]?.id);

  useEffect(()=>{
    setLatestVersionId(whatsnewVersionEntity?.[whatsnewVersionEntity.length-1]?.id);
    // LatestVersionId = whatsnewVersionEntity?.[whatsnewVersionEntity.length-1]?.id
  }, [whatsnewVersionEntity?.length])

  const redirectionDropdownItems = [
    {
      name: localizationMessage("projectString.strings"),
      redirect: `/projects/${projectId}/strings`
    },
    {
      name: localizationMessage("storeListing.storeListing"),
      redirect: `/projects/${projectId}/storelisting`
    },
    {
      name: localizationMessage("logs.logs"),
      redirect: `/projects/${projectId}/logs`
    },
    {
      name: localizationMessage("projectDetail.detail"),
      redirect: `/projects/${projectId}/detail`
    }
  ];

  const TranslateAll = async () => {
    if(selectedVersionId){
      dispatch(whatsnewActions.translateAll(selectedVersionId));
    }
  };

  useLayoutEffect(() => {
    let versionId = whatsnewVersionEntity[0]?.id || null;
    dispatch(whatsnewActions.getAllByVersionId(versionId, true));
    setSelectedVersionId(versionId);
  }, [dispatch, whatsnewVersionEntity]);

  useEffect(()=>{
  }, [whatsnewVersionEntity?.length])


  //sort object with default language at top in new array using(isDefault) in this case
  var reorder = function (comparisonValue, originalArray) {
    var newArray = originalArray.slice(); // clone array
    newArray.sort(function (a) {
      return a?.projectLanguage?.isDefault !== comparisonValue ? 1 : -1;
    });
    return newArray;
  };

  return (
    <>
      <Modal
        isOpen={newModal}
        fade={false}
        className="mx-auto minWidth-28 maxWidth-45"
        toggle={() => setNewModal(!newModal)}
      >
        <ModalHeader toggle={() => setNewModal(!newModal)}>
          {localizationMessage("whatsNew.createNewTitle")}
        </ModalHeader>
        <ModalBody>
          <WhatsNewForm setNewModal={setNewModal} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteAlert}
        fade={false}
        toggle={() => setDeleteAlert(!deleteAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setDeleteAlert(!deleteAlert)}>
          <span className="mr-5">{localizationMessage("whatsNew.deleteVersion")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("whatsNew.deleteVersionConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              // can only delete the latest version
              const versionId = LatestVersionId;
              const success = dispatch(whatsnewVersionsActions.remove(versionId));
              if(success && whatsnewVersionEntity?.length>0){
                setSelectedVersionId(whatsnewVersionEntity?.[0]?.id || null);
              }
              setDeleteAlert(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper>
        <div className="plr-7 minWidth-28">
          <div className="flex header row">
            <div className="col-12 col-sm-5">
              <span className="d-flex h4 font-weight-bold">
                {localizationMessage("whatsNew.version")}
                {whatsnewVersionEntity?.length > 0 ? (
                  <FormGroup className="ml-2 mtb-0 version-dropdown">
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      className="cursor-pointer"
                      style={{ appearance: "auto" }}
                      onChange={e => {
                        setSelectedVersionId(e?.target?.value);
                        dispatch(
                          whatsnewActions.getAllByVersionId(e?.target?.value)
                        );
                      }}
                      value={selectedVersionId}
                    >
                      {whatsnewVersionEntity?.length > 0 &&
                        whatsnewVersionEntity?.map((version, index) => {
                          return (
                            <option key={version?.id} value={version?.id}>
                              {version?.nameId}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                ) : (
                  <></>
                )}
                <span id="options"></span>
              </span>
            </div>
            <div className="fs-12 p-sm-0 ml-auto col-12 col-sm-auto d-flex align-items-center justify-content-end">
              {
                (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
                selectedVersionId===LatestVersionId ?
                <Button
                  className="fs-12 btn c-danger ml-2 d-flex"
                  onClick={() => {
                    setDeleteAlert(true);
                  }}
                >
                  {localizationMessage("delete")}
                </Button>
                : null
              }
              {
                (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) &&
                whatsnewVersionEntity?.length>0 ? 
                <Button
                  className="fs-12 btn c-primary ml-2 d-flex "
                  onClick={() => TranslateAll()}
                >
                  {localizationMessage("translateAll")}
                </Button>
                : null
              }
              {
                (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission)) ?
                <Button
                  className="fs-12 btn c-primary ml-2 d-flex"
                  onClick={() => setNewModal(true)}
                >
                  {localizationMessage("whatsNew.addNew")}
                </Button>
                : null
              }
              <div className="ml-2">
                <DropdownRedirection 
                  openDropdown={openGotoDropdown}
                  setOpenDropdown={setOpenGotoDropdown}
                  dropdownTitle={localizationMessage("goto")}
                  items={redirectionDropdownItems}
                />
              </div>
            </div>
              {!whatsnewLoading && whatsnewVersionEntity?.length > 0 && whatsnew?.entity?.length > 0 ? (
                <div className="flex header justify-content-end col-12 col-sm-auto ml-auto ml-sm-0 mt-2 mt-sm-0">
                  <div className="add-btn d-flex">
                    <button
                      onClick={() => {
                        setCollapse(!collapse);
                      }}
                    >
                      <p className="_ml-10 mtb-0">
                        {!collapse ? (
                          <>
                            {localizationMessage("collapseAll")}
                            <i className="fas fa-angle-up" />
                          </>
                        ) : (
                          <>
                            {localizationMessage("expandAll")}
                            <i className="fas fa-angle-down" />
                          </>
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                ) : null
              }
          </div>
          {!whatsnewLoading && whatsnew?.entity?.length === 0 && !whatsNewError ? (
            <div className="text-center font-weight-bold text-muted mt-3">
              {localizationMessage("whatsNew.addNewVersionRequestMessage")}
            </div>
          ) : null}

          

          {useMemo(() => {
            return whatsnewLoading && whatsnew?.entity?.length === 0 ? (
              <div className="text-center">
                <Spinner
                  color="#563c91"
                  type="grow"
                  style={{
                    color: "#563c91 !important",
                    textAlign: "center",
                    position: "fixed",
                    top: "50vh",
                    right: "45vw",
                    height: "3rem",
                    width: "3rem"
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            ) : whatsnew?.entity?.length === 0 && whatsNewError ? <ErrorComponent /> : null;
          }, [whatsnewLoading, whatsnew, whatsNewError])}

          {useMemo(() => {
            return (
              <>
                {!whatsnewLoading &&
                  whatsnew?.entity?.length > 0 &&
                  reorder(1, whatsnew.entity).map((whatsnewItem, index) => {
                    return (
                      <div key={index}>
                        <CardWhatsnew
                          entity={whatsnewItem}
                          parentCollapse={collapse}
                        />
                      </div>
                    );
                  })
                }
              </>
            );
          }, [collapse, whatsnew, whatsnewLoading])}
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(withRouter(WhatsNewTable));
