import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as commonStringsAction from "redux/app/commonStrings/Actions";
import Button from "reactstrap/lib/Button";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { clearToast } from "helper/toast";
import "assets/customstyles/projects.css";
import { useRef } from "react";
import { useIntlMessage } from "util/getIntlMessage";

const AddToProject = ({
  projectData,
  projectSelectedLanguageId,
  setAddToProjectAlert,
  string_keyId
}) => {
  const localizationMessage = useIntlMessage();
  let { projectEntity, fetchingData } = useSelector(
    state => ({
      projectEntity: state.project.entity,
      fetchingData: state?.commonString?.fetchingData

    }),
    shallowEqual
  );

  const [projectListArray, setProjectListArray] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [addAlertMultiple, setAddAlertMultiple] = useState(false);
  const [viewTranslationStatusArray, setViewTranslationStatusArray] = useState([]);
  const [translatedPrimary, setTranslatedPrimary] = useState(false);

  const skipTranslation = useRef(true);

  const dispatch = useDispatch();

  const Add = async () => {
    if (!selectedProject) {
      document.getElementById("error-tag").style.display = "block";
    } else {
      document.getElementById("error-tag").style.display = "none";
      const payload = {
        projectId: selectedProject,
        commonStringKeyId: string_keyId
      }
      const result = await dispatch(commonStringsAction.verifyBeforeAddToProject(payload, setAddToProjectAlert));

      // if string's translated in all languages only then success is true.

      setTranslatedPrimary(result?.data?.translationPrimary);

      if (result?.status >= 200 && result?.status < 300) {
        if (result?.data?.success && result?.data?.translationPrimary) {
          const payload = {
            projectId: selectedProject,
            commonStringKeyId: string_keyId
          }
          dispatch(commonStringsAction.addToProject(payload, true, setAddAlertMultiple, setAddToProjectAlert));
        }
        else {
          if (result?.data?.languages) {
            skipTranslation.current = (result?.data?.skipTranslation || false);
            setViewTranslationStatusArray(result?.data?.languages || [])
            setAddAlertMultiple(true);
          }
          else {
            // clear all toasts.
            clearToast();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (projectEntity.length > 0) {
      if (projectEntity) {
        const arr = [];
        projectEntity.map(data =>
          arr.push({ value: data?.id, label: data?.name })
        );
        setProjectListArray(arr);
      }
    }
  }, [projectEntity]);

  return (
    <>
      <Modal
        isOpen={addAlertMultiple}
        fade={false}
        toggle={() => setAddAlertMultiple(!addAlertMultiple)}
        className="mx-auto modalAddToProject"
        backdrop={"static"}
        keyboard={false}
        style={{ maxWidth: '700px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => setAddAlertMultiple(!addAlertMultiple)}
        >
          {localizationMessage("commonString.addStringsToProject")}
        </ModalHeader>
        <ModalBody style={{ maxHeight: '400px', height: '100%', overflowY: "scroll" }}>
          {string_keyId?.length === 1 ?
            <Table>
              <thead>
                <tr>
                  <th>
                    {localizationMessage("languages")}
                  </th>
                  <th>
                    {localizationMessage("commonString.translationStatus")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  viewTranslationStatusArray.map((data, index) => {
                    return (
                      <tr key={index} className={data.success === true ? "table-success" : "table-danger"}>
                        <td>
                          {data.name}
                        </td>
                        <td>
                          {data.success === true ? "Translated" : "Not-translated"}
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table> :
            <div>
              {localizationMessage("commonString.addStringToProjectConfirmationMessage")}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          {translatedPrimary ?
            <Button
              className="btn ml-auto c-primary modalButtonSize"
              disabled={fetchingData}
              onClick={() => {
                const payload = {
                  projectId: selectedProject,
                  commonStringKeyId: string_keyId
                }
                dispatch(commonStringsAction.addToProject(payload, true, setAddAlertMultiple, setAddToProjectAlert, "random"));
                // setAddAlertMultiple(false);
              }}
            >
              {localizationMessage("commonString.addWithoutChange")}
            </Button> : null
          }
          <Button
            className="btn ml-2 c-primary modalButtonSize"
            disabled={fetchingData}
            onClick={() => {
              const payload = {
                projectId: selectedProject,
                commonStringKeyId: string_keyId
              }
              dispatch(commonStringsAction.addToProject(payload, false, setAddAlertMultiple, setAddToProjectAlert, "random"));
              // setAddAlertMultiple(false);
            }}
          >
            {localizationMessage("commonString.translateAllThenAdd")}
          </Button>
        </ModalFooter>
      </Modal>

      <form id="form" className="project-form">
        <div className="form-group">
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder={localizationMessage("commonString.selectProject")}
            onChange={e => {
              setSelectedProject(e.value);
            }}
            options={projectListArray}
            noOptionsMessage={() => localizationMessage("commonString.selectFieldEmptyOrUnauthorizedMessage")}
          />
        </div>

        <div className="form-group">
          <span id="error-tag">{localizationMessage("aboveFieldIsRequired")}</span>
        </div>

        <Button
          className="btn c-primary modalButtonSize w-100"
          type="button"
          disabled={fetchingData}
          onClick={Add}
        >
          {localizationMessage("add")}
        </Button>
      </form>
    </>
  );
};

export default AddToProject;