import React from "react";
import "assets/customstyles/projects.css";
import { Input } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as whatsnewVersionsActions from "redux/app/whatsnewVersions/Actions";
import Button from "reactstrap/lib/Button";
import { useIntlMessage } from "util/getIntlMessage";

const WhatsNewForm = ({ setNewModal }) => {

  const localizationMessage = useIntlMessage();

  const dispatch = useDispatch();

  //redux
  const { projectDefaultLanguage, projectId, buttonLoading } = useSelector(
    state => ({
      projectDefaultLanguage: state?.project?.projectDefaultLanguage,
      projectId: state?.project?.projectEntity?.id,
      buttonLoading: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  //Initial State
  const InitialState = {
    nameId: "",
    description: ""
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    nameId: Yup.string()
      .required(" required")
      .matches(/^[0-9.]+$/), //allow only numbers and dot
    description: Yup.string().required(" required")
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    const payload = {
      ...values,
      projectLanguageId: projectDefaultLanguage?.id,
      projectId: projectId
    };
    dispatch(whatsnewVersionsActions.create(payload, setNewModal));
  };

  const handleSaveAndTranslate = (values) => {
    // formMik.submitForm();
    if(!formMik.values?.nameId || !formMik.values?.description){
      formMik.setTouched({
        nameId: true,
        description: true,
      });
      return;
    }
    
    // below code run only when form is valid
    const payloadCreate = {
      ...values,
      projectLanguageId: projectDefaultLanguage?.id,
      projectId: projectId
    };
    const shouldTranslateAll = true;
    dispatch(whatsnewVersionsActions.create(payloadCreate, setNewModal, shouldTranslateAll));
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <form id="form" className="project-form" onSubmit={formMik.handleSubmit}>
      <div className="form-group">
        <Input
          placeholder={localizationMessage("whatsNew.nameField.placeholder")}
          name="nameId"
          className={
            formMik.errors.nameId && formMik.touched.nameId
              ? "is-invalid"
              : formMik.touched.nameId
                ? "is-valid"
                : ""
          }
          onChange={e => {
            formMik.setFieldValue("nameId", e.target.value);
          }}
          onBlur={formMik.handleBlur}
          value={formMik.values.nameId}
        />
      </div>

      <div className="form-group">
        <textarea
          id="description"
          name="description"
          className={
            formMik.errors.description && formMik.touched.description
              ? "is-invalid form-control"
              : formMik.touched.description
                ? "is-valid form-control"
                : " form-control"
          }
          onChange={e => {
            formMik.setFieldValue("description", e.target.value);
          }}
          onBlur={formMik.handleBlur}
          value={formMik.values.description}
          placeholder={localizationMessage("whatsNew.descField.placeholder")}
          rows="5"
        />
      </div>

      <div className="flex justify-content-end">
        <Button
          className="btn c-primary d-block"
          type="button"
          onClick={()=> formMik.isValid && handleSaveAndTranslate(formMik.values)}
          disabled={buttonLoading}
          >
          {localizationMessage("whatsNew.addAndTranslate")}
        </Button>
        <Button
          className="btn c-primary ml-2"
          type="submit"
          disabled={buttonLoading}
        >
          {localizationMessage("whatsNew.add")}
        </Button>
      </div>
    </form>
  );
};

export default WhatsNewForm;
