import authActions from "redux/auth/actions";
import { getIdToken } from "helper/getIdToken";
import { ApiUrl } from "services/Api";

export default async function setupAxios(axios, store) {
  /*************| request interceptor |*************/
  axios.interceptors.request.use(async request => {
    // add auth header with jwt if account is logged in
    // const authToken = store.getState("auth")?.auth?.accessToken;
    const authToken = localStorage.getItem("accessToken");

    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }
    
    return request;
  });

  /*************| response interceptors |*************/
  axios.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      if (error?.response?.data) {
        const authToken = localStorage.getItem("accessToken");
        error.message = error?.response?.data?.message;

        if (error?.response?.status === 401) {
          error.message = error?.response?.data?.message;
          // Logged out when unauthorized
          authActions.logout();
          // store.dispatch(authActions.logout());
        }
        // refreshing firebase token, if expired
        else if (error?.response?.status === 440 && authToken) {
          error.message = error?.response?.data?.message;
          const decodedJwt = JSON.parse(atob(authToken.split(".")[1]));
          // check if token is expired only then refresh token
          if(decodedJwt.exp * 1000 < Date.now()){
            const originalRequest = error?.config;
            let firebaseRefreshToken = await getIdToken();
            authActions.refreshToken(firebaseRefreshToken);
            return axios(originalRequest);
          }
        }
        // below code is to updated user role on frontend when user role modified on backend and reload page forcely
        else if(error?.response?.status === 405 && error.message==="api.errorMessages.forbiddenAccess" && authToken){
          axios({
            method: ApiUrl.Users.Login.Method,
            url: ApiUrl.Users.Login.Url,
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          })
          .then(response => {
            localStorage.setItem("user", JSON.stringify(response?.data));
            window.location.href = "/projects"; // refresh and redirect to projects page
          })
          .catch(error => {
            // Handle error
          });
        }
      } else {

        error.message = "Network/server Error";
      }
      return Promise.reject(error);
    }
  );
}
