import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.String;

// export function getAllByVersionId(versionId) {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" },
//     where:{whatsNewVersionId:versionId},
//     relations:['projectLanguage','projectLanguage.language']
//   });
// }
// export function getAll() {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" }
//   });
// }

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}


export function update(stringKeyId, projectLanguageId, id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  url = url + `?stringKeyId=${stringKeyId}&projectLanguageId=${projectLanguageId}`;
  return axios[API.Update.Method](url, payload);
}

export function addBulk(payload) {
  return axios[API.addBulk.Method](API.addBulk.Url, payload);
}

export function addFile(payload) {
  return axios[API.addFile.Method](API.addFile.Url, payload);
}

export function currentLanguageTranslateAll(payload) {
  return axios[API.currentLanguageTranslateAll.Method](
    API.currentLanguageTranslateAll.Url,
    payload
  );
}
export function currentLanguageTranslateMissing(payload) {
  return axios[API.currentLanguageTranslateMissing.Method](
    API.currentLanguageTranslateMissing.Url,
    payload
  );
}
export function allLanguageTranslateAll(payload) {
  return axios[API.allLanguageTranslateAll.Method](
    API.allLanguageTranslateAll.Url,
    payload
  );
}
export function allLanguageTranslateMissing(payload) {
  return axios[API.allLanguageTranslateMissing.Method](
    API.allLanguageTranslateMissing.Url,
    payload
  );
}

export function getTranslationProgress(projectId) {
  let url = API.GetTranslationProgress.Url;
  url = url.replace("##paramid##", projectId);
  return axios[API.GetTranslationProgress.Method](url);
}

// export function removeAll() {
//   return axios[API.DeleteAll.Method](API.DeleteAll.Url);
// }
export function getAllByprojectLangaugeId(projectLanguageId) {
  let url = API.GetAllByprojectLangaugeId.Url;
  url = url.replace("##paramid##", projectLanguageId);
  return axios[API.GetAllByprojectLangaugeId.Method](url);
}

export function remove(payload) {
  let url = API.Delete.Url;
  return axios[API.Delete.Method](url, payload);
}

export function removeAll(projectLanguageId) {
  let url = API.DeleteAll.Url;
  url = url.replace("##paramid##", projectLanguageId);
  return axios[API.DeleteAll.Method](url);
}

export function translateOne(option, payload) {
  let url = API.translateOne.Url;
  url = url.replace("##paramid##", option);
  return axios[API.translateOne.Method](url, payload);
}


export function addStringToCommonStrings(payload) {
  let url = API.AddStringToCommonStrings.Url;
  return axios[API.AddStringToCommonStrings.Method](url, payload);
}

export function downloadCurrent(projectLanguageId) {
  let url = API.DownloadCurrent.Url;
  url = url.replace("##paramid##", projectLanguageId);
  return axios[API.DownloadCurrent.Method](url, { responseType: "blob" });
}

export function downloadAll(projectId) {
  let url = API.DownloadAll.Url;
  url = url.replace("##paramid##", projectId);
  return axios[API.DownloadAll.Method](url, { responseType: "blob" });
}

// export function remove(id) {
//   let url = API.Delete.Url;
//   url = url.replace("##paramid##", id);
//   return axios[API.Delete.Method](url);
// }
