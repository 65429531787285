// this custom file made by 19 tested on below version
// "@mui/material": "^5.11.1",
// @material-ui/core": "version": "4.11.0",

//option format or input array format that comes from props
// const options = [
//   { value: "value 1", label: "label 1" },
//   { value: "value 2", label: "label 2" },
//   { value: "value 3", label: "label 3" }
// ];

import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useRef } from "react";
import { useIntlMessage } from "util/getIntlMessage";

function MultiSelect({ name: fieldName, options, formMik, ListItemName, placeholderValue }) {

  const localizationMessage = useIntlMessage();

  const useStyles = makeStyles((theme) => {

    const selectColor = "rgba(134,183,254,1)";
    const errorColor = "rgba(220,53,69,1)";
    const successColor = "rgba(25,135,84,1)";

    const selectAllCheckboxColor = "#3f51b5";
    const selectAllIndeterminateColor = "#3f51b5";
    const selectAllIBackgroundColor = "#ffffff";
    const selectAllIBackgroundHoverColor = "#00000014";
    const checkboxColor = "#3f51b5";
    const selectBorderUnaffectedColor = "#ced4da";

    const selectMatches = selectColor.match(/,([^,]+)\)$/);
    const selectOpacityRatio = selectMatches ? selectMatches[1] : null;
    const newSelectOpacityRatio = selectOpacityRatio * 0.25;
    const selectLighterColor = selectColor.replace(/[^,]+(?=\)$)/, `${newSelectOpacityRatio}`);

    const errorMatches = errorColor.match(/,([^,]+)\)$/);
    const errorOpacityRatio = errorMatches ? errorMatches[1] : null;
    const newErrorOpacityRatio = errorOpacityRatio * 0.25;
    const errorLighterColor = errorColor.replace(/[^,]+(?=\)$)/, `${newErrorOpacityRatio}`);

    const successMatches = successColor.match(/,([^,]+)\)$/);
    const successOpacityRatio = successMatches ? successMatches[1] : null;
    const newSuccessOpacityRatio = successOpacityRatio * 0.25;
    const successLighterColor = successColor.replace(/[^,]+(?=\)$)/, `${newSuccessOpacityRatio}`);

    const selectBorderColor = (formMik.errors[fieldName] && formMik.touched[fieldName]) ? errorColor : (formMik.touched[fieldName] ? successColor : selectBorderUnaffectedColor);
    const selectBorderFocusedColor = (formMik.errors[fieldName] && formMik.touched[fieldName]) ? errorColor : (formMik.touched[fieldName] ? successColor : selectColor);
    const selectFocusedHoverColor = (formMik.errors[fieldName] && formMik.touched[fieldName]) ? errorColor : (formMik.touched[fieldName] ? successColor : selectColor);
    const selectHoverColor = (formMik.errors[fieldName] && formMik.touched[fieldName]) ? errorColor : (formMik.touched[fieldName] ? successColor : selectBorderUnaffectedColor);
    const selectBoxshadowColor = (formMik.errors[fieldName] && formMik.touched[fieldName]) ? errorLighterColor : (formMik.touched[fieldName] ? successLighterColor : selectLighterColor);

    return {
      formControl: {
        margin: theme.spacing(1),
        width: "100%",
      },
      selectAllCheckbox: {
        color: selectAllCheckboxColor, // Custom color for the unchecked state
        "&$checked": {
          color: selectAllCheckboxColor, // Custom color for the checked state
        },
        "&$indeterminate": {
          color: selectAllIndeterminateColor, // Custom color for the indeterminate state
        },
      },
      checked: {},
      indeterminate: {},

      selectInput: {
        color: "#757575",
        padding: "7px 14px",
        '&:focus': {
          backgroundColor: '#ffffff',
        },

      },
      icon: {
        fill: 'green',
      },
      checkedColor: {
        color: checkboxColor
      },
      selectAllText: {
        // height: 400,
        fontWeight: 500
      },
      selectedAll: {
        backgroundColor: selectAllIBackgroundColor,
        "&:hover": {
          backgroundColor: selectAllIBackgroundHoverColor
        }
      },
      menuItem: {
        minHeight: '30px', // Adjust the height as per your requirement
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      listItemIcon: {
        minWidth: 'fit-content', // Set the width as per your requirement
        minHeight: '10px',
        marginRight: theme.spacing(2), // Adjust the margin as needed
        '& > span': {
          padding: "0px"
        },
        '& .MuiSvgIcon-root': {
          height: '18px',
          width: '18px',
        },
      },
      fieldset: {
        '& fieldset': {
          borderColor: `${selectBorderColor}`,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: `${selectBorderFocusedColor}`,
          outline: '0',
          boxShadow: `0 0 0 0.25rem ${selectBoxshadowColor}`
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${selectHoverColor}`,
        },
        '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${selectFocusedHoverColor}`,
        },
      },
      errorField: {
        marginTop: "2px",
        marginLeft: "4px",
        color: "#d52323",
        fontSize: "12px",
      },
      customClass: {
        '& fieldset': {
          backgroundColor: "transparent !important"
        },
        '& .MuiSelect-icon': {
          display: "none" //disable the default icon of select component
        },
      },
    }
  });

  const classes = useStyles();
  const selected = useRef([]);
  const [updateComponent, setUpdateComponent] = useState(false);

  const valueArray = options.map((option) => option.value);
  const isAllSelected = useRef(false);

  const handleChange = (event) => {
    let value = event.target.value;
    if (value[value.length - 1] === "all") {
      selected.current = selected.current.length === options?.length ? [] : valueArray;
      if (!isAllSelected.current) { value = valueArray; }
      else { value = []; }
    }
    else { selected.current = value; }
    isAllSelected.current =
      options.length > 0 && selected.current.length === options.length;
    formMik.setFieldValue(fieldName, value);

    setUpdateComponent(!updateComponent)
  };

  //select styling...
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: "500"
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };

  useEffect(() => {
    const elements = document.getElementsByClassName(`${classes.customClass}`);
    // Loop through each element with the specified class
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      // element.classList.add('form-select');
      const fieldsetElement = element.querySelector('fieldset');
      if (fieldsetElement) {
        if (formMik?.errors[fieldName] && formMik?.touched[fieldName]) {
          fieldsetElement.classList.add("is-invalid", "form-select");
          fieldsetElement.classList.remove("is-valid");
        }
        else {
          if (formMik?.touched[fieldName]) {
            fieldsetElement.classList.add("is-valid", "form-select");
            fieldsetElement.classList.remove("is-invalid");

          }
          else {
            fieldsetElement.classList.add("form-select");
            fieldsetElement.classList.remove("is-invalid", "is-valid");
          }
        }
      }
    }
  });

  return (
    <div className={`${classes.customClass} multiselect-wrapper`}>
      <FormControl
        className={`${classes.fieldset}`}
        size="small"
        style={{ width: "100%" }}
      >
        <Select
          variant="outlined"
          name={fieldName}
          labelId="mutiple-select-label"
          multiple
          IconComponent={() => { }}
          onBlur={formMik.handleBlur}
          value={selected.current}
          onChange={handleChange}
          MenuProps={MenuProps}
          displayEmpty
          inputProps={{ className: classes.selectInput }}
          renderValue={() => {
            return selected.current.length > 0 ? `${selected.current.length} ${ListItemName || "item's"} selected` : placeholderValue || "select item's";
          }}
        >
          {options?.length > 0 ? (
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected.current ? classes.selectedAll : ""
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  color="default"
                  classes={{
                    root: classes.selectAllCheckbox,
                    checked: classes.checked,
                    indeterminate: classes.indeterminate,
                  }}
                  checked={isAllSelected.current}
                  indeterminate={selected.current.length > 0 && selected.current.length < options.length}
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
          ) : (
            <MenuItem disabled className={classes.menuItem}>
              <ListItemText primary={localizationMessage("noItemsListed")} />
            </MenuItem>
          )}

          {options?.length > 0 && <hr className="border-top my-0" />}

          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={classes.menuItem}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  color="default"
                  className={classes.checkedColor}
                  checked={selected.current.indexOf(option.value) > -1}
                />

              </ListItemIcon>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>

        {/* {(formMik.errors[fieldName] && formMik.touched[fieldName]) ?
          <div className="">
            <span className={classes.errorField}>{formMik.errors[fieldName]}</span>
          </div> : null
        } */}
      </FormControl>
    </div>
  );
}
export default MultiSelect;