import React from "react";
import "assets/customstyles/projects.css";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import * as projectsActions from "redux/app/projects/Actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntlMessage } from "util/getIntlMessage";
import { permissionOptionsForNonAdminUser } from "util/data/reactTableData";

const ProjectPermissionUpdateForm = ({ setModal, row }) => {
  const localizationMessage = useIntlMessage();

  const dispatch = useDispatch();
  //Initial State
  const InitialState = {
    permissionField: row?.permission ? row?.permission : ""
  };
  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    permissionField: Yup.string().required(" required")
  });

  const SubmitHandler = async values => {
    const lowerCasePermissionField = values.permissionField?.toLowerCase();
    const payload = {
      permission: lowerCasePermissionField
    }
    // dispatch(userAndPermissionActions.updatePermission(row.id, payload));
    dispatch(projectsActions.updateProjectPermission(row.id, payload));
    setModal(false);
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  return (
    <form id="form" className="" onSubmit={formMik.handleSubmit}>
      <div className="mb-3">
        {localizationMessage("projectDetail.updateProjectPermissionRequestMessage")}"<b>{row?.user?.email}</b>"
      </div>
      <div className="form-group">
        <div className="form-group">
          <select
            name="permissionField"
            className={
              formMik.errors.permissionField && formMik.touched.permissionField
                ? "is-invalid form-select"
                : formMik.touched.permissionField
                  ? "is-valid form-select"
                  : "form-select"
            }
            onChange={formMik.handleChange}
            onBlur={formMik.handleBlur}
            value={formMik.values.permissionField}
          >
            <option value="">
              {localizationMessage("userAndPermission.selectPermissionField.placeholder")}
            </option>
            {permissionOptionsForNonAdminUser?.map((item, i) => (
              <option
                value={item.value}
                key={i}>
                {localizationMessage(item.label)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Button className="btn form-button c-primary"
        // onClick={Add}
        type="submit"
      >
        {localizationMessage("userAndPermission.updateProjectPermission")}
      </Button>
    </form>
  );
};
export default ProjectPermissionUpdateForm;
