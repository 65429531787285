import React, { useState } from "react";
import "assets/customstyles/projects.css";
import Button from "reactstrap/lib/Button";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import TranslationServiceProviderFormModal from "./TranslationServiceProviderFormModal";
import { useIntlMessage } from "util/getIntlMessage";

const ViewTranslationServiceProviderDetail = ({ providerEntity }) => {
  const localizationMessage = useIntlMessage();

  const [modal, setModal] = useState(false);
  const [row, setRow] = useState();
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  const ShowHide = (inputID, btnID) => {
    if (document.getElementById(btnID).className !== "fas fa-eye-slash") {
      document.getElementById(inputID).type = "text";
      document.getElementById(btnID).className = "fas fa-eye-slash";
    } else {
      document.getElementById(inputID).type = "password";
      document.getElementById(btnID).className = "fas fa-eye";
    }
  };

  const updateFunc = () => {
    let translationServiceData = [];
    providerEntity.translationServiceData.forEach(item => {
      translationServiceData.push({
        key: item.key,
        value: item.value
      });
      return item;
    });
    setRow({
      name: providerEntity?.name || "",
      translationServiceData: translationServiceData
    });
  }

  return (
    <div className="plr-7 minWidth-28">
      <div className="roe-shadow-2">

        <Modal isOpen={modal} fade={false} backdrop={"static"} className="mx-auto minWidth-28 maxWidth-45" toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}>
            {localizationMessage("translationServiceProvider.translationService")}
          </ModalHeader>
          <ModalBody>
            <TranslationServiceProviderFormModal
              setmodal={setModal}
              object={row || null}
              isEdit={true}
              translationServiceDataId={providerEntity?.id}
              isDefault={providerEntity?.isDefault}
            />
          </ModalBody>
        </Modal>

        <div className="flex detail-header align-items-center row mb-2">
          <div className="col-sm-8 col-12 d-flex">
            <h4 className="grey--text detail-heading d-inline">
              {providerEntity?.name}
            </h4>
          </div>
          <div className="col-sm-4 col-12 d-flex">
            <Button
              className="c-primary py-1 mt-1 mt-sm-0 ml-auto"
              disabled={!(isSuperAdmin || false)}
              onClick={() => {
                updateFunc();
                setModal(true);
              }}
            >
              {localizationMessage("update")}
            </Button>
          </div>
        </div>
        {/* ***************** */}
        {(providerEntity && providerEntity?.translationServiceData?.length > 0) ?
          providerEntity?.translationServiceData.map((entityItem, index) => {
            const inputID = "inputID" + (index + 1);
            const btnID = "btn" + (index + 1);
            return (
              <div className="detail-description-box2" key={index}>
                <div className="detail-des row align-items-center">
                  <div className="detail-heading-small col-12 col-sm-4 c-text-dark">{entityItem?.key} :</div>
                  <div className="col-11 col-sm-8 offset-1 offset-sm-0 c-text-focus">
                    <div className="d-flex row">

                      <div className="col-12 col-sm-7 d-flex mt-2 mt-sm-0">
                        <div className="input-group minmaxWidthCustom">
                          <input
                            className="form-control password block"
                            id={inputID}
                            disabled
                            type="password"
                            value={entityItem.value}
                            // placeholder={localizationMessage("value")}
                            style={{ background: "#edf2f7" }} />
                          <span className="input-group-text togglePassword" style={{ cursor: "pointer" }} onClick={() => ShowHide(inputID, btnID)}>
                            <i id={btnID} className="fas fa-eye "></i>
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            );
          }) :
          <>
            <span className="text-center font-weight-bold text-muted mt-3 w-75 d-block mlr-auto">
              {localizationMessage("translationServiceProvider.selectedServiceDetailNotFoundMessage")}
            </span>
          </>
        }
      </div>
    </div>
  );
};

export default ViewTranslationServiceProviderDetail;
