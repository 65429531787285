import React, { useState } from "react";
import "assets/customstyles/projectStrings.css";
import { Input, InputGroup, InputGroupAddon, ModalFooter } from "reactstrap";
import * as commonStringsAction from "redux/app/commonStrings/Actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntlMessage } from "util/getIntlMessage";
import ReactMentionsInput from "components/reactmentions/ReactMentionsInput";
import { stringVariableMentionsInputTags } from "util/data/stringPlaceholderVariableMentionsTags";
import useGetProjectPermission from "views/app/useGetProjectPermission";

const CommonStringList = props => {
  const localizationMessage = useIntlMessage();
  const {currentProjectPermission} = useGetProjectPermission();
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  let {
    loadingUpdate,
    project
  } = useSelector(
    state => ({
      loadingUpdate: state?.commonString?.loadingUpdate,
      project: state?.project || {}
    }),
    shallowEqual
  );

  const languageDirection = props?.data?.languageDirection;
  const dispatch = useDispatch();
  const row = props?.data;
  const projectLanguageId = props?.projectLanguageId;
  const string_key = row?.key;
  const string_value = row?.value;
  const string_comment = row?.comment;
  const projectCommonStringId = row?.projectCommonStringId;
  const [isEdit, setIsEdit] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [deleteOneAlert, setDeleteOneAlert] = useState(false);
  const [deleteAlertCurrent, setDeleteAlertCurrent] = useState(false);
  const [deleteAlertMultiple, setDeleteAlertMultiple] = useState(false);
  const [addToProjectstringAlert, setAddToProjectstringAlert] = useState(false);

  //Initial State
  const InitialStateUpdateField = {
    value: row?.value || "",
  };

  //Validation Schema :
  const ValidationSchemaUpdateField = Yup.object().shape({
    value: Yup.string().required(" required")
  });

  //Handler for Update field submit button
  const SubmitUpdateHandler = values => {
    const payload = {
      projectCommonStringId,
      projectLanguageId : project?.projectDefaultLanguage?.id,
      ...values,
    };
    dispatch(commonStringsAction.addToProjectString(payload));
  };

  const formik = useFormik({
    initialValues: InitialStateUpdateField,
    validationSchema: ValidationSchemaUpdateField,
    onSubmit: SubmitUpdateHandler
  });

  const Delete = async option => {
    dispatch(commonStringsAction.removeByProjectCommonStringId(projectCommonStringId));
  };

  return (
    <>
      <Modal
        isOpen={deleteAlertMultiple}
        fade={false}
        toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader
          toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        >
          {localizationMessage("deleteString")}
        </ModalHeader>
        <ModalBody>{localizationMessage("deleteSingleFromConfirmationMessage")}</ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary modalButtonSize"
            onClick={() => {
              Delete("all");
              setDeleteAlertMultiple(false);
            }}
          >
            {localizationMessage("allLanguages")}
          </Button>
          <Button
            className="btn ml-2 c-primary modalButtonSize"
            onClick={() => {
              Delete("one");
              setDeleteAlertMultiple(false);
            }}
          >
            {localizationMessage("currentLanguage")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* delete current dialogbox */}
      <Modal
        isOpen={deleteAlertCurrent}
        fade={false}
        toggle={() => setDeleteAlertCurrent(!deleteAlertCurrent)}
        className="mx-auto minWidth-28 maxWidth-45"
        >
        <ModalHeader toggle={() => setDeleteAlertCurrent(!deleteAlertCurrent)}>
          <span className="mr-5">{localizationMessage("deleteOneStringFromCurrentLanguage")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlertCurrent(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              Delete("one");
              setDeleteAlertCurrent(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>


      {/* delete all dialogbox */}
      <Modal
        isOpen={deleteOneAlert}
        fade={false}
        toggle={() => setDeleteOneAlert(!deleteOneAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
        >
        <ModalHeader toggle={() => setDeleteOneAlert(!deleteOneAlert)}>
          <span >{localizationMessage("deleteOneStringFromAllLanguages")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteOneAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              // Delete("all");
              dispatch(commonStringsAction.removeByProjectCommonStringId(projectCommonStringId));
              setDeleteOneAlert(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* add to project string dialogbox */}
      <Modal
        isOpen={addToProjectstringAlert}
        fade={false}
        toggle={() => setAddToProjectstringAlert(!addToProjectstringAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setAddToProjectstringAlert(!addToProjectstringAlert)}>
          <span >{localizationMessage("projectString.commonStrings.addToProjectString")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("moveSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setAddToProjectstringAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              // Delete("all");
              const payload = {
                projectCommonStringId,
                projectLanguageId
              };
              dispatch(commonStringsAction.addToProjectString(payload));
              setAddToProjectstringAlert(false);
            }}
          >
            {localizationMessage("add")}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Edit common string alert dialogbox */}
      <Modal
        isOpen={editAlert}
        fade={false}
        toggle={() => setEditAlert(!editAlert)}
        className="mx-auto minWidth-28 maxWidth-45"
      >
        <ModalHeader toggle={() => setEditAlert(!editAlert)}>
          <span >{localizationMessage("projectString.commonStrings.editCommonStringTitle")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          <div className="">
            <p className="alert alert-warning">
              {localizationMessage("projectString.commonStrings.editClarificationMessage")}
            </p>

            <div className="">
              <div className="flex mt-2 mt-sm-0">
                <i className="fas fa-user-gear icon-commonstr" />
                <div className="input"></div>
                <div className="key">
                  <div className="overflow-hidden">{row?.key}</div>
                </div>
              </div>

              <div 
                style={{display: "flex", flexGrow: 1, marginLeft: "40px"}} 
                className="mt-2"
                >
                <div 
                  className={`custom-input ${formik?.errors?.value ? 'is-invalid' : 'is-valid'}`}
                  >
                  <ReactMentionsInput
                    fieldName={"value"} 
                    fieldValue={formik.values.value} 
                    setFieldValue={formik.setFieldValue} 
                    mentionPlaceholder={localizationMessage("value")}
                    mensionsTrigger = {"/"}
                    mentionsInputTags = {stringVariableMentionsInputTags}
                    handleChangeFromParent = {formik.handleChange}
                    setValues = {formik.setValues}
                    handleSubmit = {formik.handleSubmit}
                    />
                </div>
              </div>
            </div>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setEditAlert(false)}
          >
            {localizationMessage("cancel")}
          </Button>
        </ModalFooter>
      </Modal>

      <form name="editStringFieldForm" onSubmit={formik.handleSubmit}>
        <div>
          {isEdit ? (
            <div className="row flex edit-row">
              <div className="col-12 col-sm-4 flex v-LineForNotSmallScreen pl-2 mt-2 mt-sm-0">
                <i className="fas fa-user-gear icon-commonstr ml-2" />
                <div className="input"></div>
                <div className="key">
                  <div className="overflow-hidden">{string_key}</div>
                </div>
              </div>
              <div className="col ml-sm-0 1 mb-1 mt-1 mb-sm-0 mt-sm-0">
                <InputGroup>
                  <Input
                    autoFocus={true}
                    name="value"
                    style={languageDirection==="rtl" ? {direction: "rtl"} : {}}
                    className={
                      formik.errors.value && formik.touched.value
                        ? "is-invalid valueInputClass"
                        : formik.touched.value
                          ? "is-valid valueInputClass"
                          : "valueInputClass"
                    }
                    // onChange={formik.handleChange}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={string_value}
                  />
                  <InputGroupAddon addonType="append">
                    <LoadingButton
                      sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                      className="btnBorder rounded-0"
                      type="submit"
                      loading={loadingUpdate}
                      variant="outlined"
                    >
                      <i className="fas fa-check" />
                    </LoadingButton>
                    <LoadingButton
                      sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                      className="btnBorder leftRadius"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      <i className="fas fa-xmark" />
                    </LoadingButton>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          ) : (
            <div id="data-rowInstance" className="row flex display-row">
              <div className="col-12 col-sm-4 p-0 flex v-LineForNotSmallScreen pl-2 mt-1 mt-sm-0">
                <i className="fas fa-user-gear icon-commonstr ml-2 " />
                <div className="key overflow-hidden">{string_key}</div>
              </div>
              <div className="col-12 col-sm-8 p-0 flex pl-2 mb-1 mt-2 mb-sm-0 mt-sm-0">
                <div className="ml-4 ml-sm-0 overflow-hidden flex">
                  <div className="value" style={languageDirection==="rtl" ? {direction: "rtl", whiteSpace: "pre-wrap"} : {whiteSpace: "pre-wrap"}} >{string_value}</div>
                </div>
                { 
                  (isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission))
                  ?
                  <div className="col-sm-auto p-0 ml-auto mr-1 w-auto d-flex row align-content-start align-items-center">
                    <div className="col-12 col-sm-6 p-0 order-sm-0 order-last d-flex justify-content-end">
                      <div className="width-20 mr-0 _pr-10">
                        <button
                          // title={
                          //   entity?.projectLanguage?.isDefault !== 1
                          //     ? "translate"
                          //     : "translate all"
                          // }
                          // string_comment,
                          // onClick={() => setCommentModal(true)}
                          className={string_comment?.length > 0 ? "d-block p-0 mx-auto" : "d-none"}
                        >
                          <i className="fa-solid fa-file-lines _fs-18" style={{ color: "#757575" }}></i>
                          {/* <i style={{color: "#757575"}} className="fa-solid fa-language"></i> */}
                        </button>
                      </div>
                    </div>
                    <div className="dropdown col-12 col-sm-6 p-0 d-flex justify-content-end">
                      <button
                        className="btn btn-secondary dropdownStringButton"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-ellipsis-vertical" />
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item font-weight-bold"
                          disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                          type="button"
                          onClick={() => {
                            setAddToProjectstringAlert(true);
                          }}
                        >
                          {localizationMessage("projectString.addToProjectStrings")}
                        </button>
                        <button
                          className="dropdown-item font-weight-bold"
                          type="button"
                          onClick={() => {
                            // setIsEdit(true);
                            setEditAlert(true);
                          }}
                        >
                          {localizationMessage("edit")}
                        </button>
                        <button
                          className="dropdown-item font-weight-bold"
                          disabled={!(isSuperAdmin || ["admin", "editor"].includes(currentProjectPermission) || false)}
                          type="reset"
                          onClick={() => {
                            setDeleteOneAlert(true);
                          }}
                        >
                          {localizationMessage("delete")}
                        </button>

                        {/* <button
                          type="button"
                          className={string_comment?.length === 0 ? "dropdown-item" : "d-none"}
                          onClick={() => {
                            setCommentModal(true);
                          }}
                        >
                          Comment
                        </button> */}
                      </div>
                    </div>
                  </div>
                  :
                  null
                }

              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default CommonStringList;