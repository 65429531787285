import { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

function useGetProjectPermission() {
  const [currentProjectPermission, setCurrentProjectPermission] = useState("viewer");
  const user = JSON.parse(localStorage.getItem("user"));

  let {
    project,
  } = useSelector(
    state => ({
      project: state?.project?.projectEntity,
    }),
    shallowEqual
  );
  
  useDeepCompareEffect(()=>{
    // get the current project permission
    const permission = project?.userPermission?.find(({userId})=> user?.id === userId)?.permission;
    if(permission){
      setCurrentProjectPermission(permission);
    }
  }, [project?.userPermission]);

  return {
    currentProjectPermission
  };
}

export default useGetProjectPermission;