import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import IntlMessages from "util/intlMessages";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useHistory } from "react-router-dom";
import "assets/customstyles/pagetitle.css";
import { useIntlMessage } from "util/getIntlMessage";

const PageTitle = ({ title, middle, className, breadCrumb }) => {
  const localizationMessage = useIntlMessage();
  let history = useHistory();

  const goTo = str => {
    history.push(`/${str}`);
  };

  return (
    <Fragment>
      <div className={classNames("new-page-title", className)}>
        {title && (
          <div className={classNames("Page-title", className)}>
            <div className="title flex-1 styled-div">
              <IntlMessages id={title} />
            </div>
          </div>
        )}
        {breadCrumb && (
          <div>
            <Breadcrumb className="custom-breadcumb">
              {breadCrumb &&
                breadCrumb.map((e, i) => {
                  if (i === breadCrumb.length - 1) {
                    return (
                      <div className="styled-div text-transform-none" key={i}>
                        <BreadcrumbItem active>
                          {/* <IntlMessages id={e.name} /> */}
                          {e.localizationId ? localizationMessage(e.localizationId) : e.name||"current_page"}
                        </BreadcrumbItem>
                      </div>
                    );
                  } else if (middle && i === 1) {
                    return (
                      <div className="styled-div text-transform-none" key={i}>
                        <BreadcrumbItem className="breadcumb-color d-inline">
                          {/* <IntlMessages id={e.name} /> */}
                          {e.localizationId ? localizationMessage(e.localizationId) : e.name}
                          <div className="d-inline _ml-8 mt-auto">{">"}</div>
                        </BreadcrumbItem>
                      </div>
                    );
                  } else {
                    return (
                      <div className="styled-div text-transform-none" key={i}>
                        <BreadcrumbItem
                          onClick={() => {
                            if (e.name === "Users And Permission") goTo("usersAndPermission")
                            else if (e.name === "Translation Service Provider") goTo("provider")
                            else goTo(e.name)
                          }}
                          className="breadcumb-color d-inline"
                        >
                          {/* <IntlMessages id={e.name} /> */}
                          {e.localizationId ? localizationMessage(e.localizationId) : e.name}
                          <div className="d-inline _ml-8 mt-auto">{">"}</div>
                        </BreadcrumbItem>
                      </div>
                    );
                  }
                })}
            </Breadcrumb>
          </div>
        )}
      </div>
    </Fragment>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(PageTitle);
