import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  entity: [],
  error: null,
  isNew: true
};

export const storeListingSlice = createSlice({
  name: "storeListing",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
    },
    startCall: (state, action) => {
      state.loading = true;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
      state.isNew = action.payload.isNew;
    },
    create: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.error = null;
    },
    remove: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        return obj.id !== action.payload.id;
      });
      state.loading = false;
      state.error = null;
    },
    update: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        if (obj.id === action.payload.data.projectLanguageId) {
          let object = obj;
          object = obj;
          if (object.storeListing) {
            object.storeListing.description = action.payload.data.description;
          } else {
            object = {
              ...object,
              storeListing: { description: action.payload.data.description }
            };
          }

          return object;
        } else return obj;
      });
      state.loading = false;
      state.error = null;
    }
  }
});
