import React, { useState, useEffect } from "react";
import "assets/customstyles/projects.css";
import { Button, Input } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as userAndPermissionActions from "redux/app/userAndPermission/Actions";
import * as usersActions from "redux/app/users/Actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import MultiSelect from "../../../components/forms/MultiSelect";
import { useIntlMessage } from "util/getIntlMessage";
import { permissionOptionsForNonAdminUser } from "util/data/reactTableData";

const UserPermissionForm = ({ setModal, handleReset_searchField }) => {
  const localizationMessage = useIntlMessage();

  const [projectListArray, setProjectListArray] = useState([]);
  const dispatch = useDispatch();

  let {
    projectEntity,
    usersPerPageDefault,
    loadingButton
  } = useSelector(
    (state) => ({
      projectEntity: state.project.entity,
      usersPerPageDefault: state?.pagination?.usersPerPageDefault,
      loadingButton: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  //Initial State
  const InitialState = {
    name: "",
    emailField: "",
    passwordField: "",
    superAdminCheckboxField: false,
    projectField: [],
    permissionField: "",
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(localizationMessage("validate.required")),
    emailField: Yup.string()
      .email(localizationMessage("validate.invalidEmailAddress"))
      .required(localizationMessage("validate.emailIsRequired")),
    passwordField: Yup.string()
      .required(localizationMessage("validate.passwordIsRequired"))
      .min(8, localizationMessage("validate.passwordLengthMessage")),
    // superAdminCheckboxField: Yup.boolean().oneOf([true], "please select check box"),
    superAdminCheckboxField: Yup.boolean(),
    // projectField: Yup.array().min(1),
    projectField: Yup.array().when('superAdminCheckboxField', {
      is: false,
      then: Yup.array().min(1, localizationMessage("validate.fieldIsRequired")),
      otherwise: Yup.array(), // Optional when superAdminCheckboxField is true
    }),
    // permissionField: Yup.string().required(" required"),
    permissionField: Yup.string().when('superAdminCheckboxField', {
      is: false, // When superAdminCheckboxField is false
      then: Yup.string().required(localizationMessage("validate.fieldIsRequired")),
      otherwise: Yup.string(), // Optional when superAdminCheckboxField is true
    }),
  });

  const SubmitHandler = async values => {
    let payload = {
      name: values?.name || "",
      email: values?.emailField || "",
      password: values?.passwordField || ""
    }
    let success = false;
    const obj = { take: usersPerPageDefault, skip: 0, count: -1, order: "ASC", searchText: "" };
    // create super admin
    if(values?.superAdminCheckboxField){
      success = await dispatch(usersActions.CreateSuperAdmin(payload, setModal, obj));
    }
    // create simple user
    else{
      payload = {
        ...payload,
        projectIds: values?.projectField || [],
        permission: values?.permissionField || ""
      }
      success = await dispatch(userAndPermissionActions.create(payload, setModal, obj));
    }
    
  
    if (success) handleReset_searchField();
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  useEffect(() => {
    if (projectEntity.length > 0) {
      if (projectEntity) {
        const arr = [];
        projectEntity.map(data =>
          arr.push({ value: data?.id, label: data?.name })
        );
        setProjectListArray(arr);
      }
    }

  }, [projectEntity]);

  return (
    <form id="form" className="project-form" onSubmit={formMik.handleSubmit}>
      <div className="form-group">
        <Input
          name="name"
          placeholder={localizationMessage("userAndPermission.nameField.placeholder")}
          className={
            formMik.errors.name && formMik.touched.name
              ? "is-invalid"
              : formMik.touched.name
                ? "is-valid"
                : ""
          }
          onChange={formMik.handleChange}
          onBlur={formMik.handleBlur}
          value={formMik.values.name}
        />
      </div>

      <div className="form-group">
        <Input
          placeholder={localizationMessage("userAndPermission.emailField.placeholder")}
          type="emailField"
          className={
            formMik.errors.emailField && formMik.touched.emailField
              ? "is-invalid form-control "
              : formMik.touched.emailField
                ? "is-valid form-control "
                : "form-control "
          }
          onChange={e => {
            formMik.setFieldValue("emailField", e.target.value);
          }}
          onBlur={formMik.handleBlur}
          value={formMik.values.emailField}
          name="emailField"
        />
      </div>

      <div className="form-group">
        <Input
          type="passwordField"
          placeholder={localizationMessage("userAndPermission.passwordField.placeholder")}
          className={
            formMik.errors.passwordField && formMik.touched.passwordField
              ? "is-invalid form-control "
              : formMik.touched.passwordField
                ? "is-valid form-control "
                : "form-control "
          }
          onChange={e => {
            formMik.setFieldValue("passwordField", e.target.value);
          }}
          onBlur={formMik.handleBlur}
          value={formMik.values.passwordField}
          name="passwordField"
        />
      </div>

      {/* superAdmin checkbox */}
      <div className="form-group">
        <Input
          type="checkbox"
          id="superAdminCheckboxField"
          className={
            formMik.errors.superAdminCheckboxField && formMik.touched.superAdminCheckboxField
              ? "is-invalid form-check-input me-2"
              : formMik.touched.superAdminCheckboxField
                ? "is-valid form-check-input me-2"
                : "form-check-input me-2"
          }
          style={{position: "relative", marginLeft: "0px"}}
          onChange={e => {
            formMik.setFieldValue("superAdminCheckboxField", e.target.checked);
          }}
          onBlur={formMik.handleBlur}
          checked={formMik.values.superAdminCheckboxField}
          name="superAdminCheckboxField"
        />
        <label className="form-check-label" style={{userSelect: "none"}} htmlFor="superAdminCheckboxField">
          {localizationMessage("systemAdmin")}
        </label>
      </div>

      {
        !formMik?.values?.superAdminCheckboxField
        ?
        <>
          {/* multiple project select field using checkboxes */}
          <div className="form-group">
            <div className="form-group">
              <MultiSelect
                name="projectField"
                options={projectListArray}
                formMik={formMik}
                ListItemName="project's" // optional prop, will be shown on select like, "6 {ListItemName} selected" ==> "6 project's selected"
                placeholderValue={localizationMessage("userAndPermission.selectProjectField.placeholder")} // optional prop, if not then placeholder will be "select item's"
                noOptionsMessage={() => localizationMessage("noItemsListed")}
              />
            </div>
          </div>

          {/* ********* permission field ******** */}
          <div className="form-group">
            <div className="form-group">
              <select
                name="permissionField"
                className={
                  formMik.errors.permissionField &&
                    formMik.touched.permissionField
                    ? "is-invalid form-select"
                    : formMik.touched.permissionField
                      ? "is-valid form-select"
                      : "form-select"
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.permissionField}
              >
                <option value="">{localizationMessage("userAndPermission.selectPermissionField.placeholder")}</option>
                {permissionOptionsForNonAdminUser.map((item, i) => (
                  <option value={item.value} key={i}>
                    {localizationMessage(item.label)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
        :
        null
      }

      <Button className="btn form-button c-primary"
        // onClick={Add}
        disabled={loadingButton}
        type="submit"
      >
        {localizationMessage("userAndPermission.addUser")}
      </Button>
    </form>
  );
};
export default UserPermissionForm;
