import React, { useMemo } from "react";
import PageTitle from "components/common/PageTitle";
import ProjectsTable from "views/app/projects/ProjectsTable";
import { useDispatch } from "react-redux";
import { useSelector, shallowEqual } from "react-redux";
import * as languageActions from "redux/app/language/Actions";
import * as platformActions from "redux/app/platform/Actions";
import * as translationProviderActions from "redux/app/translation-service-provider/Actions";
import * as projectsActions from "redux/app/projects/Actions";
import { useEffect } from "react";
import Spinner from "reactstrap/lib/Spinner";
import ErrorComponent from "views/ErrorComponent";
import { useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";

function AllProjects() {
  const dispatch = useDispatch();

  /**** Redux ****/
  const {
    projectLoading,
    languageLoading,
    platformLoading,
    translationServiceProviderLoading,
    projectError,
    languageError,
    platformError,
    translationServiceProviderError,
    reduxProjectId,
    projectsPerPageDefault
  } = useSelector(
    state => ({
      projectLoading: state?.project?.loading,
      languageLoading: state?.language?.loading,
      platformLoading: state?.platform?.loading,
      translationServiceProviderLoading:
        state?.translationServiceProvider?.loading,
      projectError: state?.project?.error,
      languageError: state?.language?.error,
      platformError: state?.platform?.error,
      translationServiceProviderError: state?.translationServiceProvider?.error,
      reduxProjectId: state?.project?.projectEntity?.id || null,
      projectsPerPageDefault: state?.pagination?.projectsPerPageDefault,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(languageActions.getAll());
    dispatch(platformActions.getAll());
    dispatch(translationProviderActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(projectsActions.getAllProject({ take: projectsPerPageDefault, skip: 0, count: -1, order: "ASC", searchText: "" }, true));
  }, [dispatch, projectsPerPageDefault]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title=""
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Projects",
            localizationId: "projects.title"
          }
        ]}
      />

      {useMemo(() => {
        return (
          projectLoading || translationServiceProviderLoading || languageLoading || platformLoading ? (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "60vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          ) : null
        );
      }, [projectLoading, translationServiceProviderLoading, languageLoading, platformLoading])}

      {useMemo(() => {
        if (
          !projectLoading &&
          !languageLoading &&
          !platformLoading &&
          !translationServiceProviderLoading
        ) {
          if (projectError ||
            (languageError || platformError || translationServiceProviderError)
          ) {
            return <ErrorComponent />;
          } if (!reduxProjectId) return <ProjectsTable />;
        }
      }, [
        reduxProjectId,
        languageLoading,
        platformLoading,
        projectLoading,
        translationServiceProviderLoading,
        projectError,
        languageError,
        platformError,
        translationServiceProviderError
      ])}

    </>
  );
}

export default AllProjects;
