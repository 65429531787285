import * as requestFromServer from "./Cruds";
import { languageSlice } from "./Reducer";
const { actions } = languageSlice;

//get all records :
export const getAll = () => dispatch => {
  dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error: error.message }));
    });
};

export const clear = () => dispatch => {
  dispatch(actions.clear());
};
