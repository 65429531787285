import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  loadingCreate: false,
  loadingUpdate: false,
  fetchingData: false,
  entity: [],
  error: null
};

export const commonStringsSlice = createSlice({
  name: "commonStrings",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action?.payload?.error || "error";
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    startCall: (state, action) => {
      state.loading = true;
      // state.entity = [];
    },
    startCallAnother: (state, action) => {
      state.loading = true;
    },
    startCallCreate: (state, action) => {
      state.loadingCreate = true;
      state.fetchingData = false;
    },
    startCallUpdate: (state, action) => {
      state.loadingUpdate = true;
      state.fetchingData = false;
    },
    clear: (state, action) => {
      state.entity = [];
      state.loading = true;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    setFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
    endCall: (state, action) => {
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    getAll: (state, action) => {
      state.entity = action.payload.data;
      state.error = null;
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
    },
    create: (state, action) => {
      state.entity.push(action.payload.data);
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    remove: (state, action) => {
      // state.entity = state.entity.filter(function (obj) {
      //   // let object = { ...obj };
      //   // object.commonStringValue[0].value = 'deleted';
      //   return obj.commonStringValue[0]?.id !== action?.payload?.stringValueId;
      // });

      state.entity = state.entity.map(function (obj) {
        if (obj?.commonStringValue[0]?.id === action?.payload?.stringValueId) {
          let object = { ...obj };
          object.commonStringValue[0].value = '';
          return object;
        } else return obj;
      });

      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    removeProjectCommonString: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        if (obj?.commonStringValueId === action?.payload?.stringValueId) {
          let object = { ...obj };
          object.value = '';
          return object;
        } else return obj;
      });

      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    removeRow: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        // let object = { ...obj };
        // object.commonStringValue[0].value = 'deleted';
        return obj.commonStringValue[0]?.id !== action?.payload?.stringValueId;
      });

      // state.entity = state.entity.map(function (obj) {
      //   if (obj?.commonStringValue[0]?.id === action?.payload?.stringValueId) {
      //     let object = { ...obj };
      //     object.commonStringValue[0].value = '';
      //     return object;
      //   } else return obj;
      // });

      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    removeByProjectCommonStringId: (state, action) => {
      state.entity = state.entity.filter(function (obj) {
        return obj?.projectCommonStringId !== action?.payload?.id;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    removeAll: (state, action) => {
      state.entity = [];
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    removeAllFromCurrentLanguage: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        let object = { ...obj };
        object = {
          ...object,
          commonStringValue: ""
        };
        return object;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },

    updateProjectCommonString: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        if (obj.projectCommonStringId === action.payload.projectCommonStringId) {
          let object = obj;
          object = {
            ...obj, value: action.payload.value
          };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },

    update: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        if (obj.id === action.payload.id) {
          let object = obj;
          object = {
            ...obj, value: action.payload.data.value,
            stringKey: { ...obj.stringKey, comment: action.payload.data.comment }
          };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    },
    updateString: (state, action) => {
      state.entity = state.entity.map(function (obj) {
        if (obj?.commonStringValue[0].id === action.payload?.string_valueId) {


          let object = { ...obj };
          object.commonStringValue[0].value = action.payload?.data?.value;
          // object = {
          //   ...object,
          //   commonStringValue: [
          //     {
          //       ...object.commonStringValue[0],
          //       value: action.payload.data.value,
          //       comment: action.payload.data.comment,
          //     }
          //   ]
          // };
          // object = {
          //   ...object,
          //   commonStringValue: action.payload.data.commonStringValue
          // };
          return object;
        } else return obj;
      });
      state.loading = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.fetchingData = false;
      state.error = null;
    }
  }
});
