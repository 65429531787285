// import appLocaleData from "react-intl/locale-data/it";
import saMessages from "../locales/it_IT.json";

const saLang = {
  messages: {
    ...saMessages
  },
  locale: "it-IT",
  // data: appLocaleData
};
export default saLang;
