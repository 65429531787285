import paginationAction from "./actions";

const initState = {
  projectsPerPageDefault: 10,
  usersPerPageDefault: 5,
  logsPerPageDefault: 10
};

export function paginationReducer(state = initState, action) {
  switch (action.type) {
    case paginationAction.SET_PROJECT_ROWS:
      return {
        ...state,
        projectsPerPageDefault: action.payload
      };
    default:
      return state;
  }
}