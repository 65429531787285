import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.CommonString;

// export function getAllByVersionId(versionId) {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" },
//     where:{whatsNewVersionId:versionId},
//     relations:['projectLanguage','projectLanguage.language']
//   });
// }
// export function getAll() {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" }
//   });
// }

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function updateProjectCommonString(payload) {
  let url = API.UpdateProjectCommonString.Url;
  url = url.replace("##paramid##", `${payload?.projectCommonStringId}/${payload?.projectLanguageId}/'${payload?.value}'`);
  return axios[API.UpdateProjectCommonString.Method](url);
}



export function getAllByLangaugeId(languageId) {
  let url = API.GetAll.Url;
  url = url.replace("##paramid##", languageId);
  return axios[API.GetAll.Method](url);
}

export function getAllByprojectLangaugeId(projectLanguageId) {
  let url = API.GetAllByProjectLanguageId.Url;
  url = url.replace("##paramid##", projectLanguageId);
  return axios[API.GetAllByProjectLanguageId.Method](url);
}

export function update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}

export function downloadCurrent(languageId) {
  let url = API.DownloadCurrent.Url;
  url = url.replace("##paramid##", languageId);
  return axios[API.DownloadCurrent.Method](url, { responseType: "blob" });
}

export function downloadAll() {
  let url = API.DownloadAll.Url;
  return axios[API.DownloadAll.Method](url, { responseType: "blob" });
}

// export function remove(payload) {
//   let url = API.Delete.Url;
//   url = url.replace("##paramid##", `${payload.stringkeyId}/${payload.option}`);
//   return axios[API.Delete.Method](url);
// }

export function remove(payload) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", `${payload.stringValueId}/${payload.stringkeyId}/${payload.option}`);
  return axios[API.Delete.Method](url);
}

export function removeCommonString(option, payload) {
  let url = API.DeleteCommonString.Url;
  url = url.replace("##paramid##", option);
  return axios[API.DeleteCommonString.Method](url, payload);
}

export function removeAll() {
  let url = API.DeleteAll.Url;
  url = url.replace("##paramid##", ``);
  return axios[API.DeleteAll.Method](url);
}

export function removeAllFromCurrentLanguage(languageId) {
  let url = API.DeleteAll.Url;
  url = url.replace("##paramid##", `${languageId}`);
  return axios[API.DeleteAll.Method](url);
}

export function removeByProjectCommonStringId(projectCommonStringId) {
  let url = API.DeleteByProjectCommonStringId.Url;
  url = url.replace("##paramid##", `${projectCommonStringId}`);
  return axios[API.DeleteByProjectCommonStringId.Method](url);
}


export function addBulk(payload) {
  return axios[API.addBulk.Method](API.addBulk.Url, payload);
}

export function addFile(payload) {
  return axios[API.addFile.Method](API.addFile.Url, payload);
}

export function addToproject(payload, skipTranslation) {
  let url = API.AddToProject.Url;
  url = url.replace("##paramid##", `${skipTranslation}`);
  return axios[API.AddToProject.Method](url, payload);
}

export function addToProjectString(payload) {
  return axios[API.AddToProjectString.Method](
      API.AddToProjectString.Url, 
      payload
    );
}

export function verifyBeforeAddToProject(payload) {
  return axios[API.VerifyBeforeAddToProject.Method](API.VerifyBeforeAddToProject.Url, payload);
}

export function currentLanguageTranslateAll(payload) {
  return axios[API.currentLanguageTranslateAll.Method](
    API.currentLanguageTranslateAll.Url,
    payload
  );
}
export function currentLanguageTranslateMissing(payload) {
  return axios[API.currentLanguageTranslateMissing.Method](
    API.currentLanguageTranslateMissing.Url,
    payload
  );
}
export function allLanguageTranslateAll(payload) {
  return axios[API.allLanguageTranslateAll.Method](
    API.allLanguageTranslateAll.Url,
    payload
  );
}
export function allLanguageTranslateMissing(payload) {
  return axios[API.allLanguageTranslateMissing.Method](
    API.allLanguageTranslateMissing.Url,
    payload
  );
}

// export function removeAll() {
//   return axios[API.DeleteAll.Method](API.DeleteAll.Url);
// }








// export function remove(id) {
//   let url = API.Delete.Url;
//   url = url.replace("##paramid##", id);
//   return axios[API.Delete.Method](url);
// }
