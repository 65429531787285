import React from "react";
import "assets/customstyles/projects.css";
import Button from "reactstrap/lib/Button";
import { useIntlMessage } from "util/getIntlMessage";

const ViewPlatforms = ({ platformData, setRow, setmodal, setDeleteAlert, setPlatformDataId }) => {

  const localizationMessage = useIntlMessage();

  const styles = {
    small_heading: "detail-heading-small col-12 col-sm-4 c-text-dark whiteSpaceNoWrap"
  };
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;

  return (
    <div>
      <div className="detail-card roe-shadow-2">
        <div className="detail-header flex flex-wrap align-items-center">
          <span className="font-weight-bold my-auto">{platformData?.name}</span>
          <div className="ml-auto">
            <Button
              className="c-btn c-primary c-btn-sm maxWidth-minContent ml-2 _mt-3 _mb-3"
              disabled={!(isSuperAdmin || false) || platformData?.isDefault}
              onClick={() => {
                setRow(platformData);
                setmodal(true);
              }}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
            <Button
              className="c-btn c-danger c-btn-sm maxWidth-minContent ml-1 _mt-3 _mb-3"
              disabled={!(isSuperAdmin || false) || platformData?.isDefault}
              onClick={() => {
                setPlatformDataId(platformData?.id);
                setDeleteAlert(true)
              }}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>

        </div>
        {/* ***************** */}
        <div className="detail-description-box">
          <div className="detail-des row">
            <div className={styles.small_heading}>{localizationMessage("platforms.fileStartFormat")}:</div>
            <div className="col-11 col-sm-8 offset-1 offset-sm-0 c-text-focus">{platformData?.startFormat}</div>
          </div>
          {/* ***************** */}
          <div className="detail-des row">
            <div className={styles.small_heading}>{localizationMessage("platforms.fileEndFormat")}:</div>
            <div className="col-11 col-sm-8 offset-1 offset-sm-0 c-text-focus">{platformData?.endFormat}</div>
          </div>
          {/* ***************** */}
          <div className="detail-des row">
            <div className={styles.small_heading}>{localizationMessage("platforms.keyValuePair")}:</div>
            <div className="col-11 col-sm-8 offset-1 offset-sm-0 c-text-focus flex">
              {platformData?.iteration}
            </div>
          </div>
          {/* ***************** */}
          <div className="detail-des row">
            <div className={styles.small_heading}>{localizationMessage("platforms.keyValuePairSeparator")}:</div>
            <div className="col-11 col-sm-8 offset-1 offset-sm-0 c-text-focus flex">
              {platformData?.iterationSeprator}
            </div>
          </div>
          {/* ***************** */}
          <div className="detail-des row">
            <div className={styles.small_heading}>{localizationMessage("platforms.fileName")}:</div>
            <div className="col-11 col-sm-8 offset-1 offset-sm-0 c-text-focus flex">
              {platformData?.fileName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPlatforms;