import * as requestFromServer from "./Cruds";
import { placeHolderSlice } from "./Reducer";
const { actions } = placeHolderSlice;

//get all records :
export const getAll = () => dispatch => {
  dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error: error.message }));
    });
};

// // create a new record :
// export const create = (data) => (dispatch) => {
//   return requestFromServer
//     .create(data)
//     .then(async (response) => {
//       return dispatch(actions.create({ data: response?.data }));
//     })
//     .catch((error) => {
//      return dispatch(actions.catchError({ error: error.message }));
//     });
// };

// // delete a new record :
// export const remove = (id, enqueueSnackbar) => (dispatch) => {
//   return requestFromServer
//     .remove(id)
//     .then(async (response) => {
//       dispatch(actions.remove({ id: id }));
//       return enqueueSnackbar(getLocalizedString("successMessages.recordDeleted"), {
//         variant: "success",
//       });
//     })
//     .catch((error) => {
//       dispatch(actions.catchError({ error: error.message }));
//       return enqueueSnackbar(error.message || getLocalizedString("errorMessages.somethingWentWrong"), {
//         variant: "error",
//       });
//     });
// };
