import React, { useEffect, useLayoutEffect } from "react";
import PageTitle from "components/common/PageTitle";
import "assets/customstyles/projects.css";
import CommonStringsTable from "views/app/commonStrings/CommonStringsTable";
import * as languageActions from "redux/app/language/Actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Spinner } from "reactstrap";
import { useMemo } from "react";
import ErrorComponent from "views/ErrorComponent";
import * as projectsActions from "redux/app/projects/Actions";

const Strings = () => {

  const dispatch = useDispatch();

  let {
    languageLoading,
    languageEntity,
    languageError,
    projectLoading,
    projectError
  } = useSelector(
    state => ({
      languageLoading: state?.language?.loading,
      languageEntity: state?.language?.entity,
      languageError: state?.language?.error,
      projectLoading: state?.project?.loading,
      projectError: state?.project?.error
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(languageActions.getAll());
    dispatch(projectsActions.getAll());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title=""
        middle={true}
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "Common String",
            localizationId: "commonString.commonString"
          },
        ]}
      />

      {useMemo(() => {
        return (
          languageLoading && (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        );
      }, [languageLoading])}

      {useMemo(() => {
        if (!languageLoading && !projectLoading) {
          if (languageEntity?.length > 0 && !(languageError || projectError)) {
            return <CommonStringsTable />;
          }
          else {
            return <ErrorComponent />;
          }
        }
      }, [languageLoading, languageError, projectLoading, projectError, languageEntity])}
    </>
  );
};

export default Strings;