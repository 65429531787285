import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import * as stringsActions from "redux/app/strings/Actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "assets/customstyles/projects.css";
import Button from "reactstrap/lib/Button";
import { toastError } from "helper/toast";
import { useIntlMessage } from "util/getIntlMessage";

const BulkAddForm = ({
  projectData,
  projectSelectedLanguageId,
  setBulkAddForm
}) => {
  const localizationMessage = useIntlMessage();
  let { loadingButton, allPlatforms } = useSelector(
    state => ({
      loadingButton: state?.buttonLoading?.loading,
      allPlatforms: state?.platform?.entity || []
    }),
    shallowEqual
  );

  const [platformsArr, setPlatformArr] = useState([]);
  const [string, setString] = useState();
  const [platform, setPlatform] = useState();
  const error = useRef(null);
  const dispatch = useDispatch();

  const Add = async () => {
    if (!string || !platform) {
      document.getElementById("error-tag").style.display = "block";
    } else {
      document.getElementById("error-tag").style.display = "none";
      // const defaulLanguage = projectData?.projectLanguages.find(
      //   item => item.isDefault === 1
      // );
      if(!error.current){
        const val = {
          string: string,
          projectId: projectData?.id,
          platformId: platform,
          projectLanguageId: projectSelectedLanguageId,
          // projectLanguageId: defaulLanguage,
          Duplicate: "skip"
        };
        dispatch(stringsActions.addBulk(val, setBulkAddForm));
      }
      else{
        toastError("random", error.current)
      }
    }
  };

  useEffect(()=>{
    const platformId = platform;
    if(platformId && allPlatforms?.length>0){
      const selectedPlatform = allPlatforms.find((value, index)=>value?.id==platformId)
      const platformsFileName = selectedPlatform?.fileName;
      if(platformsFileName){
        const parts = platformsFileName.split(".")
        if (parts.length < 2) {
          error.current = localizationMessage("projectString.bulkAddPlatformFileNameError");
          toastError("random", error.current);
          // setAcceptedFileExtension("")
        } else {
          error.current = ""; // remove error if already exist.
          // Get the last part of the array which represents the extension
          const extension = parts[parts.length - 1];
          // setAcceptedFileExtension("." + extension)
          // console.log("yuurfvx: ", platformsFileName, parts);
        }
      }
    }
  }, [platform]);

  // useEffect(() => {
  //   if (projectData && projectData?.projectPlatforms) {
  //     const arr = [];
  //     projectData.projectPlatforms.forEach(data =>
  //       arr.push({ value: data?.id, label: data?.platform?.name })
  //     );
  //     setPlatformArr(arr);
  //   }
  // }, [projectData]);

  useEffect(() => {
    if (allPlatforms?.length > 0) {
      const arr = [];
      allPlatforms.map(data =>
        arr.push({ value: data?.id, label: data?.name })
      );
      setPlatformArr(arr);
    }
  }, [allPlatforms]);

  return (
    <form id="form" className="project-form">
      <div className="form-group">
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder={localizationMessage("projectString.bulkAddSelectPlatform.placeholder")}
          onChange={e => {
            setPlatform(e.value);
          }}
          options={platformsArr}
          noOptionsMessage={() => localizationMessage("noItemsListed")}
        />
      </div>

      <div className="form-group">
        <Input
          autoFocus={true}
          className="rich-input"
          type="textarea"
          rows={5}
          onChange={e => setString(e.target.value)}
          placeholder={localizationMessage("projectString.bulkAddStringField.placeholder")}
        />
      </div>

      <div className="form-group">
        <span id="error-tag">{localizationMessage("allFieldsRequired")}</span>
      </div>

      <Button
        className="btn c-primary modalButtonSize w-100"
        type="button"
        disabled={loadingButton}
        onClick={Add}
      >
        {localizationMessage("add")}
      </Button>
    </form>
  );
};

export default BulkAddForm;
