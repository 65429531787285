import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.WhatsNew;

export function getAllByVersionId(versionId) {
  return axios[API.Search.Method](API.Search.Url,
    {
      order: { projectLanguageId: "ASC" },
      where: { whatsNewVersionId: versionId },
      // relations: ["projectLanguage", "projectLanguage.language"],
      join: {
        alias: 'whatsnew',
        innerJoinAndSelect: {
          projectLanguage: 'whatsnew.projectLanguage',
          language: 'projectLanguage.language',
        },
      }
    }
  );
}

// export function getAllByVersionId(versionId) {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" },
//     where: { whatsNewVersionId: versionId },
//     relations: ["projectLanguage", "projectLanguage.language"]
//   });
// }

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function Update(id, payload) {
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}

export function Translate(payload) {
  let url = API.Translate.Url;
  return axios[API.Translate.Method](url, payload);
}

export function TranslateAll(id) {
  let url = API.TranslateAll.Url;
  url = url.replace("##paramid##", id);
  return axios[API.TranslateAll.Method](url);
}

