import React, { Fragment, useEffect } from "react";
import { Redirect } from "react-router-dom";

export const ProtectedRoute = ({ authData, children }) => {
  useEffect(() => {
  });
  return (
    <div>
      {localStorage.getItem("accessToken") ? (
        // <Route>{children}</Route>
        <Fragment>{children}</Fragment>
      ) : (
        <Redirect to={"/login"} />
      )}
    </div>
  );
};
