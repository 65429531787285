import React from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";

const LanguageSwitcher = props => {

  const intl = useIntl();

  function updateToastDirection(intl) {
    // Determine direction based on locale
    let direction = "ltr";
    if (intl.locale === "ur-PK") {
      direction = "rtl";
    }
  
    // Create or select the style element
    let styleElement = document.getElementById('dynamic-toast-styles');
    if (!styleElement) {
      styleElement = document.createElement("style");
      styleElement.id = 'dynamic-toast-styles';
      document.head.appendChild(styleElement);
    }
  
    // Get the stylesheet
    const stylesheet = styleElement.sheet;
  
    // Rule identifier
    const ruleIdentifier = '.Toastify__toast-body > div:last-child {';
  
    // Check if the rule already exists
    let ruleFound = false;
    for (let i = 0; i < stylesheet.cssRules.length; i++) {
      const rule = stylesheet.cssRules[i];
      if (rule.cssText.includes(ruleIdentifier)) {
        // Update the rule
        stylesheet.deleteRule(i);
        stylesheet.insertRule(`${ruleIdentifier} direction: ${direction}; }`, i);
        ruleFound = true;
        break;
      }
    }
  
    // If the rule was not found, add a new one
    if (!ruleFound) {
      stylesheet.insertRule(`${ruleIdentifier} direction: ${direction}; }`, stylesheet.cssRules.length);
    }
  }

  // this updates the globalIntl whenever locale changes
  useEffect(()=>{
    if(intl){
      window.globalIntl = intl;
      // update toast content direction according to currently selected language
      updateToastDirection(intl);
    }
  },[intl.locale]);
  

  const { changeLanguage, config, selectedId } = props;
  return (
    <div className="themeChangeBlock">
      {/* <h4>
        <IntlMessages id="languageSwitcher.label" />
        this is label
      </h4> */}
      <div className="themeChangeBtnWrapper">
        {config.options.map(option => {
          const { languageId, icon } = option;
          const customClass = languageId === selectedId ? "selectedTheme" : "";
          return (
            <div 
              className="d-flex align-items-center justify-content-between cursor-pointer gap-2 px-3 dropdown-item"
              onClick={async () => {
                await changeLanguage(languageId);
              }}
              key={languageId}
              style={languageId===selectedId ? {background: "#d7d7d7"} : {}}
              >
              <span 
                // style={{marginBottom: "0px"}}
                >{option?.locale || "lan_name"}
              </span>
              <img 
                src={process.env.PUBLIC_URL + icon} 
                alt="flag" 
                style={{width: "30px", border: "1px solid #434343"}}
                />
              {/* <button
                type="button"
                key={languageId}
                className={customClass}
                // onClick={() => {
                //   changeLanguage(languageId);
                // }}
              >
                <img 
                  src={process.env.PUBLIC_URL + icon} 
                  alt="flag" 
                  style={{width: "30px"}}
                  />
              </button> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
