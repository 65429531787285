import Dashboard from "views/app/Dashboard";
import Intro from "views/Intro";
import Analytics from "views/app/Analytics";
import LogoutConfirmation from "views/pages/authentication/LogoutConfirmation";
import AllProjects from "views/app/projects/AllProjects";
import ViewProject from "views/app/projects/ViewProject";
import Strings from "views/app/projects/strings/Strings";
import ProjectLogs from "views/app/projects/ProjectLogs";
import StoreListing from "views/app/projects/storelisting/StoreListing";
import WhatsNew from "views/app/projects/whatsnew/WhatsNew";
import Platform from "views/app/platforms/Platform";
import commonString from "views/app/commonStrings/Strings";
import UserPermissions from "views/app/usersAndPermissions/UserPermissions";
import ViewUserPermissions from "views/app/usersAndPermissions/ViewUserPermissions";

import TranslationServiceProvider from "views/app/translationServiceProvider/TranslationServiceProvider";
import ViewTranslationServiceProvider from "views/app/translationServiceProvider/ViewTranslationServiceProvider";
import Logs from "views/app/logs/Logs";
import Settings from "views/app/settings/Settings";
// import { Error400 } from "views/pages";


const dashboardRoutes = [


  { path: "/logout", component: LogoutConfirmation },

  // Dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard/Project", component: Analytics },
  { path: "/dashboard/String", component: Analytics },
  { path: "/dashboard/Platforms", component: Analytics },
  { path: "/dashboard/Logs", component: Analytics },
  { path: "/dashboard/Favourite", component: Analytics },
  { path: "/dashboard/Store", component: Analytics },

  // Project
  { path: "/projects", component: AllProjects },
  { path: "/projects/:id/detail", component: ViewProject },
  { path: "/projects/:id/strings", component: Strings },
  { path: "/projects/:id/logs", component: ProjectLogs },
  { path: "/projects/:id/storelisting", component: StoreListing },
  { path: "/projects/:id/whatsnew", component: WhatsNew },

  // Platform
  { path: "/platform", component: Platform },

  // Common Strings
  { path: "/commonstr", component: commonString },

  // Users & Permissions
  { path: "/usersAndPermission", component: UserPermissions },
  { path: "/usersAndPermission/:id/view", component: ViewUserPermissions },

  // Translation Service Provider
  { path: "/provider", component: TranslationServiceProvider },
  { path: "/provider/:id/view", component: ViewTranslationServiceProvider },

  // Logs
  { path: "/log", component: Logs },

  // Settings
  { path: "/settings", component: Settings },

  // Temp
  { path: "/intro", component: Intro },
  { path: "/temp", component: Dashboard },

  // Error page
  // { path: "*", component: Error400 }
  { path: "*" }

];

export default dashboardRoutes;
