import React, { useEffect, useLayoutEffect } from "react";
import PageTitle from "components/common/PageTitle";
import "assets/customstyles/projects.css";
import ProjectStringsTable from "views/app/projects/strings/ProjectStringsTable";
import * as platformActions from "redux/app/platform/Actions";
import * as projectActions from "redux/app/projects/Actions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Spinner } from "reactstrap";
import { useMemo } from "react";
import ErrorComponent from "views/ErrorComponent";

const Strings = (props) => {
  const dispatch = useDispatch();
  let projectId = props?.match?.params?.id;

  let {
    project,
    reduxProjectId,
    projectError,
    projectLoading,
  } = useSelector(
    (state) => ({
      project: state?.project?.projectEntity || null,
      reduxProjectId: state?.project?.projectEntity?.id || null,
      projectError: state?.project?.error,
      projectLoading: state?.project?.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(projectActions.getOne(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(platformActions.getAll());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      {useMemo(() => {
        return (
          <PageTitle
            title=""
            middle={true}
            className="plr-7 minWidth-28"
            breadCrumb={[
              {
                name: "Projects",
                localizationId: "projects.title"
              },
              {
                name: project?.name ? project?.name : "Project-Name"
              },
              {
                name: "Strings",
                localizationId: "projectString.strings"
              }
            ]}
          />
        );
      }, [project])}

      {useMemo(() => {
        if ((projectLoading)) {
          return (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem",
                  // zIndex: "10",
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        }
      }, [projectLoading])}

      {useMemo(() => {
        if (!projectLoading) {
          if (reduxProjectId && !projectError) {
            return <ProjectStringsTable />;
          }
          else {
            return <ErrorComponent />;
          }
        }
      }, [reduxProjectId, projectLoading, projectError])}
    </>
  );
};

export default Strings;