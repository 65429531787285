import btnLoadingAction from "./actions";

const initState = {
  loading: false,
  loadingProgress: false
};

export function btnLoadingReducer(state = initState, action) {
  switch (action.type) {
    case btnLoadingAction.SETLOADING:
      return {
        ...state,
        loading: action.payload
      };
    case btnLoadingAction.SETLOADINGPROGRESS:
      return {
        ...state,
        loadingProgress: action.payload
      };
    default:
      return state;
  }
}
