import {
  toastLoadingStart,
  toastLoadingEnd,
  // toastSuccess,
  toastError
} from "helper/toast";
import * as requestFromServer from "./Cruds";
import { projectSlice } from "./Reducer";
import btnLoadingActions from "redux/btnLoading/actions";
import { getLocalizedString } from "util/getLocalizedString";
import { UpdatePermission } from "../userAndPermission/Cruds";
const { actions } = projectSlice;

//get all records :
export const getAllStrings = id => dispatch => {
  dispatch(actions.startCall());
  return requestFromServer
    .getAllStrings(id)
    .then(async response => {
      dispatch(actions.getAllStrings({ data: response?.data || [] }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error: error.message }));
    });
};

//get all records :
export const getAll = (shouldStartCall = true) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      if (shouldStartCall) {
        const messageLocation = "--error from getAllProject--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      }
    });
};

//get all records :
export const getAllProject = (obj, shouldStartCall = false) => async (dispatch) => {
  const toastId = "fetchingDataFromServer";
  let success = false;
  await dispatch(btnLoadingActions.setLoading(true));
  const queryString = Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  if (shouldStartCall) {
    await dispatch(actions.startCall());
  }
  await requestFromServer
    .getAllProject(queryString)
    .then(async response => {
      await dispatch(actions.getAll({ data: response?.data || [] }));
      await dispatch(btnLoadingActions.setLoading(false));
      success = true;
    })
    .catch(async (error) => {
      success = false;
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      if (shouldStartCall) {
        const messageLocation = "--error from getAllProject--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      }
      await dispatch(btnLoadingActions.setLoading(false));
    });
  return success;
};

//get all records :
export const searchProject = (searchTitle) => dispatch => {
  const min = 1;
  const max = 1000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const toastId = randomNumber;
  // dispatch(actions.startCall());
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      dispatch(btnLoadingActions.setLoading(false));

      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      // const messageLocation = "--error from create--";
      // const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      // dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const addUser = (payload, setmodal) => dispatch => {
  const toastId = "addUser";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .addUser(payload)
    .then(async response => {
      // if (response?.data[0]?.userId) {
      //   dispatch(getByUserId(response?.data[0]?.userId));
      // }
      // getAllUnPermissionedUsers(payload?.projectId);
      dispatch(getOne(payload?.projectId, false));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

export const getAllUnPermissionedUsers = (projectId) => dispatch => {
  const toastId = "fetchingDataFromServer";
  // dispatch(actions.startCall());
  return requestFromServer
    .getAllUnPermissionedUsers(projectId)
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
    });
};

export const removeProjectPermission = (id) => dispatch => {
  const toastId = id || "removeProjectPermission";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .removeProjectPermission(id)
    .then(async response => {
      dispatch(actions.removeProjectPermission({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

export const getAllUnPermissionedProjects = (userId) => dispatch => {
  const toastId = "fetchingDataFromServer";
  dispatch(actions.startCall());
  return requestFromServer
    .getAllUnPermissionedProjects(userId)
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
    });
};

export const getOne = (id, shouldStartCall = true) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getOne(id)
    .then(async response => {
      let defaulLanguage = null;
      if (response?.data?.id) {
        defaulLanguage = response?.data?.projectLanguages.find(
          item => item.isDefault === 1
        );
      }
      dispatch(
        actions.getOne({
          data: response?.data || null,
          defaulLanguage: defaulLanguage || {}
        })
      );
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getOne--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const getOneBySearch = (id, shouldStartCall = true) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getOneBySearch(id)
    .then(async response => {
      let defaulLanguage = null;
      if (response?.data?.length > 0) {
        defaulLanguage = response?.data[0]?.projectLanguages.find(
          item => item.isDefault === 1
        );
      }
      dispatch(
        actions.getOneBySearch({
          data: response?.data[0] || null,
          defaulLanguage: defaulLanguage || {}
        })
      );
    })
    .catch(error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getOne--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// create a new record :
export const create = (data, setmodal, obj) => async (dispatch) => {
  const toastId = "createProject";
  let success = false;
  await dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .create(data)
    .then(async response => {
      // dispatch(actions.create({ data: response?.data }));

      setmodal(false);

      await dispatch(btnLoadingActions.setLoading(false));

      await dispatch(getAllProject(obj));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.projectCreated")
      );
      success = response?.data?.id;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      // dispatch(actions.catchError({ error: error.message }));
      await dispatch(btnLoadingActions.setLoading(false));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      success = false;
    });
    return success;
};

// update a new record :
export const update = (id, payload, setmodal) => dispatch => {
  const toastId = "fetchingDataFromServer";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .Update(id, payload)
    .then(async response => {
      dispatch(actions.update({ id: id, data: payload }));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.projectUpdated")
      );
      dispatch(getOne(id, false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      dispatch(btnLoadingActions.setLoading(false));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

// update updateProjectPermission of specific user :
export const updateProjectPermission = (id, payload) => dispatch => {
  const toastId = "fetchingDataFromServer";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return UpdatePermission (id, payload)
    .then(async response => {
      dispatch(actions.updateProjectPermission({ id: id, data: payload }));
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
      // dispatch(getOne(id, false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      dispatch(btnLoadingActions.setLoading(false));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

export const clear = () => dispatch => {
  dispatch(actions.clear());
};

// // delete a new record :
export const remove = (id) => async (dispatch) => {
  const toastId = id || "fetchingDataFromServer";
  let success = false;
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .remove(id)
    .then(async response => {
      // dispatch(actions.remove({ id: id }));
      await dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(toastId, "success", getLocalizedString("successMessages.recordDeleted"));
      // dispatch(getAllProject(payload));
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
      success = false;
    });
  return success;
};


export const removeAll = payload => dispatch => {
  const toastId = payload?.stringValueId || "fetchingDataFromServer";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .removeAll()
    .then(response => {
      // dispatch(actions.removeAll());
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};
