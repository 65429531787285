import { getLocalizedString } from "util/getLocalizedString";
import * as requestFromServer from "./Cruds";
import { stringsLogsSlice } from "./Reducer";
import {
  toastLoadingStart,
  toastLoadingEnd,
  toastSuccess,
  toastError,
  // clearToast
} from "helper/toast";
// import btnLoadingActions from "redux/btnLoading/actions";

const { actions } = stringsLogsSlice;

export const clear = () => dispatch => {
  dispatch(actions.clear());
};

export const setFetchingData = bool_val => dispatch => {
  dispatch(actions.setFetchingData(bool_val));
};

//get all strings of specific projectLanguageId
export const getAllByprojectLangaugeId = (projectLanguageId, shouldLoading = false, whichToast = "toastSimple") => dispatch => {
  let toastId = "fetchingDataFromServer";
  if (whichToast === "toastPromise"){
    toastId = projectLanguageId || "fetchingDataFromServer";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  // dispatch(actions.startCall());
  if (shouldLoading) dispatch(actions.setFetchingData(true));
  return requestFromServer
    .getAllByprojectLangaugeId(projectLanguageId)
    .then(response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
      if (whichToast === "toastPromise") toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
      if (shouldLoading) dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

export const getAllByprojectId = (projectId, paginationPayload, shouldLoading = false, whichToast = "toastSimple") => async dispatch => {
  let toastId = "fetchingDataFromServer";
  let success = false;
  if (whichToast === "toastPromise"){
    toastId = projectId || "fetchingDataFromServer";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  // dispatch(actions.startCall());
  if (shouldLoading) await dispatch(actions.setFetchingData(true));
  await requestFromServer
    .getAllByprojectId(projectId, paginationPayload)
    .then(async response => {
      if (response?.data?.length > 0) {
        const data = response.data;
        for (let i = 0; i < data.length; i++) {
          const inputDate = new Date(data[i].updatedAt);
          const outputDate = inputDate.toLocaleString('en-US', { timeZone: 'UTC', dateStyle: 'short', timeStyle: 'short', hourCycle: 'h23' });
          data[i].updatedAt = outputDate;
        }
        await dispatch(actions.getAllByprojectId({ data: data || [] }));
      }
      else {
        await dispatch(actions.getAllByprojectId({ data: response?.data }));
      }

      if (whichToast === "toastPromise") toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
      if (shouldLoading) await dispatch(actions.setFetchingData(false));
      success = true;
    })
    .catch(async (error) => {
      success = false;
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
  return success;
};

// create a new string record
export const create = (data, resetForm) => dispatch => {
  const toastId = "random";
  dispatch(actions.startCallCreate());
  return requestFromServer
    .create(data)
    .then(response => {
      resetForm();
      dispatch(actions.create({ data: response?.data }));
      toastSuccess(toastId, getLocalizedString("successMessages.recordAdded"));
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};


// update value of existing key-value pair/record
export const update = (strValueId, payload, setIsEdit, whichToast) => dispatch => {
  let toastId = "random";
  if (whichToast === "toastPromise"){
    toastId = strValueId || "random";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  dispatch(actions.startCallUpdate());
  return requestFromServer
    .update(strValueId, payload)
    .then(async response => {
      if (response?.data?.affected === 1) {
        dispatch(actions.update({ id: strValueId, data: payload }));
        if (whichToast === "toastSimple") toastSuccess(toastId, getLocalizedString("successMessages.recordUpdated"));
        if (whichToast === "toastPromise") toastLoadingEnd(
          toastId,
          "success",
          getLocalizedString("successMessages.recordUpdated")
        );
        setIsEdit(false);
      }
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};


// delete string record from spicific/all language
export const remove = (payload) => dispatch => {
  const toastId = payload || "fetchingDataFromServer";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .remove(payload)
    .then(response => {
      dispatch(actions.remove({ id: payload }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      const messageLocation = "--error from create--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};



// export const removeAll = (setDeleteConfirm, enqueueSnackbar) => dispatch => {
//   dispatch(actions.startCall());
//   return requestFromServer
//     .removeAll()
//     .then(async response => {
//       dispatch(actions.getAll({ data: [] }));
//       setDeleteConfirm(false);
//       return enqueueSnackbar("All records deleted successfully", {
//         variant: "success"
//       });
//     })
//     .catch(error => {
//       // dispatch(actions.catchError({ error: error.message }));
//       return enqueueSnackbar(getLocalizedString("successMessages.recordUpdated"), {
//         variant: "error"
//       });
//     });
// };
