import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import AuthActions from "redux/auth/actions";
const { logout } = AuthActions;

const LogoutConfirmation = props => {
  const dispatch = useDispatch();

  const userSignout = () => {
    dispatch(logout());
    props.history.push("/login");
  };

  const handleCancel = () => {
    props.history.push("/dashboard");
  };

  return (
    <>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Logout"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={userSignout}
        onCancel={handleCancel}
      >
        You can always access your content by signing in back!
      </SweetAlert>
    </>
  );
};

export default LogoutConfirmation;
