import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useIntlMessage } from 'util/getIntlMessage';

const EditPlatformPreviewPanel = ({formMik}) => {

  const localizationMessage = useIntlMessage();
  
  const [generatedPreview, setGeneratedPreview] = useState("");
  const [error, setError] = useState("");

  useEffect(()=>{
    const {startFormat, endFormat, iteration, iterationSeprator="", fileName, placeholderId} = formMik?.values;
    if(iteration && !(iteration.includes("##KEY##") && iteration.includes("##VALUE##"))){
      setError(localizationMessage("platforms.keyValuePairFormatError"));
    }
    else{
      setError("");
    }

    //generating three sample key-value pair with seperator
    let keyValuePairs = [];
    if (iteration.includes("##KEY##") && iteration.includes("##VALUE##")) {
      for (let i = 1; i <= 3; i++) {
        let temp = iteration;
        temp = temp.replace("##KEY##", `key${i}`);
        temp = temp.replace("##VALUE##", `value${i}`);
        keyValuePairs.push(temp);
      }
    }
    //reformatting keyValuePairs
    let keyValuePairsString = "";
    for (let j = 0; j < keyValuePairs.length; j++) {
      keyValuePairsString = `${keyValuePairsString}\n  ${keyValuePairs[j]}`;
      if(j != (keyValuePairs.length - 1)){
        keyValuePairsString = `${keyValuePairsString}${iterationSeprator}`;
      }
    }        

    const tempGenerated = 
          // `${startFormat}\n  ${keyValuePairs}${iterationSeprator}\n${endFormat}`;
          `${startFormat}  ${keyValuePairsString}\n${endFormat}`;
      setGeneratedPreview(tempGenerated);
  }, [formMik.values]);

  return (
    <div className='d-flex flex-column'>
      <span className='fw-bold fs-5'>{localizationMessage("platforms.previewStructure")}</span>
      <div className='mt-3'>
        <pre>{generatedPreview}</pre>
        <span style={{color: "red"}}>{error}</span>
      </div>
    </div>
  )
}

export default EditPlatformPreviewPanel
