import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import "assets/customstyles/projects.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import * as projectActions from "redux/app/projects/Actions";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useIntlMessage } from "util/getIntlMessage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProjectFormModal = ({ setModal, modal, isEdit, data, rowsPerPage, setPage, handleReset_searchField }) => {

  const localizationMessage = useIntlMessage();

  const [selectedDefaultLanguageName, setSelectedDefaultLanguageName] = useState("");

  const dispatch = useDispatch();
  //redux
  const {
    languageEntity,
    platformEntity,
    translationServiceEntity,
    loadingButton
  } = useSelector(
    state => ({
      languageEntity: state?.language?.entity,
      platformEntity: state?.platform?.entity,
      translationServiceEntity: state?.translationServiceProvider?.entity || [],
      loadingButton: state?.buttonLoading?.loading
    }),
    shallowEqual
  );

  const history = useHistory();

  const currentPlatforms = () => {
    let resultArray = [];
    if (data?.projectPlatforms?.length > 0) {
      data.projectPlatforms.forEach(x => {
        const isFound = platformEntity.find(y => y.id === x.platformId);
        if (isFound) resultArray.push(isFound);
      });
    }
    return resultArray;
  };

  //Initial State
  const InitialState = {
    name: isEdit ? data?.name : "",
    description: isEdit ? data?.description : "",
    status: isEdit ? data?.status : "active",
    defaultLanguageId: isEdit
      ? data?.projectLanguages?.find(x => x.isDefault === 1)?.language?.id
      : null,
    platformIds: isEdit ? currentPlatforms() : [],
    translationServiceId: isEdit ? data?.translationServiceId : "",
    projectlanguageIds: isEdit
      ? data?.projectLanguages?.map(item => item.language)
      : []
  };

  //Validation Schema :
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(" required"),
    // description: Yup.string().required(" required"),
    platformIds: Yup.array().min(1),
    translationServiceId: Yup.string().required(" required"),
    projectlanguageIds: Yup.array().min(1)
  });

  //Handler for submit button
  const SubmitHandler = async values => {
    //preparing payload data for API:
    let payload;
    if (isEdit) {
      payload = {
        translationServiceId: values?.translationServiceId,
        status: values?.status,
        description: values?.description,
        projectlanguageIds: [],
        platformIds: [],
        defaultLanguageId: +values?.defaultLanguageId
      };
    } else {
      handleReset_searchField();
      values.name = values?.name.trim();
      payload = {
        ...values,
        projectlanguageIds: [],
        platformIds: [],
        defaultLanguageId: +values?.defaultLanguageId
      };
    }

    values.projectlanguageIds.map(item => {
      payload.projectlanguageIds.push(+item.id);
      return item;
    });
    values.platformIds.map(item => {
      payload.platformIds.push(item.id);
      return item;
    });

    if (isEdit) {
      dispatch(projectActions.update(data?.id, payload, setModal))
    }
    else {
      const obj = { take: rowsPerPage, skip: 0, count: -1, order: "ASC", searchText: "" };
      const projectId = await dispatch(projectActions.create(payload, setModal, obj));
      if(projectId){
        setPage(0);
        const redirectTo = `/projects/${projectId}/detail`;
        history.push(redirectTo);
      }
    }
  };

  //formMik Handler
  const formMik = useFormik({
    initialValues: InitialState,
    validationSchema: ValidationSchema,
    onSubmit: SubmitHandler
  });

  useEffect(()=>{
    let languageName = formMik?.values?.projectlanguageIds.find((value, index)=>{
      return value?.id==formMik?.values?.defaultLanguageId
    })?.name;
    if(!languageName) languageName = "";
    setSelectedDefaultLanguageName(languageName);
  }, [formMik?.values?.defaultLanguageId]);
 
  return (
    <Modal
      isOpen={modal}
      fade={false}
      className="mx-auto minWidth-28 maxWidth-45"
      toggle={() => {
        setModal(!modal);
      }}
    >
      <ModalHeader
        toggle={() => {
          setModal(!modal);
        }}
      >
        {isEdit ? localizationMessage("projects.updateProject") : localizationMessage("projects.addProject")} 
      </ModalHeader>
      <ModalBody>
        <form
          id="form"
          className="project-form my-0"
          onSubmit={formMik.handleSubmit}
        >
          <div>
            {isEdit && (
              <div className="form-group d-flex mb-2">
                <ToggleButtonGroup
                  aria-label="Status"
                  sx={{ ml: "auto", mr: 0 }}
                  size="small"
                  color={
                    formMik.values.status === "active" ? "primary" : "error"
                  }
                  value={formMik.values.status}
                  exclusive
                  onChange={e => {
                    formMik.setFieldValue("status", e.target.value);
                  }}
                >
                  <ToggleButton value="active">{localizationMessage("active")}</ToggleButton>
                  <ToggleButton value="inactive">{localizationMessage("inactive")}</ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}

            <div className="form-group mb-2">
              <Input
                disabled={isEdit ? true : false}
                name="name"
                placeholder={localizationMessage("projects.name.placeholder")}
                className={
                  formMik.errors.name && formMik.touched.name
                    ? "is-invalid"
                    : formMik.touched.name
                      ? "is-valid"
                      : ""
                }
                onChange={!isEdit ? formMik.handleChange : null}
                onBlur={formMik.handleBlur}
                value={formMik.values.name}
              />
            </div>

            {/* ********* translationService ******** */}
            <div className="form-group mb-2">
              <div className="form-group">
                <select
                  name="translationServiceId"
                  className={
                    formMik.errors.translationServiceId &&
                      formMik.touched.translationServiceId
                      ? "is-invalid form-select"
                      : formMik.touched.translationServiceId
                        ? "is-valid form-select"
                        : "form-select"
                  }
                  onChange={formMik.handleChange}
                  onBlur={formMik.handleBlur}
                  value={formMik.values.translationServiceId}
                >
                  <option value="">{localizationMessage("projects.selectTService.placeholder")}</option>
                  {translationServiceEntity?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* ********* languages ******** */}
            <div className="form-group">
              <div className="form-group">
                <select
                  onBlur={formMik.handleBlur}
                  name="projectlanguageIds"
                  className={
                    formMik.errors.projectlanguageIds &&
                      formMik.touched.projectlanguageIds
                      ? "is-invalid form-select"
                      : formMik.touched.projectlanguageIds
                        ? "is-valid form-select"
                        : "form-select"
                  }
                  onChange={e => {
                    const language = JSON.parse(e.target.value);
                    let temp = formMik.values.projectlanguageIds;
                    temp.push(language);
                    formMik.setFieldValue("projectlanguageIds", temp);
                    if (!formMik.values.defaultLanguageId) {
                      formMik.setFieldValue("defaultLanguageId", language.id);
                    }
                  }}
                >
                  <option value="">{localizationMessage("projects.selectTlanguage.placeholder")}</option>
                  {languageEntity?.map((item, i) => {
                    if (
                      !formMik.values.projectlanguageIds.find(
                        findItem => findItem.id === item.id
                      )
                    ) {
                      return (
                        <option value={JSON.stringify(item)} key={item.id + i}>
                          {item.name}
                        </option>
                      );
                    } else return null;
                  })}
                </select>

                {formMik.values?.projectlanguageIds?.length ? (
                  <div
                    className="d-flex pt-3"
                    style={{
                      width: "auto",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    {formMik.values.projectlanguageIds &&
                      formMik.values.projectlanguageIds?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              background:
                                item.id === formMik.values.defaultLanguageId
                                  ? "#00f5ff"
                                  : "#bdd6f2",
                              cursor: "pointer"
                            }}
                            className="px-2 py-1 mx-1 my-1"
                          >
                            <span
                              onClick={() => {
                                formMik.setFieldValue(
                                  "defaultLanguageId",
                                  item.id
                                );
                              }}
                            >
                              {item.name}
                            </span>
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let temp = formMik.values.projectlanguageIds;
                                temp = temp.filter(
                                  tempItem => tempItem.id !== item.id
                                );
                                formMik.setFieldValue(
                                  "projectlanguageIds",
                                  temp
                                );
                                if (temp?.length > 0) {
                                  if (
                                    item.id === formMik.values.defaultLanguageId
                                  ) {
                                    formMik.setFieldValue(
                                      "defaultLanguageId",
                                      temp[0].id
                                    );                                    
                                  }
                                } else {
                                  formMik.setFieldValue(
                                    "defaultLanguageId",
                                    null
                                  );
                                }
                              }}
                              className="fa-regular fa-circle-xmark ml-2 text-danger"
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <></>
                )}

                { selectedDefaultLanguageName
                  ?
                  <div className="mx-1 mt-2 grey--text" style={{fontSize: "13px"}}>
                    <span className="font-weight-bold">{selectedDefaultLanguageName}</span>
                    <span> {localizationMessage("projects.changeLanguageDescription")}</span>
                  </div>
                  :
                  null
                }
              </div>
            </div>

            {/* ********* platform ******** */}
            <div className="form-group">
              <div className="form-group">
                <select
                  onBlur={formMik.handleBlur}
                  name="platformIds"
                  className={
                    formMik.errors.platformIds && formMik.touched.platformIds
                      ? "is-invalid form-select"
                      : formMik.touched.platformIds
                        ? "is-valid form-select"
                        : "form-select"
                  }
                  onChange={e => {
                    let temp = formMik.values.platformIds;
                    temp.push(JSON.parse(e.target.value));
                    formMik.setFieldValue("platformIds", temp);
                  }}
                >
                  <option value="">{localizationMessage("projects.selectPlatform.placeholder")}</option>

                  {platformEntity?.map((item, i) => {
                    if (
                      !formMik.values.platformIds.find(
                        findItem => findItem.id === item.id
                      )
                    ) {
                      return (
                        <option value={JSON.stringify(item)} key={item.id}>
                          {item.name}
                        </option>
                      );
                    } else return null;
                  })}
                </select>
                {formMik?.values?.platformIds?.length ? (
                  <div
                    className="d-flex pt-3"
                    style={{
                      width: "auto",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    {formMik.values.platformIds?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{ background: "#bdd6f2"}}
                          className=" px-2 py-1 mx-1 my-1"
                        >
                          {item.name}
                          <i
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let temp = formMik.values.platformIds;
                              temp = temp.filter(
                                tempItem => tempItem.id !== item.id
                              );
                              formMik.setFieldValue("platformIds", temp);
                            }}
                            className="fa-regular fa-circle-xmark ml-2 text-danger"
                          ></i>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* ********* description ******** */}
            <div className="form-group">
              <textarea
                name="description"
                className={
                  formMik.errors.description && formMik.touched.description
                    ? "is-invalid form-control input-border"
                    : formMik.touched.description
                      ? "is-valid form-control input-border"
                      : "form-control input-border"
                }
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                value={formMik.values.description}
                placeholder={localizationMessage("projects.description.placeholder")}
                rows="5"
              />
            </div>

            <Button
              disabled={loadingButton}
              className="btn form-button c-primary"
              type="submit"
            >
              {localizationMessage("submit")}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ProjectFormModal;
