import React, { useMemo, useState, useCallback, useRef } from "react";
import { useTable, useSortBy } from "react-table";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Button from "components/button/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactTableWrapper from "../../../components/reacttable/reacttbl.style";
import * as userAndPermissionActions from "redux/app/userAndPermission/Actions";
import classnames from "classnames";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import UserPermissionForm from "./UserPermissionForm";
import "assets/customstyles/users&permissions.css";
import TablePagination from '@mui/material/TablePagination';
import { useIntlMessage } from "util/getIntlMessage";
import UserRoleUpdateForm from "./UserRoleUpdateForm";

const HeaderComponent = props => {
  let classes = {
    "my-2": true,
    "mx-3": true,
    "-sort-asc": props.isSortedDesc !== undefined && !props.isSortedDesc,
    "-sort-desc": props.isSortedDesc !== undefined && props.isSortedDesc
  };
  return <div className={classnames(classes)}>{props.title}</div>;
};

const UsersProjectList = props => {
  const localizationMessage = useIntlMessage();
  const selectedRow = useRef({});

  const {
    loading,
    entity,
    userCount,
    buttonLoading,
    usersPerPageDefault,
    languageLocale
  } = useSelector(
    state => ({
      loading: state?.userAndPermission?.loading,
      entity: state?.userAndPermission?.entity?.data || [],
      userCount: state?.userAndPermission?.entity?.count || 0,
      buttonLoading: state?.buttonLoading?.loading,
      usersPerPageDefault: state?.pagination?.usersPerPageDefault,
      languageLocale: state?.languageSwitcher?.language?.locale
    }),
    shallowEqual
  );

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin || false;
  const currentUserId = JSON.parse(localStorage.getItem("user") || "{}")?.id || "";

  const tblData = entity;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(usersPerPageDefault);
  const search_useRef = useRef("");
  const [searchText, setSearchText] = useState("");

  //form
  const [modal, setmodal] = useState(false);
  const [userUpdateModal, setUserUpdateModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [row, setRow] = useState(null);

  const dispatch = useDispatch();

  const findByTitle = async (searchValue) => {
    const payload = { take: rowsPerPage, skip: page * rowsPerPage, count: -1, order: "ASC", searchText: searchValue };
    const success = await dispatch(userAndPermissionActions.getAllUserPermission(payload));
    if (success) {
      setPage(0);
    }
    setSearchText(searchValue);
  }

  const handleReset_searchField = () => {
    if (search_useRef.current) {
      search_useRef.current.value = ''; // Reset the value
    }
    setSearchText("");
  };

  const formAction = useCallback(
    (action, data = null) => {
      if (action === "edit") {
      }
      else if (action === "view") {
        if (data?.user_id) {
          props.history.push({
            pathname: `/usersAndPermission/${data?.user_id}/view`,
          });
        }
      } else if (action === "delete") {
        setRow(data);
        setDeleteConfirm(true);
      }
    },
    [props.history]
  );

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("serialNumber")} />;
        },
        accessor: "user_id",
        Cell: tableInstance => {
          return (
            <div className="react-action-class">
              {/* {+tableInstance?.row?.id + 1} */}
              {((rowsPerPage * page) + parseInt(tableInstance?.row?.id)) + 1}
            </div>
          );
        }
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("userAndPermission.userName")} />;
        },
        accessor: "user_name"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("userAndPermission.userEmail")} />;
        },
        accessor: "user_email"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("role")} />;
        },
        accessor: "super_admin",
        Cell: tableInstance => (
          <div 
            className={`react-action-class ${
              isSuperAdmin && currentUserId != tableInstance?.row?.original?.user_id
              ?
              "clickableRowStyle"
              :
              ""
            }`}
            onClick={()=>{
              if(isSuperAdmin && currentUserId != tableInstance?.row?.original?.user_id){
                selectedRow.current = tableInstance?.row?.original || {};
                setUserUpdateModal(true);
              }
            }}>
            {
              tableInstance?.row?.original?.super_admin
              ?
              localizationMessage("admin")
              :
              localizationMessage("user")
            }
          </div>
        )
      }, 
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("userAndPermission.projects")} />;
        },
        accessor: "count"
      },
      {
        Header: tableInstance => {
          return <HeaderComponent title={localizationMessage("userAndPermission.detail")} />;
        },
        accessor: "view",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className={`c-btn c-btn-sm ${ !isSuperAdmin ? "" : "c-success"}`}
              disabled={!isSuperAdmin}
              onClick={() => formAction("view", tableInstance.row.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-file-lines" />
              </div>
            </Button>
          </div>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title={localizationMessage("delete")} 
            />
          );
        },
        accessor: "delete",
        disableSortBy: true,
        Cell: tableInstance => (
          <div className="react-action-class">
            <Button
              className={`c-btn c-btn-sm ${(!isSuperAdmin || currentUserId === tableInstance?.row?.original?.user_id || tableInstance?.row?.original?.is_default) ? "" : "c-danger"}`}
              disabled={(!isSuperAdmin || currentUserId === tableInstance?.row?.original?.user_id || tableInstance?.row?.original?.is_default)}
              onClick={() => formAction("delete", tableInstance.row.original)}
            >
              <div className="_fs-14 medium-text">
                <i className="fas fa-trash-can" />
              </div>
            </Button>
          </div>
        )
      }
    ],
    [formAction, rowsPerPage, page, isSuperAdmin, languageLocale]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups
  } = useTable(
    {
      data: tblData,
      columns: columns,
      manualSortBy: true,
    },
    useSortBy
  );

  const handleChangePage = async (event, newPage) => {
    const payload = { take: rowsPerPage, skip: newPage * rowsPerPage, count: -1, order: "ASC", searchText: searchText };
    const success = await dispatch(userAndPermissionActions.getAllUserPermission(payload));
    if (success) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    const noOfRowsToShow = event.target.value;
    const payload = { take: noOfRowsToShow, skip: 0, count: -1, order: "ASC", searchText: searchText }
    const success = await dispatch(userAndPermissionActions.getAllUserPermission(payload));
    if (success) {
      setRowsPerPage(parseInt(noOfRowsToShow, 10));
      setPage(0);
    }
  };

  return (
    <>
      <Modal
        isOpen={modal}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setmodal(!modal)}>
        <ModalHeader toggle={() => setmodal(!modal)}>
          {localizationMessage("userAndPermission.addNewUser")} 
        </ModalHeader>
        <ModalBody>
          <UserPermissionForm setModal={setmodal} handleReset_searchField={handleReset_searchField} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={userUpdateModal}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setUserUpdateModal(!userUpdateModal)}>
        <ModalHeader toggle={() => setUserUpdateModal(!userUpdateModal)}>
          {localizationMessage("userAndPermission.updateUser")} {selectedRow.current?.user_email ? `"${selectedRow.current?.user_email}"` : ""}
        </ModalHeader>
        <ModalBody>
          <UserRoleUpdateForm setModal={setUserUpdateModal} row={selectedRow.current} />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteConfirm}
        fade={false}
        className="mx-auto maxWidth-45"
        toggle={() => setDeleteConfirm(!deleteConfirm)}
      >
        <ModalHeader toggle={() => setDeleteConfirm(!deleteConfirm)}>
          <span className="_mr-5">{localizationMessage("userAndPermission.deleteUser")} </span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteConfirm(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={async () => {
              setDeleteConfirm(false)
              const success_remove = await dispatch(userAndPermissionActions.removeUser(row.user_id));
              if (success_remove) {
                let payload = {};
                if (entity && entity?.length > 0) {
                  payload = { take: rowsPerPage, skip: page * rowsPerPage, count: -1, order: "ASC", searchText: searchText };
                }
                if (entity?.length === 1 && page > 0) {
                  payload = { take: rowsPerPage, skip: (page - 1) * rowsPerPage, count: -1, order: "ASC", searchText: searchText };
                }
                const success_getAllUserPermission = await dispatch(userAndPermissionActions.getAllUserPermission(payload))
                if (success_getAllUserPermission && entity?.length === 1 && page > 0) setPage(page - 1);
              }
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <ReactTableWrapper {...props}>
        <div className="plr-7 minWidth-28">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header flex">
              <div className="ml-auto">
                <Button
                  className="c-btn c-primary d-block d-sm-inline ml-auto ml-sm-2"
                  disabled={buttonLoading || !(isSuperAdmin || false)}
                  onClick={() => setmodal(!modal)}
                >
                  <i className="fas fa-plus _mr-10" />
                  {localizationMessage("userAndPermission.addNewUser")}
                </Button>
                {/* <Button className="c-btn c-primary mt-2 mt-sm-0 d-block d-sm-inline ml-auto ml-sm-2"
                  onClick={RemoveAll}
                >
                  <i className="fas fa-trash _mr-10" />
                  Remove All Users
                </Button> */}
              </div>
            </div>

            {/* search field is here */}
            <div className="ml-3 mr-3">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={localizationMessage("userAndPermission.searchByEmail")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      findByTitle(search_useRef.current.value);
                    }
                  }}
                  ref={search_useRef}
                />
                <div className="input-group-append">
                  <button
                    className="c-primary btn btn-secondary"
                    type="button"
                    disabled={buttonLoading}
                    onClick={() => {
                      findByTitle(search_useRef.current.value);
                    }}
                  >
                    {localizationMessage("search")}
                  </button>
                </div>
              </div>
            </div>

            {entity && entity.length > 0 && loading === false ?
              <div className="table-container text-center overflow-auto">
                <table
                  border={1}
                  className="custom-react-table-theme-class"
                  {...getTableProps()}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps(
                              header.getSortByToggleProps()
                            )}
                          >
                            <div>{header.render("Header")}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {rows?.length > 0 ? (
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr 
                            className={`${row?.values?.user_id===currentUserId ? "loggedInUserStyle" : ""}`} 
                            {...row.getRowProps()}
                            >
                            {row.cells.map((cell, index) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
              </div>
              : null
            }

            {
              entity?.length === 0 && (searchText.length === 0) &&
                loading === false ? (
                <div className="text-center font-weight-bold text-muted">
                  {localizationMessage("userAndPermission.addNewUserRequestMessage")}
                </div>
              ) : null
            }
            {
              entity?.length === 0 && (searchText.length > 0) &&
                loading === false ? (
                // {rows?.length === 0 && (searchText.length === 0) ? (
                <span className="text-center font-weight-bold text-muted mt-3 w-75 d-block mlr-auto">
                  {localizationMessage("userAndPermission.userNotFoundMessage")}
                </span>
              ) : null
            }
            {
              entity && entity.length > 0 && loading === false ?
                <TablePagination
                  SelectProps={{
                    disabled: buttonLoading
                  }}
                  backIconButtonProps={
                    buttonLoading
                      ? {
                        disabled: buttonLoading
                      }
                      : undefined
                  }
                  nextIconButtonProps={
                    buttonLoading
                      ? {
                        disabled: buttonLoading
                      }
                      : undefined
                  }
                  labelRowsPerPage={localizationMessage("pagination.rowsPerPage")} // Custom label
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userCount} //total no.of.rows
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> : null
            }
          </div>
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(UsersProjectList));
