import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as commonStringsAction from "redux/app/commonStrings/Actions";
import Button from "reactstrap/lib/Button";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import "assets/customstyles/projects.css";
import { useRef } from "react";
import { useIntlMessage } from "util/getIntlMessage";

const AddAllToProject = ({
  setAddToProjectAlert,
  string_keyId
}) => {
  const localizationMessage = useIntlMessage();

  let { projectEntity, fetchingData } = useSelector(
    state => ({
      projectEntity: state.project.entity,
      fetchingData: state?.commonString?.fetchingData

    }),
    shallowEqual
  );

  const [projectListArray, setProjectListArray] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [addAlertMultiple, setAddAlertMultiple] = useState(false);
  const [viewTranslationStatusArray, setViewTranslationStatusArray] = useState([]);
  const skipTranslation = useRef(true);

  const dispatch = useDispatch();

  const Add = async () => {
    if (!selectedProject) {
      document.getElementById("error-tag").style.display = "block";
    } else {
      document.getElementById("error-tag").style.display = "none";
      const payload = {
        projectId: selectedProject,
        commonStringKeyId: string_keyId
      }
      const result = await dispatch(commonStringsAction.verifyBeforeAddToProject(payload, setAddToProjectAlert));
      // result?.data?.success===true means already translated in all languages in project
      if (result?.data?.success) {
        const payload = {
          projectId: selectedProject,
          commonStringKeyId: string_keyId
        }
        dispatch(commonStringsAction.addToProject(payload, true, setAddAlertMultiple, setAddToProjectAlert));
      }
      else {
        if (result?.data?.languages) {
          skipTranslation.current = (result?.data?.skipTranslation);
          setViewTranslationStatusArray(result?.data?.languages)
          setAddAlertMultiple(true);
        }
      }
    }
  };

  useEffect(() => {
    if (projectEntity.length > 0) {
      if (projectEntity) {
        const arr = [];
        projectEntity.map(data =>
          arr.push({ value: data?.id, label: data?.name })
        );
        setProjectListArray(arr);
      }
    }
  }, [projectEntity]);

  return (
    <>
      <Modal
        isOpen={addAlertMultiple}
        fade={false}
        toggle={() => setAddAlertMultiple(!addAlertMultiple)}
        className="mx-auto modalAddToProject"
        backdrop={"static"}
        keyboard={false}
        style={{ maxWidth: '700px', width: '100%' }}
      >
        <ModalHeader
          toggle={() => setAddAlertMultiple(!addAlertMultiple)}
        >
          {localizationMessage("commonString.addStringsToProject")}
        </ModalHeader>
        <ModalBody style={{ maxHeight: '400px', height: '100%', overflowY: "scroll" }}>
          <Table>
            <thead>
              <tr>
                <th>
                  {localizationMessage("languages")}
                </th>
                <th>
                  {localizationMessage("commonString.translationStatus")}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                viewTranslationStatusArray.map((data, index) => {
                  return (
                    <tr key={index} className={data.success === true ? "table-success" : "table-danger"}>
                      <td>
                        {data.name}
                      </td>
                      <td>
                        {data.success === true 
                          ? 
                          localizationMessage("translated") 
                          : 
                          localizationMessage("notTranslated") 
                        }
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary modalButtonSize"
            disabled={fetchingData}
            onClick={() => {
              const payload = {
                projectId: selectedProject,
                commonStringKeyId: string_keyId
              }
              dispatch(commonStringsAction.addToProject(payload, true, setAddAlertMultiple, setAddToProjectAlert));
              // setAddAlertMultiple(false);
            }}
          >
            {localizationMessage("commonString.addWithoutChange")}
          </Button>
          <Button
            className="btn ml-2 c-primary modalButtonSize"
            disabled={fetchingData}
            onClick={() => {
              const payload = {
                projectId: selectedProject,
                commonStringKeyId: string_keyId
              }
              dispatch(commonStringsAction.addToProject(payload, false, setAddAlertMultiple, setAddToProjectAlert));
              // setAddAlertMultiple(false);
            }}
          >
            {localizationMessage("commonString.translateAllThenAdd")}
          </Button>
        </ModalFooter>
      </Modal>

      <form id="form" className="project-form">
        <div className="form-group">
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder={localizationMessage("commonString.selectProject")}
            onChange={e => {
              setSelectedProject(e.value);
            }}
            options={projectListArray}
            noOptionsMessage={() => localizationMessage("noItemsListed")}
          />
        </div>

        <div className="form-group">
          <span id="error-tag">{localizationMessage("aboveFieldIsRequired")}</span>
        </div>

        <Button
          className="btn c-primary modalButtonSize w-100"
          type="button"
          disabled={fetchingData}
          onClick={Add}
        >
          {localizationMessage("add")}
        </Button>
      </form>
    </>
  );
};

export default AddAllToProject;