import { auth } from "firebase/firebase.js";

export const getIdToken = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        user
          .getIdToken(true)
          .then(function(idToken) {
            resolve(idToken);
          })
          .catch(function(error) {
            resolve("");
          });
      } else {
        resolve("");
      }
    });
  });
};
