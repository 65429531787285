import * as requestFromServer from "./Cruds";
import btnLoadingActions from "redux/btnLoading/actions";
import { userAndPermissionSlice } from "./Reducer";
import {
  toastLoadingStart,
  toastLoadingEnd,
  // toastSuccess,
  toastError
} from "helper/toast";
import { getLocalizedString } from "util/getLocalizedString";
const { actions } = userAndPermissionSlice;

export const getOne = (id, shouldStartCall = true) => dispatch => {
  const toastId = "fetchingDataFromServer";
  if (shouldStartCall) dispatch(actions.startCall());
  return requestFromServer
    .getOne(id)
    .then(async response => {
      // let defaulLanguage = null;
      // if (response?.data?.id) {
      //   defaulLanguage = response?.data?.projectLanguages.find(
      //     item => item.isDefault === 1
      //   );
      // }
      // dispatch(
      //   actions.getOne({
      //     data: response?.data || null,
      //     defaulLanguage: defaulLanguage || {}
      //   })
      // );
    })
    .catch((error) => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getOne--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};


//get all records :
export const getAll = () => dispatch => {
  const toastId = "fetchingDataFromServer";
  // dispatch(actions.startCall());
  return requestFromServer
    .getAll()
    .then(async response => {
      dispatch(actions.getAll({ data: response?.data || [] }));
    })
    .catch(error => {
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      const messageLocation = "--error from getAll--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

//get all records :
export const getAllUserPermission = (obj, shouldStartCall = false) => async (dispatch) => {
  const toastId = "fetchingDataFromServer";
  let success = false;
  dispatch(btnLoadingActions.setLoading(true));
  const queryString = Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  if (shouldStartCall) dispatch(actions.startCall());
  // dispatch(actions.startCall());
  await requestFromServer
    .getAllUserPermission(queryString)
    .then(async response => {
      await dispatch(actions.getAll({ data: response?.data || [] }));
      await dispatch(btnLoadingActions.setLoading(false));
      success = true;
    })
    .catch(error => {
      success = false;

      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastError(toastId, getLocalizedString(errorMessage));
      if (shouldStartCall) {
        const messageLocation = "--error from getAllUserPermission--";
        const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
        dispatch(actions.catchError({ error: errorMessageWithLocation }));
      }

      dispatch(btnLoadingActions.setLoading(false));
    });
  return success;
};


export const getByUserId = (userId, shouldLoading = false, whichToast = "toastSimple") => dispatch => {
  let toastId = "fetchingDataFromServer";
  if (whichToast === "toastPromise"){
    toastId = userId || "fetchingDataFromServer";
    toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  }
  // dispatch(actions.startCall());
  if (shouldLoading) dispatch(actions.setFetchingData(true));
  return requestFromServer
    .getByUserId(userId)
    .then(response => {
      dispatch(actions.getOneBySearch({ data: response?.data || [] }));

      if (whichToast === "toastPromise") toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.dataFetched")
      );
      if (shouldLoading) dispatch(actions.setFetchingData(false));
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
      error?.code === "ERR_NETWORK"
        ? "errorMessages.networkOrServerError"
        : error?.message || "errorMessages.somethingWentWrong";
      if (whichToast === "toastPromise"){
        toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      }
      else{
        toastError(toastId, getLocalizedString(errorMessage));
      }
      const messageLocation = "--error from getAllByprojectLangaugeId--";
      const errorMessageWithLocation = `${errorMessage} ${messageLocation}`;
      await dispatch(actions.catchError({ error: errorMessageWithLocation }));
    });
};

// create a new record :
export const create = (data, setmodal, obj) => async (dispatch) => {
  const toastId = "createUserAndPermission";
  let success = false;
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await dispatch(btnLoadingActions.setLoading(true));
  await requestFromServer
    .create(data)
    .then(async response => {
      success = true;
      // dispatch(actions.create({ data: response?.data }));
      setmodal(false);
      await dispatch(getAllUserPermission(obj))
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
      await dispatch(btnLoadingActions.setLoading(false));
    })
    .catch(async (error) => {
      success = false;
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));

      let errorMessage = "";
      if (Array.isArray(error?.message)) {
        errorMessage = error?.message.join(' \n');
      }
      else {
        errorMessage = error?.message;
      }

      errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
    });
  return success;
};

// update a new record :
export const updateExistingUserAndPermission = (id, payload, setmodal) => dispatch => {
  const toastId = "updateExistingUserAndPermission";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .updateExistingUserAndPermission(id, payload)
    .then(async response => {
      requestFromServer.getAll()
        .then((res) => {
          setmodal(false);
          dispatch(actions.getAll({ data: res?.data || [] }));
        })
        .catch(async (error) => {
          await new Promise(resolve => setTimeout(resolve, 200));
          const errorMessage =
            error?.code === "ERR_NETWORK"
              ? "errorMessages.networkOrServerError"
              : error?.message || "errorMessages.somethingWentWrong";
          toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
        })
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

// update a new record :
export const update = (id, payload, setmodal) => dispatch => {
  const toastId = "updatePlatform";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .Update(id, payload)
    .then(async response => {
      dispatch(actions.update({ id: id, data: payload }));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

// updateUser role:
export const updateUser = (id, payload, setmodal) => dispatch => {
  const toastId = "updatePlatform";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .updateUser(id, payload)
    .then(async response => {
      dispatch(actions.updateUser({ id: id, data: payload }));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.processCompleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

export const addProject = (id, payload, setmodal) => dispatch => {
  const toastId = "addProject";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .addProject(id, payload)
    .then(async response => {
      if (response?.data[0]?.userId) {
        dispatch(getByUserId(response?.data[0]?.userId));
      }
      // getByUserId(response?.data?.userId);
      // dispatch(actions.addProject({ data: response?.data }));
      setmodal(false);
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordAdded")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};

export const updatePermission = (id, payload) => dispatch => {
  const toastId = "updatePermission";
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .UpdatePermission(id, payload)
    .then(async response => {
      dispatch(actions.updatePermission({ id: id, data: payload }));
      dispatch(btnLoadingActions.setLoading(false));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordUpdated")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      dispatch(btnLoadingActions.setLoading(false));
    });
};


// delete a new record :
export const remove = (id) => dispatch => {
  const toastId = id || "updatePermission";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .remove(id)
    .then(async response => {
      dispatch(actions.remove({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

export const removeUser = (id) => async (dispatch) => {
  const toastId = id || "removeUser";
  let success = false;
  dispatch(btnLoadingActions.setLoading(true));
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  await requestFromServer
    .removeUser(id)
    .then(async response => {
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
      await dispatch(btnLoadingActions.setLoading(false));
      // await dispatch(actions.removeUser({ id: id }));
      success = true;
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
      await dispatch(btnLoadingActions.setLoading(false));
      success = false;
    });
  return success;
};

export const removeProjectPermission = (id) => dispatch => {
  const toastId = id || "removeProjectPermission";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  return requestFromServer
    .removeProjectPermission(id)
    .then(async response => {
      dispatch(actions.removeProjectPermission({ id: id }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

//get all records :
export const removeAll = () => dispatch => {
  const toastId = "removeAll";
  toastLoadingStart(toastId, getLocalizedString("infoMessages.processingRequest"));
  // dispatch(actions.startCall());
  return requestFromServer
    .removeAll()
    .then(async response => {
      dispatch(actions.getAll({ data: [] }));
      toastLoadingEnd(
        toastId,
        "success",
        getLocalizedString("successMessages.recordsDeleted")
      );
    })
    .catch(async (error) => {
      //wait to get id(toastId) for what it started with
      await new Promise(resolve => setTimeout(resolve, 200));
      const errorMessage =
        error?.code === "ERR_NETWORK"
          ? "errorMessages.networkOrServerError"
          : error?.message || "errorMessages.somethingWentWrong";
      toastLoadingEnd(toastId, "error", getLocalizedString(errorMessage));
    });
};

export const clear = () => dispatch => {
  dispatch(actions.clear());
};

export const resetCall = () => dispatch => {
  dispatch(actions.resetCall());
};

export const setBackFlow = () => dispatch => {
  dispatch(actions.setBackFlow());
};