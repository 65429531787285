import React, { useState } from "react";
import "assets/customstyles/projectStrings.css";
import { Input, InputGroup, InputGroupAddon, ModalFooter } from "reactstrap";
import * as commonStringsAction from "redux/app/commonStrings/Actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import AddToProject from "./AddToProject";
import ReactMentionsInput from "components/reactmentions/ReactMentionsInput";
import { stringVariableMentionsInputTags } from "util/data/stringPlaceholderVariableMentionsTags";
import { useIntlMessage } from "util/getIntlMessage";

const StringList = props => {

  const localizationMessage = useIntlMessage();

  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}")?.superAdmin;
  let checked = props?.checked || false;
  let handleCheckboxChange = props?.handleCheckboxChange;

  let {
    loadingUpdate,
    userPermission
  } = useSelector(
    state => ({
      loadingUpdate: state?.commonString?.loadingUpdate,
      userPermission: state?.project?.projectEntity?.userPermission[0]?.permission || "viewer"
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const row = props?.data;
  const languageId = props?.languageId;
  const string_keyId = row?.id;
  const string_valueId = row?.commonStringValue[0]?.id;
  const string_key = row?.key;
  const string_value = row?.commonStringValue[0]?.value;
  const string_comment = row?.comment;

  const [isEdit, setIsEdit] = useState(false);
  const [deleteAlertAll, setDeleteAlertAll] = useState(false);
  const [deleteAlertCurrent, setDeleteAlertCurrent] = useState(false);
  const [addToProjectAlert, setAddToProjectAlert] = useState(false);

  const Delete = async option => {
    const val = {
      option: option,
      stringValueId: string_valueId,
      stringkeyId: string_keyId,
    };
    const success = await dispatch(commonStringsAction.remove(val));
    if (success) {
      handleCheckboxChange(); // to update the selectAll checkbox to unchecked state when any string deleted
    }
  };

  //Initial State
  const InitialStateUpdateField = {
    value: string_value || "",
  };

  //Validation Schema :
  const ValidationSchemaUpdateField = Yup.object().shape({
    value: Yup.string().required(" required")
  });

  //Handler for Update field submit button
  const SubmitUpdateHandler = values => {
    // const payload = {
    //   // key: string_key,
    //   comment: string_comment?.length > 0 ? string_comment : "",
    //   // stringKeyId: string_keyId,
    //   // languageId: string_languageId,
    //   ...values
    // };
    // dispatch(commonStringsAction.update(string_valueId, payload, setIsEdit));
    const payload = {
      key: string_key,
      comment: string_comment || "",
      // stringKeyId: string_keyId,
      languageId: languageId,
      ...values
    };
    dispatch(commonStringsAction.updateString(string_valueId, payload, setIsEdit));
  };

  const formik = useFormik({
    initialValues: InitialStateUpdateField,
    validationSchema: ValidationSchemaUpdateField,
    onSubmit: SubmitUpdateHandler
  });

  // const deleteOptions = () => {
  //   const isPrimaryLanguage =
  //     reduxString?.entity[0]?.projectLanguage?.isDefault;
  //   if (isPrimaryLanguage === 1) {
  //     setDeleteAlertAll(true);
  //   } else {
  //     setDeleteAlertMultiple(true);
  //   }
  // };

  return (
    <>
      {/* <Modal
        isOpen={deleteAlertMultiple}
        fade={false}
        toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        className="mx-auto"
      >
        <ModalHeader
          toggle={() => setDeleteAlertMultiple(!deleteAlertMultiple)}
        >
          Delete string
        </ModalHeader>
        <ModalBody>Are you sure to delete this item from?</ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary modalButtonSize"
            onClick={() => {
              Delete("all");
              setDeleteAlertMultiple(false);
            }}
          >
            All languages
          </Button>
          <Button
            className="btn ml-2 c-primary modalButtonSize"
            onClick={() => {
              Delete("one");
              setDeleteAlertMultiple(false);
            }}
          >
            Current language
          </Button>
        </ModalFooter>
      </Modal> */}

      {/* delete current dialogbox */}
      <Modal
        isOpen={deleteAlertCurrent}
        fade={false}
        toggle={() => setDeleteAlertCurrent(!deleteAlertCurrent)}
        className="mx-auto"
        style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteAlertCurrent(!deleteAlertCurrent)}>
          <span className="mr-5">{localizationMessage("deleteOneStringFromCurrentLanguage")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlertCurrent(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              Delete("one");
              setDeleteAlertCurrent(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>


      {/* delete one from all languages dialogbox */}
      <Modal
        isOpen={deleteAlertAll}
        fade={false}
        toggle={() => setDeleteAlertAll(!deleteAlertAll)}
        // className="mx-auto"
        className="mx-auto maxWidth-45"
      // style={{ width: "fit-content" }}
      >
        <ModalHeader toggle={() => setDeleteAlertAll(!deleteAlertAll)}>
          <span className="mr-5">{localizationMessage("delete")}</span>
        </ModalHeader>
        <ModalBody className="d-flex">
          {localizationMessage("deleteSingleConfirmationMessage")}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn ml-auto c-primary"
            onClick={() => setDeleteAlertAll(false)}
          >
            {localizationMessage("cancel")}
          </Button>
          <Button
            className="btn ml-2 c-primary"
            onClick={() => {
              Delete("all");
              setDeleteAlertAll(false);
            }}
          >
            {localizationMessage("delete")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={addToProjectAlert}
        fade={false}
        backdrop={"static"}
        keyboard={false}
        className="mx-auto maxWidth-45"
        toggle={() => setAddToProjectAlert(!addToProjectAlert)}
      >
        <ModalHeader toggle={() => setAddToProjectAlert(!addToProjectAlert)}>
          {localizationMessage("commonString.addToProject")}
        </ModalHeader>
        <ModalBody>
          <AddToProject
            // projectData={project}
            // projectSelectedLanguageId={
            //   projectLanguages[projectLanguageIndex]?.id
            // }
            string_keyId={[string_keyId]}
            setAddToProjectAlert={setAddToProjectAlert}
          />
        </ModalBody>
      </Modal>

      <form name="editStringFieldForm" onSubmit={formik.handleSubmit}>
        <div>
          <div>
          </div>
          {isEdit ? (
            <div className="row flex edit-row">
              <div className="col-12 col-sm-4 flex v-LineForNotSmallScreen pl-2 mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                    style={{ height: "16px", width: "16px" }}
                  />
                </div>
                <i className="fas fa-user-gear icon-commonstr ml-2" />
                <div className="input"></div>
                <div className="key">
                  <div className="overflow-hidden">{string_key}</div>
                </div>
              </div>
              <div className="col ml-sm-0 1 mb-1 mt-1 mb-sm-0 mt-sm-0">
                <div style={{display: "flex", flexGrow: 1}} >
                  {/* <Input
                    autoFocus={true}
                    name="value"
                    className={
                      formik.errors.value && formik.touched.value
                        ? "is-invalid valueInputClass"
                        : formik.touched.value
                          ? "is-valid valueInputClass"
                          : "valueInputClass"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={string_value}
                  /> */}
                  <div 
                    className={`custom-input ${formik?.errors?.value ? 'is-invalid' : 'is-valid'}`}
                    >
                    <ReactMentionsInput 
                      fieldName={"value"} 
                      fieldValue={formik.values.value} 
                      setFieldValue={formik.setFieldValue} 
                      mentionPlaceholder={localizationMessage("value")}
                      mensionsTrigger = {"/"}
                      mentionsInputTags = {stringVariableMentionsInputTags}
                      handleChangeFromParent = {formik.handleChange}
                      setValues = {formik.setValues}
                      handleSubmit = {formik.handleSubmit}
                      />
                  </div>
                  <InputGroupAddon addonType="append" style={{marginLeft: "1px"}}>
                    <LoadingButton
                      sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                      className="btnBorder rounded-0"
                      type="submit"
                      loading={loadingUpdate}
                      variant="outlined"
                    >
                      <i className="fas fa-check" />
                    </LoadingButton>
                    <LoadingButton
                      sx={{ minWidth: 40, maxWidth: 40, maxHeight: 38 }}
                      className="btnBorder leftRadius"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      <i className="fas fa-xmark" />
                    </LoadingButton>
                  </InputGroupAddon>
                </div>
              </div>
            </div>
          ) : (
            <div id="data-rowInstance" className="row flex display-row">
              <div className="col-12 col-sm-4 p-0 flex v-LineForNotSmallScreen pl-2 mt-1 mt-sm-0">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                    style={{ height: "16px", width: "16px" }}
                  />
                </div>
                
                <i className="fas fa-user-gear icon-commonstr ml-2 " />
                <div className="key overflow-hidden">{string_key}</div>
              </div>
              <div className="col-12 col-sm-8 p-0 flex pl-2 mb-1 mt-2 mb-sm-0 mt-sm-0">
                <div 
                  className="ml-4 ml-sm-0 overflow-hidden flex"
                  style={{flexGrow: 1}}
                  onDoubleClick={()=>{
                    if(isSuperAdmin){
                      setIsEdit(true);
                    }
                  }}
                  >
                  <div className="value" style={{whiteSpace: "pre-wrap"}} >{string_value}</div>
                </div>

                {/* {
                  (isSuperAdmin || ["admin", "editor"].includes(userPermission || ""))
                  ? */}
                  <div className="p-0 mr-1 w-auto d-flex row align-content-start align-items-center">
                    {/* className="width-20 col-12 col-sm-6" */}
                    <div className="col-12 col-sm-6 p-0 order-sm-0 order-last d-flex justify-content-end">
                      <div className="width-20 mr-0 _pr-10">
                        <button
                          // title={
                          //   entity?.projectLanguage?.isDefault !== 1
                          //     ? "translate"
                          //     : "translate all"
                          // }
                          // string_comment,
                          // onClick={() => setCommentModal(true)}
                          className={string_comment?.length > 0 ? "d-block p-0 mx-auto" : "d-none"}
                        >
                          <i className="fa-solid fa-file-lines _fs-18" style={{ color: "#757575" }}></i>
                          {/* <i style={{color: "#757575"}} className="fa-solid fa-language"></i> */}
                        </button>
                      </div>
                    </div>
                    <div className="dropdown col-12 col-sm-6 p-0 d-flex justify-content-end">
                      <button
                        className="btn btn-secondary dropdownStringButton"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-ellipsis-vertical" />
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setAddToProjectAlert(true);
                          }}
                        >
                          {localizationMessage("commonString.addToProject")}
                        </button>
                        <button
                          className="dropdown-item"
                          disabled={!(isSuperAdmin || false)}
                          onClick={() => {
                            setIsEdit(true);
                          }}
                        >
                          {localizationMessage("edit")}
                        </button>
                        <button
                          className="dropdown-item"
                          disabled={!(isSuperAdmin || false)}
                          type="button"
                          onClick={() => {
                            // deleteOptions();
                            if (languageId === 1) setDeleteAlertAll(true);
                            else setDeleteAlertCurrent(true);
                          }}
                        >
                          {localizationMessage("delete")}
                        </button>

                        {/* <button
                          type="button"
                          className={string_comment?.length === 0 ? "dropdown-item" : "d-none"}
                          onClick={() => {
                            setCommentModal(true);
                          }}
                        >
                          Comment
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {/* : null
                } */}

              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default StringList;