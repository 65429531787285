import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as stringsActions from "redux/app/strings/Actions";
import { Input, FormText } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "assets/customstyles/projects.css";
import { toastError } from "helper/toast";
import { useIntlMessage } from "util/getIntlMessage";

const AddFile = ({
  projectData,
  projectSelectedLanguageId,
  setFileAddForm
}) => {
  const localizationMessage = useIntlMessage();
  let { loadingButton, allPlatforms } = useSelector(
    state => ({
      loadingButton: state?.buttonLoading?.loading,
      allPlatforms: state?.platform?.entity || []
    }),
    shallowEqual
  );

  const [platformsArr, setPlatformArr] = useState([]);
  const [file, setFile] = useState();
  const [platform, setPlatform] = useState();
  const [acceptedFileExtension, setAcceptedFileExtension] = useState("");
  const dispatch = useDispatch();

  const Add = async () => {
    if (!file || !platform) {
      document.getElementById("error-tag").style.display = "block";
    } else {
      document.getElementById("error-tag").style.display = "none";
      // const defaulLanguage = projectData?.projectLanguages.find(
      //   item => item.isDefault === 1
      // );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("projectId", projectData?.id);
      formData.append("platformId", platform);
      formData.append("projectLanguageId", projectSelectedLanguageId);
      formData.append("Duplicate", "skip");

      await dispatch(stringsActions.addFile(formData, setFileAddForm));
    }
  };

  useEffect(()=>{
    const platformId = platform;
    if(platformId && allPlatforms?.length>0){
      const selectedPlatform = allPlatforms.find((value, index)=>value?.id==platformId)
      const selectedFileName = selectedPlatform?.fileName;
      if(selectedFileName){
        const parts = selectedFileName.split(".")
        if (parts.length < 2) {
          const error = localizationMessage("projectString.bulkAddPlatformFileNameError");
          toastError("random", error);
          setAcceptedFileExtension("")
        } else {
          // Get the last part of the array which represents the extension
          const extension = parts[parts.length - 1];
          setAcceptedFileExtension("." + extension)
          // console.log("yuurfvx: ", selectedFileName, parts);
        }
      }
    }
  }, [platform]);

  // useEffect(() => {
  //   if (projectData) {

  //     if (projectData?.projectPlatforms?.length > 0) {
  //       const arr = [];
  //       projectData.projectPlatforms.map(data =>
  //         arr.push({ value: data?.id, label: data?.platform?.name })
  //       );
  //       setPlatformArr(arr);
  //     }
  //   }
  // }, [projectData]);

  useEffect(() => {
    if (allPlatforms?.length > 0) {
      const arr = [];
      allPlatforms.map(data =>
        arr.push({ value: data?.id, label: data?.name })
      );
      setPlatformArr(arr);
    }
  }, [allPlatforms]);

  const validateFileType = (event)=>{
    const tempSelectedFile = event.target.files[0];

    if (!tempSelectedFile) {
      event.target.value = null;
      setFile(null);
      return;
    }

    if(tempSelectedFile){
      const tempSelectedFileNameparts = tempSelectedFile?.name.split(".");
      const tempSelectedFileExtension = "." + tempSelectedFileNameparts[tempSelectedFileNameparts.length - 1]
      if(tempSelectedFileExtension!=acceptedFileExtension){
        const error = localizationMessage("projectString.bulkAddFileExtErrorStartString") + acceptedFileExtension + localizationMessage("projectString.bulkAddFileExtErrorEndString");
        toastError("random", `${error}`)
        event.target.value = null;
        setFile(null);
      }
      else{
        //below code works when user click select right file for uploading w.r.t selected platform.
        setFile(event.target.files[0]);
      }
    }
  }

  return (
    <form id="form" className="project-form">
      <div className="form-group">
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder={localizationMessage("projectString.bulkAddSelectPlatform.placeholder")}
          onChange={e => {
            setPlatform(e.value);
            // reset file input field data
            setFile(null);
          }}
          options={platformsArr}
          noOptionsMessage={() => localizationMessage("noItemsListed")}
        />
      </div>

      <div className="form-group">
        {/* {console.log("jndvyug: ", platform)} */}
        <Input
          type="file"
          name="file"
          key={acceptedFileExtension}
          accept={acceptedFileExtension}
          disabled={!acceptedFileExtension}
          // onChange={event => {
          //   validateFileType(event);
          // }}
          onChange={event => {
            validateFileType(event);
          }}
        />
        <FormText color="muted">{localizationMessage("projectString.clickHereToUpload")}</FormText>
      </div>

      <div className="form-group">
        <span id="error-tag">{localizationMessage("allFieldsRequired")}</span>
      </div>

      <Button
        className="btn c-primary modalButtonSize w-100"
        type="button"
        disabled={loadingButton}
        onClick={Add}
      >
        {localizationMessage("add")}
      </Button>
    </form>
  );
};

export default AddFile;
