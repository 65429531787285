import axios from "axios";
// const baseUrl = process.env.REACT_APP_API_URL;
import { ApiUrl } from "services/Api";

const API = ApiUrl.TraslationServiceProvider;

export function getOne(id) {
  let url = API.GetOne.Url;
  url = url.replace("##paramid##", id);
  return axios[API.GetOne.Method](url);
}

// export function getOne(id) {
//   return axios[API.Search.Method](API.Search.Url, {
//     order: { createdAt: "ASC" },
//     where: { id: id },
//     relations: ["translationServiceData", "projects"]
//   });
// }

export function getAll() {
  return axios[API.Search.Method](API.Search.Url, {
    relations: ["translationServiceData", "projects"]
  });
}

export function create(payload) {
  return axios[API.Create.Method](API.Create.Url, payload);
}

export function update(id, payload) {
  // payload.id = id;
  let url = API.Update.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Update.Method](url, payload);
}

export function removeAll() {
  return axios[API.DeleteAll.Method](API.DeleteAll.Url);
}

export function remove(id) {
  let url = API.Delete.Url;
  url = url.replace("##paramid##", id);
  return axios[API.Delete.Method](url);
}

// export function remove(id) {
//   return axios.delete(`${baseUrl}contact-from-finder/${id}`);
// }
