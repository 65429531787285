import React, { useState } from "react";
import { loginBack, ResetIcon } from "helper/constant";
import * as Yup from "yup";
import { useFormik } from "formik";
import { checkActionCode, confirmPasswordReset, getAuth } from "firebase/auth";
import { LoadingButton } from "@mui/lab";
import AuthActions from "redux/auth/actions";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-use";
import { toastError, toastSuccess } from "helper/toast";
import { useEffect } from "react";
import { useIntlMessage } from "util/getIntlMessage";

const ResetPassword = props => {

  const localizationMessage = useIntlMessage();

  let { authLoading } = useSelector(
    state => ({
      authLoading: state?.auth?.loading
    }),
    shallowEqual
  );
  
  const location = useLocation();
  const [email, setEmail] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const oobCode = searchParams.get("oobCode");

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  }; 

  const initialValues = {
    newPassword: "",
    confirmNewPassword: ""
  };

  const SubmitHandler = async values => {
    const auth = getAuth();
    await confirmPasswordReset(auth, oobCode, values.confirmNewPassword)
      .then(response => {
        toastSuccess("random", localizationMessage("successMessages.passwordReset"));
        props.history.push("/login")
      })
      .catch(error => {
        toastError(
          "confirmResetPassword",
          error?.code==="auth/invalid-action-code" ? "Reset link invalid or expired!" :  error?.message || localizationMessage("errorMessages.somethingWentWrong")
        );
      });
  };

  // form field validation schema
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, "Password should be atleast 6 characters")
      .required("Password is Required!"),
    confirmNewPassword: Yup.string()
      .required("Password is Required!")
      .oneOf([Yup.ref("newPassword"), null], "Password doesn't matched")
  });

  //formMik Handler
  const formMik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: SubmitHandler
  });

  useEffect(()=>{

    async function getEmailFromOOB(oobCode) {
      try {
        const auth = getAuth();
        const actionCodeInfo = await checkActionCode(auth, oobCode);
        const userEmail = actionCodeInfo.data.email;
        setEmail(userEmail);
      } catch (error) {
        const errorCode = error?.code;
        let errorMessage = error?.message || localizationMessage("errorMessages.somethingWentWrong");
        if(errorCode==="auth/internal-error" || errorCode==="auth/invalid-action-code" || errorCode==="auth/expired-action-code"){
          errorMessage = localizationMessage("errorMessages.resetLinkInvalidOrExpired");
        }
        toastError(
          "getEmailFromOOB",
          errorMessage
        );
      }
    }
    getEmailFromOOB(oobCode);
  }, []);

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ResetIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Reset Password</div>
        {/* <div className="text-center form-info-text plr-24 _mt-16">
          for below account
        </div> */}
        <div className="text-center form-info-text plr-24 font-weight-bold mt-2">
          {email} &zwnj;
        </div>
        <form className="pa-24" onSubmit={formMik.handleSubmit}>

          <div className="form-group">
            {/* <label>New Password</label> */}
            <input
              type="password"
              placeholder="New Password"
              className={
                formMik.errors.newPassword && formMik.touched.newPassword
                  ? "is-invalid form-control "
                  : formMik.touched.newPassword
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.newPassword}
              name="newPassword"
            />
          </div>

          {/* ****************** */}
          <div className="form-group">
            {/* <label>Confirm New Password</label> */}
            <input
              type="password"
              placeholder="Confirm New Password"
              className={
                formMik.errors.confirmNewPassword &&
                  formMik.touched.confirmNewPassword
                  ? "is-invalid form-control "
                  : formMik.touched.confirmNewPassword
                    ? "is-valid form-control "
                    : "form-control "
              }
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              value={formMik.values.confirmNewPassword}
              name="confirmNewPassword"
            />
          </div>

          <button
            type="submit"
            disabled={authLoading}
            className="btn form-button c-primary"
          >
            Reset Password
          </button>
          <div className="text-center _mt-2">
            <span
              className="text-center link-label _mr-1 "
              onClick={() => props.history.push("/login")}
            >
              Login
            </span>
            <pre className="d-inline"> to your account</pre>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
