import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";

// const IntlMessage = props => <FormattedMessage {...props} />;

const intlMessage = props => {
  
    return (

        // <FormattedMessage {...props} />

        <FormattedMessage {...props} children={
          (message) => {
            // console.log("wefdf: ", message);
            return <>{message}</>
          }
        }/>
    )
}

export default injectIntl(intlMessage, {
  withRef: false
});



// const intlMessage = (id, defaultMessage="") => {
//   const message = intl.formatMessage({ id: id, defaultMessage: defaultMessage });

//   console.log("wefdf: ", id, default);
//   return message;
// }

// export default injectIntl(intlMessage, {
//   withRef: false
// });






// import { useIntl } from "react-intl";

// const getIntlMessage = (id, defaultMessage) => {
//     const intl = useIntl();
//     return intl.formatMessage({ id, defaultMessage });
// };

// export default getIntlMessage;

