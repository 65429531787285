import React, { useEffect, useLayoutEffect, useMemo } from "react";
import PageTitle from "components/common/PageTitle";
import PlatformsTable from "views/app/platforms/PlatformsTable";
import * as platformActions from "redux/app/platform/Actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Spinner from "reactstrap/lib/Spinner";
import ErrorComponent from "views/ErrorComponent";

function Platform() {
  const dispatch = useDispatch();

  const { platformEntity, platformLoading, platformError } = useSelector(
    state => ({
      platformEntity: state?.platform?.entity || null,
      platformLoading: state?.platform?.loading,
      platformError: state?.platform?.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(platformActions.getAll());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_REDUX" });
    };
  }, [dispatch]);

  return (
    <>
      <PageTitle
        title=""
        className="plr-7 minWidth-28"
        breadCrumb={[
          {
            name: "platforms",
            localizationId: "platforms.platform"
          }
        ]}
      />

      {useMemo(() => {
        return (
          platformLoading && (
            <div className="text-center">
              <Spinner
                color="#563c91"
                type="grow"
                style={{
                  color: "#563c91 !important",
                  textAlign: "center",
                  position: "fixed",
                  top: "55vh",
                  right: "45vw",
                  height: "3rem",
                  width: "3rem"
                }}
              >
                Loading...
              </Spinner>
            </div>
          )
        );
      }, [platformLoading])}

      {useMemo(() => {
        if (!platformLoading) {
          if (platformError && platformEntity?.length === 0) {
            return <ErrorComponent />;
          }
          if (platformEntity) return <PlatformsTable />;
        }
      }, [platformEntity, platformLoading, platformError])}
    </>
  );
}

export default Platform;
